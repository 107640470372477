/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Form, Label, Input, FormFeedback,Collapse, Button } from "reactstrap"
import classnames from "classnames";

import {Link} from "react-router-dom"

import './specification.scss'
import { toast } from "react-toastify";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from "axios";

import SweetAlert from "react-bootstrap-sweetalert"
import ReactLoading from "react-loading";
import prettyBytes from "pretty-bytes";
import { useForm } from "react-hook-form";
import axiosConfig from "axiosConfig";

    
const Deliverable = ({deliverables,getDeliverables}) => {
    const DELIVERABLE_MODAL =(deliverId)=>{
        return{
            id:deliverId,
            titleEn:'',
            titleAr:'',
            status:'new'
        }
    }
    const [active, setActive] = useState(false)
    const [msg, setMsg] = useState("")
    const [success_msg, setsuccess_msg] = useState(false)
    const [links, setLinks] = useState([DELIVERABLE_MODAL(1)])

    function handleAddRowNested() {
        const modifiedLinks = [...links]
        modifiedLinks.push(DELIVERABLE_MODAL(modifiedLinks.length + 1))
        setLinks(modifiedLinks)
    }

    function handleRemoveRow(id) {
        if (id !== 1) {
            let modifiedLinks = [...links]
            modifiedLinks = modifiedLinks.filter(x => x["id"] !== id)
            setLinks(modifiedLinks)
        }
    }
    function deleteCard(key,cardId,status){
        
        if(status==='old'){
            axiosConfig.post(`/dashboard/configurations/deliverable/delete`, {id:cardId}, {
                headers: {
                    Authorization: `Bearer ${
                        JSON.parse(localStorage.getItem("authUser")).accessToken
                    }`,
                },
                }).then(res=>{
                    
                    setMsg(`Deleted Successfully`)
                    setsuccess_msg(true)
                    handleRemoveRow(cardId)
                    // setIsLoading(false)
                }).catch(err=>{
                
                toast.error(err.response.data.message)
                // setIsLoading(false)
            })
        }else{
            handleRemoveRow(cardId)
            }
        }
    
    function informationsChange(e, key, type) {
        let modifiedLinks =[...links]
        modifiedLinks[key][type]=e.target.value
        setLinks(modifiedLinks)
    }

    function submit(e) {
        e.preventDefault();
        setActive(true)
        const formData = new FormData();
        
        links.forEach((spec,i) => {
            formData.append(`cards[${i}][titleEn]`, spec['titleEn']);
            formData.append(`cards[${i}][titleAr]`, spec['titleAr']);
        }); 

        axiosConfig.post(`/dashboard/configurations/deliverable/add`,formData ,{
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
            }
        })
        .then(res => {
            setActive(false)
            setMsg(`Saved!`)
            setsuccess_msg(true)
        }).catch(err=>{
            toast.error(err.response.data.message)
            setActive(false)
        })
    }
    useEffect(()=>{
        deliverables&& setLinks(deliverables)
    },[deliverables])


    return (
            <React.Fragment>
                <div className="container-fluid p-0">
                <Card>
                    <CardBody>
                    {success_msg ? (
                        <SweetAlert
                        title={msg}
                        success
                        confirmBtnBsStyle="success"
                        onConfirm={() => {
                            setsuccess_msg(false)
                        }}
                        onCancel={() => {
                            setsuccess_msg(false)
                        }}
                        >
                            </SweetAlert>
                        ) : null}

                    <Form className="border-0">
                        <Row>
                            <Col>
                                {/* <h2 className="mb-0 mt-3 fs-4">Links</h2> */}
                                <div className="inner-repeater">
                                {(links || []).map((formRow, key) => (
                                    <Fragment key={key}>
                                    <Row className="mt-3">
                                        <Col>
                                            <Label>Title (english)</Label>
                                            <Input 
                                                className="input_cus"
                                                placeholder="Title"
                                                value={links[key].titleEn}
                                                onChange={(e) => informationsChange(e, key,"titleEn")}
                                            />
                                        </Col>
                                        <Col>
                                            <Label>Title (arabic)</Label>
                                            <Input 
                                                className="input_cus"
                                                placeholder="عنوان"
                                                dir="rtl"
                                                value={links[key].titleAr}
                                                onChange={(e) => informationsChange(e, key,"titleAr")}
                                            />
                                        </Col>
                                    </Row>
                                    {key !== 0 ? (
                                        <Button
                                        color="none"
                                        variant="primary"
                                        className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                                        id="unknown-btn"
                                        style={{
                                            color: "#ff3838",
                                            boxShadow: "none",
                                            height: "45px",
                                            borderRadius: 0,
                                        }}
                                        onClick={e => {
                                            deleteCard(key,formRow.id,formRow?.status=='new'?'new':'old')
                                        }}
                                        >
                                        <i className="bx bx-trash-alt fs-4 p-0 mr-1"></i>{" "}
                                        Delete
                                        </Button>

                                    ) : null}
                                    {key !== 0 ? (
                                        <hr style={{ margin: "5px -20px 20px -20px" }} />
                                    ) : <hr style={{ margin: "25px -20px 20px -20px" }} />}
                                    </Fragment>
                                ))}
                                <Button
                                    onClick={() => {
                                    handleAddRowNested()
                                    }}
                                    color="none"
                                    className="mt-2 fs-5 fw-bold p-0 text-left"
                                    style={btnStyle}
                                >
                                    <i className="bx bx-plus-circle fs-4"></i>{" "}
                                    <span>Add Deliverable</span>
                                </Button>
                                
                                </div>
                            </Col>
                        </Row>

                        <hr style={{ margin: "25px -20px 20px -20px" }} />
                        <Row>
                            <Col className="d-flex justify-content-end gap-2">
                                <div className="save__btn top m-0">
                                    <button onClick={(e) => submit(e)}>{active?<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />:"Save"}</button>
                                </div>
        
                            </Col>
                        </Row>
            </Form>
            </CardBody>
        </Card>      
        </div>
    </React.Fragment>
  )
}

export default Deliverable

const btnStyle = {
    boxShadow: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    paddingLeft: "0",
}


const deleteBtn = {
    background: "transparent",
    border: "none",
    color:"#D91414",
    display: "flex",
    alignItems: "center",
    gap: "5px"
}

const editBtn = {
    background: "transparent",
    border: "none",
    color:"green",
    display: "flex",
    alignItems: "center",
    gap: "5px"
}