/* eslint-disable react/prop-types */
import React, { useEffect, useLayoutEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import axios from "axios"
import { toast } from "react-toastify"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import ReactLoading from "react-loading";
import prettyBytes from "pretty-bytes"

import { DndContext } from '@dnd-kit/core'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'
import ProductCard from "pages/Pages/Components/Products/ProductCard"
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { Toggle } from "rsuite"

const ProductsForm = ({data, dataId, indexId, col, t_col1, active, deleteLoad, handleChange, deletePage, submit, ShowItem}) => {
    const [checkedToggle, setCheckedToggle] = useState(false);
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({id: indexId});
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    useEffect(() => {
      setCheckedToggle(data[dataId].active == 1? true:false)
    }, [data])

    const toggleChange = (value) => {
      ShowItem(data[dataId].id,value?0:1)
      setCheckedToggle(!value)
    }

  return (
    <div className="accordion-item" ref={setNodeRef} style={{...style, position: "relative"}}>
                  <h2 className="accordion-header" id="headingOne">
                    <button {...attributes} {...listeners} className="dragable_btn">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 12C11 13.1046 10.1046 14 9 14C7.89543 14 7 13.1046 7 12C7 10.8954 7.89543 10 9 10C10.1046 10 11 10.8954 11 12Z" fill="black"/>
                            <path d="M17 12C17 13.1046 16.1046 14 15 14C13.8954 14 13 13.1046 13 12C13 10.8954 13.8954 10 15 10C16.1046 10 17 10.8954 17 12Z" fill="black"/>
                            <path d="M11 6C11 7.10457 10.1046 8 9 8C7.89543 8 7 7.10457 7 6C7 4.89543 7.89543 4 9 4C10.1046 4 11 4.89543 11 6Z" fill="black"/>
                            <path d="M17 6C17 7.10457 16.1046 8 15 8C13.8954 8 13 7.10457 13 6C13 4.89543 13.8954 4 15 4C16.1046 4 17 4.89543 17 6Z" fill="black"/>
                            <path d="M11 18C11 19.1046 10.1046 20 9 20C7.89543 20 7 19.1046 7 18C7 16.8954 7.89543 16 9 16C10.1046 16 11 16.8954 11 18Z" fill="black"/>
                            <path d="M17 18C17 19.1046 16.1046 20 15 20C13.8954 20 13 19.1046 13 18C13 16.8954 13.8954 16 15 16C16.1046 16 17 16.8954 17 18Z" fill="black"/>
                        </svg>
                    </button>
                    <button
                      className={classnames(
                        "accordion-button",
                        "fw-medium",
                        "fw-bold",
                        "fs-5",
                        { collapsed: !col[dataId] }
                      )}
                      type="button"
                      onClick={() => t_col1(dataId)}
                      style={{ cursor: "pointer", paddingLeft: "70px",paddingRight: "40px", position: "relative" }}
                    >
                      {data[dataId].titleEn} 
                    </button>
                    {/* checked={project.status === 1?true:false} onChange={(value) => {ShowItem(project.id,value)}} */}
                    <button className="toggle_button_drag">
                      <Toggle size="sm" checked={checkedToggle} onClick={(value) => toggleChange(checkedToggle)}/>
                    </button>
                    <button  className="delete_btn" onClick={(e) => deletePage(dataId)}>
                    <svg width="22" height="22" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.8333 4.33341C10.235 4.33341 9.75 4.81844 9.75 5.41675H16.25C16.25 4.81844 15.765 4.33341 15.1667 4.33341H10.8333ZM18.4167 7.58341H7.58333V18.4167C7.58333 20.2117 9.03841 21.6667 10.8333 21.6667H15.1667C16.9616 21.6667 18.4167 20.2117 18.4167 18.4167V7.58341ZM5.41667 7.58341V18.4167C5.41667 21.4083 7.84179 23.8334 10.8333 23.8334H15.1667C18.1582 23.8334 20.5833 21.4083 20.5833 18.4167V7.58341H21.6667C22.265 7.58341 22.75 7.09839 22.75 6.50008C22.75 5.90177 22.265 5.41675 21.6667 5.41675H18.4167C18.4167 3.62182 16.9616 2.16675 15.1667 2.16675H10.8333C9.03841 2.16675 7.58333 3.62182 7.58333 5.41675H4.33333C3.73502 5.41675 3.25 5.90177 3.25 6.50008C3.25 7.09839 3.73502 7.58341 4.33333 7.58341H5.41667Z" fill="#DE0000"/>
                    </svg>
                    </button>
                  </h2>
                  <Collapse isOpen={col[dataId]} className="accordion-collapse">
                    <div className="accordion-body">
                      <Form className="border-0 p-0">
                      <Row>
                        <Col xs="12">
                          <div className="mb-3">
                            <Label htmlFor="productdesc">Image</Label>
                            <Input
                              type="file"
                              className="form-control form-control-lg "
                              onChange={e => handleChange(e.target.files[0], dataId, "image")}
                              style={{ borderRadius: 0 }}
                            />
                          </div>
                        </Col>
                        <Col xs="12">
                          <div className="dropzone-previews" id="file-previews">
                            {data[dataId].image ? (
                              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={data[dataId].image.name}
                                        src={data[dataId].image?.preview||data[dataId].image}
                                        style={{ objectFit: "cover" }}
                                      />
                                    </Col>
                                    <Col>
                                      <Link to="#" className="text-muted ">
                                        {data[dataId].image?.name}
                                      </Link>
                                    </Col>
                                    <Col>
                                      <div className="image__options">
                                        <button
                                          className="p-0"
                                          type="button"
                                          onClick={e =>
                                            prevSelectImage(e, formRow?.image.preview?formRow?.image.preview:formRow?.image)
                                          }
                                        >
                                          <i className="far fa-eye  fs-5 p-0"></i>
                                        </button>
                                        <button
                                          type="button"
                                          style={{ color: "#ff3838" }}
                                          onClick={e =>
                                            setData(d => d.map((d,i) => i== dataId?{...d, image:null}: d))
                                          }
                                        >
                                          <i className="bx bx-trash fs-4"></i>
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            ) : null}
                          </div>
                        </Col>
                        </Row>
                       

                        <Row>
                          <Col>
                            <div className="mb-3 mt-3">
                              <Label htmlFor="productdesc">Website Name (English)</Label>
                              <Input
                                name="titleEn"
                                type="text"
                                className="form-control input_cus"
                                placeholder="e.g.Name"
                                value={data[dataId].titleEn}
                                onChange= {e => handleChange(e.target.value, dataId, "titleEn")}
                              />
                              <FormFeedback className="fs-6">
                                This Fileld Is Required!
                              </FormFeedback>
                            </div>
                          </Col>
                          <Col>
                            <div className="mb-3">
                            <Label htmlFor="productdesc">Website Name (Arabic)</Label>
                              <Input
                                name="titleAr"
                                type="text"
                                className="form-control input_cus"
                                placeholder="e.g.Name"
                                value={data[dataId].titleAr}
                                onChange= {e => handleChange(e.target.value, dataId, "titleAr")}
                              />
                              <FormFeedback className="fs-6">
                                This Fileld Is Required!
                              </FormFeedback>
                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3 mb-3">
                          <Col sm="6">
                            <div>
                              <Label htmlFor="productdesc">
                                Description (English)
                              </Label>
                              <textarea
                                className="form-control  mb-0"
                                name="descriptionEn"
                                id="specification"
                                rows="5"
                                placeholder="Enter your description here"
                                value={data[dataId].descriptionEn}
                                onChange={e => handleChange(e.target.value, dataId, "descriptionEn")}
                              />
                            </div>
                          </Col>
                          <Col sm="6">
                            <div>
                              <Label htmlFor="productdesc">Description (Arabic)</Label>
                              <textarea
                                className="form-control mb-0"
                                name="descriptionAr"
                                id="specification"
                                rows="5"
                                placeholder="اكتب وصف هنا"
                                value={data[dataId].descriptionAr}
                                onChange={e => handleChange(e.target.value, dataId, "descriptionAr")}
                                dir="rtl"
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col>
                            <div className="d-flex">
                              <Label htmlFor="colorID"> Color</Label>
                            </div>
                            <div className="d-flex">
                              <input
                                type="color"
                                className="form-control colorInput"
                                name="Color"
                                value={data[dataId].color}
                                onChange={e => handleChange(e.target.value, dataId, "color")}
                              />
                              <Input
                                type="text"
                                className="color__name rounded-0"
                                name="Color"
                                value={data[dataId].color}
                                onChange={e => handleChange(e.target.value, dataId, "color")}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="mb-3">
                              <Label htmlFor="productdesc">Link</Label>
                              <Input
                                id="title"
                                name="link"
                                type="text"
                                className="form-control input_cus"
                                placeholder="e.g.link"
                                value={data[dataId].link}
                                onChange= {e => handleChange(e.target.value, dataId, "link")}
                              />
                              <FormFeedback className="fs-6">
                                This Fileld Is Required!
                              </FormFeedback>
                            </div>
                          </Col>
                        </Row>
                        
                        <Row>
                          <Col className="d-flex justify-content-end gap-2">
                            <div className="save__btn top m-0 mt-2">
                              <button type="button" onClick={(e) => submit(e, dataId)}>{active?<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />:"Save"}</button>
                            </div>
                            {/* <div className="delete__btn top m-0 mt-2">
                              <button type="button" onClick={(e) => deletePage(dataId)}>{deleteLoad?<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />:"Delete"}</button>
                            </div> */}
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Collapse>
                </div>
  )
}

export default ProductsForm