import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import ReactTagInput from "@pathofdev/react-tag-input"
import Lightbox from "react-image-lightbox"
import prettyBytes from "pretty-bytes"
import axiosConfig from "axiosConfig"
import SweetAlert from "react-bootstrap-sweetalert"
import { toast } from "react-toastify"

const Process = ({sectionData}) => {
  const [active, setActive] = useState(false)
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState(null)
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")

  const [cardOneTitleEn, setCardOneTitleEn] = useState("")
  const [cardOneTitleAr, setCardOneTitleAr] = useState("")
  const [cardOnePointsEn, setCardOnePointsEn] = useState([])
  const [cardOnePointsAr, setCardOnePointsAr] = useState([])
  const [cardOneImage, setCardOneImage] = useState(null)

  const [cardTwoTitleEn, setCardTwoTitleEn] = useState("")
  const [cardTwoTitleAr, setCardTwoTitleAr] = useState("")
  const [cardTwoPointsEn, setCardTwoPointsEn] = useState([])
  const [cardTwoPointsAr, setCardTwoPointsAr] = useState([])
  const [cardTwoImage, setCardTwoImage] = useState(null)

  const [cardThreeTitleEn, setCardThreeTitleEn] = useState("")
  const [cardThreeTitleAr, setCardThreeTitleAr] = useState("")
  const [cardThreePointsEn, setCardThreePointsEn] = useState([])
  const [cardThreePointsAr, setCardThreePointsAr] = useState([])
  const [cardThreeImage, setCardThreeImage] = useState(null)
 
  const [cardFourTitleEn,  setCardFourTitleEn] = useState("")
  const [cardFourTitleAr,  setCardFourTitleAr] = useState("")
  const [cardFourPointsEn, setCardFourPointsEn] = useState([])
  const [cardFourPointsAr, setCardFourPointsAr] = useState([])
  const [cardFourImage, setCardFourImage] = useState(null)


  function handleImage(e, files, type) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    type == 1?setCardOneImage(Object.values(files)[0]):type == 2?setCardTwoImage(Object.values(files)[0]):type == 4?setCardFourImage(Object.values(files)[0]):setCardThreeImage(Object.values(files)[0])
    e.target.value = ""
  }

  function prevSelectImage(e, prev) {
    e.preventDefault()
    setPrev(prev)
    setisOpen(!isOpen)
  }

  function submit(e) {
    e.preventDefault()
    const formData = new FormData()
    const data = {
      titleEn,
      titleAr,
      descriptionEn: descEn,
      descriptionAr: descAr,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    //
    formData.append("cards[0][titleEn]", cardOneTitleEn)
    formData.append("cards[0][titleAr]", cardOneTitleAr)
    formData.append("cards[0][ProcessPointsEn]", [])
    formData.append("cards[0][ProcessPointsAr]", [] )
    formData.append("cards[0][image]", cardOneImage)
    cardOnePointsEn.forEach((point,pointIndex)=>{
      formData.append(`cards[0][ProcessPointsEn][${pointIndex}]`, point)
    })
    cardOnePointsAr.forEach((point,pointIndex)=>{
      formData.append(`cards[0][ProcessPointsAr][${pointIndex}]`, point)
    })
    
    formData.append("cards[1][titleEn]", cardTwoTitleEn)
    formData.append("cards[1][titleAr]", cardTwoTitleAr)
    formData.append("cards[1][ProcessPointsEn]", [] )
    formData.append("cards[1][ProcessPointsAr]", [] )
    formData.append("cards[1][image]", cardTwoImage)
    cardTwoPointsEn.forEach((point,pointIndex)=>{
      formData.append(`cards[1][ProcessPointsEn][${pointIndex}]`, point)
    })
    cardTwoPointsAr.forEach((point,pointIndex)=>{
      formData.append(`cards[1][ProcessPointsAr][${pointIndex}]`, point)
    })

    formData.append("cards[2][titleEn]", cardThreeTitleEn)
    formData.append("cards[2][titleAr]", cardThreeTitleAr)
    formData.append("cards[2][ProcessPointsEn]", [] )
    formData.append("cards[2][ProcessPointsAr]", [] )
    formData.append("cards[2][image]", cardThreeImage)
    cardThreePointsEn.forEach((point,pointIndex)=>{
      formData.append(`cards[2][ProcessPointsEn][${pointIndex}]`, point)
    })
    cardThreePointsAr.forEach((point,pointIndex)=>{
      formData.append(`cards[2][ProcessPointsAr][${pointIndex}]`, point)
    })

    formData.append("cards[3][titleEn]", cardFourTitleEn)
    formData.append("cards[3][titleAr]", cardFourTitleAr)
    formData.append("cards[3][ProcessPointsEn]", [] )
    formData.append("cards[3][ProcessPointsAr]", [] )
    formData.append("cards[3][image]", cardFourImage)
    cardFourPointsEn.forEach((point,pointIndex)=>{
      formData.append(`cards[3][ProcessPointsEn][${pointIndex}]`, point)
    })
    cardFourPointsAr.forEach((point,pointIndex)=>{
      formData.append(`cards[3][ProcessPointsAr][${pointIndex}]`, point)
    })

    // formData.append("cardOneImage", cardOneImage)
    // formData.append("cardTwoImage", cardTwoImage)
    // formData.append("cardThreeImage", cardThreeImage)
    setIsLoading(true)

    axiosConfig.post(`/dashboard/homePage/processDesignSkills`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res=>{
      //
       toast.success(res.data.message)
      setsuccess_msg(true)
      setIsLoading(false)
    }).catch(err=>{
      //
      toast.error(err.response.data.message)
      setIsLoading(false)
    })
  }

  
  useEffect(()=>{
    setTitleEn(sectionData?.titleEn)
    setTitleAr(sectionData?.titleAr)
    setDescEn(sectionData?.descriptionEn)
    setDescAr(sectionData?.descriptionAr)
    //

    setCardOneTitleEn(sectionData?.cards[0]?.titleEn)
    setCardOneTitleAr(sectionData?.cards[0]?.titleAr)
    
    sectionData?.cards[0]?.ProcessPointsEn&& setCardOnePointsEn(sectionData?.cards[0]?.ProcessPointsEn)
    sectionData?.cards[0]?.ProcessPointsAr&& setCardOnePointsAr(sectionData?.cards[0]?.ProcessPointsAr)
    setCardOneImage(sectionData?.cards[0]?.image)

    setCardTwoTitleEn(sectionData?.cards[1]?.titleEn)
    setCardTwoTitleAr(sectionData?.cards[1]?.titleAr)
    sectionData?.cards[1]?.ProcessPointsEn && setCardTwoPointsEn(sectionData?.cards[1]?.ProcessPointsEn)
    sectionData?.cards[1]?.ProcessPointsAr && setCardTwoPointsAr(sectionData?.cards[1]?.ProcessPointsAr)
    setCardTwoImage(sectionData?.cards[1]?.image)

    setCardThreeTitleEn(sectionData?.cards[2]?.titleEn)
    setCardThreeTitleAr(sectionData?.cards[2]?.titleAr)
    sectionData?.cards[2]?.ProcessPointsAr && setCardThreePointsAr(sectionData?.cards[2]?.ProcessPointsAr)
    sectionData?.cards[2]?.ProcessPointsEn && setCardThreePointsEn(sectionData?.cards[2]?.ProcessPointsEn)
    setCardThreeImage(sectionData?.cards[2]?.image)

    setCardFourTitleEn(sectionData?.cards[3]?.titleEn)
    setCardFourTitleAr(sectionData?.cards[3]?.titleAr)
    sectionData?.cards[3]?.ProcessPointsEn && setCardFourPointsEn(sectionData?.cards[3]?.ProcessPointsEn)
    sectionData?.cards[3]?.ProcessPointsAr && setCardFourPointsAr(sectionData?.cards[3]?.ProcessPointsAr)
    setCardFourImage(sectionData?.cards[3]?.image)
  },[sectionData])

  return (
    <div>
      {success_msg ? (
        <SweetAlert
          title={msg}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        >
        </SweetAlert>
      ) : null}
      {isOpen ? (
        <Lightbox
          mainSrc={prev}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
      <Form className="border-0 pb-2">
      <Row className="">
          <h3 className="fs-4 text-black fw-bolder mb-3">Header Content</h3>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (english)</Label>
              <Input
                id="itemnameen"
                name="itemnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={titleEn}
                onChange={e => setTitleEn(e.target.value)}
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (arabic)</Label>
              <Input
                id="itemnamear"
                name="itemnamear"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. "
                value={titleAr}
                onChange={e => setTitleAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (English)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="Enter your description here"
                value={descEn}
                onChange={e => setDescEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (Arabic)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="اكتب وصف هنا"
                value={descAr}
                onChange={e => setDescAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>
        <div className="mt-2 pt-4">
          <hr style={{ margin: "0px -20px 20px -20px" }} />

          <h3 className="fs-4 text-black fw-bolder mb-3">Card (01)</h3>
          <Row>
            <Col xs="12">
              <div className="">
                <Label htmlFor="productdesc">Image</Label>
                <Input
                  type="file"
                  className="form-control form-control-lg "
                  onChange={e => handleImage(e, e.target.files,1)}
                  style={{ borderRadius: 0 }}
                  multiple
                />
              </div>
            </Col>
            <Col xs="12">
              <div className="dropzone-previews" id="file-previews">
                {cardOneImage ? (
                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={cardOneImage.name}
                            src={cardOneImage?.preview?cardOneImage?.preview:cardOneImage}
                            style={{ objectFit: "cover" }}
                          />
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted ">
                            {cardOneImage.name}
                          </Link>
                        </Col>
                        <Col>
                          <div className="image__options">
                            <button
                              className="p-0"
                              onClick={e =>
                                prevSelectImage(e, cardOneImage?.preview?cardOneImage?.preview:cardOneImage)
                              }
                            >
                              <i className="far fa-eye  fs-5 p-0"></i>
                            </button>
                            <button
                              style={{ color: "#ff3838" }}
                              onClick={e => setCardOneImage(null)}
                            >
                              <i className="bx bx-trash fs-4"></i>
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Title (english)</Label>
                <Input
                  id="itemnameen"
                  name="itemnameen"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g. Focus Keypharse"
                  value={cardOneTitleEn}
                  onChange={e => setCardOneTitleEn(e.target.value)}
                />
              </div>
            </Col>

            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Title (arabic)</Label>
                <Input
                  id="itemnamear"
                  name="itemnamear"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. "
                  value={cardOneTitleAr}
                  onChange={e => setCardOneTitleAr(e.target.value)}
                  dir="rtl"
                  lang="ar"
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Process Points (english)</Label>
                <ReactTagInput
                  tags={cardOnePointsEn}
                  editable={true}
                  placeholder="e.g Title"
                  onChange={newTags => setCardOnePointsEn(newTags)}
                />
              </div>
            </Col>

            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Process Points (arabic)</Label>
                <ReactTagInput
                  tags={cardOnePointsAr}
                  editable={true}
                  placeholder="e.g Title"
                  onChange={newTags => setCardOnePointsAr(newTags)}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="mt-2 pt-4">
          <hr style={{ margin: "0px -20px 20px -20px" }} />
          <h3 className="fs-4 text-black fw-bolder mb-3">Card (02)</h3>
          <Row>
            <Col xs="12">
              <div className="">
                <Label htmlFor="productdesc">Image</Label>
                <Input
                  type="file"
                  className="form-control form-control-lg "
                  onChange={e => handleImage(e, e.target.files,2)}
                  style={{ borderRadius: 0 }}
                  multiple
                />
              </div>
            </Col>
            <Col xs="12">
              <div className="dropzone-previews" id="file-previews">
                {cardTwoImage ? (
                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={cardTwoImage.name}
                            src={cardTwoImage?.preview?cardTwoImage?.preview:cardTwoImage}
                            style={{ objectFit: "cover" }}
                          />
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted ">
                            {cardTwoImage.name}
                          </Link>
                        </Col>
                        <Col>
                          <div className="image__options">
                            <button
                              className="p-0"
                              onClick={e =>
                                prevSelectImage(e, cardTwoImage?.preview?cardTwoImage?.preview:cardTwoImage)
                              }
                            >
                              <i className="far fa-eye  fs-5 p-0"></i>
                            </button>
                            <button
                              style={{ color: "#ff3838" }}
                              onClick={e => setCardTwoImage(null)}
                            >
                              <i className="bx bx-trash fs-4"></i>
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Title (english)</Label>
                <Input
                  id="itemnameen"
                  name="itemnameen"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g. Focus Keypharse"
                  value={cardTwoTitleEn}
                  onChange={e => setCardTwoTitleEn(e.target.value)}
                />
              </div>
            </Col>

            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Title (arabic)</Label>
                <Input
                  id="itemnamear"
                  name="itemnamear"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. "
                  value={cardTwoTitleAr}
                  onChange={e => setCardTwoTitleAr(e.target.value)}
                  dir="rtl"
                  lang="ar"
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Process Points (english)</Label>
                <ReactTagInput
                  tags={cardTwoPointsEn}
                  editable={true}
                  placeholder="e.g Title"
                  onChange={newTags => setCardTwoPointsEn(newTags)}
                />
              </div>
            </Col>

            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Process Points (arabic)</Label>
                <ReactTagInput
                  tags={cardTwoPointsAr}
                  editable={true}
                  placeholder="e.g Title"
                  onChange={newTags => setCardTwoPointsAr(newTags)}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="mt-2 pt-4">
          <hr style={{ margin: "0px -20px 20px -20px" }} />

          <h3 className="fs-4 text-black fw-bolder mb-3">Card (03)</h3>
          <Row>
            <Col xs="12">
              <div className="">
                <Label htmlFor="productdesc">Image</Label>
                <Input
                  type="file"
                  className="form-control form-control-lg "
                  onChange={e => handleImage(e, e.target.files,3)}
                  style={{ borderRadius: 0 }}
                  multiple
                />
              </div>
            </Col>
            <Col xs="12">
              <div className="dropzone-previews" id="file-previews">
                {cardThreeImage ? (
                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={cardThreeImage.name}
                            src={cardTwoImage?.preview?cardTwoImage?.preview:cardTwoImage}
                            style={{ objectFit: "cover" }}
                          />
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted ">
                            {cardThreeImage.name}
                          </Link>
                        </Col>
                        <Col>
                          <div className="image__options">
                            <button
                              className="p-0"
                              onClick={e =>
                                prevSelectImage(e, cardTwoImage?.preview?cardTwoImage?.preview:cardTwoImage)
                              }
                            >
                              <i className="far fa-eye  fs-5 p-0"></i>
                            </button>
                            <button
                              style={{ color: "#ff3838" }}
                              onClick={e => setCardThreeImage(null)}
                            >
                              <i className="bx bx-trash fs-4"></i>
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Title (english)</Label>
                <Input
                  id="itemnameen"
                  name="itemnameen"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g. Focus Keypharse"
                  value={cardThreeTitleEn}
                  onChange={e => setCardThreeTitleEn(e.target.value)}
                />
              </div>
            </Col>

            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Title (arabic)</Label>
                <Input
                  id="itemnamear"
                  name="itemnamear"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. "
                  value={cardThreeTitleAr}
                  onChange={e => setCardThreeTitleAr(e.target.value)}
                  dir="rtl"
                  lang="ar"
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Process Points (english)</Label>
                <ReactTagInput
                  tags={cardThreePointsEn}
                  editable={true}
                  placeholder="e.g Title"
                  onChange={newTags => setCardThreePointsEn(newTags)}
                />
              </div>
            </Col>

            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Process Points (arabic)</Label>
                <ReactTagInput
                  tags={cardThreePointsAr}
                  editable={true}
                  placeholder="e.g Title"
                  onChange={newTags => setCardThreePointsAr(newTags)}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="mt-2 pt-4">
          <hr style={{ margin: "0px -20px 20px -20px" }} />

          <h3 className="fs-4 text-black fw-bolder mb-3">Card (04)</h3>
          <Row>
            <Col xs="12">
              <div className="">
                <Label htmlFor="productdesc">Image</Label>
                <Input
                  type="file"
                  className="form-control form-control-lg "
                  onChange={e => handleImage(e, e.target.files,4)}
                  style={{ borderRadius: 0 }}
                  multiple
                />
              </div>
            </Col>
            <Col xs="12">
              <div className="dropzone-previews" id="file-previews">
                {cardFourImage ? (
                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={cardFourImage.name}
                            src={cardFourImage?.preview?cardFourImage?.preview:cardFourImage}
                            style={{ objectFit: "cover" }}
                          />
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted ">
                            {cardFourImage.name}
                          </Link>
                        </Col>
                        <Col>
                          <div className="image__options">
                            <button
                              className="p-0"
                              onClick={e =>
                                prevSelectImage(cardFourImage?.preview?cardFourImage?.preview:cardFourImage)
                              }
                            >
                              <i className="far fa-eye  fs-5 p-0"></i>
                            </button>
                            <button
                              style={{ color: "#ff3838" }}
                              onClick={e => setCardThreeImage(null)}
                            >
                              <i className="bx bx-trash fs-4"></i>
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Title (english)</Label>
                <Input
                  id="itemnameen"
                  name="itemnameen"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g. Focus Keypharse"
                  value={cardFourTitleEn}
                  onChange={e => setCardFourTitleEn(e.target.value)}
                />
              </div>
            </Col>

            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Title (arabic)</Label>
                <Input
                  id="itemnamear"
                  name="itemnamear"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. "
                  value={cardFourTitleAr}
                  onChange={e => setCardFourTitleAr(e.target.value)}
                  dir="rtl"
                  lang="ar"
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Process Points (english)</Label>
                <ReactTagInput
                  tags={cardFourPointsEn}
                  editable={true}
                  placeholder="e.g Title"
                  onChange={newTags => setCardFourPointsEn(newTags)}
                />
              </div>
            </Col>

            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Process Points (arabic)</Label>
                <ReactTagInput
                  tags={cardFourPointsAr}
                  editable={true}
                  placeholder="e.g Title"
                  onChange={newTags => setCardFourPointsAr(newTags)}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Form>
      <hr style={{ margin: "20px -20px 20px -20px" }} />
      <Row className="mt-2">
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0">
              <button type='button' onClick={submit} disabled={isLoading?true:false}>
                {isLoading ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
    </div>
  )
}

export default Process

