import axios from "axios"

const axiosConfig = axios.create({
  baseURL: `${process.env.REACT_APP_API}`,
  headers: {
    "Content-Type": "application/json",
  },
})

export default axiosConfig
