import axios from "axios"
import React, { Fragment, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap"

const TaskDescriptionForm = ({
  data,
  ChangeHandler,
}) => {
  return (
      <>
        <Row className="">
          <Col>
            <div className="d-flex">
              <Label>Main Title (English)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="Label"
              name="DesignAppTaskDescription_titleEn"
              value={
                data["DesignAppTaskDescription_titleEn"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, DesignAppTaskDescription_titleEn: e.target.value}
                )
              }
            />
          </Col>
          <Col>
            <div className="d-flex">
              <Label>Main Title (Arabic)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="عنوان"
              dir="rtl"
              name="DesignAppTaskDescription_titleAr"
              value={
                data["DesignAppTaskDescription_titleAr"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, DesignAppTaskDescription_titleAr: e.target.value}
                )
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <div className="d-flex">
              <Label>Description (English)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <textarea
              className="form-control  mb-0"
              rows="5"
              placeholder="Description"
              name="DesignAppTaskDescription_descriptionEn"
              value={
                data["DesignAppTaskDescription_descriptionEn"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, DesignAppTaskDescription_descriptionEn: e.target.value}
                )
              }
            />
          </Col>
          <Col>
            <div className="d-flex">
              <Label>Description (Arabic)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <textarea
              className="form-control  mb-0"
              rows="5"
              placeholder="وصف"
              name="DesignAppTaskDescription_descriptionAr"
              value={
                data["DesignAppTaskDescription_descriptionAr"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, DesignAppTaskDescription_descriptionAr: e.target.value}
                )
              }
              dir="rtl"
            />
          </Col>
        </Row>
      </>
  )
}

export default TaskDescriptionForm
const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0"
}