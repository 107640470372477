/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import AddUserForm from './AddUserForm'
import UpdateUserForm from './UpdateUserForm'
import "./datatables.scss"
import './specification.scss'
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert"
import { useSelector, useDispatch } from "react-redux";
import { getUserData } from "../../../../store/actions";
import axiosConfig from "axiosConfig";
import { toast } from "react-toastify";



const Admins = (props) => {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.UserData.user)
  const [active, setActive] = useState(false)
  const [addActive, setAddActive] = useState(false)
  const[productData, setProductData] = useState([])
  const[user, setUser] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const columns = [{
    dataField: 'id',
    text: 'Id',
    sort: true,
  }, {
    dataField: 'image',
    text: 'Image',
    sort: true
  }, {
    dataField: 'name',
    text: 'Name',
    sort: true
  },{
    dataField: 'email',
    text: 'Email',
    sort: true
  },{
    dataField: 'role',
    text: 'Role',
    sort: true
  },{
    dataField: 'actions',
    text: 'Actions',
    sort: true
  }];

  const pageOptions = {
    totalSize: props?.pagination.total, // replace later with size(customers),
    custom: true,
    sizePerPageList: [ {
      text: '10', value: 10
    }, {
      text: '25', value: 25
    }, {
      text: '50', value: 50
    }, {
      text: '75', value: 75
    }, {
      text: '100', value: 100
    }, {
      text: 'All', value: props?.pagination.total
    } ]
  }
  const onPageHandler =  (type, { page }) => {
    props.setPageNo(page)
  }
  



  //Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search;

  
  function clickHandle (user, type) {
    setActive(type)
    setUser(user)
  }
  function clickAddHandle (user, type) {
    setAddActive(type)
  }

  function update(data, type) {
    props.getData()
    if (type === "add"){
      setAddActive(false)
      setsuccess_msg(true)
      setMsg("Added!")
      
        // setProductData(users => users.concat({...data, 
        //   image: <img src={data.image} className='usernametabl' alt=""/>, 
        //   role:data.role === "1"? "Admin":data.role === "2"?"Viewer": "User",
        //   actions: <div className="d-flex">
        //                 <button style={editBtn}  onClick={() => clickHandle(data, true)}><i className='bx bx-edit fs-4 pr-1' style={{marginRight: "10px"}}></i> Edit</button>
        //                 <button style={deleteBtn}  onClick={(e)=>deleteHandler(data.id)}><i className='bx bx-trash fs-4' style={{marginRight: "10px"}}></i> Delete</button>
        //           </div>
        // }))
      }else {
      
      // setProductData(users => users.map(user =>user.id === data.id? {...data, 
      //   image: <img src={data.image} className='usernametabl' alt=""/>,
      //   role: data.role === "1"? "Admin":data.role === "2"?"Viewer": "User",
      //   actions:  <div className="d-flex">
      //   <button style={editBtn}  onClick={() => clickHandle(data, true)}><i className='bx bx-edit fs-4 pr-1' style={{marginRight: "10px"}}></i> Edit</button>
      //   {user.key !== 1?<button style={deleteBtn}  onClick={(e)=>deleteHandler(user.id)}><i className='bx bx-trash fs-4' style={{marginRight: "10px"}}></i> Delete</button>:null}
      // </div>}: user))
      setsuccess_msg(true)
      setMsg("Updated!")
      setActive(false)
      if(userData.id == data.id) {
        let u= JSON.parse(localStorage.getItem("authUser"))
        dispatch(getUserData(data.data.data))
        
        localStorage.setItem("authUser", JSON.stringify({...u, ...data.data.data}))
      }
    }
  }

  function deleteHandler(id) {
    axiosConfig.post(`/dashboard/configurations/admins/delete`, {id}, {
      headers: { 
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
      }
    })
    .then(res => {
      setProductData(products => products.filter(prod => prod.id != id))
      setMsg("Deleted!")
      setsuccess_msg(true)
      props.getData(  )
    }).catch(err=>{
      toast.error(err.response.data.message)
    })
  }

  useEffect(() => {
    props?.admin&& setProductData(props?.admin.map(user =>  {return{...user, 
      image: <img src={user.image} className='usernametabl' alt=""/>, 
      role:user.role == "1"? "Admin":user.role == "2"?"Viewer": "User",
      actions: <div className="d-flex">
                    <button style={editBtn}  onClick={() => clickHandle(user,true)}><i className='bx bx-edit fs-4 pr-1' style={{marginRight: "10px"}}></i> Edit</button>
                    {(user.key !== 1&&user.canDelete==1)?<button style={deleteBtn}  onClick={(e)=>deleteHandler(user.id)}><i className='bx bx-trash fs-4' style={{marginRight: "10px"}}></i> Delete</button>:null}
              </div>
    }}))
  }, [props.admin]) 


  return (
    <React.Fragment>
        <div className="container-fluid p-0">
            <UpdateUserForm active={active} clickHandle={clickHandle} user={user} update={update} getData={props.getData}/>
            <AddUserForm active={addActive} clickAddHandle={clickAddHandle}  update={update} getData={props.getData}/>
          <Row>
            <Col className="col-12">
              <Card >
                <CardBody className="pb-0">
                {success_msg ? (
                    <SweetAlert
                      title={msg}
                      success
                      confirmBtnBsStyle="success"
                      onConfirm={() => {
                        setsuccess_msg(false)
                      }}
                      onCancel={() => {
                        setsuccess_msg(false)
                      }}
                    >
                    </SweetAlert>
                  ) : null}
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col>
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative ">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      className="input_cus"
                                    />
                                    <i className="bx bx-search-alt search-icon fs-3 pt-1" />
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                  <div className="save__btn top">
                                      <button onClick={() => clickAddHandle(null, true)}><i className='bx bx-plus-circle fs-4'></i> Add Admin</button>
                                  </div>
                              </Col>
                            </Row>
                            
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    remote
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    onTableChange={onPageHandler}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                  {productData.length === 0?<div className="emptyStateTable" style={{
                                      width: '100%',
                                      textAlign: 'center',
                                      fontSize: '16px',
                                      padding: '10px',
                                      paddingTop: '0', paddingBottom: "20px"}}>no data available in table</div>:null}
                                </div>
                              </Col>
                            </Row>

                            {productData.length !== 0?<Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>:null}
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }
                    
                    </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    </React.Fragment>
  )
}

export default Admins

const deleteBtn = {
  background: "transparent",
  border: "none",
  color:"#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px"
}