import React, { useEffect,useState, Fragment} from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import prettyBytes from "pretty-bytes"
import axiosConfig from "axiosConfig"
import SweetAlert from "react-bootstrap-sweetalert"
import { toast } from "react-toastify"

const Section4 = ({data}) => {
  const [active, setActive] = useState(false)
  const [rows2, setrows2] = useState([{ id: 1 }])
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")

  const [prev, setPrev] = useState(null)
  const [isOpen, setisOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")

  const [multiData, setMultiData] = useState([{
      id:1, 
      descriptionEn: '', 
      status:'new'
  }])

  
  function handleAddRowNested() {
    const modifiedRows = [...rows2]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows2(modifiedRows)
    setMultiData(data => data.concat({
      id:modifiedRows.length, 
      descriptionEn: '', 
      descriptionAr: '', 
      status:'new'
    }))
  }

  function deleteCard(key,cardId,status){
    
    if(status==='old'){
      axiosConfig.post(`/dashboard/etoy/termsAndConditions/pointsPolicy/delete`, {id:cardId}, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      }).then(res=>{
        setMsg(`Deleted Successfully`)
        setsuccess_msg(true)
        handleRemoveRow(key,cardId)
      }).catch(err=>{
        toast.error(err.response.data.message)
      })
    }else{
      handleRemoveRow(key,cardId)
    }

  }

  function handleRemoveRow(id) {
    if (id !== 0) {
      setrows2(r => r.filter((x,i) => i !== id))
      setMultiData(d => d.filter((x,i) => i !== id))
    }
  }

  function multiChange(e, key, type,event) {
    setMultiData(cards =>
      cards.map((data, index) => {
        
        if (index === key && type === "descriptionEn") {
          return { ...data, descriptionEn: e.target.value }
        } else if (index === key && type === "descriptionAr") {
          return { ...data, descriptionAr: e.target.value }
        } 
        return data
      })
      )
      
  }

  

  function submit(e) {
    setActive(true)
    e.preventDefault()
    const formData = new FormData()
    const data = {
      titleEn:titleEn,
      titleAr:titleAr, 
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    multiData.forEach((data, i) => {
      formData.append(`cards[${i}][descriptionEn]`, data.descriptionEn)
      formData.append(`cards[${i}][descriptionAr]`, data.descriptionAr)
    })
    setIsLoading(true)

    axiosConfig.post(`/dashboard/etoy/termsAndConditions/pointsPolicy`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res=>{
      
       toast.success(res.data.message)
      setsuccess_msg(true)
    setActive(fasle)
    setIsLoading(false)
    }).catch(err=>{
      toast.error(err.response.data.message)
      setIsLoading(false)
    })
  }
  
  useEffect(()=>{
    if(data) {
      setTitleEn(data?.titleEn)
      setTitleAr(data?.titleAr)

      let multiDataModified=data?.cards.map((card,index)=>{
        return{
          id:card?.id?card?.id:index+1, 
          descriptionEn: card?.descriptionEn?card?.descriptionEn:'', 
          descriptionAr: card?.descriptionAr?card?.descriptionAr:'', 
        }
      })
      setMultiData(multiDataModified)
    }
    

  },[data])

  return (
    <div>
      {success_msg ? (
        <SweetAlert
          title={msg}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        >
        </SweetAlert>
      ) : null}
        {isOpen ? (
        <Lightbox
          mainSrc={prev}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
      <Form className="border-0 pb-2">
        <Row className="">
          <h3 className="fs-4 text-black fw-bolder mb-3">Header Content</h3>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (English)</Label>
              <Input
                id="itemnameen"
                name="itemnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={titleEn}
                onChange={e => setTitleEn(e.target.value)}
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (arabic)</Label>
              <Input
                id="itemnamear"
                name="itemnamear"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. "
                value={titleAr}
                onChange={e => setTitleAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>
        
        
        <hr style={{ margin: "25px -20px 20px -20px" }} />
        {(multiData || []).map((formRow, key) => (
          <Fragment key={key}>
            
            
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (English)</Label>
                  <textarea
                    className="form-control  mb-0"
                    id="specification"
                    rows="5"
                    placeholder="Write Description Here"
                    value={formRow?.descriptionEn}
                    onChange={e =>  multiChange(e, key, "descriptionEn")}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (Arabic)</Label>
                  <textarea
                    className="form-control  mb-0"
                    id="specification"
                    rows="5"
                    placeholder="اكتب وصف هنا"
                    value={formRow?.descriptionAr}
                    onChange={e => multiChange(e, key, "descriptionAr")}
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            
            {key !== 0 ? (
              <Button
                color="none"
                variant="primary"
                className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                id="unknown-btn"
                style={{
                  color: "#ff3838",
                  boxShadow: "none",
                  height: "45px",
                  borderRadius: 0,
                }}
                onClick={e => {
                  deleteCard(key,formRow.id,formRow?.status=='new'?'new':'old')
                }}
              >
                <i className="bx bx-trash-alt fs-4 p-0 mr-1" /> Delete
              </Button>
            ) : null}
            {
                key!=0?
                <hr style={{ margin: "5px -20px 20px -20px" }} />
                :
                <hr style={{ margin: "25px -20px 20px -20px" }} />
              }
          </Fragment>
        ))}
        
        <Button
          onClick={() => {
            handleAddRowNested()
          }}
          color="none"
          className="mt-2 fs-5 fw-bold p-0 text-left"
          style={btnStyle}
        >
          <i className="bx bx-plus-circle fs-4" /> <span>Add Card</span>
        </Button>
        <hr style={{ margin: "20px -20px 0px -20px" }} />
      </Form>
      <Row className="mt-2">
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0">
              <button type='button' onClick={submit} disabled={isLoading?true:false}>
                {isLoading ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
    </div>
  )
}

export default Section4

const cardStyle = {
  borderTop: "2px solid #dedede",
}
const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
