import React, { Fragment, useState, useRef, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import MetaTags from "react-meta-tags"
// import Select from 'react-select'
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
} from "reactstrap"
import Dropzone from "react-dropzone"

import { toast } from "react-toastify"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { useHistory } from "react-router-dom"
import axios from "axios"
import PulseLoader from "react-spinners/PulseLoader"

import Select from "react-select"
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw } from "draft-js"
import draftToHtml from "draftjs-to-html"
import "./addproject.scss"
import { setTitle } from "../../../../../store/actions"
import { useDispatch, useSelector } from "react-redux"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css" // ES6
import axiosConfig from "axiosConfig"
import SweetAlert from "react-bootstrap-sweetalert"
import prettyBytes from "pretty-bytes"
import SeoComponent from "./SeoComponent"

const EditProject = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const cats = useSelector(state => state.MainData.categories)
  const [rows1, setrows1] = useState([{ id: 1 }])
  const [isOpen, setisOpen] = useState(false)
  const [photoIndex, setphotoIndex] = useState(0)
  const [loading, setLoading] = useState(false)
  const [categ, setCateg] = useState([])

  //form data collect
  const [coverImages, setCoverImages] = useState([])
  const [thumbImage, setThumbImage] = useState(null)
  const [facebookImage, setFacebookImage] = useState(null)
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [createdEn, setCreatedEn] = useState("")
  const [createdAr, setCreatedAr] = useState("")
  const [availabilityEn, setAvailabilityEn] = useState("")
  const [availabilityAr, setAvailabilityAr] = useState("")
  const [smallDescEn, setSmallDescEn] = useState("")
  const [smallDescAr, setSmallDescAr] = useState("")
  const [state, setState] = useState("")
  const [link, setLink] = useState("")
  const [price, setPrice] = useState("")
  const [contactUs, setContactUs] = useState("")
  const [category, setCategory] = useState("")
  const [date, setDate] = useState("")
  const [informations, setInformations] = useState([
    { titleEn: "", descEn: "", titleAr: "", descAr: "" },
  ])
  const [infoEn, setInfoEn] = useState("")
  const [infoAr, setInfoAr] = useState("")
  const [selectedFiles, setselectedFiles] = useState([])
  const [seoFocusEn, setSeoFocusEn] = useState("")
  const [seoFocusAr, setSeoFocusAr] = useState("")
  const [seoTitleEn, setSeoTitleEn] = useState("")
  const [seoTitleAr, setSeoTitleAr] = useState("")
  const [seoDescEn, setSeoDescEn] = useState("")
  const [seoDescAr, setSeoDescAr] = useState("")
  const [seoSlugEn, setSeoSlugEn] = useState("")
  const [seoSlugAr, setSeoSlugAr] = useState("")
  const [seoFaceTitleEn, setSeoFaceTitleEn] = useState("")
  const [seoFaceTitleAr, setSeoFaceTitleAr] = useState("")
  const [seoFaceDescEn, setSeoFaceDescEn] = useState("")
  const [seoFaceDescAr, setSeoFaceDescAr] = useState("")
  const [design365, setDesign365] = useState([])

  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")

  const optionGroup = [
    {
      options: [
        { label: "m", value: "m" },
        { label: "lg", value: "lg" },
        { label: "xl", value: "xl" },
        { label: "xxl", value: "xxl" },
      ],
    },
  ]

  function handleAddRowNested() {
    const modifiedRows = [...rows1]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows1(modifiedRows)
    setInformations(specifications =>
      specifications.concat({
        titleEn: "",
        descEn: "",
        titleAr: "",
        descAr: "",
      })
    )
  }

  function handleRemoveRow(id) {
    if (id !== 1) {
      var modifiedRows = [...rows1]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setrows1(modifiedRows)
      setInformations(specifications =>
        specifications.filter((c, i) => i !== id - 1)
      )
    }
  }

  function informationsChange(e, key, type) {
    setInformations(specs =>
      specs.map((spec, index) => {
        if (index === key && type === "titleen") {
          return { ...spec, titleEn: e.target.value }
        } else if (index === key && type === "descen") {
          return { ...spec, descEn: e.target.value }
        } else if (index === key && type === "titlear") {
          return { ...spec, titleAr: e.target.value }
        } else if (index === key && type === "descar") {
          return { ...spec, descAr: e.target.value }
        }
        return spec
      })
    )
  }

  function handleFacebookFiles(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    setFacebookImage(Object.values(files)[0])
    e.target.value = ""
  }

  function handleThumbFiles(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    setThumbImage(Object.values(files)[0])
    e.target.value = ""
  }

  function handleCoverFiles(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    setCoverImages(Object.values(files))
    e.target.value = ""
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    setselectedFiles(fil => fil.concat(files))
  }

  function deleteSelectImage(e, id, type, imgId) {
    e.preventDefault()
    setCoverImages(files => files.filter((f, i) => i !== id))
    if (type === "cover") {
      axiosConfig.post(`/dashboard/365Design/projets/deleteCoverImage`, {id: imgId}, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      return;
    }
    type === "thumb"
      ? setThumbImage(null)
      : type === "face"
      ? setFacebookImage(null)
      : setselectedFiles(files => files.filter((f, i) => i !== id))
      axiosConfig.post(`/dashboard/365Design/projets/deleteImage`, {id: imgId}, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
  }

  function prevSelectImage(e, id) {
    e.preventDefault()
    setphotoIndex(id)
    setisOpen(!isOpen)
  }

  function get365DesignData() {
    axiosConfig
      .get(`/dashboard/365Design/category`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setCateg(res.data.data)
      })
      
  }
  useEffect(() => {
    get365DesignData()
  }, [])

  const [seoData, setSeoData] = useState({
    focusKeypharseEn: "",
    focusKeypharseAr: "",
    seoTitleEn: "",
    seoTitleAr: "",
    descriptionEn: "",
    descriptionAr: "",
    slugEn: "",
    slugAr: "",
    facebookTitleEn: "",
    facebookTitleAr: "",
    facebookDescriptionEn: "",
    facebookDescriptionAr: "",
    facebookImage: null,
  })

  function handleChangeData(e,keyName, keyValue) {
    e.preventDefault()
    setSeoData(prevValue => {
      return {
        ...prevValue,
        [keyName]: keyValue,
      }
    })
  }

  function submitHandler() {
    let formData = new FormData()
    let data = {
      id: params.id,
      date,
      nameEn: titleEn,
      nameAr: titleAr,
      ceatedByEn: createdEn,
      ceatedByAr: createdAr,
      availabilityProgramsEn: availabilityEn,
      availabilityProgramsAr: availabilityAr,
      smallDescriptionEn: smallDescEn,
      smallDescriptionAr: smallDescAr,
      state: state,
      link: link,
      price,
      category: category,
      informationEn: infoEn,
      informationAr: infoAr,
      seo_focusKeypharseEn: seoData.focusKeypharseEn,
      seo_focusKeypharseAr: seoData.focusKeypharseAr,
      seo_seoTitleEn: seoData.seoTitleEn,
      seo_seoTitleAr: seoData.seoTitleAr,
      seo_descriptionEn: seoData.descriptionEn,
      seo_descriptionAr: seoData.descriptionAr,
      seo_slugEn: seoData.slugEn,
      seo_slugAr: seoData.slugAr,
      seo_facebookTitleEn: seoData.facebookTitleEn,
      seo_facebookTitleAr: seoData.facebookTitleAr,
      seo_facebookDescriptionEn: seoData.facebookDescriptionEn,
      seo_facebookDescriptionAr: seoData.facebookDescriptionAr,
      seo_facebookImage: seoData.facebookImage,
      image: thumbImage,
    }

    for (let key in data) {
      formData.append(key, data[key])
    }
    coverImages.forEach((photo, index) => {
      formData.append(`CoverImages[${index}][image]`, photo)
    })
    selectedFiles.forEach((photo, index) => {
      formData.append(`Images[${index}][image]`, photo)
    })

    informations.forEach((spec, i) => {
      formData.append(`Informations[${i}][titleEn]`, spec.titleEn)
      formData.append(`Informations[${i}][titleAr]`, spec.titleAr)
      formData.append(`Informations[${i}][descriptionEn]`, spec.descEn)
      formData.append(`Informations[${i}][descriptionAr]`, spec.descAr)
    })

    setLoading(true)
    axiosConfig
      .post(`/dashboard/365Design/projets/add`, formData, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setLoading(false)
        history.push("/365design")
        toast.success("Item Updated Successfully", { position: "top-right" })
      })
      .catch(err => {
        setLoading(false)
        toast.error("Something went wrong")
      })
  }

  function changeDateFormat(dateFormat){
    // let dateFormat = "Monday, 02 Jan 2023 - 12:00 AM"
    let datee = dateFormat.split(', ')
    let dateeeOne = datee[1].split(' -')[0]
    let getDay = dateeeOne.split(' ')[0]
    let dateOne = dateeeOne.split(' ')[1]
    let getYear = dateeeOne.split(' ')[2]
    
    let monthTry1;
    switch (dateOne) {
      case 'Jan':
        monthTry1 = '01';
        break;
      case "Feb":
        monthTry1 = '02';
        break;
      case "Mar":
        monthTry1 = '03';
        break;
      case "Apr":
        monthTry1 = '04';
        break;
      case "May":
        monthTry1 = '05';
        break;
      case "Jun":
        monthTry1 = '06';
        break;
      case "Jul":
        monthTry1 = '07';
        break;
      case "Aug":
        monthTry1 = '08';
        break;
      case "Nov":
        monthTry1 = '09';
        break;
      case "Oct":
        monthTry1 = '10';
        break;
      case "Sep":
        monthTry1 = '11';
        break;
      case "Dec":
        monthTry1 = '12';
        break;
      default:
        break;
    }
    
    let format1 = `${getYear}-${monthTry1}-${getDay}`
    return format1
}

  useEffect(() => {
    dispatch(setTitle("Add Project"))
  }, [cats])
  const params = useParams()
  useEffect(() => {
    axiosConfig
      .get(`/dashboard/365Design/projets?id=${params.id}`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        const data = res.data.data
        let dateFormatto = changeDateFormat(data.date)
        setTitleEn(data.nameEn)
        setTitleAr(data.nameAr)
        setCreatedEn(data.ceatedByEn)
        setCreatedAr(data.ceatedByAr)
        setAvailabilityEn(data.availabilityProgramsEn)
        setAvailabilityAr(data.availabilityProgramsAr)
        setSmallDescEn(data.smallDescriptionEn)
        setSmallDescAr(data.smallDescriptionAr)
        setDate(dateFormatto)
        setState(data.state)
        setLink(data.link)
        setPrice(data.price || "")
        setInformations(
          data.Informations.map(r => {
            return {
              titleEn: r.titleEn,
              descEn: r.descriptionEn,
              titleAr: r.titleAr,
              descAr: r.descriptionAr,
            }
          })
        )
        setrows1(
          data.Informations.map((r, i) => {
            return { id: i + 1 }
          })
        )
        setThumbImage({preview: data.image })
        data.coverImage &&
          setCoverImages(
            data.coverImage.map(s => {
              return { preview: s.image, id: s.id }
            })
          )
        setselectedFiles(
          data.Images.map(s => {
            return { id: s.id,preview: s.image }
          })
        )
        setInfoEn(data.informationEn)
        setInfoAr(data.informationAr)
        setCategory(data.category.id)
        setSeoData({
          focusKeypharseEn: data.SEO.focusKeypharseEn,
          focusKeypharseAr: data.SEO.focusKeypharseAr,
          seoTitleEn: data.SEO.seoTitleEn,
          seoTitleAr: data.SEO.seoTitleAr,
          descriptionEn: data.SEO.descriptionEn,
          descriptionAr: data.SEO.descriptionAr,
          slugEn: data.SEO.slugEn,
          slugAr: data.SEO.slugAr,
          facebookTitleEn: data.SEO.facebookTitleEn,
          facebookTitleAr: data.SEO.facebookTitleAr,
          facebookDescriptionEn: data.SEO.facebookDescriptionEn,
          facebookDescriptionAr: data.SEO.facebookDescriptionAr,
          facebookImage: { preview: data.SEO.facebookImage },
        })
      }).catch(err => {
      })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Edit Project | 365Design</title>
        </MetaTags>
        <Container fluid>
          {isOpen ? (
            <Lightbox
              mainSrc={photoIndex}
              enableZoom={false}
              onCloseRequest={() => {
                setisOpen(!isOpen)
              }}
            />
          ) : null}
          {success_msg ? (
            <SweetAlert
              title={msg}
              success
              confirmBtnBsStyle="success"
              onConfirm={() => {
                setsuccess_msg(false)
              }}
              onCancel={() => {
                setsuccess_msg(false)
              }}
            ></SweetAlert>
          ) : null}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody className="p-0">
                  <h2
                    className="head__title fs-4 pl-4 pt-3 mb-2"
                    style={{
                      color: "#000",
                      fontSize: "24px",
                      fontWeight: "700 !important",
                      marginLeft: "20px",
                    }}
                  >
                    <i
                      className="dripicons-arrow-thin-left fs-5 fw-bold"
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    />{" "}
                    Project Information
                  </h2>

                  <Form className="p-4 add-project-form">
                    <Row>
                      <Col xs="12">
                        <div className="mb-3">
                          <Label htmlFor="productdesc">thumbnail Image</Label>
                          <Input
                            type="file"
                            className="form-control form-control-lg "
                            onChange={e => handleThumbFiles(e, e.target.files)}
                            style={{ borderRadius: 0 }}
                            multiple
                          />
                        </div>
                      </Col>
                      <Col xs="12">
                        <div
                          className="dropzone-previews mb-3"
                          id="file-previews"
                        >
                          {thumbImage ? (
                            <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={thumbImage.name}
                                      src={thumbImage.preview}
                                      style={{ objectFit: "cover" }}
                                    />
                                  </Col>
                                  <Col>
                                    <Link to="#" className="text-muted ">
                                      {thumbImage.name}
                                    </Link>
                                  </Col>
                                  <Col>
                                    <div className="image__options">
                                      <button
                                        className="p-0"
                                        onClick={e =>
                                          prevSelectImage(e, thumbImage.preview)
                                        }
                                      >
                                        <i className="far fa-eye  fs-5 p-0" />
                                      </button>
                                      <button
                                        type="button"
                                        style={{ color: "#ff3838" }}
                                        onClick={e =>
                                          deleteSelectImage(e, null, "thumb", thumbImage.id)
                                        }
                                      >
                                        <i className="bx bx-trash fs-4" />
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="12">
                        <div className="mb-3">
                          <Label htmlFor="productdesc">Cover Images</Label>
                          <Input
                            type="file"
                            className="form-control form-control-lg "
                            onChange={e => handleCoverFiles(e, e.target.files)}
                            style={{ borderRadius: 0 }}
                            multiple
                          />
                        </div>
                      </Col>
                      <Col xs="12">
                        <div
                          className="dropzone-previews mb-3"
                          id="file-previews"
                        >
                          {coverImages.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                        style={{ objectFit: "cover" }}
                                      />
                                    </Col>
                                    <Col>
                                      <Link to="#" className="text-muted ">
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                    <Col>
                                      <div className="image__options">
                                        <button
                                          className="p-0"
                                          onClick={e =>
                                            prevSelectImage(e, f.preview)
                                          }
                                        >
                                          <i className="far fa-eye  fs-5 p-0" />
                                        </button>
                                        <button
                                          style={{ color: "#ff3838" }}
                                          onClick={e =>
                                            deleteSelectImage(e, i, "cover", f.id)
                                          }
                                        >
                                          <i className="bx bx-trash fs-4" />
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            )
                          })}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productdesc">
                            Project Name (english)
                          </Label>
                          <Input
                            type="text"
                            className="form-control input_cus"
                            placeholder="e.g. Project Name"
                            value={titleEn}
                            onChange={e => setTitleEn(e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productdesc">
                            Project Name (arabic)
                          </Label>
                          <Input
                            id="itemnamear"
                            name="itemnamear"
                            type="text"
                            className="form-control input_cus input_cus"
                            placeholder="مثال. اسم المشروع"
                            value={titleAr}
                            onChange={e => setTitleAr(e.target.value)}
                            dir="rtl"
                            lang="ar"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productdesc">
                            Created By (english)
                          </Label>
                          <Input
                            id="brandnameen"
                            name="brandnameen"
                            type="text"
                            className="form-control input_cus"
                            placeholder="e.g. Yasser Nazzal"
                            value={createdEn}
                            onChange={e => setCreatedEn(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productdesc">
                            Created By (arabic)
                          </Label>
                          <Input
                            id="brandnamear"
                            name="brandnamear"
                            type="text"
                            className="form-control input_cus"
                            placeholder="مثال. ياسر نازال"
                            value={createdAr}
                            onChange={e => setCreatedAr(e.target.value)}
                            dir="rtl"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productdesc">
                            Availability Programs (english)
                          </Label>
                          <Input
                            id="brandnameen"
                            name="brandnameen"
                            type="text"
                            className="form-control input_cus"
                            placeholder="e.g. Figma, Xd"
                            value={availabilityEn}
                            onChange={e => setAvailabilityEn(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productdesc">
                            Availability Programs (arabic)
                          </Label>
                          <Input
                            id="brandnamear"
                            name="brandnamear"
                            type="text"
                            className="form-control input_cus"
                            placeholder="مثال. فيجما"
                            value={availabilityAr}
                            onChange={e => setAvailabilityAr(e.target.value)}
                            dir="rtl"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productdesc">
                            Small Description (English)
                          </Label>
                          <textarea
                            className="form-control  mb-0"
                            id="specification"
                            rows="5"
                            placeholder="Enter your description here"
                            value={smallDescEn}
                            onChange={e => setSmallDescEn(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="productdesc">
                            Small Description (Arabic)
                          </Label>
                          <textarea
                            className="form-control  mb-0"
                            id="specification"
                            rows="5"
                            placeholder="اكتب وصف هنا"
                            value={smallDescAr}
                            onChange={e => setSmallDescAr(e.target.value)}
                            dir="rtl"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <div className="mb-3">
                          <Label htmlFor="productdesc">Date</Label>
                          <Input
                            id="brandnameen"
                            name="brandnameen"
                            type="date"
                            className="form-control input_cus"
                            placeholder="e.g. Figma, Xd"
                            value={date}
                            onChange={e => setDate(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="productdesc">State</Label>
                          <select
                            className="form-select fs-5 input_cus"
                            value={state}
                            onChange={e => setState(e.target.value)}
                          >
                            <option value="">Select</option>
                            <option value="free">Free</option>
                            <option value="price">Price</option>
                          </select>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="productdesc" className="">
                            Link
                          </Label>
                          <Input
                            id="brandnameen"
                            name="brandnameen"
                            type="text"
                            className="form-control input_cus"
                            placeholder="e.g. https://www.example.com"
                            value={link}
                            onChange={e => setLink(e.target.value)}
                          />
                        </div>
                      </Col>
                      {state == "price" ? (
                        <Col>
                          <div className="mb-3">
                            <Label htmlFor="productdesc">Price</Label>
                            <Input
                              id="priceaftersale"
                              name="priceaftersale"
                              type="text"
                              className="form-control input_cus"
                              placeholder="e.g. 2359"
                              value={price}
                              onChange={e => setPrice(e.target.value)}
                            />
                          </div>
                        </Col>
                      ) : null}
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <Label htmlFor="productdesc">Category</Label>
                          <select
                            className="form-control input_cus"
                            value={category}
                            onChange={e => setCategory(e.target.value)}
                          >
                            <option value={""}>Select</option>
                            {categ.map(cat => (
                              <option key={cat.id} value={cat.id}>
                                {cat.titleEn}
                              </option>
                            ))}
                          </select>
                          <FormFeedback className="fs-6">
                            This Fileld Is Required!
                          </FormFeedback>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                  <Form className="p-4">
                    <Row>
                      <Col>
                        <h2 className="mb-0 mt-3 fs-4">Informations</h2>
                        <div className="inner-repeater mb-4">
                          {(rows1 || []).map((formRow, key) => (
                            <Fragment key={key}>
                              <Row>
                                <Col>
                                  <Label htmlFor="productdesc" className="mt-3">
                                    title (English)
                                  </Label>
                                  <Input
                                    name="brandnameen"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g. title"
                                    value={informations[key].titleEn}
                                    onChange={e =>
                                      informationsChange(e, key, "titleen")
                                    }
                                  />
                                </Col>
                                <Col>
                                  <Label htmlFor="productdesc" className="mt-3">
                                    title (Arabic)
                                  </Label>
                                  <Input
                                    name="brandnameen"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="مثال. عنوان"
                                    value={informations[key].titleAr}
                                    onChange={e =>
                                      informationsChange(e, key, "titlear")
                                    }
                                    dir="rtl"
                                  />
                                </Col>
                              </Row>

                              <Row className="mb-0 mt-3">
                                <Col>
                                  <Label htmlFor="productdesc">
                                    Description (English)
                                  </Label>
                                  <textarea
                                    className="form-control  mb-0"
                                    rows="5"
                                    placeholder="Enter your description here"
                                    value={informations[key].descEn}
                                    onChange={e =>
                                      informationsChange(e, key, "descen")
                                    }
                                  />
                                </Col>
                                <Col>
                                  <Label htmlFor="productdesc">
                                    Description (Arabic)
                                  </Label>
                                  <textarea
                                    className="form-control  mb-0"
                                    rows="5"
                                    placeholder="اكتب وصف هنا"
                                    value={informations[key].descAr}
                                    onChange={e =>
                                      informationsChange(e, key, "descar")
                                    }
                                    dir="rtl"
                                  />
                                </Col>
                              </Row>
                              {key !== 0 ? (
                                <Button
                                  color="none"
                                  variant="primary"
                                  className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                                  id="unknown-btn"
                                  style={{
                                    color: "#ff3838",
                                    boxShadow: "none",
                                    height: "45px",
                                    borderRadius: 0,
                                  }}
                                  onClick={e => {
                                    handleRemoveRow(formRow.id)
                                  }}
                                >
                                  <i className="bx bx-trash-alt fs-4 p-0 mr-1" />{" "}
                                  Delete
                                </Button>
                              ) : null}
                            </Fragment>
                          ))}

                          <Button
                            onClick={() => {
                              handleAddRowNested()
                            }}
                            color="none"
                            className="mt-2 fs-5 fw-bold p-0 text-left"
                            style={btnStyle}
                          >
                            <i className="bx bx-plus-circle fs-4" />{" "}
                            <span>Add Information</span>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label htmlFor="productdesc">
                          Information (English)
                        </Label>
                        <ReactQuill
                          value={infoEn}
                          onChange={data => setInfoEn(data)}
                          modules={modulesQuill}
                          formats={formatsQuill}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-0 mt-3">
                      <Col>
                        <Label htmlFor="productdesc">
                          Information (Arabic)
                        </Label>
                        <ReactQuill
                          value={infoAr}
                          onChange={data => setInfoAr(data)}
                          modules={modulesQuill}
                          formats={formatsQuill}
                        />
                      </Col>
                    </Row>
                  </Form>
                  <h2
                    className="mb-3 mt-4 fs-4 pl-4"
                    style={{ marginLeft: "20px" }}
                  >
                    Project Images
                  </h2>
                  <div className="d-flex justify-content-between">
                    <Label
                      className="fs-5  pl-4 "
                      style={{ marginLeft: "20px" }}
                    >
                      Attached image
                    </Label>
                  </div>
                  <Form
                    className="pl-4 pr-4 pb-4"
                    style={{ paddingLeft: "20px", paddingRight: "20px" }}
                  >
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles)
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                          <div
                            className="dz-message needsclick"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="dz-message needsclick">
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h4>Drop files here or click to upload.</h4>
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews mt-3" id="file-previews">
                      {selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                    style={{ objectFit: "cover" }}
                                  />
                                </Col>
                                <Col>
                                  <Link to="#" className="text-muted ">
                                    {f.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                  </p>
                                </Col>
                                <Col>
                                  <div className="image__options">
                                    <button
                                      className="p-0"
                                      onClick={e =>
                                        prevSelectImage(e, f.preview)
                                      }
                                    >
                                      <i className="far fa-eye  fs-5 p-0" />
                                    </button>
                                    <button
                                      type="button"
                                      style={{ color: "#ff3838" }}
                                      onClick={e => deleteSelectImage(e, i, "dd", f.id)}
                                    >
                                      <i className="bx bx-trash fs-4" />
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                  </Form>
                  <h2
                    className="mb-0 mt-4 fs-4 pl-4"
                    style={{ marginLeft: "20px" }}
                  >
                    SEO
                  </h2>
                  <SeoComponent
                    handleChangeData={handleChangeData}
                    seoData={seoData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="submit__btn footer fixed fixed-bottom">
            <Col lg={10} md={10}>
              <button className="cancel mr-3" onClick={() => history.goBack()}>
                cancel
              </button>{" "}
              <button className="save" onClick={submitHandler}>
                {loading ? (
                  <PulseLoader color={"#ffffff"} loading={true} size={10} />
                ) : (
                  "Save"
                )}
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditProject

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}

const modulesQuill = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      [{ align: [] }],
      ["bold", "italic", "underline"],
      [
        { list: "ordered" },
        { list: "bullet" },
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
        { background: [] },
        "link",
        "emoji",
      ],
    ],
    handlers: {
      color: function (value) {
        if (value === "custom-color")
          value = window.prompt("Enter Hex Color Code")
        this.quill.format("color", value)
      },
    },
  },
  keyboard: {
    bindings: {
      tab: false,
      custom: {
        key: 13,
        shiftKey: true,
        handler: function (range, context) {
          this.quill.setSelection(range.index, "silent")
          this.quill.insertText(range.index, "\n", "user")
          this.quill.setSelection(range.index + 1, "silent")
          this.quill.format("linebreak", true, "user")
        },
      },
      handleEnter: {
        key: 13,
        handler: function (range, context) {
          this.quill.setSelection(range.index, "silent")
          this.quill.insertText(range.index, "\n", "user")
          this.quill.setSelection(range.index + 1, "silent")
          this.quill.format("linebreak", true, "user")
        },
      },
    },
  },
}

const formatsQuill = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "align",
  "link",
  "image",
  "background",
  "color",
  "emoji",
]
