import React, { Fragment, useState, useRef, useEffect } from "react"
import { Link } from "react-router-dom"
import MetaTags from "react-meta-tags"
// import Select from 'react-select'
import "@pathofdev/react-tag-input/build/index.css"

import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    Input,
    Label,
    Row,
    FormFeedback,
} from "reactstrap"
import Dropzone from "react-dropzone"

import { toast } from "react-toastify"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { useHistory, useParams } from "react-router-dom"
import axios from "axios"
import PulseLoader from "react-spinners/PulseLoader"

import Select from "react-select"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import "./addproject.scss"
import {
    setTitle
} from "../../../../../store/actions";
import { useDispatch, useSelector } from "react-redux"

import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css'; // ES6

import EditorToolbar, { modules, formats } from "./EditorToolbar";



const EditProduct = () => {
    const history = useHistory()
    const id = useParams()
    const dispatch = useDispatch()
    const cats = useSelector(state => state.MainData.categories)
    const projects = useSelector(state => state.projects.projects)  
    const [rows1, setrows1] = useState([{ id: 1 }])
    const [isOpen, setisOpen] = useState(false)
    const [photoIndex, setphotoIndex] = useState(0)
    const [loading, setLoading] = useState(false)
    const [categ, setCateg] = useState([])

    //form data collect
    const [coverImages, setCoverImages] = useState([])
    const [thumbImage, setThumbImage] = useState(null)
    const [facebookImage, setFacebookImage] = useState(null)
    const [titleEn, setTitleEn] = useState("")
    const [titleAr, setTitleAr] = useState("")
    const [createdEn, setCreatedEn] = useState("")
    const [createdAr, setCreatedAr] = useState("")
    const [availabilityEn, setAvailabilityEn] = useState("")
    const [availabilityAr, setAvailabilityAr] = useState("")
    const [smallDescEn, setSmallDescEn] = useState("")
    const [smallDescAr, setSmallDescAr] = useState("")
    const [state, setState] = useState("")
    const [link, setLink] = useState("")
    const [price, setPrice] = useState("")
    const [contactUs, setContactUs] = useState("")
    const [category, setCategory] = useState("")
    const [date, setDate] = useState("")
    const [informations, setInformations] = useState([
        { titleEn: "", descEn: "", titleAr: "", descAr: "" },
    ])
    const [infoEn, setInfoEn] = useState('')
    const [infoAr, setInfoAr] = useState('')
    const [selectedFiles, setselectedFiles] = useState([])
    const [seoFocusEn, setSeoFocusEn] = useState('');
    const [seoFocusAr, setSeoFocusAr] = useState('');
    const [seoTitleEn, setSeoTitleEn] = useState('')
    const [seoTitleAr, setSeoTitleAr] = useState('')
    const [seoDescEn, setSeoDescEn] = useState('')
    const [seoDescAr, setSeoDescAr] = useState('')
    const [seoSlugEn, setSeoSlugEn] = useState('')
    const [seoSlugAr, setSeoSlugAr] = useState('')
    const [seoFaceTitleEn, setSeoFaceTitleEn] = useState('')
    const [seoFaceTitleAr, setSeoFaceTitleAr] = useState('')
    const [seoFaceDescEn, setSeoFaceDescEn] = useState('')
    const [seoFaceDescAr, setSeoFaceDescAr] = useState('')
    
    
    const optionGroup = [
        {
        options: [
            { label: "m", value: "m" },
            { label: "lg", value: "lg" },
            { label: "xl", value: "xl" },
            { label: "xxl", value: "xxl" },
        ],
        },
    ]

    const options = [
        {
        label: "Primary Colors",
        options: [
            { label: "Yellow", value: "yellow" },
            { label: "Red", value: "red" },
            { label: "Blue", value: "blue" },
        ],
        },
        {
        label: "Secondary Colors",
        options: [
            { label: "Orange", value: "orange" },
            {
            label: "Purple",
            options: [
                { label: "Light Purple", value: "light_purple" },
                { label: "Medium Purple", value: "medium_purple" },
                { label: "Dark Purple", value: "dark_purple" },
            ],
            },
            { label: "Green", value: "green" },
        ],
        },
        {
        label: "White",
        value: "white",
        },
    ]

    function handleAddRowNested() {
        const modifiedRows = [...rows1]
        modifiedRows.push({ id: modifiedRows.length + 1 })
        setrows1(modifiedRows)
        setInformations(specifications =>
        specifications.concat({ titleEn: "", descEn: "", titleAr: "", descAr: "" }))
    }

    function handleRemoveRow(id) {
        if (id !== 1) {
        var modifiedRows = [...rows1]
        modifiedRows = modifiedRows.filter(x => x["id"] !== id)
        setrows1(modifiedRows)
        setInformations(specifications =>
            specifications.filter((c, i) => i != id - 1)
        )
        }
    }

    function informationsChange(e, key, type) {
        setInformations(specs =>
        specs.map((spec, index) => {
            if (index === key && type == "titleen") {
            return { ...spec, titleEn: e.target.value }
            } else if (index === key && type == "descen") {
            return { ...spec, descEn: e.target.value }
            } else if (index === key && type == "titlear") {
            return { ...spec, titleAr: e.target.value }
            } else if (index === key && type == "descar") {
            return { ...spec, descAr: e.target.value }
            }
            return spec
        })
        )
    }

    function handleFacebookFiles(e, files) {
        [...files].map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )
        setFacebookImage(Object.values(files)[0])
        e.target.value = '';
    }

    function handleThumbFiles(e, files) {
        [...files].map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )
        setThumbImage(Object.values(files)[0])

        e.target.value = '';
    }

    function handleCoverFiles(e, files) {
        [...files].map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )
        setCoverImages(Object.values(files))
        e.target.value = '';
    }

    function handleAcceptedFiles(files) {
        files.map(file =>
        Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
        })
        )
        setselectedFiles(fil => fil.concat(files))
    }

    function deleteSelectImage(e, id,key, type) {
        e.preventDefault()
        if(type=="cover") {
            axios.post(`${process.env.REACT_APP_API}/admin/projects/deleteSliderImage`, {id: key}, {
                headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("authUser")).authToken
                }`
                },
            })
            setCoverImages(files => files.filter((f, i) => i !== id))
        }else if(type=="thumb") {
            setThumbImage(null)
            
        }else if(type=="face") {
            setFacebookImage(null)
        }else {
            axios.post(`${process.env.REACT_APP_API}/admin/projects/deleteProjectImage`, {id: key}, {
                headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("authUser")).authToken
                }`
                },
            })
            setselectedFiles(files => files.filter((f, i) => i !== id))
        }
    }

    function prevSelectImage(e, id) {
        e.preventDefault()
        setphotoIndex(id)
        setisOpen(!isOpen)
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }
    

    function  editorState (editorState, type)  {
        type == "en"? setInfoEn(draftToHtml(convertToRaw(editorState.getCurrentContent()))):setInfoAr(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    };

    function submitHandler() {
        setLoading(true)
        let formData = new FormData()
        let data = {
            id: id.id,
            date,
            nameEn: titleEn,
            nameAr: titleAr,
            createdByEn: createdEn,
            createdByAr: createdAr,
            availabilityEn: availabilityEn,
            availabilityAr: availabilityAr,
            descEn: smallDescEn,
            descAr: smallDescAr,
            status: state,
            link: link,
            price,
            projectcatId: category,
            infotextEn: infoEn,
            infotextAr: infoAr,
            keyphraseEn: seoFocusEn,
            keyphraseAr: seoFocusAr,
            seoTitleEn: seoTitleEn,
            seoTitleAr: seoTitleAr,
            metadescriptionEn: seoDescEn,
            metadescriptionAr: seoDescAr,
            slugEn: seoSlugEn,
            slugAr: seoSlugAr,
            facebookTitlteEn: seoFaceTitleEn,
            facebookTitlteAr: seoFaceTitleAr,
            facebookDescriptionEn: seoFaceDescEn,
            facebookDescriptionAr: seoFaceDescAr,
        }

        for (let key in data) {
            formData.append(key, data[key])
        }

        selectedFiles.forEach(photo => {
            if(photo.name) {
                formData.append("media[]", photo);
            }
        }); 

        if(facebookImage && facebookImage.name) {
            formData.append("facebookImage", facebookImage);
        } 
        if(thumbImage && thumbImage.name) {
            formData.append("thumbnail", thumbImage);
        } 

        coverImages.forEach(photo => {
            if(photo.name) {
                formData.append("slider[]", photo);
            }
        }); 

        informations.forEach((spec,i) => {
            formData.append(`record[${i}][titleEn]`, spec.titleEn);
            formData.append(`record[${i}][titleAr]`, spec.titleAr);
            formData.append(`record[${i}][descEn]`, spec.descEn);
            formData.append(`record[${i}][descAr]`, spec.descAr);
        }); 

        axios
        .post(`${process.env.REACT_APP_API}/admin/projects/update`, formData, {
            headers: {
            Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("authUser")).authToken
            }`,
            },
        })
        .then(res => {
            setLoading(false)
            history.push('/layouti') 
        })
    }
    useEffect(() => {
        if(cats) {
            setCateg(cats)
        }
    }, [cats])
    useEffect(() => {
        dispatch(setTitle("Edit Project"))
        if(projects) {
            let data = projects.filter(p => p.id == id.id)[0]
            
            setTitleEn(data.nameEn)
            setTitleAr(data.nameAr)
            setCreatedEn(data.createdByEn)
            setCreatedAr(data.createdByAr)
            setAvailabilityEn(data.availabilityEn)
            setAvailabilityAr(data.availabilityAr)
            setSmallDescEn(data.descEn)
            setSmallDescAr(data.descAr)
            setDate(data.date)
            setState(data.status)
            setLink(data.link)
            setPrice(data.price || '')
            setInformations(data.records.map(r => {return{ titleEn: r.titleEn, descEn: r.descriptionEn, titleAr: r.titleAr, descAr: r.descriptionAr }}))
            setrows1(data.records.map((r, i)=>{return {id: i+1}}));
            setSeoFocusEn(data.keyphraseEn)
            setSeoFocusAr(data.keyphraseAr)
            setSeoTitleEn(data.seoTitleEn)
            setSeoTitleAr(data.seoTitleAr)
            setSeoSlugEn(data.slugEn)
            setSeoSlugAr(data.slugAr)
            setSeoDescEn(data.metadescriptionEn)
            setSeoDescAr(data.metadescriptionAr)
            setSeoFaceTitleEn(data.facebookTitlteEn)
            setSeoFaceTitleAr(data.facebookTitlteAr)
            setSeoFaceDescEn(data.facebookDescriptionEn)
            setSeoFaceDescAr(data.facebookDescriptionAr)
            setThumbImage({preview: data.thumbnail})
            setFacebookImage({preview: data.facebookImage})
            setCoverImages(data.sliders.map(s => {return{preview: s.image, id: s.key}}))
            setselectedFiles(data.images.map(s => {return{preview: s.image, id: s.key}}))
            setInfoEn(data.infotextEn)
            setInfoAr(data.infotextAr)
            setCategory(data.projectcatId)

            // let data = projects.filter(p => p.id == id.id)[0]
            // 
            // setTitleEn(data.nameEn)
            // setTitleAr(data.nameAr)
            // setCreatedEn(data.createdByEn)
            // setCreatedAr(data.createdByAr)
            // setAvailabilityEn(data.availabilityEn)
            // setAvailabilityAr(data.availabilityAr)
            // setSmallDescEn(data.descEn)
            // setSmallDescAr(data.descAr)
            // setDate(data.date)
            // setState(data.status)
            // setLink(data.link)
            // setPrice(data.price || '')
            // setInformations(data.records.map(r => {return{ titleEn: r.titleEn, descEn: r.descriptionEn, titleAr: r.titleAr, descAr: r.descriptionAr }}))
            // setrows1(data.records.map((r, i)=>{return {id: i+1}}));
            // setSeoFocusEn(data.keyphraseEn)
            // setSeoFocusAr(data.keyphraseAr)
            // setSeoTitleEn(data.seoTitleEn)
            // setSeoTitleAr(data.seoTitleAr)
            // setSeoSlugEn(data.slugEn)
            // setSeoSlugAr(data.slugAr)
            // setSeoDescEn(data.metadescriptionEn)
            // setSeoDescAr(data.metadescriptionAr)
            // setSeoFaceTitleEn(data.facebookTitlteEn)
            // setSeoFaceTitleAr(data.facebookTitlteAr)
            // setSeoFaceDescEn(data.facebookDescriptionEn)
            // setSeoFaceDescAr(data.facebookDescriptionAr)
            // setThumbImage({preview: data.thumbnail})
            // setFacebookImage({preview: data.facebookImage})
            // setCoverImages(data.sliders.map(s => {return{preview: s.image, id: s.key}}))
            // setselectedFiles(data.images.map(s => {return{preview: s.image, id: s.key}}))
            // setInfoEn(data.infotextEn)
            // setInfoAr(data.infotextAr)
            // setCategory(data.projectcatId)
        }
    }, [projects])

    return (
        <React.Fragment>
        <div className="page-content">
            <MetaTags>
            <title>Edit Product | Layouti</title>
            </MetaTags>
            <Container fluid>
            {isOpen ? (
                <Lightbox
                mainSrc={photoIndex}
                enableZoom={false}
                onCloseRequest={() => {
                    setisOpen(!isOpen)
                }}
                />
            ) : null}
            <Row>
                <Col xs="12">
                <Card>
                    <CardBody className="p-0">
                    <h2
                        className="head__title fs-4 pl-4 pt-3 mb-2"
                        style={{
                        color: "#000",
                        fontSize: "24px",
                        fontWeight: "700 !important",
                        marginLeft: "20px",
                        }}
                    >
                        <i
                        className="dripicons-arrow-thin-left fs-5 fw-bold"
                        style={{ cursor: "pointer" }}
                        onClick={() => history.goBack()}
                        ></i>{" "}
                        Product Information
                    </h2>
                    <Form className="p-4">
                        <Row>
                            <Col xs="12">
                                <div className="mb-3">
                                    <Label htmlFor="productdesc" >
                                        thumbnail Image
                                    </Label>
                                    <Input
                                        type="file"
                                        className="form-control form-control-lg "
                                        onChange={e => handleThumbFiles(e, e.target.files)}
                                        style={{borderRadius: 0}}
                                        multiple
                                    />
                                </div>
                            </Col>
                            <Col xs="12">
                                <div className="dropzone-previews mb-3" id="file-previews">
                                    {thumbImage?
                                        <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        >
                                            <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                <img
                                                    data-dz-thumbnail=""
                                                    height="80"
                                                    className="avatar-sm rounded bg-light"
                                                    alt={thumbImage.name}
                                                    src={thumbImage.preview}
                                                    style={{ objectFit: "cover" }}
                                                />
                                                </Col>
                                                <Col>
                                                <Link to="#" className="text-muted ">
                                                    {thumbImage.name}
                                                </Link>
                                                
                                                </Col>
                                                <Col>
                                                <div className="image__options">
                                                    <button
                                                    className="p-0"
                                                    onClick={e => prevSelectImage(e, thumbImage.preview)}
                                                    >
                                                    <i className="far fa-eye  fs-5 p-0"></i>
                                                    </button>
                                                    <button
                                                    style={{ color: "#ff3838" }}
                                                    onClick={e => deleteSelectImage(e, null, "thumb")}
                                                    >
                                                    <i className="bx bx-trash fs-4"></i>
                                                    </button>
                                                </div>
                                                </Col>
                                            </Row>
                                            </div>
                                        </Card>:null
                                        
                                    }
                                    </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="12">
                                <div className="mb-3">
                                    <Label htmlFor="productdesc" >
                                        Cover Images
                                    </Label>
                                    <Input
                                        type="file"
                                        className="form-control form-control-lg "
                                        onChange={e => handleCoverFiles(e, e.target.files)}
                                        style={{borderRadius: 0}}
                                        multiple
                                    />
                                </div>
                            </Col>
                            <Col xs="12">
                                <div className="dropzone-previews mb-3" id="file-previews">
                                    {coverImages.map((f, i) => {
                                        return (
                                        <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            key={i + "-file"}
                                        >
                                            <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                <img
                                                    data-dz-thumbnail=""
                                                    height="80"
                                                    className="avatar-sm rounded bg-light"
                                                    alt={f.name}
                                                    src={f.preview}
                                                    style={{ objectFit: "cover" }}
                                                />
                                                </Col>
                                                <Col>
                                                <Link to="#" className="text-muted ">
                                                    {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                    <strong>{f.formattedSize}</strong>
                                                </p>
                                                </Col>
                                                <Col>
                                                <div className="image__options">
                                                    <button
                                                    className="p-0"
                                                    onClick={e => prevSelectImage(e, f.preview)}
                                                    >
                                                    <i className="far fa-eye  fs-5 p-0"></i>
                                                    </button>
                                                    <button
                                                    style={{ color: "#ff3838" }}
                                                    onClick={e => deleteSelectImage(e, i, f.id, "cover")}
                                                    >
                                                    <i className="bx bx-trash fs-4"></i>
                                                    </button>
                                                </div>
                                                </Col>
                                            </Row>
                                            </div>
                                        </Card>
                                        )
                                    })}
                                    </div>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col sm="6">
                                <div className="mb-3" >
                                    <Label htmlFor="productdesc" >
                                        Project Name (english)
                                    </Label>
                                    <Input
                                        type="text"
                                        className="form-control input_cus"
                                        placeholder="e.g. Project Name"
                                        value={titleEn}
                                        onChange={e => setTitleEn(e.target.value)}
                                    />
                                </div>
                            </Col>

                            <Col sm="6">
                                <div className="mb-3">
                                <Label htmlFor="productdesc" >
                                    Project Name (arabic)
                                </Label>
                                <Input
                                    id="itemnamear"
                                    name="itemnamear"
                                    type="text"
                                    className="form-control input_cus input_cus"
                                    placeholder="مثال. اسم المشروع"
                                    value={titleAr}
                                    onChange={e => setTitleAr(e.target.value)}
                                    dir="rtl"
                                    lang="ar"
                                />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm="6">
                                <div className="mb-3">
                                    <Label htmlFor="productdesc" >
                                        Created By (english)
                                    </Label>
                                    <Input
                                        name="brandnameen"
                                        type="text"
                                        className="form-control input_cus"
                                        placeholder="e.g. Yasser Nazzal"
                                        value={createdEn}
                                        onChange={e => setCreatedEn(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col sm="6">
                                <div className="mb-3" >
                                    <Label htmlFor="productdesc" >
                                        Created By (arabic)
                                    </Label>
                                    <Input
                                        name="brandnamear"
                                        type="text"
                                        className="form-control input_cus"
                                        placeholder="مثال. ياسر نازال"
                                        value={createdAr}
                                        onChange={e => setCreatedAr(e.target.value)}
                                        dir="rtl"
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm="6">
                                <div className="mb-3" >
                                    <Label htmlFor="productdesc" >
                                        Availability Programs (english)
                                    </Label>
                                    <Input
                                        id="brandnameen"
                                        name="brandnameen"
                                        type="text"
                                        className="form-control input_cus"
                                        placeholder="e.g. Figma, Xd"
                                        value={availabilityEn}
                                        onChange={e => setAvailabilityEn(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col sm="6">
                                <div className="mb-3" >
                                    <Label htmlFor="productdesc" >
                                        Availability Programs (arabic)
                                    </Label>
                                    <Input
                                        id="brandnamear"
                                        name="brandnamear"
                                        type="text"
                                        className="form-control input_cus"
                                        placeholder="مثال. فيجما"
                                        value={availabilityAr}
                                        onChange={e => setAvailabilityAr(e.target.value)}
                                        dir="rtl"
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm="6">
                                <div className="mb-3" >
                                    <Label htmlFor="productdesc" >
                                        Small Description (English)
                                    </Label>
                                    <textarea
                                        className="form-control  mb-0"
                                        id="specification"
                                        rows="5"
                                        placeholder="Enter your description here"
                                        value={smallDescEn}
                                        onChange={e => setSmallDescEn(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col sm="6">
                                <div className="mb-3" >
                                    <Label htmlFor="productdesc" >
                                        Small Description (Arabic)
                                    </Label>
                                    <textarea
                                        className="form-control  mb-0"
                                        id="specification"
                                        rows="5"
                                        placeholder="اكتب وصف هنا"
                                        value={smallDescAr}
                                        onChange={e => setSmallDescAr(e.target.value)}
                                        dir="rtl"
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm="12">
                                <div className="mb-3" >
                                    <Label htmlFor="productdesc" >
                                        Date
                                    </Label>
                                    <Input
                                        id="brandnameen"
                                        name="brandnameen"
                                        type="date"
                                        className="form-control input_cus"
                                        placeholder="e.g. Figma, Xd"
                                        value={date}
                                        onChange={e => setDate(e.target.value)}
                                    />
                                </div>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col>
                            <div className="mb-3" >
                                    <Label htmlFor="productdesc" >
                                        State
                                    </Label>
                                    <select
                                        className="form-select fs-5 input_cus"
                                        value={state}
                                        onChange={e => setState(e.target.value)}
                                    >
                                    <option value="">Select</option>
                                    <option value="1">Free</option>
                                    <option value="2">Pay</option>
                                </select>
                                </div>
                            
                            </Col>
                        </Row>
                        <Row>
                        <Col >
                            <div className="mb-3">
                                <Label htmlFor="productdesc" className="">
                                    Link 
                                </Label>
                                <Input
                                    id="brandnameen"
                                    name="brandnameen"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g. https://www.example.com"
                                    value={link}
                                    onChange={e => setLink(e.target.value)}
                                />
                            </div>
                        </Col>
                        {state == 2? <Col >
                            <div className="mb-3" >
                                <Label htmlFor="productdesc" >
                                    Price
                                </Label>
                                <Input
                                    id="priceaftersale"
                                    name="priceaftersale"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g. 2359"
                                    value={price}
                                    onChange={e => setPrice(e.target.value)}
                                />
                            </div>
                        </Col>:null}
                        </Row>
                        <Row>
                            <Col>
                                <div className="mb-3" >
                                <Label htmlFor="productdesc" >
                                    Category
                                </Label>
                                <select className="form-control input_cus" value={category} onChange={e => setCategory(e.target.value)}>
                                    <option value={''}>Select</option>
                                    {categ.map(cat => <option key={cat.id} value={cat.id}>{cat.nameEn}</option>)}
                                </select>
                                <FormFeedback className="fs-6">
                                    This Fileld Is Required!
                                </FormFeedback>
                                </div>
                            </Col>
                        </Row>
                    </Form>

                    <Form className="p-4">

                        <Row>
                            <Col>
                                <h2 className="mb-0 mt-3 fs-4">Informations</h2>
                                <div className="inner-repeater mb-4">
                                {(rows1 || []).map((formRow, key) => (
                                    <Fragment key={key}>
                                    <Row>
                                        <Col>
                                        <Label htmlFor="productdesc" className="mt-3">
                                            title (English)
                                        </Label>
                                        <Input
                                            name="brandnameen"
                                            type="text"
                                            className="form-control input_cus"
                                            placeholder="e.g. title"
                                            value={informations[key].titleEn}
                                            onChange={(e) => informationsChange(e, key,"titleen")}
                                        />
                                        </Col>
                                        <Col>
                                        <Label htmlFor="productdesc" className="mt-3">
                                            title (Arabic)
                                        </Label>
                                        <Input
                                            name="brandnameen"
                                            type="text"
                                            className="form-control input_cus"
                                            placeholder="مثال. عنوان"
                                            value={informations[key].titleAr}
                                            onChange={(e) => informationsChange(e, key,"titlear")}
                                            dir="rtl"
                                            />
                                        </Col>
                                    </Row>
                                    
                                    <Row className="mb-0 mt-3">
                                    <Col>
                                        <Label htmlFor="productdesc" >
                                        Description (English)
                                        </Label>
                                        <textarea
                                            className="form-control  mb-0"
                                            rows="5"
                                            placeholder="Enter your description here"
                                            value={informations[key].descEn}
                                            onChange={(e) => informationsChange(e, key,"descen")}
                                        />
                                        </Col>
                                        <Col>
                                        <Label htmlFor="productdesc" >
                                            Description (Arabic)
                                        </Label>
                                        <textarea
                                            className="form-control  mb-0"
                                            rows="5"
                                            placeholder="اكتب وصف هنا"
                                            value={informations[key].descAr}
                                            onChange={(e) => informationsChange(e, key,"descar")}
                                            dir="rtl"
                                        />
                                        </Col>
                                    </Row>
                                    {key !== 0 ? (
                                        <Button
                                        color="none"
                                        variant="primary"
                                        className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                                        id="unknown-btn"
                                        style={{
                                            color: "#ff3838",
                                            boxShadow: "none",
                                            height: "45px",
                                            borderRadius: 0,
                                        }}
                                        onClick={e => {
                                            handleRemoveRow(formRow.id)
                                        }}
                                        >
                                        <i className="bx bx-trash-alt fs-4 p-0 mr-1"></i>{" "}
                                        Delete
                                        </Button>
                                    ) : null}
                                    </Fragment>
                                ))}
                                <Button
                                    onClick={() => {
                                    handleAddRowNested()
                                    }}
                                    color="none"
                                    className="mt-2 fs-5 fw-bold p-0 text-left"
                                    style={btnStyle}
                                >
                                    <i className="bx bx-plus-circle fs-4"></i>{" "}
                                    <span>Add Information</span>
                                </Button>
                                </div>
                            </Col>
                        </Row>


                        <Row>
                            <Col>
                                <Label htmlFor="productdesc" >
                                    Information (English)
                                </Label>
                                <ReactQuill 
                                    value={infoEn}
                                    onChange={data => setInfoEn(data)} 
                                    modules={modulesQuill}
                                    formats={formatsQuill}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-0 mt-3">
                            <Col>
                                <Label htmlFor="productdesc" >
                                    Information (Arabic)
                                </Label>
                                <ReactQuill 
                                    value={infoAr}
                                    onChange={data => setInfoAr(data)} 
                                    modules={modulesQuill}
                                    formats={formatsQuill}
                                />
                            </Col>
                        </Row>
                    </Form>
                    <h2
                        className="mb-3 mt-4 fs-4 pl-4"
                        style={{ marginLeft: "20px" }}
                    >
                        Project Images
                    </h2>
                    <div className="d-flex justify-content-between">
                        <Label
                        className="fs-5  pl-4 "
                        style={{ marginLeft: "20px" }}
                        >
                            Attached image
                        </Label>
                        
                    </div>
                    <Form
                        className="pl-4 pr-4 pb-4"
                        style={{ paddingLeft: "20px", paddingRight: "20px" }}
                    >
                        <Dropzone
                        onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                        }}
                        >
                        {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                            <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                                </div>
                            </div>
                            </div>
                        )}
                        </Dropzone>
                        <div className="dropzone-previews mt-3" id="file-previews">
                        {selectedFiles.map((f, i) => {
                            return (
                            <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                            >
                                <div className="p-2">
                                <Row className="align-items-center">
                                    <Col className="col-auto">
                                    <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                        style={{ objectFit: "cover" }}
                                    />
                                    </Col>
                                    <Col>
                                    <Link to="#" className="text-muted ">
                                        {f.name}
                                    </Link>
                                    <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                    </p>
                                    </Col>
                                    <Col>
                                    <div className="image__options">
                                        <button
                                        className="p-0"
                                        onClick={e => prevSelectImage(e, f.preview)}
                                        >
                                        <i className="far fa-eye  fs-5 p-0"></i>
                                        </button>
                                        <button
                                        style={{ color: "#ff3838" }}
                                        onClick={e => deleteSelectImage(e, i, f.id, 'null')}
                                        >
                                        <i className="bx bx-trash fs-4"></i>
                                        </button>
                                    </div>
                                    </Col>
                                </Row>
                                </div>
                            </Card>
                            )
                        })}
                        </div>
                    </Form>
                    {/* <Form className="p-4">
                        <h2
                            className="mb-3 mt-3 fs-4"
                        >
                            SEO
                        </h2>
                        <Row>
                            <Col sm="6">
                                <div className="mb-3" >
                                    <Label htmlFor="productdesc" >
                                        Focus Keypharse (english)
                                    </Label>
                                    <Input
                                        id="itemnameen"
                                        name="itemnameen"
                                        type="text"
                                        className="form-control input_cus"
                                        placeholder="e.g. Focus Keypharse"
                                        value={seoFocusEn}
                                        onChange={e => setSeoFocusEn(e.target.value)}
                                    />
                                </div>
                            </Col>

                            <Col sm="6">
                                <div className="mb-3">
                                <Label htmlFor="productdesc" >
                                    Focus Keypharse (arabic)
                                </Label>
                                <Input
                                    id="itemnamear"
                                    name="itemnamear"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="مثال. "
                                    value={seoFocusAr}
                                    onChange={e => setSeoFocusAr(e.target.value)}
                                    dir="rtl"
                                    lang="ar"
                                />
                                </div>
                            </Col>
                        </Row>


                        <Row>
                            <Col sm="6">
                                <div className="mb-3">
                                    <Label htmlFor="productdesc" >
                                        Title (english)
                                    </Label>
                                    <Input
                                        id="brandnameen"
                                        name="brandnameen"
                                        type="text"
                                        className="form-control input_cus"
                                        placeholder="e.g. Title"
                                        value={seoTitleEn}
                                        onChange={e => setSeoTitleEn(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col sm="6">
                                <div className="mb-3" >
                                    <Label htmlFor="productdesc" >
                                        Title (arabic)
                                    </Label>
                                    <Input
                                        id="brandnamear"
                                        name="brandnamear"
                                        type="text"
                                        className="form-control input_cus"
                                        placeholder="مثال. عنوان"
                                        value={seoTitleAr}
                                        onChange={e => setSeoTitleAr(e.target.value)}
                                        dir="rtl"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <div className="mb-3">
                                    <Label htmlFor="productdesc" >
                                        Slug (english)
                                    </Label>
                                    <Input
                                        id="brandnameen"
                                        name="brandnameen"
                                        type="text"
                                        className="form-control input_cus"
                                        placeholder="e.g. Slug"
                                        value={seoSlugEn}
                                        onChange={e => setSeoSlugEn(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col sm="6">
                                <div className="mb-3" >
                                    <Label htmlFor="productdesc" >
                                        Slug (arabic)
                                    </Label>
                                    <Input
                                        id="brandnamear"
                                        name="brandnamear"
                                        type="text"
                                        className="form-control input_cus"
                                        placeholder="مثال. وصف"
                                        value={seoSlugAr}
                                        onChange={e => setSeoSlugAr(e.target.value)}
                                        dir="rtl"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <div className="mb-3" >
                                    <Label htmlFor="productdesc" >
                                        Description (English)
                                    </Label>
                                    <textarea
                                        className="form-control  mb-0"
                                        id="specification"
                                        rows="5"
                                        placeholder="Enter your description here"
                                        value={seoDescEn}
                                        onChange={e => setSeoDescEn(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col sm="6">
                                <div className="mb-3" >
                                    <Label htmlFor="productdesc" >
                                        Description (Arabic)
                                    </Label>
                                    <textarea
                                        className="form-control  mb-0"
                                        id="specification"
                                        rows="5"
                                        placeholder="اكتب وصف هنا"
                                        value={seoDescAr}
                                        onChange={e => setSeoDescAr(e.target.value)}
                                        dir="rtl"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <div className="mb-3">
                                    <Label htmlFor="productdesc" >
                                        Facebook Image
                                    </Label>
                                    <Input
                                        type="file"
                                        className="form-control form-control-lg "
                                        onChange={e => handleFacebookFiles(e, e.target.files)}
                                        style={{borderRadius: 0}}
                                        multiple
                                    />
                                </div>
                            </Col>
                            <Col xs="12">
                                <div className="dropzone-previews mb-3" id="file-previews">
                                    {facebookImage?
                                        <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        >
                                            <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                <img
                                                    data-dz-thumbnail=""
                                                    height="80"
                                                    className="avatar-sm rounded bg-light"
                                                    alt={facebookImage.name}
                                                    src={facebookImage.preview}
                                                    style={{ objectFit: "cover" }}
                                                />
                                                </Col>
                                                <Col>
                                                <Link to="#" className="text-muted ">
                                                    {facebookImage.name}
                                                </Link>
                                                
                                                </Col>
                                                <Col>
                                                <div className="image__options">
                                                    <button
                                                    className="p-0"
                                                    onClick={e => prevSelectImage(e, facebookImage.preview)}
                                                    >
                                                    <i className="far fa-eye  fs-5 p-0"></i>
                                                    </button>
                                                    <button
                                                    style={{ color: "#ff3838" }}
                                                    onClick={e => deleteSelectImage(e, null, "face")}
                                                    >
                                                    <i className="bx bx-trash fs-4"></i>
                                                    </button>
                                                </div>
                                                </Col>
                                            </Row>
                                            </div>
                                        </Card>:null
                                        
                                    }
                                    </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <div className="mb-3">
                                    <Label htmlFor="productdesc" >
                                        Facebook Title (english)
                                    </Label>
                                    <Input
                                        id="brandnameen"
                                        name="brandnameen"
                                        type="text"
                                        className="form-control input_cus"
                                        placeholder="e.g. Title"
                                        value={seoFaceTitleEn}
                                        onChange={e => setSeoFaceTitleEn(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col sm="6">
                                <div className="mb-3" >
                                    <Label htmlFor="productdesc" >
                                        Facebook Title (arabic)
                                    </Label>
                                    <Input
                                        id="brandnamear"
                                        name="brandnamear"
                                        type="text"
                                        className="form-control input_cus"
                                        placeholder="مثال. عنوان"
                                        value={seoFaceTitleAr}
                                        onChange={e => setSeoFaceTitleAr(e.target.value)}
                                        dir="rtl"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <div className="mb-3" >
                                    <Label htmlFor="productdesc" >
                                        Facebook Description (English)
                                    </Label>
                                    <textarea
                                        className="form-control  mb-0"
                                        id="specification"
                                        rows="5"
                                        placeholder="Enter your description here"
                                        value={seoFaceDescEn}
                                        onChange={e => setSeoFaceDescEn(e.target.value)}
                                    />
                                </div>
                            </Col>
                            <Col sm="6">
                                <div className="mb-3" >
                                    <Label htmlFor="productdesc" >
                                        Facebook Description (Arabic)
                                    </Label>
                                    <textarea
                                        className="form-control mb-0"
                                        id="specification"
                                        rows="5"
                                        placeholder="اكتب وصف هنا"
                                        value={seoFaceDescAr}
                                        onChange={e => setSeoFaceDescAr(e.target.value)}
                                        dir="rtl"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form> */}
                    </CardBody>
                </Card>
                </Col>
            </Row>
            <Row className="submit__btn footer fixed fixed-bottom">
                <Col lg={10} md={10}>
                <button className="cancel mr-3" onClick={() => history.goBack()}>
                    cancel
                </button>{" "}
                <button className="save" onClick={submitHandler}>
                    {loading ? (
                    <PulseLoader color={"#ffffff"} loading={true} size={10} />
                    ) : (
                    "Save"
                    )}
                </button>
                </Col>
            </Row>
            </Container>
        </div>
        </React.Fragment>
    )
}

export default EditProduct

const btnStyle = {
    boxShadow: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    paddingLeft: "0",
}



const modulesQuill = {
toolbar: {
    container: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'font': []}],
    [ { 'align': []}],
    ['bold', 'italic', 'underline'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'custom-color'] }, { 'background': [] }, 'link', 'emoji'],
    ],
    handlers: {
    'color': function (value) {
        if (value == 'custom-color') value = window.prompt('Enter Hex Color Code');
        this.quill.format('color', value);
    }
    }
},
keyboard: {
    bindings: {
    tab: false,
    custom: {
        key: 13,
        shiftKey: true,
        handler: function (range,context) {
            this.quill.setSelection(range.index,'silent');
            this.quill.insertText(range.index, '\n', 'user')
            this.quill.setSelection(range.index +1,'silent');
            this.quill.format('linebreak', true,'user');
        }
    },
    handleEnter: {
        key: 13,
        handler: function (range,context) {
            this.quill.setSelection(range.index,'silent');
            this.quill.insertText(range.index, '\n', 'user')
            this.quill.setSelection(range.index +1,'silent');
            this.quill.format('linebreak', true,'user');
        }
    }
    }
}
};

const formatsQuill = [
'header', 'font', 'size',
'bold', 'italic', 'underline', 'strike', 'blockquote',
'list', 'bullet', 'indent', 'align',
'link', 'image', 'background', 'color', 'emoji'
];