import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import classnames from "classnames";
import {
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import Sticky from 'react-sticky-el';
import './configration.scss'
import axios from "axios";
import { useSelector, useDispatch } from "react-redux"
import {
    setTitle
} from "../../../store/actions";
import { useHistory } from "react-router-dom";
import UiGenerator from "./Components/UiGenerator";
import ContentGenerator from "./Components/ContentGenerator";
import Design365 from "./Components/Design365";
import Products from "./Components/Products/Products";
// import Products from "./Components/Products";
import Links from "./Components/Links";
import LearnUiComp from "./Components/LearnUiComp";
import CVComp from "./Components/CVComp";
import axiosConfig from "axiosConfig";

const Pages365 = () => {
  const [pageData, setPageData] = useState([])
  const dispatch = useDispatch()
    const history  = useHistory()
    const   user = useSelector(state => state.UserData.user)
    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };


    function userChanges(user, type) {
        if(type === "active"){
            setActive(users => users.concat(user))
            setUnActive(users => users.filter(u => u.key !== user.key ))
        } else if(type === "unactive"){
            setActive(users => users.filter(u => u.key !== user.key ))
            setUnActive(users => users.concat(user))
        }else if(type === "del") {
            setActive(users => users.filter(u => u.key !== user.key ))
        }else {
            setPending(users => users.filter(u => u.key !== user.key ))
            setUnActive(users => users.concat(user))
        }
    }

    function getData (){
        axiosConfig.get(`/dashboard/365Design`,{
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
            }
            }).then(res => {
                setPageData(res.data.data)
            })
    }
    useEffect(()=>{
        getData()
    },[])
    
    useEffect(() => {
        if(user && user.role == 2) {
            history.push('/dashboard')
            return;
        } 
    }, [user])



    useEffect(() => {   
        dispatch(setTitle("Pages"))
    }, [])

    return (
        <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>Pages | Layouti</title>
        </MetaTags>
        <Container fluid>
        <Col>
            <Row>
            <Col md="3">
            <Sticky topOffset={-400}>
                <Nav pills className="flex-column justify-content-between navs_aside">

                    <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "1",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("1");
                        }}
                        >
                        365 Design
                        </NavLink>
                    </NavItem>
                    <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "4",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("4");
                        }}
                        >
                            Products
                        </NavLink>
                    </NavItem>
                    <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "5",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("5");
                        }}
                        >
                            Links
                        </NavLink>
                    </NavItem>
                    {/* <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "6",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("6");
                        }}
                        >
                            Learn Ui
                        </NavLink>
                    </NavItem> */}
                    <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "7",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("7");
                        }}
                        >
                            CV
                        </NavLink>
                    </NavItem>
                    {/* <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "7",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("7");
                        }}
                        >
                            Contact us 
                        </NavLink>
                    </NavItem> */}
                </Nav>
                </Sticky>
            </Col>
            <Col md="9">
                <TabContent
                activeTab={verticalActiveTab}
                className="text-muted table__content mt-4 mt-md-0"
                >
                    <TabPane tabId="1">
                        <Design365 sectionData={pageData?.DesignHeaderContent}/>
                    </TabPane>
                    <TabPane tabId="4">
                        <Products sectionData={pageData?.DesignProductsStatic}/>
                    </TabPane>
                    <TabPane tabId="5">
                        <Links sectionData={pageData?.DesignLinks}/>
                    </TabPane>
                    <TabPane tabId="7" >
                        <CVComp/>
                    </TabPane>
                    </TabContent>
            </Col>
            </Row>
        </Col>
        </Container>
        </div>
    </React.Fragment>
)
}

export default Pages365



const navsStyle = { 
    cursor: "pointer", 
    borderRadius: 0,

}