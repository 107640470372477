/* eslint-disable react/prop-types */
import axios from 'axios';
import React, {useState, useRef, useEffect} from 'react';
import { Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";
import "@pathofdev/react-tag-input/build/index.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import * as xlsx from 'xlsx'
import '../specification.scss'
import axiosConfig from 'axiosConfig';


const TitlesEditForm = (props) => {
    const [isOpen, setisOpen] = useState(false);
    const [loading, setLoading] = useState(false)    
    const [titleEn, settitleEn] = useState(null);
    const [titleAr, settitleAr] = useState(null);
    const [id, setID] = useState(null);
    
    const close = (type) => {
        document.querySelector('#titlesForm').reset()
        props.clickHandle(null, false, type)
    }

  

    function submitHandler() {
        setLoading(true)
        let data = {
            id: id,
            titleEn : titleEn,
            titleAr : titleAr
        }


        axiosConfig.post(`/dashboard/uiAvatar/contentGenerator/title/edit`,data, {
            headers: {
              'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
            }
          })
        .then(res => {
            setLoading(false)
            if(res.data.error) {
                
                return;
            }
            settitleEn(null)
            settitleAr(null)
            setID(null)
            close(true)
            props.update(res.data.data,"title")
        })
    }

    useEffect(() => {
        if(props?.data){
            settitleEn(props.data.titleEn)
            settitleAr(props.data.titleAr)
            setID(props.data.id)
        }
        
      }, [props.data ]) 

    return(
        <div className={`overlay_coup ${props.active? "active":""}`}>
            {isOpen ? (
            <Lightbox
                mainSrc={image.preview}
                enableZoom={false}
                onCloseRequest={() => {
                setisOpen(!isOpen);
                }}
            />
            ) : null}
            <Form id='titlesForm' onSubmit={(e) => e.preventDefault()} style={{paddingBottom: "70px", height: "340px"}}>
                <div className='head_form'>
                    <div className='log'>
                    <i className='bx bx-text fs-3'></i>
                        Edit Title
                    </div>
                    <button onClick={() => close(false)}><i className='dripicons dripicons-cross'></i></button>
                </div>
                <div className='inputs__wrap '>
                    <Row>
                        <Col className='col-12'>
                            <div className="mb-3" >
                                <Label htmlFor="productdesc">
                                Title (English)
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control form-control-lg "
                                    value={titleEn}
                                    onChange=
                                    {
                                    
                                        e => settitleEn(e.target.value)
                                    }
                                    style={{borderRadius: 0}}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                        <Col className='col-12'>
                            <div className="mb-3" >
                                <Label htmlFor="productdesc">
                                Title (Arabic)
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control form-control-lg "
                                    value={titleAr}
                                    onChange=
                                    {
                                    
                                        e => settitleAr(e.target.value)
                                    }
                                    style={{borderRadius: 0}}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='foot_form'>
                    <button className='cancel' onClick={() => close(false)}>Cancel</button>
                    <button className='save' onClick={submitHandler}>{loading?<PulseLoader color={"#ffffff"} loading={true}  size={10} />:"Save"}</button>
                </div>
            </Form>
        </div>
    );
};

export default TitlesEditForm;
