import React, { Fragment, useEffect, useState } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Collapse, Button
  } from "reactstrap"
  import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
// import { MultiSelect } from "react-multi-select-component";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { MultiSelect, SelectItem } from "react-multi-select-component";
import axiosConfig from "axiosConfig";
import { toast } from "react-toastify";
import SweetAlert from "react-bootstrap-sweetalert";
// import Select from "react-select/dist/declarations/src/Select";

const OurLatestWork = ({sectionData}) => {
    const [rows2, setrows2] = useState([{ id: 1 }])
    const [multiData, setMultiData] = useState([{
      id:1, 
      titleEn:"",
      titleAr: '', 
      descriptionEn: '', 
      descriptionAr: '', 
      dateEn: '', 
      dateAr: '', 
  }])
  
  function handleChangeData(keyName,keyValue,key) {
    let modifiedData = [...multiData]
    modifiedData[key][keyName]=keyValue
    setMultiData(modifiedData)
    
  }


    function handleAddRowNested() {
      const modifiedRows = [...rows2]
      modifiedRows.push({ id: modifiedRows.length + 1 })
      setrows2(modifiedRows)
      setMultiData(data => data.concat({
        id:modifiedRows.length, 
        titleEn:"",
        titleAr: '', 
        descriptionEn: '', 
        descriptionAr: '', 
        dateEn: '', 
        dateAr: '', 
      }))
    }
  
    function handleRemoveRow(id) {
      if (id !== 0) {
        var modifiedRows = [...rows2]
        modifiedRows = modifiedRows.filter(x => x["id"] !== id)
        setrows2(modifiedRows)
        setMultiData(d => d.filter((x,i) => i !== id))
      }
    }

    function deleteCard(key,cardId,status){
      //
      if(status==='old'){
        axiosConfig.post(`/dashboard/thingsPage/OpportunityAlwaysExists/deleteCard`, {id:cardId}, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }).then(res=>{
          setMsg(`Deleted Successfully`)
          setsuccess_msg(true)
          handleRemoveRow(key,cardId)
        }).catch(err=>{
          toast.error(err.response.data.message)
        })
      }else{
        handleRemoveRow(key,cardId)
      }
  
    }
  const [isLoading, setIsLoading] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  function submit() {
    const formData = new FormData()
  
    multiData.forEach((item,index)=>{
      formData.append(`cards[${index}][titleEn]`, item.titleEn)
      formData.append(`cards[${index}][titleAr]`, item.titleAr)
      formData.append(`cards[${index}][descriptionEn]`, item.descriptionEn)
      formData.append(`cards[${index}][descriptionAr]`, item.descriptionAr)
      formData.append(`cards[${index}][dateEn]`, item.dateEn)
      formData.append(`cards[${index}][dateAr]`, item.dateAr)
    })
    setIsLoading(true)
    axiosConfig.post(`/dashboard/thingsPage/OpportunityAlwaysExists`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res=>{
      //
       toast.success(res.data.message)
      setsuccess_msg(true)
      setIsLoading(false)
    }).catch(err=>{
      //
      toast.error(err.response.data.message)
      setIsLoading(false)
    })
  }
    
  useEffect(()=>{
    sectionData&&setMultiData(sectionData)
    //
  },[sectionData])

  return (
    <div>
       {success_msg ? (
        <SweetAlert
          title={msg}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        >
        </SweetAlert>
      ) : null}
      <Form className="border-0 pb-2">
      {(multiData || []).map((formRow, key) => (
          <Fragment key={key}>

            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Title (english)</Label>
                  <Input
                    id="itemnameen"
                    name="titleEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Focus Keypharse"
                    value={formRow.titleEn}
                    onChange={e => handleChangeData(e.target.name,e.target.value,key)}
                  />
                </div>
              </Col>

              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Title (arabic)</Label>
                  <Input
                    id="itemnamear"
                    name="titleAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. "
                    value={formRow.titleAr}
                    onChange={e => handleChangeData(e.target.name,e.target.value,key)}
                    dir="rtl"
                    lang="ar"
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (English)</Label>
                  <textarea
                    className="form-control  mb-0"
                    id="specification"
                    rows="5"
                    name="descriptionEn"
                    placeholder="Enter your description here"
                    value={formRow.descriptionEn}
                    onChange={e => handleChangeData(e.target.name,e.target.value,key)}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (Arabic)</Label>
                  <textarea
                    className="form-control  mb-0"
                    id="specification"
                    name="descriptionAr"
                    rows="5"
                    placeholder="اكتب وصف هنا"
                    value={formRow.descriptionAr}
                    onChange={e => handleChangeData(e.target.name,e.target.value,key)}
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Date (english)</Label>
                  <Input
                    id="itemnameen"
                    name="dateEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Focus Keypharse"
                    value={formRow.dateEn}
                    onChange={e => handleChangeData(e.target.name,e.target.value,key)}
                  />
                </div>
              </Col>

              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Date (arabic)</Label>
                  <Input
                    id="itemnamear"
                    name="dateAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. "
                    value={formRow.dateAr}
                    onChange={e => handleChangeData(e.target.name,e.target.value,key)}
                    dir="rtl"
                    lang="ar"
                  />
                </div>
              </Col>
            </Row>
            {key !== 0 ? (
              <Button
                color="none"
                variant="primary"
                className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                id="unknown-btn"
                style={{
                  color: "#ff3838",
                  boxShadow: "none",
                  height: "45px",
                  borderRadius: 0,
                }}
                onClick={e => {
                  deleteCard(key,formRow.id,formRow?.status=='new'?'new':'old')
                }}
              >
                <i className="bx bx-trash-alt fs-4 p-0 mr-1" /> Delete
              </Button>
            ) : null}
            {
              key!=0?
              <hr style={{ margin: "5px -20px 20px -20px" }} />
              :
              <hr style={{ margin: "25px -20px 20px -20px" }} />
            }
          </Fragment>
        ))}
        
        <Button
          onClick={() => {
            handleAddRowNested()
          }}
          color="none"
          className="mt-2 fs-5 fw-bold p-0 text-left"
          style={btnStyle}
        >
          <i className="bx bx-plus-circle fs-4" /> <span>Add Projects</span>
        </Button>
        <hr style={{ margin: "20px -20px 0px -20px" }} />
        <Row className="mt-4">
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0">
              <button type='button' onClick={submit} disabled={isLoading?true:false}>
                {isLoading ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default OurLatestWork



const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
