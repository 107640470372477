/* eslint-disable react/prop-types */
import React, { useState } from "react"
import { Card, CardBody, Collapse } from "reactstrap"
import classnames from "classnames"
import "./specification.scss"
import "react-image-lightbox/style.css"
// Form Editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import HeaderContent from "./CV/HeaderContent"
import CanAttend from "./LearnUi/CanAttend"
import Experience from "./CV/Experience"
import HightlitsDesign from "./LearnUi/HightlitsDesign"
import Testimonials from "./LearnUi/Testimonials"
import UiDesign from "./LearnUi/UiDesign"
import UiUxDesign from "./LearnUi/UiUxDesign"
import UxDesign from "./LearnUi/UxDesign"
import StepsReach from "./LearnUi/StepsReach"
import Questions from "./LearnUi/Questions"
import CoverLetter from "./CV/CoverLetter"
import Interest from "./CV/Interest"
import Courses from "./CV/Courses"
import Languages from "./CV/Languages"
import Skills from "./CV/Skills"
import Education from "./CV/Education"

const CVComp = () => {
  const [col, setCol] = useState([
    { id: 0, status: false },
    { id: 1, status: false },
    { id: 2, status: false },
    { id: 3, status: false },
    { id: 4, status: false },
    { id: 5, status: false },
    { id: 6, status: false },
    { id: 7, status: false },
    { id: 8, status: false },
    { id: 9, status: false },
    { id: 10, status: false },
  ])

  function ChangeColState(id) {
    setCol(col =>
      col.map(col => {
        if (col.id === id) {
          return { ...col, status: !col.status }
        }
        return { ...col, status: false }
      })
    )
  }

  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        <Card>
          <CardBody>
            <div className="accordion" id="accordion">

              {/* Header Collapse */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[1].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(1)}
                    style={{ cursor: "pointer" }}
                  >
                    Header Content
                  </button>
                </h2>
                <Collapse isOpen={col[1].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <HeaderContent />
                  </div>
                </Collapse>
              </div>

              {/* Can Attend Collapse */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[2].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(2)}
                    style={{ cursor: "pointer" }}
                  >
                    Cover Letter
                  </button>
                </h2>
                <Collapse isOpen={col[2].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <CoverLetter />
                  </div>
                </Collapse>
              </div>

              {/* Experiences Collapse */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[3].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(3)}
                    style={{ cursor: "pointer" }}
                  >
                    Experiences
                  </button>
                </h2>
                <Collapse isOpen={col[3].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <Experience />
                  </div>
                </Collapse>
              </div>

              {/* Education Package */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[6].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(6)}
                    style={{ cursor: "pointer" }}
                  >
                    Education
                  </button>
                </h2>
                <Collapse isOpen={col[6].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <Education />
                  </div>
                </Collapse>
              </div>

              {/* Courses Package */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[7].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(7)}
                    style={{ cursor: "pointer" }}
                  >
                    Courses
                  </button>
                </h2>
                <Collapse isOpen={col[7].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <Courses />
                  </div>
                </Collapse>
              </div>

              {/* Languages Package */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[8].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(8)}
                    style={{ cursor: "pointer" }}
                  >
                    Languages
                  </button>
                </h2>
                <Collapse isOpen={col[8].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <Languages />
                  </div>
                </Collapse>
              </div>

              {/* Skills collapse */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[9].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(9)}
                    style={{ cursor: "pointer" }}
                  >
                    Skills
                  </button>
                </h2>
                <Collapse isOpen={col[9].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <Skills />
                  </div>
                </Collapse>
              </div>

              {/* Interest Collapse */}
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[10].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(10)}
                    style={{ cursor: "pointer" }}
                  >
                    Interest
                  </button>
                </h2>
                <Collapse
                  isOpen={col[10].status}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <Interest />
                  </div>
                </Collapse>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default CVComp

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "green",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}
