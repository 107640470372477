import classNames from 'classnames'
import React, { useState } from 'react'
import { Card, CardBody, Col, Collapse, Label, Row } from 'reactstrap'
import ChallengesForm from './DesignAppComponents/ChallengesForm'
import DeliverablesForm from './DesignAppComponents/DeliverablesForm'
import DesignAppProjectInfoForm from './DesignAppComponents/DesignAppProjectInfoForm'
import ExportScreenForm from './DesignAppComponents/ExportScreenForm'
import ImageForm from './DesignAppComponents/ImageForm'
import ImagesSectionsFrom from './DesignAppComponents/ImagesSectionsFrom'
import IntroducingForm from './DesignAppComponents/IntroducingForm'
import LetsCheckForm from './DesignAppComponents/LetsCheckForm'
import MobileAppsForm from './DesignAppComponents/MobileAppsForm'
import PersonaImagesForm from './DesignAppComponents/PersonaImagesForm'
import ResultForm from './DesignAppComponents/ResultForm'
import SectionsForm from './DesignAppComponents/SectionsForm'
import SectionsTwoForm from './DesignAppComponents/SectionsTwoForm'
import TaskDescriptionForm from './DesignAppComponents/TaskDescriptionForm'
import WhatIsTheProjectForm from './DesignAppComponents/WhatIsTheProjectForm'
import WhatIsTheProject from './DesignAppComponents/WhatIsTheProjectForm'
import ReactLoading from "react-loading"
import { toast } from "react-toastify"
import axios from 'axios'
const DesignAppForm = ({data, ChangeHandler,handleAcceptedImage, setImgSrc,setisOpen}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [col, setCol] = useState([
    { id: 1, status: false  },
    { id: 2, status: false  },
    { id: 3, status: false  },
    { id: 4, status: false  },
    { id: 5, status: false  },
    { id: 6, status: false  },
    { id: 7, status: false  },
    { id: 8, status: false  },
    { id: 9, status: false  },
    { id: 10, status: false },
    { id: 11, status: false },
    { id: 12, status: false },
    { id: 13, status: false },
    { id: 14, status: false },
    { id: 15, status: false },
  ])

  function ChangeColState(id) {
    setCol(col =>
      col.map(col => {
        if (col.id == id) {
          
          return { ...col, status: !col.status }
        }
        return { ...col, status: false }
      })
    )
  }

  function submit(e) {
    e.preventDefault()
    setIsLoading(true)
    const formData = new FormData();
    
    for (let key in data.introducingData) {
        formData.append(key, data.introducingData[key])
    }
    for (let key in data.taskDescriptionData) {
        formData.append(key, data.taskDescriptionData[key])
    }
  
    /**************deliverablesData***********/
     for (let key in data.deliverablesData) {
      if(key !== "cards" && key !== "DesignAppDeliverables_deliverables") {
          formData.append(key, data.deliverablesData[key])
      }
      }
      if(data.deliverablesData.DesignAppDeliverables_deliverables.length !== 0){
          data.deliverablesData.DesignAppDeliverables_deliverables.forEach((d,i) => {
              formData.append(`DesignAppDeliverables_deliverables[${i}]`, d.value)
          })
      }else { 
          formData.append(`DesignAppDeliverables_deliverables`, null)
      }
    /**************deliverablesData***********/
    /**************design app image***********/
      for (let key in data.designAppImageImage) {
        if(key !== "cards") {
          if(data.designAppImageImage[key]) {
            formData.append(key, data.designAppImageImage[key])
          }else { 
            formData.append(key, null)
          }
        }
      }
    /**************design app image***********/

    /**************project information***********/
    data.designAppProjectInfo.forEach((d,i) => {
      formData.append(`DesignAppProjectInfo[${i}][labelEn]`, d.labelEn||"")
      formData.append(`DesignAppProjectInfo[${i}][labelAr]`, d.labelAr||"")
      formData.append(`DesignAppProjectInfo[${i}][textEn]`, d.textEn||"")
      formData.append(`DesignAppProjectInfo[${i}][textAr]`, d.textAr||"")
    })
    /**************project information***********/
    /**************Whats is project data***********/
      for (let key in data.whatIsProjData) {
        if(key !== "cards") {
            formData.append(key, data.whatIsProjData[key])
        }
      }
    /**************Whats is project data***********/

    /**************Challenges Data***********/
    for (let key in data.challengesData) {
      if(key !== "cards") {
          formData.append(key, data.challengesData[key])
      }
    }
    data.challengesData.cards.forEach((d,i) => {
      formData.append(`DesignAppChallengesCards[${i}][titleEn]`, d.titleEn||"")
      formData.append(`DesignAppChallengesCards[${i}][titleAr]`, d.titleAr||"")
      formData.append(`DesignAppChallengesCards[${i}][descriptionEn]`, d.descriptionEn||"")
      formData.append(`DesignAppChallengesCards[${i}][descriptionAr]`, d.descriptionAr||"")
    })
    /**************Challenges Data***********/

    /**************Let's Check Data***********/
    for (let key in data.letsCheckData) {
      
      if(key !== "cards") {
          formData.append(key, data.letsCheckData[key])
      }
      }
    /**************Let's Check Data***********/
    /**************Sections Data***********/
    data.sectionsData.forEach((d,i) => {
          formData.append(`DesignAppSections[${i}][titleEn]`, d.titleEn||"")
          formData.append(`DesignAppSections[${i}][titleAr]`, d.titleAr||"")
          formData.append(`DesignAppSections[${i}][descriptionEn]`, d.descriptionEn||"")
          formData.append(`DesignAppSections[${i}][descriptionAr]`, d.descriptionAr||"")
      })
    /**************Sections Data***********/
    /**************Persona Data***********/
    data.personaData.forEach((d,i) => {
          formData.append(`DesignAppPersona[${i}][titleEn]`, d.titleEn||"")
          formData.append(`DesignAppPersona[${i}][titleAr]`, d.titleAr||"")
          formData.append(`DesignAppPersona[${i}][descriptionEn]`, d.descriptionEn||"")
          formData.append(`DesignAppPersona[${i}][descriptionAr]`, d.descriptionAr||"")
          formData.append(`DesignAppPersona[${i}][otherTitleEn]`, d.otherTitleEn||"")
          formData.append(`DesignAppPersona[${i}][otherTitleAr]`, d.otherTitleAr||"")
          formData.append(`DesignAppPersona[${i}][otherDescriptionEn]`, d.otherDescriptionEn||"")
          formData.append(`DesignAppPersona[${i}][otherDescriptionAr]`, d.otherDescriptionAr||"")
          formData.append(`DesignAppPersona[${i}][image]`, d.image)
    })
    /**************Persona Data***********/
    /**************Section Two Data***********/
    data.sectionsTwoData.forEach((d,i) => {
          formData.append(`DesignAppSectionsTwo[${i}][titleEn]`, d.titleEn||"")
          formData.append(`DesignAppSectionsTwo[${i}][titleAr]`, d.titleAr||"")
          formData.append(`DesignAppSectionsTwo[${i}][descriptionEn]`, d.descriptionEn||"")
          formData.append(`DesignAppSectionsTwo[${i}][descriptionAr]`, d.descriptionAr||"")
          formData.append(`DesignAppSectionsTwo[${i}][image]`, d.image)
    })
    /**************Section Two Data***********/
    /**************Mobile Apps Data***********/
      for (let key in data.mobileAppsData) {
          if(key !== "cards") {
              formData.append(key, data.mobileAppsData[key])
          }
      }
      data.mobileAppsData.cards.forEach((d,i) => {
          formData.append(`DesignAppMobileAppsCards[${i}][image]`, d.image)
      })
    /**************Mobile Apps Data***********/
    /**************Export Screen Data***********/
      for (let key in data.exportScreenData) {
          if(key !== "cards") {
              formData.append(key, data.exportScreenData[key])
          }
      }
    /**************Export Screen Data***********/
    /**************Images Section Data***********/
      for (let key in data.imagesSectionData) {
          if(key !== "cards") {
              formData.append(key, data.imagesSectionData[key])
          }
      }
    /**************Images Section Data***********/

      /**************Design App Results***********/
      for (let key in data.resultData) {
        if(key !== "cards") {
            formData.append(key, data.resultData[key])
        }
      }
      data.resultData.cards.forEach((d,i) => {
        formData.append(`DesignAppResultsCards[${i}][titleEn]`, d.titleEn||"")
        formData.append(`DesignAppResultsCards[${i}][titleAr]`, d.titleAr||"")
        formData.append(`DesignAppResultsCards[${i}][descriptionEn]`, d.descriptionEn||"")
        formData.append(`DesignAppResultsCards[${i}][descriptionAr]`, d.descriptionAr||"")
    })
    /**************Design App Results***********/


    formData.append("PID", data.PID)
    /*************GeneralInformationData*************/
    axios.post(`${process.env.REACT_APP_API}/dashboard/product/designapp/save`,formData,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
      toast.success(res.data.message, {position: "top-right"})
      setIsLoading(false)
      ChangeHandler.getData(data.PID)
    })
  }


  return (
    <>
        <Card>
            <CardBody>
                <div className="accordion" id="accordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classNames(
                          "accordion-button",
                          "fw-medium",
                          "fw-bold",
                          "fs-5",
                          { collapsed: !col[0].status }
                        )}
                        type="button"
                        onClick={() => ChangeColState(1)}
                        style={{ cursor: "pointer" }}
                      >
                        1- Introducing
                      </button>
                    </h2>
                    <Collapse isOpen={col[0].status} className="accordion-collapse">
                      <div className="accordion-body">
                        <IntroducingForm data ={data.introducingData} ChangeHandler={ChangeHandler.setIntroducingData}/>
                      </div>
                    </Collapse>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classNames(
                          "accordion-button",
                          "fw-medium",
                          "fw-bold",
                          "fs-5",
                          { collapsed: !col[1].status }
                        )}
                        type="button"
                        onClick={() => ChangeColState(2)}
                        style={{ cursor: "pointer" }}
                      >
                        2- Task Description
                      </button>
                    </h2>
                    <Collapse isOpen={col[1].status} className="accordion-collapse">
                      <div className="accordion-body">
                        <TaskDescriptionForm data ={data.taskDescriptionData} ChangeHandler={ChangeHandler.setTaskDescriptionData}/>
                      </div>
                    </Collapse>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classNames(
                          "accordion-button",
                          "fw-medium",
                          "fw-bold",
                          "fs-5",
                          { collapsed: !col[2].status }
                        )}
                        type="button"
                        onClick={() => ChangeColState(3)}
                        style={{ cursor: "pointer" }}
                      >
                        3- Deliverables
                      </button>
                    </h2>
                    <Collapse isOpen={col[2].status} className="accordion-collapse">
                      <div className="accordion-body">
                        <DeliverablesForm data ={data.deliverablesData} ChangeHandler={ChangeHandler.setDeliverablesData}/>
                      </div>
                    </Collapse>
                  </div>
                  
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classNames(
                          "accordion-button",
                          "fw-medium",
                          "fw-bold",
                          "fs-5",
                          { collapsed: !col[3].status }
                        )}
                        type="button"
                        onClick={() => ChangeColState(4)}
                        style={{ cursor: "pointer" }}
                      >
                        4- Image
                      </button>
                    </h2>
                    <Collapse isOpen={col[3].status} className="accordion-collapse">
                      <div className="accordion-body">
                        <ImageForm 
                          data ={data.designAppImageImage} 
                          ChangeHandler={ChangeHandler.setDesignAppImageImage}
                          setImgSrc={setImgSrc} setisOpen={setisOpen} 
                          handleAcceptedImage={handleAcceptedImage}
                        />
                      </div>
                    </Collapse>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classNames(
                          "accordion-button",
                          "fw-medium",
                          "fw-bold",
                          "fs-5",
                          { collapsed: !col[4].status }
                        )}
                        type="button"
                        onClick={() => ChangeColState(5)}
                        style={{ cursor: "pointer" }}
                      >
                        5- Project Information
                      </button>
                    </h2>
                    <Collapse isOpen={col[4].status} className="accordion-collapse">
                      <div className="accordion-body">
                        <DesignAppProjectInfoForm 
                          data ={data.designAppProjectInfo} 
                          PID={data.PID}
                          getData={ChangeHandler.getData}
                          ChangeHandler={ChangeHandler.setDesignAppProjectInfo}
                        />
                      </div>
                    </Collapse>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classNames(
                          "accordion-button",
                          "fw-medium",
                          "fw-bold",
                          "fs-5",
                          { collapsed: !col[5].status }
                        )}
                        type="button"
                        onClick={() => ChangeColState(6)}
                        style={{ cursor: "pointer" }}
                      >
                        6- What Is The Project
                      </button>
                    </h2>
                    <Collapse isOpen={col[5].status} className="accordion-collapse">
                      <div className="accordion-body">
                        <WhatIsTheProjectForm 
                          data ={data.whatIsProjData} 
                          ChangeHandler={ChangeHandler.setWhatIsProjData}
                        />
                      </div>
                    </Collapse>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classNames(
                          "accordion-button",
                          "fw-medium",
                          "fw-bold",
                          "fs-5",
                          { collapsed: !col[6].status }
                        )}
                        type="button"
                        onClick={() => ChangeColState(7)}
                        style={{ cursor: "pointer" }}
                      >
                        7- Challenges & Objectives
                      </button>
                    </h2>
                    <Collapse isOpen={col[6].status} className="accordion-collapse">
                      <div className="accordion-body">
                        <ChallengesForm 
                          data ={data.challengesData} 
                          PID={data.PID}
                          getData={ChangeHandler.getData}
                          ChangeHandler={ChangeHandler.setChallengesData}
                        />
                      </div>
                    </Collapse>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classNames(
                          "accordion-button",
                          "fw-medium",
                          "fw-bold",
                          "fs-5",
                          { collapsed: !col[7].status }
                        )}
                        type="button"
                        onClick={() => ChangeColState(8)}
                        style={{ cursor: "pointer" }}
                      >
                        8- Let`s check
                      </button>
                    </h2>
                    <Collapse isOpen={col[7].status} className="accordion-collapse">
                      <div className="accordion-body">
                        <LetsCheckForm 
                          data ={data.letsCheckData} 
                          ChangeHandler={ChangeHandler.setLetsCheckData}
                          setImgSrc={setImgSrc} setisOpen={setisOpen} 
                          handleAcceptedImage={handleAcceptedImage}
                        />
                      </div>
                    </Collapse>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classNames(
                          "accordion-button",
                          "fw-medium",
                          "fw-bold",
                          "fs-5",
                          { collapsed: !col[8].status }
                        )}
                        type="button"
                        onClick={() => ChangeColState(9)}
                        style={{ cursor: "pointer" }}
                      >
                       9- Sections
                      </button>
                    </h2>
                    <Collapse isOpen={col[8].status} className="accordion-collapse">
                      <div className="accordion-body">
                        <SectionsForm
                          data ={data.sectionsData} 
                          ChangeHandler={ChangeHandler.setSectionsData}
                          PID={data.PID}
                          getData={ChangeHandler.getData}
                        />
                      </div>
                    </Collapse>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classNames(
                          "accordion-button",
                          "fw-medium",
                          "fw-bold",
                          "fs-5",
                          { collapsed: !col[9].status }
                        )}
                        type="button"
                        onClick={() => ChangeColState(10)}
                        style={{ cursor: "pointer" }}
                      >
                       10- Persona
                      </button>
                    </h2>
                    <Collapse isOpen={col[9].status} className="accordion-collapse">
                      <div className="accordion-body">
                        <PersonaImagesForm
                          data ={data.personaData} 
                          ChangeHandler={ChangeHandler.setPersonaData}
                          setImgSrc={setImgSrc} setisOpen={setisOpen} 
                          handleAcceptedImage={handleAcceptedImage}
                          PID={data.PID}
                          getData={ChangeHandler.getData}
                        />
                      </div>
                    </Collapse>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classNames(
                          "accordion-button",
                          "fw-medium",
                          "fw-bold",
                          "fs-5",
                          { collapsed: !col[10].status }
                        )}
                        type="button"
                        onClick={() => ChangeColState(11)}
                        style={{ cursor: "pointer" }}
                      >
                       11- Sections
                      </button>
                    </h2>
                    <Collapse isOpen={col[10].status} className="accordion-collapse">
                      <div className="accordion-body">
                        <SectionsTwoForm
                          data ={data.sectionsTwoData} 
                          ChangeHandler={ChangeHandler.setSectionsTwoData}
                          setImgSrc={setImgSrc} setisOpen={setisOpen} 
                          handleAcceptedImage={handleAcceptedImage}
                          PID={data.PID}
                          getData={ChangeHandler.getData}
                        />
                      </div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classNames(
                          "accordion-button",
                          "fw-medium",
                          "fw-bold",
                          "fs-5",
                          { collapsed: !col[11].status }
                        )}
                        type="button"
                        onClick={() => ChangeColState(12)}
                        style={{ cursor: "pointer" }}
                      >
                       12- Mobile Apps
                      </button>
                    </h2>
                    <Collapse isOpen={col[11].status} className="accordion-collapse">
                      <div className="accordion-body">
                        <MobileAppsForm
                          data ={data.mobileAppsData} 
                          ChangeHandler={ChangeHandler.setMobileAppsData}
                          setImgSrc={setImgSrc} setisOpen={setisOpen} 
                          handleAcceptedImage={handleAcceptedImage}
                          PID={data.PID}
                          getData={ChangeHandler.getData}
                        />
                      </div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classNames(
                          "accordion-button",
                          "fw-medium",
                          "fw-bold",
                          "fs-5",
                          { collapsed: !col[12].status }
                        )}
                        type="button"
                        onClick={() => ChangeColState(13)}
                        style={{ cursor: "pointer" }}
                      >
                       13- Export Screen
                      </button>
                    </h2>
                    <Collapse isOpen={col[12].status} className="accordion-collapse">
                      <div className="accordion-body">
                        <ExportScreenForm
                          data ={data.exportScreenData} 
                          ChangeHandler={ChangeHandler.setExportScreenData}
                          setImgSrc={setImgSrc} setisOpen={setisOpen} 
                          handleAcceptedImage={handleAcceptedImage}
                          PID={data.PID}
                          getData={ChangeHandler.getData}
                        />
                      </div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classNames(
                          "accordion-button",
                          "fw-medium",
                          "fw-bold",
                          "fs-5",
                          { collapsed: !col[13].status }
                        )}
                        type="button"
                        onClick={() => ChangeColState(14)}
                        style={{ cursor: "pointer" }}
                      >
                       14- Images Section
                      </button>
                    </h2>
                    <Collapse isOpen={col[13].status} className="accordion-collapse">
                      <div className="accordion-body">
                        <ImagesSectionsFrom
                          data ={data.imagesSectionData} 
                          ChangeHandler={ChangeHandler.setImagesSectionData}
                          setImgSrc={setImgSrc} setisOpen={setisOpen} 
                          handleAcceptedImage={handleAcceptedImage}
                        />
                      </div>
                    </Collapse>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className={classNames(
                          "accordion-button",
                          "fw-medium",
                          "fw-bold",
                          "fs-5",
                          { collapsed: !col[14].status }
                        )}
                        type="button"
                        onClick={() => ChangeColState(15)}
                        style={{ cursor: "pointer" }}
                      >
                       15- Results
                      </button>
                    </h2>
                    <Collapse isOpen={col[14].status} className="accordion-collapse">
                      <div className="accordion-body">
                        <ResultForm
                          data ={data.resultData} 
                          ChangeHandler={ChangeHandler.setResultData}
                          PID={data.PID}
                          getData={ChangeHandler.getData}
                        />
                      </div>
                    </Collapse>
                  </div>
                </div>
                <hr style={{ margin: "20px -20px 20px -20px" }} />
        <Row>
          <Col className="d-flex justify-content-end">
            <button
              type="button"
              className="add-project__submit-button"
              onClick={e => submit(e)}
            >
              {isLoading ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </Col>
        </Row>     
            </CardBody>
        </Card>
    </>
  )
}

export default DesignAppForm