/* eslint-disable react/prop-types */
import axios from "axios"
import React, { useState, useRef, useEffect } from "react"
import { Card, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import PulseLoader from "react-spinners/PulseLoader"
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import "./specification.scss"
import prettyBytes from "pretty-bytes"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"

const AddCategoryForm365Design = props => {
  let p = props
  const [isOpen, setisOpen] = useState(false)
  const [name_en, setNameEn] = useState("")
  const [name_ar, setNameAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")
  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)

  const [prev, setPrev] = useState(null)
  const [facebookImage, setFacebookImage] = useState(null)
  const [coverImage, setCoverImage] = useState(null)

  function handleFacebookFiles(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    setFacebookImage(Object.values(files)[0])
    e.target.value = ""
  }

  function handleCoverImageFiles(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    setCoverImage(Object.values(files)[0])
    e.target.value = ""
  }

  function deleteSelectImage() {
    setFacebookImage(null)
  }

  function deleteSelectCoverImage() {
    setCoverImage(null)
  }

  function prevSelectImage(e, img) {
    e.preventDefault()
    setPrev(img)
    setisOpen(true)
  }

  function submitHandler() {
    setLoading(true)
    const formData = new FormData()
    let data = {
      titleEn: name_en,
      titleAr: name_ar,
      descriptionEn: descEn,
      descriptionAr: descAr,
      id,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    formData.append("image", facebookImage)
    formData.append("coverImage", coverImage)
    let reqLink = props.category ? `update` : `add`
    axiosConfig
      .post(`dashboard/365Design/category/${reqLink}`, formData, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
          "Content-Type": "application/json",
        },
      })
      .then(res => {
        props.addCategorySuccsess()
        setLoading(false)
        setNameAr("")
        setNameEn("")
        setDescEn("")
        setDescAr("")
        setFacebookImage(null)
        setCoverImage(null)
        props.clickHandle(null, false)
      })
      .catch(err => {
        setLoading(false)
        toast.error("Something went wrong")
      })
  }

  function close() {
    document.getElementById("categForm").reset()
    setNameAr("")
    setNameEn("")
    props.clickHandle()
  }
  useEffect(() => {
    if (p.category) {
      setNameEn(p.category.titleEn)
      setNameAr(p.category.titleAr)
      setDescEn(p.category.descriptionEn)
      setDescAr(p.category.descriptionAr)
      setFacebookImage(p.category.image ? p.category.image : null)
      setCoverImage(p.category.coverImage ? p.category.coverImage : null)
      setId(p.category.id)
      return
    }
  }, [p.category])

  return (
    <div className={`overlay_coup ${p.active ? "active" : ""}`}>
      <Form
        id="categForm"
        onSubmit={e => e.preventDefault()}
        style={{ paddingBottom: "70px", height: "650px" }}
      >
        <div className="head_form">
          <div className="log fs-5">
            Category
          </div>
          <button onClick={close}>
            <i className="dripicons dripicons-cross"></i>
          </button>
        </div>
        <div className="inputs__wrap ">
          {isOpen ? (
            <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
                setisOpen(!isOpen)
              }}
            />
          ) : null}

          <Row>
            <Col xs="12">
              <div>
                <Label htmlFor="productdesc">Cover Image</Label>
                <Input
                  type="file"
                  className="form-control form-control-lg "
                  name="coverImage"
                  onChange={e => handleCoverImageFiles(e, e.target.files)}
                  style={{ borderRadius: 0 }}
                  // multiple
                />
              </div>
            </Col>
            <Col xs="12">
              <div className="dropzone-previews" id="file-previews">
                {coverImage ? (
                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={coverImage.name}
                            src={
                              coverImage.preview
                                ? coverImage.preview
                                : coverImage
                            }
                            style={{ objectFit: "cover" }}
                          />
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted ">
                            {coverImage.name}
                          </Link>
                        </Col>
                        <Col>
                          <div className="image__options">
                            <button
                              className="p-0"
                              onClick={e =>
                                prevSelectImage(
                                  e,
                                  coverImage.preview
                                    ? coverImage.preview
                                    : coverImage
                                )
                              }
                            >
                              <i className="far fa-eye  fs-5 p-0"></i>
                            </button>
                            <button
                              style={{ color: "#ff3838" }}
                              onClick={e => deleteSelectCoverImage()}
                            >
                              <i className="bx bx-trash fs-4"></i>
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs="12">
              <div>
                <Label htmlFor="productdesc">Attachement Image</Label>
                <Input
                  type="file"
                  className="form-control form-control-lg "
                  name="attachmentImage"
                  onChange={e => handleFacebookFiles(e, e.target.files)}
                  style={{ borderRadius: 0 }}
                  // multiple
                />
              </div>
            </Col>
            <Col xs="12">
              <div className="dropzone-previews" id="file-previews">
                {facebookImage ? (
                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={facebookImage.name}
                            src={
                              facebookImage.preview
                                ? facebookImage.preview
                                : facebookImage
                            }
                            style={{ objectFit: "cover" }}
                          />
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted ">
                            {facebookImage.name}
                          </Link>
                        </Col>
                        <Col>
                          <div className="image__options">
                            <button
                              className="p-0"
                              onClick={e =>
                                prevSelectImage(
                                  e,
                                  facebookImage.preview
                                    ? facebookImage.preview
                                    : facebookImage
                                )
                              }
                            >
                              <i className="far fa-eye  fs-5 p-0"></i>
                            </button>
                            <button
                              style={{ color: "#ff3838" }}
                              onClick={e => deleteSelectImage()}
                            >
                              <i className="bx bx-trash fs-4"></i>
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Category Name (English)</Label>
                <Input
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={name_en}
                  onChange={e => setNameEn(e.target.value)}
                />
                <FormFeedback className="fs-6">
                  This Fileld Is Required!
                </FormFeedback>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Category Name (Arabic)</Label>
                <Input
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={name_ar}
                  onChange={e => setNameAr(e.target.value)}
                />
                <FormFeedback className="fs-6">
                  This Fileld Is Required!
                </FormFeedback>
              </div>
            </Col>
          </Row>

          <Row className="">
            <Col sm="12">
              <div>
                <Label htmlFor="productdesc">Description (English)</Label>
                <textarea
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="Enter your description here"
                  value={descEn}
                  onChange={e => setDescEn(e.target.value)}
                />
              </div>
            </Col>
            <Col sm="12" className="mt-3">
              <div>
                <Label htmlFor="productdesc">Description (Arabic)</Label>
                <textarea
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="اكتب وصف هنا"
                  value={descAr}
                  onChange={e => setDescAr(e.target.value)}
                  dir="rtl"
                />
              </div>
            </Col>
          </Row>
        </div>

        <div className="foot_form">
          <button className="cancel" onClick={close}>
            Cancel
          </button>
          <button className="save" onClick={submitHandler}>
            {loading ? (
              <PulseLoader color={"#ffffff"} loading={true} size={10} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Form>
    </div>
  )
}

export default AddCategoryForm365Design
