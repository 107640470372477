import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import { MultiSelect } from "react-multi-select-component"
import Select from "react-select"
import axiosConfig from "axiosConfig"
import SweetAlert from "react-bootstrap-sweetalert"
import { toast } from "react-toastify"

const OurLatestWork = ({sectionData,projects}) => {
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [options,setOptions] = useState([])
  const [selected, setSelected] = useState([])



  function submit(e) {
    e.preventDefault()
    let lastestWork = []
    selected.forEach(item=>{
      lastestWork.push(item.value)
    })
    let formData = new FormData();
    formData.append('titleEn',titleEn)
    formData.append('titleAr',titleAr)
    formData.append('descriptionEn',descEn)
    formData.append('descriptionAr',descAr)
    lastestWork.length!=0?
    lastestWork.forEach((item,index)=>{
      formData.append(`lastwork[${index}]`,item)
    }):formData.append(`lastwork`,[])

    
    setIsLoading(true)
    axiosConfig.post(`/dashboard/homePage/ourLastWork`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res=>{
       toast.success(res.data.message)
      setsuccess_msg(true)
      setIsLoading(false)
    }).catch(err=>{
      toast.error(err.response.data.message)
      setIsLoading(false)
    })
  }
  
  useEffect(()=>{
    setTitleEn(sectionData?.titleEn)
    setTitleAr(sectionData?.titleAr)
    setDescEn(sectionData?.descriptionEn)
    setDescAr(sectionData?.descriptionAr)
      let projectss=[];
      sectionData?.lastwork&& sectionData?.lastwork.forEach(val=>{
        options&&options.forEach(option=>{
        if(option.value ==val.id){
            projectss.push(option)
          }
        })
      })
      setSelected(projectss)
  },[sectionData,options])

  useEffect(()=>{
    let modififedProjects= projects?.map(project=>{
      return {label: project?.nameEn, value: project?.id}
    })
    setOptions(modififedProjects)
  },[projects])


  return (
    <div>
      {success_msg ? (
        <SweetAlert
          title={msg}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        >
        </SweetAlert>
      ) : null}
      <Form className="border-0 pb-2">
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (english)</Label>
              <Input
                id="itemnameen"
                name="itemnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={titleEn}
                onChange={e => setTitleEn(e.target.value)}
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (arabic)</Label>
              <Input
                id="itemnamear"
                name="itemnamear"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. "
                value={titleAr}
                onChange={e => setTitleAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (English)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="Enter your description here"
                value={descEn}
                onChange={e => setDescEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (Arabic)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="اكتب وصف هنا"
                value={descAr}
                onChange={e => setDescAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Label htmlFor="productdesc">Latest Work</Label>
            <Select
              value={selected}
              isMulti={true}
              onChange={(e) => {
                setSelected(e)
              }}
              options={options}
              classNamePrefix="select2-selection"
            />
          </Col>
        </Row>
      </Form>
      <hr style={{ margin: "20px -20px 20px -20px" }} />
      <Row className="mt-2">
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0">
              <button type='button' onClick={submit} disabled={isLoading?true:false}>
                {isLoading ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
    </div>
  )
}

export default OurLatestWork
