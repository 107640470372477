import React, { useEffect,useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import axiosConfig from "axiosConfig"
import SweetAlert from "react-bootstrap-sweetalert"
import { toast } from "react-toastify"



const Section3 = ({data}) => {
  const [active, setActive] = useState(false)
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [isLoading, setIsLoading] = useState(false)


  const [focusKeypharseEn, setFocusKeypharseEn] = useState("")
  const [focusKeypharseAr, setFocusKeypharseAr] = useState("")
  const [seoTitleEn, setSeoTitleEn] = useState("")
  const [seoTitleAr, setSeoTitleAr] = useState("")
  const [descriptionEn, setDescriptionEn] = useState("")
  const [descriptionAr, setDescriptionAr] = useState("")
  const [slugEn, setSlugEn] = useState("")
  const [slugAr, setSlugAr] = useState("")
  const [facebookTitleEn, setFacebookTitleEn] = useState("")
  const [facebookTitleAr, setFacebookTitleAr] = useState("")
  const [facebookDescriptionEn, setFacebookDescriptionEn] = useState("")
  const [facebookDescriptionAr, setFacebookDescriptionAr] = useState("")
  const [facebookImage, setFacebookImage] = useState(null)
  
  
  function handleFacebookImage(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setFacebookImage(Object.values(files)[0])
    e.target.value = ""
  }
  

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function prevSelectImage(e, prev) {
    e.preventDefault()
    setPrev(prev)
    setisOpen(!isOpen)
  }
  function submit(e) {
    setActive(true)
    e.preventDefault()
    const formData = new FormData()
    const data = {
      focusKeypharseEn,
      focusKeypharseAr,
      seoTitleEn,
      seoTitleAr,
      descriptionEn,
      descriptionAr,
      slugEn,
      slugAr,
      facebookTitleEn,
      facebookTitleAr,
      facebookDescriptionEn,
      facebookDescriptionAr,
      facebookImage,


    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    


    axiosConfig.post(`/dashboard/etoy/contactUs/seo`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res=>{
       toast.success(res.data.message)
      setsuccess_msg(true)
    setActive(false)
    setIsLoading(false)
    }).catch(err=>{
      toast.error(err.response.data.message)
      setIsLoading(false)
    })
  }

  useEffect(() => {

    if(data) {
      setFocusKeypharseEn(data.focusKeypharseEn)
      setFocusKeypharseAr(data.focusKeypharseAr)
      setSeoTitleEn(data.seoTitleEn)
      setSeoTitleAr(data.seoTitleAr)
      setDescriptionEn(data.descriptionEn)
      setDescriptionAr(data.descriptionAr)
      setSlugEn(data.slugEn)
      setSlugAr(data.slugAr)
      setFacebookTitleEn(data.facebookTitleEn)
      setFacebookTitleAr(data.facebookTitleAr)
      setFacebookDescriptionEn(data.facebookDescriptionEn)
      setFacebookDescriptionAr(data.facebookDescriptionAr)
      setFacebookImage({preview: data.facebookImage})
      
           
    }
    
  }, [data])

  return (
    <div>
      {success_msg ? (
        <SweetAlert
          title={msg}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        >
        </SweetAlert>
      ) : null}
      {isOpen ? (
        <Lightbox
          mainSrc={prev}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
      <Form className="border-0 pb-2">
      <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Focus Keypharse (english)</Label>
                  <Input
                    id="itemnameen"
                    name="focusKeypharseEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Focus Keypharse"

                    value={focusKeypharseEn}
                    onChange={e => setFocusKeypharseEn(e.target.value)}
                  />
                </div>
              </Col>

              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Focus Keypharse (arabic)</Label>
                  <Input
                    id="itemnamear"
                    name="focusKeypharseAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. "

                    value={focusKeypharseAr}
                    onChange={e => setFocusKeypharseAr(e.target.value)}
                    
                    dir="rtl"
                    lang="ar"
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Seo Title (english)</Label>
                  <Input
                    id="brandnameen"
                    name="seoTitleEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Title"

                    value={seoTitleEn}
                    onChange={e => setSeoTitleEn(e.target.value)}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Seo Title (arabic)</Label>
                  <Input
                    id="brandnamear"
                    name="seoTitleAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. عنوان"

                    value={seoTitleAr}
                    onChange={e => setSeoTitleAr(e.target.value)}
                    
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Slug (english)</Label>
                  <Input
                    id="brandnameen"
                    name="slugEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Slug"

                    value={slugEn}
                    onChange={e => setSlugEn(e.target.value)}
                    
                    
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Slug (arabic)</Label>
                  <Input
                    id="brandnamear"
                    name="slugAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. وصف"

                    value={slugAr}
                    onChange={e => setSlugAr(e.target.value)}
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (English)</Label>
                  <textarea
                    className="form-control  mb-0"
                    name="descriptionEn"
                    id="specification"
                    rows="5"
                    placeholder="Enter your description here"

                    value={descriptionEn}
                    onChange={e => setDescriptionEn(e.target.value)}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (Arabic)</Label>
                  <textarea
                    className="form-control  mb-0"
                    name="descriptionAr"
                    id="specification"
                    rows="5"
                    placeholder="اكتب وصف هنا"

                    value={descriptionAr}
                    onChange={e => setDescriptionAr(e.target.value)}
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <div className="mt-3">
                  <Label htmlFor="productdesc">Facebook Image</Label>
                  <Input
                    type="file"
                    className="form-control form-control-lg "
                    onChange={e => handleFacebookImage(e, e.target.files)}
                    style={{ borderRadius: 0 }}
                    multiple
                  />
                </div>
              </Col>
              <Col xs="12">
                <div className="dropzone-previews" id="file-previews">
                  {facebookImage ? (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={facebookImage.name}
                              src={facebookImage.preview}
                              style={{ objectFit: "cover" }}
                            />
                          </Col>
                          <Col>
                            <Link to="#" className="text-muted ">
                              {facebookImage.name}
                            </Link>
                          </Col>
                          <Col>
                            <div className="image__options">
                              <button
                                className="p-0"
                                onClick={e => prevSelectImage(e, facebookImage.preview)}
                              >
                                <i className="far fa-eye  fs-5 p-0"></i>
                              </button>
                              <button
                                style={{ color: "#ff3838" }}
                                onClick={e => setFacebookImage(null)}
                              >
                                <i className="bx bx-trash fs-4"></i>
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Facebook Title (english)</Label>
                  <Input
                    id="brandnameen"
                    name="seoFaceTitleEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Title"

                    value={facebookTitleAr}
                    onChange={e => setFacebookTitleAr(e.target.value)}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Facebook Title (arabic)</Label>
                  <Input
                    id="brandnamear"
                    name="seoFaceTitleAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. عنوان"
                    value={facebookTitleEn}
                    onChange={e => setFacebookTitleEn(e.target.value)}
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">
                    Facebook Description (English)
                  </Label>
                  <textarea
                    className="form-control  mb-0"
                    name="seoFaceDescEn"
                    id="specification"
                    rows="5"
                    placeholder="Enter your description here"

                    value={facebookDescriptionEn}
                    onChange={e => setFacebookDescriptionEn(e.target.value)}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Facebook Description (Arabic)</Label>
                  <textarea
                    className="form-control mb-0"
                    name="seoFaceDescAr"
                    id="specification"
                    rows="5"
                    placeholder="اكتب وصف هنا"
                    value={facebookDescriptionAr}
                    onChange={e => setFacebookDescriptionAr(e.target.value)}
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
      </Form>
      <hr style={{ margin: "20px -20px 20px -20px" }} />
      
      <Row className="mt-2">
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0">
              <button onClick={e => submit(e)}>
                {active ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
    </div>
  )
}

export default Section3

