/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Form, Label, Input, } from "reactstrap"
import {Link} from "react-router-dom"
import './specification.scss'
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert"
import ReactLoading from "react-loading";
import axiosConfig from "axiosConfig";
import prettyBytes from "pretty-bytes";


const ContentGenerator = (props) => {
    const [active, setActive] = useState(false)
    const [msg, setMsg] = useState("")
    const [success_msg, setsuccess_msg] = useState(false)
    const [facebookImage, setFacebookImage] = useState(null)
    const [mobileImage, setMobileLgImage] = useState(null)
    const [isOpen, setisOpen] = useState(false);
    const [titleEn, setTitleEn] = useState('')
    const [titleAr, setTitleAr] = useState('')
    const [descEn, setDescEn] = useState('')
    const [descAr, setDescAr] = useState('')
    const [smallDescEn, setSmallDescEn] = useState('')
    const [smallDescAr, setSmallDescAr] = useState('')
    const [prev, setPrev] = useState(null)


    

    function handleFacebookFiles(e, files) {
        ;[...files].map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: prettyBytes(file.size),
          })
        )
        setFacebookImage(Object.values(files)[0])
        e.target.value = ""
    }
    function handleMobileFiles(e, files) {
        [...files].map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: prettyBytes(file.size)
            })
        )
        setMobileLgImage(Object.values(files)[0])
        e.target.value = '';
    }
    
    
      
    
    
      function prevSelectImage(e, img) {
        e.preventDefault()
        setPrev(img)
        setisOpen(true)
      }
    

function submit(e) {
    e.preventDefault();
    setActive(true)
    const formData = new FormData();
    let data = {
        titleEn : titleEn,
        titleAr : titleAr,
        descriptionEn: descEn,
        descriptionAr: descAr,
        smallDescriptionEn: smallDescEn,
        smallDescriptionAr: smallDescAr,
       
        
    }
    for (let key in data) {
        formData.append(key, data[key])
    }
    formData.append("image", facebookImage)
    formData.append('mobileImage',mobileImage)
    
    axiosConfig.post(`/dashboard/uiAvatar/pages/contentGenerator`,formData,{
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
        }
    })
    .then(res => {
        setActive(false)
        setMsg(`Saved!`)
        setsuccess_msg(true)
    })
}

useEffect(() => {
    axiosConfig.get(`/dashboard/uiAvatar/pages/contentGeneratorStaticPage`,{
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
        }
      })
    .then(res => {
        let data = res.data.data;
        setTitleEn(data?.titleEn)
        setTitleAr(data?.titleAr)
        setDescEn(data?.descriptionEn)
        setDescAr(data?.descriptionAr)
        setSmallDescEn(data?.smallDescriptionEn)
        setSmallDescAr(data?.smallDescriptionAr)
        setFacebookImage({preview: data?.image})
        setMobileLgImage({preview: data?.mobileImage})
       
    })
}, []) 

return (
        <React.Fragment>
            <div className="container-fluid p-0">
            <Card>
                <CardBody>
                {success_msg ? (
                    <SweetAlert
                    title={msg}
                    success
                    confirmBtnBsStyle="success"
                    onConfirm={() => {
                        setsuccess_msg(false)
                    }}
                    onCancel={() => {
                        setsuccess_msg(false)
                    }}
                    >
                        </SweetAlert>
                    ) : null}
                {isOpen ? (
                <Lightbox
                    mainSrc={prev}
                    enableZoom={false}
                    onCloseRequest={() => {
                    setisOpen(!isOpen);
                    }}
                />
                ) : null}
                
                <Form className="border-0">
                    <Row>
                        <Col>
                            <Label>Title (english)</Label>
                            <Input 
                                className="input_cus"
                                placeholder="Title"
                                value={titleEn}
                                onChange={e => setTitleEn(e.target.value)}
                            />
                        </Col>
                        <Col>
                            <Label>Title (arabic)</Label>
                            <Input 
                                className="input_cus"
                                placeholder="عنوان"
                                dir="rtl"
                                value={titleAr}
                                onChange={e => setTitleAr(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Label>description (english)</Label>
                            <textarea
                                className="form-control  mb-0"
                                rows="5"
                                placeholder="Description"
                                value={descEn}
                                onChange={e => setDescEn(e.target.value)}
                            />
                        </Col>
                        <Col>
                            <Label>description (arabic)</Label>
                            <textarea
                                className="form-control  mb-0"
                                rows="5"
                                placeholder="وصف"
                                value={descAr}
                                onChange={e => setDescAr(e.target.value)}
                                dir="rtl"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Label>small description (english)</Label>
                            <textarea
                                className="form-control  mb-0"
                                rows="5"
                                placeholder="Description"
                                value={smallDescEn}
                                onChange={e => setSmallDescEn(e.target.value)}
                            />
                        </Col>
                        <Col>
                            <Label>small description (arabic)</Label>
                            <textarea
                                className="form-control  mb-0"
                                rows="5"
                                placeholder="وصف"
                                value={smallDescAr}
                                onChange={e => setSmallDescAr(e.target.value)}
                                dir="rtl"
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        
                        <Col xs="12">
                        <div>
                            <Label htmlFor="productdesc">Cover Image</Label>
                            <Input
                            type="file"
                            className="form-control form-control-lg "
                            name="attachmentImage"
                            onChange={e => handleFacebookFiles(e, e.target.files)}
                            style={{ borderRadius: 0 }}
                            // multiple
                            />
                        </div>
                        </Col>
                        <Col xs="12">
                        <div className="dropzone-previews" id="file-previews">
                            {facebookImage ? (
                            <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                <div className="p-2">
                                <Row className="align-items-center">
                                    <Col className="col-auto">
                                    <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={facebookImage.name}
                                        src={
                                        facebookImage.preview
                                            ? facebookImage.preview
                                            : facebookImage
                                        }
                                        style={{ objectFit: "cover" }}
                                    />
                                    </Col>
                                    <Col>
                                    <Link to="#" className="text-muted ">
                                        {facebookImage.name}
                                    </Link>
                                    </Col>
                                    <Col>
                                    <div className="image__options">
                                        <button
                                        className="p-0"
                                        onClick={e =>
                                            prevSelectImage(
                                            e,
                                            facebookImage.preview
                                                ? facebookImage.preview
                                                : facebookImage
                                            )
                                        }
                                        >
                                        <i className="far fa-eye  fs-5 p-0"></i>
                                        </button>
                                        
                                    </div>
                                    </Col>
                                </Row>
                                </div>
                            </Card>
                            ) : null}
                        </div>
                        </Col>

                        
                    </Row>
                    <Row className="mt-3">
                            <Col xs="12">
                                <div >
                                    <Label htmlFor="productdesc" >
                                        Mobile Image
                                    </Label>
                                    <Input
                                        type="file"
                                        className="form-control form-control-lg "
                                        onChange={e => handleMobileFiles(e, e.target.files)}
                                        style={{borderRadius: 0}}
                                        multiple
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="dropzone-previews" id="file-previews">
                                    {mobileImage?
                                        <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        >
                                            <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                <img
                                                    data-dz-thumbnail=""
                                                    height="80"
                                                    className="avatar-sm rounded bg-light"
                                                    alt={mobileImage.name}
                                                    src={mobileImage.preview?mobileImage.preview:mobileImage}
                                                    style={{ objectFit: "cover" }}
                                                />
                                                </Col>
                                                <Col>
                                                <Link to="#" className="text-muted ">
                                                    {mobileImage.name}
                                                </Link>
                                                
                                                </Col>
                                                <Col>
                                                <div className="image__options">
                                                    <button
                                                    className="p-0"
                                                    type="text" 
                                                    onClick={e =>
                                                        prevSelectImage(
                                                        e,
                                                        mobileImage.preview
                                                            ? mobileImage.preview
                                                            : mobileImage
                                                        )
                                                    }
                                                    >
                                                    <i className="far fa-eye  fs-5 p-0"></i>
                                                    </button>
                                                   
                                                </div>
                                                </Col>
                                            </Row>
                                            </div>
                                        </Card>:null
                                        
                                    }
                                    </div>
                            </Col>
                        </Row>

                    <hr style={{ margin: "25px -20px 20px -20px" }} />
                    
                    <Row>
                        <Col className="d-flex justify-content-end gap-2">
                            <div className="save__btn top m-0">
                                <button onClick={(e) => submit(e)}>{active?<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />:"Save"}</button>
                            </div>
                        </Col>
                    </Row>
          </Form>
        </CardBody>
      </Card>      
    </div>
</React.Fragment>
)
}

export default ContentGenerator

const deleteBtn = {
  background: "transparent",
  border: "none",
  color:"#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  color:"green",
  display: "flex",
  alignItems: "center",
  gap: "5px"
}