/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Form, Label, Input, FormFeedback,Collapse, Button } from "reactstrap"
import './specification.scss'
import { toast } from "react-toastify";
import "react-image-lightbox/style.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert"
import ReactLoading from "react-loading";
import axiosConfig from "axiosConfig";
import Tabs from "./BodySection/Tabs";
import { DndContext } from '@dnd-kit/core'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'
import TitlesForm from "./BodySection/TitlesForm";
import ParagraphForm from "./BodySection/ParagraphForm";
const DataSchema = { 
    title: {
      titleEn: "",
      titleAr: "",
      fontSizeID:"",
      fontTypeID:"",
      Type: "Title",
      RowID:null
    },
    description: {
      descriptionEn: "",
      descriptionAr: "",
      Type: "Paragraph",
      RowID:null 
    }
  }

    
const TermsForm = ({termsData, getTerms,setverticalActiveTab,index}) => {
    
    const [active, setActive] = useState(false)
    const [deleteActive, setDeleteActive] = useState(false)
    const [subTitleEn, setSubTitleEn] = useState('')
    const [subTitleAr, setSubTitleAr] = useState('')
    const [msg, setMsg] = useState("")
    const [success_msg, setsuccess_msg] = useState(false)
    const [termID, setTermID] = useState('')
    const [id, setID] = useState(null)
    const[termsCategories,setTermsCategories] = useState([]);
    const [termIDError, setTermIDError] = useState(null)
    const [fonts, setFonts] = useState(null)
    const [data, setData] = useState([])
   

    function addComponentHandler (type) { 
        const selectedType = type == "Title"?DataSchema.title:DataSchema.description
        setData(data => data.concat(selectedType))
        
    }

    
      function submit(e) {
        e.preventDefault();
        setActive(true)
        const formData = new FormData();
        data.forEach((data,i) => {
            let keys = Object.keys(data);
            keys.forEach((key, k) => {
              formData.append(`rows[${i}][${key}]`, data[key]);
            })
        }); 
       


        formData.append(`category`, termID);
        formData.append(`termID`, id);

        axios.post(`${process.env.REACT_APP_API}/dashboard/terms`, formData,{
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        })
        .then(res => {
          toast.success(res.data.message);
          setActive(false)
          getTerms()
          getTermsCategories ()
        })
    }



    function handleDrag(event) {
        const {active,over} =event
        if(active.id!=over.id){
          const activeIndex = active.data.current.sortable.index
          const overIndex = over.data.current.sortable.index
          const  newArrange = arrayMove(data,activeIndex,overIndex)
          const ids = newArrange.map(item => item.RowID?item.RowID:null)
          setData(data => newArrange)
          axios.post(`${process.env.REACT_APP_API}/dashboard/terms/arrange`, {ids, termID: id},{
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("authUser")).accessToken
              }`,
            },
          })
          .then(res => {
            toast.success(res.data.message);
          })
        }
    }


    function getTermsCategories (){
        axiosConfig.get(`/dashboard/terms/getTermCategories`,{
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
            }
        }).then(res => {
            setTermsCategories(res.data.data)
        }).catch(err=>{
        
        })
    }
    
    useEffect(()=>{
        getTermsCategories()
    },[termsData])

    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API}/dashboard/terms/font`, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        })
        .then(res => {
          setFonts(res.data.data)
        })

      },[])

    function selectTermCategory(value){
        setTermID(value)
    }

    function DeleteTerm(deletedId){
        setDeleteActive(true)
        axiosConfig.post(`/dashboard/terms/deleteTerm`,{id:deletedId} ,{
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
            }
        })
        .then(res => {
            setDeleteActive(false)
            getTerms()
            toast.success(`Deleted Term Success !`)
            if((index - 1) > 0){
                setverticalActiveTab(index - 1)
            }else{
                setverticalActiveTab(index)
            }
            getTerms()
            getTermsCategories ()

        }).catch(err=>{
            toast.error(err.response.data.message)
            setDeleteActive(false)
        })
        getTerms()
    }
    
    function deleteRow (RowID) {
        axios.post(`${process.env.REACT_APP_API}/dashboard/terms/delete`, {termID:id, RowID},{
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        })
        .then(res => {
          setActive(false)
          toast.success(res.data.message);
        })
      }

      useEffect(() => {
        if(termsData) {
          setID(termsData?.id)
          setTermID(termsData?.Category?.id)
          setData(termsData?.Collections?.map(d => ({...d, ...d.compmonent})))
        }
      }, [termsData])


    return (
            <React.Fragment>
                <div className="container-fluid p-0">
                    <Tabs addComponentHandler={addComponentHandler} />
                <Card>
                    <CardBody>
                    {success_msg ? (
                        <SweetAlert
                        title={msg}
                        success
                        confirmBtnBsStyle="success"
                        onConfirm={() => {
                            setsuccess_msg(false)
                        }}
                        onCancel={() => {
                            setsuccess_msg(false)
                        }}
                        >
                            </SweetAlert>
                        ) : null}

                       

                    <div className="border-0" >
                        <Row>
                            <Col>
                                <div className="mb-3">
                                    <div className='d-flex'>
                                        <Label>
                                            Term Category
                                        </Label>
                                    </div>
                                    <select
                                        className="form-select fs-5 input_cus"
                                        name='category'
                                        value={termID}
                                        onChange={e => selectTermCategory(e.target.value)}
                                    >
                                        <option value="">Select</option>
                                        {
                                            termsCategories.map(term=>(
                                                <option value={term.id} key={term.id}>{term.mainTitleEn}</option>
                                            ))
                                        }
                                    </select>
                                    {
                                    termIDError ? 
                                        <span className="fs-6 text-danger">
                                        { termIDError }
                                        </span> : ''
                                    }
                                </div>
                                
                            </Col>
                        </Row>
                        
                        <DndContext onDragEnd={handleDrag}>
                            <SortableContext items={data.map((d,i) => i+1)}>
                                {data.map((item, index) => {
                                return item.Type == "Title"?<TitlesForm key={index} sortId={index+1} fonts={fonts} data={data[index]} setData={setData} index={index} deleteRow={deleteRow}/>:
                                item.Type == "Paragraph"?<ParagraphForm key={index} sortId={index+1} options={{modulesQuill, formatsQuill}}  data={data[index]} setData={setData} index={index} deleteRow={deleteRow}/>:null
                                })}
                            </SortableContext>
                        </DndContext>

                        <hr style={{ margin: "25px -20px 20px -20px" }} />
                        <Row>
                            <Col className="d-flex justify-content-end gap-2">
                                <div className="save__btn top m-0">
                                    <button onClick={(e) => submit(e)}>{active?<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />:"Save"}</button>
                                </div>
                                
                                <div className="delete__btn top m-0">
                                    <button
                                        onClick={() => DeleteTerm(id)}
                                        type="button"
                                    >
                                        {deleteActive?<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />:"Delete "+" Term"}
                                    </button>
                                </div>
                               
                                
                               
                            </Col>
                        </Row>
                </div>
            </CardBody>
        </Card>      
        </div>
    </React.Fragment>
  )
}

export default TermsForm



const modulesQuill = {
    toolbar: {
      container: [
        [{ "header": [1, 2, 3, 4, 5, 6, false] }],
        [{ "font": [] }],
        [{ "align": [] }],
        ["bold", "italic", "underline"],
        [{ "list": "ordered" }, { "list": "bullet" }, { "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", "custom-color"] }, { "background": [] }, "link", "emoji"]
      ],
      handlers: {
        "color": function(value) {
          if (value === "custom-color") value = window.prompt("Enter Hex Color Code")
          this.quill.format("color", value)
        }
      }
    },
    keyboard: {
      bindings: {
        tab: false,
        custom: {
          key: 13,
          shiftKey: true,
          handler: function(range, context) {
            this.quill.setSelection(range.index, "silent")
            this.quill.insertText(range.index, "\n", "user")
            this.quill.setSelection(range.index + 1, "silent")
            this.quill.format("linebreak", true, "user")
          }
        },
        handleEnter: {
          key: 13,
          handler: function(range, context) {
            this.quill.setSelection(range.index, "silent")
            this.quill.insertText(range.index, "\n", "user")
            this.quill.setSelection(range.index + 1, "silent")
            this.quill.format("linebreak", true, "user")
          }
        }
      }
    }
  }
  
  const formatsQuill = [
    "header", "font", "size",
    "bold", "italic", "underline", "strike", "blockquote",
    "list", "bullet", "indent", "align",
    "link", "image", "background", "color", "emoji"
  ]