/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, CardTitle,Table } from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"

import AddCategoryForm from './AddCategoryForm'
import "./datatables.scss"
import './specification.scss'
import axios from "axios";


import ReactDragListView from 'react-drag-listview/lib/index.js';
import AddCategoryFormLayouti from "./AddCategoryFormLayouti"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"
import AddCategoryFormLayoutiWork from "./AddCategoryFormLayoutiWork"



const CategoryForLayoutiWork = (props) => {
  const [active, setActive] = useState(false)
  const[productData, setProductData] = useState([])
  const[category, setCategory] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")

  function clickHandle (category, set) {
    setActive(set) 
    setCategory(category)
    props.getData()
  }

  function update(data, type) {
    if(type === "add") {
      setProductData(categories => categories.concat({...data, actions: <div className="d-flex">
      <button style={editBtn}  onClick={() => clickHandle(data, true)}><i className='bx bx-edit fs-4 pr-1' style={{marginRight: "10px"}}></i> Edit</button>
      <button style={deleteBtn}  onClick={(e)=>deleteCategory(data.id)}><i className='bx bx-trash fs-4' style={{marginRight: "10px"}}></i> Delete</button>
    </div>}))
        setMsg("Added!")
    }else {
      setProductData(categories => categories.map(c => c.id === data.id? {...data, actions: <div className="d-flex">
            <button style={editBtn}  onClick={() => clickHandle(data, true)}><i className='bx bx-edit fs-4 pr-1' style={{marginRight: "10px"}}></i> Edit</button>
            <button style={deleteBtn}  onClick={(e)=>deleteCategory(data.id)}><i className='bx bx-trash fs-4' style={{marginRight: "10px"}}></i> Delete</button>
        </div>}: c))
        setMsg("Updated!")
    }
    setsuccess_msg(true)
  }

  function deleteCategory(id) {
    axiosConfig.post(`dashboard/configurations/layoutiCategories/delete`, {id}, {
      headers: { 
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
      }
    })
    .then(res => {
      setProductData(products => products.filter(prod => prod.id != id))
      setMsg("Deleted!")
      setsuccess_msg(true)
    }).catch(err=>{
      toast.error(err.response.data.message)
    })
  }

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
        const modifiedData = [...productData];
        const item = modifiedData.splice(fromIndex, 1)[0];
        modifiedData.splice(toIndex, 0, item);
        setProductData(modifiedData);
        axios.post(`${process.env.REACT_APP_API}/dashboard/configurations/layoutiCategories/arrange`, {ids: modifiedData.map(cat =>cat.id)}, {
          headers: { 
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
          }
        })
        .then(res => {
          
        })
    },
    nodeSelector: 'tr',
    handleSelector: 'tr'
  };


  useEffect(() => {
    setProductData(props?.categories?.map(category => {return{...category, 
      actions: <div className="d-flex">
                    <button style={editBtn}  onClick={() => clickHandle(category,true)}><i className='bx bx-edit fs-4 pr-1' style={{marginRight: "10px"}}></i> Edit</button>
                    <button style={deleteBtn}  onClick={(e)=>deleteCategory(category.id)}><i className='bx bx-trash fs-4' style={{marginRight: "10px"}}></i> Delete</button>
              </div>
    }}))
  }, [props?.categories]) 
  function addCategorySuccsess(){
    setMsg('Category Added Succsessfully')
    setsuccess_msg(true)
  }


  return (
    <React.Fragment>
        <div className="container-fluid p-0">
            {success_msg ? (
                <SweetAlert
                  title={msg}
                  success
                  confirmBtnBsStyle="success"
                  onConfirm={() => {
                    setsuccess_msg(false)
                  }}
                  onCancel={() => {
                    setsuccess_msg(false)
                  }}
                >
                </SweetAlert>
              ) : null}
              <AddCategoryFormLayoutiWork
              category={category} 
              active={active} 
              clickHandle={clickHandle} 
              update={update} 
              isSubCategory={props.isSubCategory}
              addCategorySuccsess={addCategorySuccsess}
              />
              <Row>
                <Col className="col-12">
                  <Card >
                    <CardBody className="pb-4">
                        <Row >
                            <div className="save__btn top mb-2">
                                <button onClick={() => clickHandle(null, true)}><i className='bx bx-plus-circle fs-4' /> Add Category</button>
                            </div>
                        </Row>
                        <div className="table-responsive">
                          <ReactDragListView {...dragProps}>
                              <Table className="table mb-0">
                                  <thead>
                                      <tr>
                                          <th>id</th>
                                          <th>Name (English)</th>
                                          <th>Name (Arabic)</th>
                                          <th>ِActions</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      {(productData || [])?.map((item, index) => (
                                          <tr key={index}>
                                              <th scope="row" className="drag-pointer">{item.id}</th>
                                              <td>{item.nameEn}</td>
                                              <td>{item.nameAr}</td>
                                              <td>{item.actions}</td>
                                          </tr>
                                      ))}
                                  </tbody>
                              </Table>
                          </ReactDragListView>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
        </div>
    </React.Fragment>
  )
}

export default CategoryForLayoutiWork

const deleteBtn = {
  background: "transparent",
  border: "none",
  color:"#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px"
}
