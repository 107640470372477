import React, { useDebugValue, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Col, Row } from "reactstrap"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import "./datatables.scss"
import axios from "axios"
import { useDispatch } from "react-redux"
import { setTitle } from "../../../store/actions"
import AdminPagination from "../../Utilities/AdminPagination"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"

const HireUs = () => {
  const dispatch = useDispatch()
  const [productData, setProductData] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const [filter, setFilter] = useState("")
  const [totalCount, setTotalCount] = useState(0)

  const sizePerPage = 10
  const columns = [
    {
      dataField: "fullName",
      text: "Full Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email Address",
      sort: true,
    },
    {
      dataField: "need",
      text: "I Need",
      sort: true,
    },
    {
      dataField: "details",
      text: "Project details",
      sort: true,
    },
    {
      dataField: "attachment",
      text: "Attachment",
      sort: true,
    },
    {
      dataField: "budget",
      text: "Budget",
      sort: true,
    },
    {
      dataField: "actions",
      text: "Actions",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Created On",
      sort: true,
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  }
  const onPageHandler = (type, { page }) => {
    setPageNo(page)
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: productData.length },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }
  const { SearchBar } = Search

  function deleteContact(id) {
    
    axiosConfig
      .post(
        `/dashboard/hireUs/delete`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        
        setProductData(prods => prods.filter(p => p.id !== id))
        toast.success("Hire Us Deleted Successfuly")
      })
      .catch(err => {
        toast.error("Something went wrong")
      })
  }

  useEffect(() => {
    axiosConfig
      .get(`/dashboard/hireUs`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        

        setProductData(
          res.data.data.hireUs.map(d => {
            return {
              ...d,
              // need:(
              //     // d.need?need.forEach(item=>{
              //         <span key={1313}>{31123}</span>
              //     // })
              // ),
              need:
                d?.need.length !== 0 ? (
                  d?.need.map((size, i) => (
                    <span
                      key={i}
                      className={``}
                      style={{
                        backgroundColor: "rgb(216 216 216)",
                        color: "rgb(0, 0, 0)",
                        borderRadius: "45px",
                        padding: "5px 20px",
                        margin: "0px 2px",
                        display: "inline-block",
                      }}
                    >
                      {size.titleEn}
                    </span>
                  ))
                ) : (
                  <span className="fs-4">-</span>
                ),
              attachment: (
                <a download href={d.attachment} target="blank">
                  Download
                </a>
              ),
              actions: (
                <button style={deleteBtn} onClick={() => deleteContact(d.id)}>
                  <i className="bx bx-trash fs-4"></i>Delete
                </button>
              ),
              created_at: d.created_at ? (
                d.created_at
              ) : (
                <span className="fs-4">-</span>
              ),
            }
          })
        )
      }).catch(err=>{
        ;
        toast.error("Something went wrong")
      })
    setTotalCount(1)
  }, [])
  useEffect(() => {
    dispatch(setTitle("Hire Us"))
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>FeedBack | Layouti</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <CardBody>
              <AdminPagination
                data={productData}
                page={pageNo}
                setFilter={setFilter}
                keyFiled="keyitem"
                buttonText="Add Product"
                url="/ecommerce-add-product"
                sizePerPage={sizePerPage}
                totalSize={totalCount}
                onTableChange={onPageHandler}
                columns={columns}
                none={true}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default HireUs

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "#000",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}
