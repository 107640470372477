import React, { useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import { useDispatch } from "react-redux"
import { setTitle } from "../../../store/actions"

function Dashboard() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setTitle("Dashboard"))
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Layouti</title>
        </MetaTags>
        <Container fluid></Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
