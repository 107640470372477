/* eslint-disable react/prop-types */
import axios from 'axios';
import React, {useState, useRef, useEffect} from 'react';
import { Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import {Link} from "react-router-dom"
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import './scss/specification.scss'


const CategoryForm = (props) => {
    let p = props 
    const [isOpen, setisOpen] = useState(false);
    const [messEn, setMessEn] = useState('');
    const [messAr, setMessAr] = useState('');
    const [link, setLink] = useState('');
    const [loading, setLoading] = useState(false)
    
    const categoryEnRef = useRef(null);
    const categoryArRef = useRef(null);
    

    function submitHandler() {
        setLoading(true)
        
        let data = {
            messageEn: messEn,
            messageAr: messAr,
            link
        } 
        axios.post(`${process.env.REACT_APP_API}/admin/notifications/pushNotification`, data, {
            headers: { 
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            setLoading(false)
            if(res.data.error) {
                return;
            }
            props.clickHandle("push")
        })
    }



    return(
        <div className={`overlay_coup ${p.active? "active":""}`}>
            {isOpen ? (
            <Lightbox
                mainSrc={image.preview}
                enableZoom={false}
                onCloseRequest={() => {
                setisOpen(!isOpen);
                }}
            />
            ) : null}
            <Form onSubmit={(e) => e.preventDefault()} style={{paddingBottom: "70px", height: "400px"}}>
                <div className='head_form'>
                    <div className='log'>
                    <i className='bx bx-bell fs-3'></i>
                        Send Notification for All Users
                    </div>
                    <button onClick={() => p.clickHandle()}><i className='dripicons dripicons-cross'></i></button>
                </div>
                <div className='inputs__wrap '>
                <Row>
                        <Col>
                            <div className="mb-3" ref={categoryEnRef}>
                                <Label htmlFor="productdesc">
                                    Message (English)
                                </Label>
                                <Input
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g.title"
                                    value={messEn}
                                    onChange= {e => setMessEn(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                <Row>
                        <Col>
                            <div className="mb-3" ref={categoryArRef}>
                                <Label htmlFor="productdesc">
                                    Message (Arabic)
                                </Label>
                                <Input
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="مثال. عنوان"
                                    dir='rtl'
                                    value={messAr}
                                    onChange= {e => setMessAr(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                <Row>
                        <Col>
                            <div className="mb-3" ref={categoryArRef}>
                                <Label htmlFor="productdesc">
                                    Link
                                </Label>
                                <Input
                                    name="title"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g.title"
                                    value={link}
                                    onChange= {e => setLink(e.target.value)}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className='foot_form'>
                    <button className='cancel' onClick={() => p.clickHandle()}>Cancel</button>
                    <button className='save' onClick={submitHandler}>{loading?<PulseLoader color={"#ffffff"} loading={true}  size={10} />:"Send"}</button>
                </div>
            </Form>
        </div>
    );
};

export default CategoryForm;
