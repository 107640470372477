import axios from "axios"
import React, { Fragment, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap"
import ReactLoading from "react-loading"
import { toast } from "react-toastify"
const ThanksSectionForm = ({
  data,
  ChangeHandler,
  PID,
  getData,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  function handleAddRowNested() {
    ChangeHandler({...data, cards: data.cards.concat({labelEn:"",labelAr:"", textEn:"",textAr:""})})
  }

  function deleteCard(indx, id) {
    ChangeHandler({...data, cards: data.cards.filter((c,i) => i !== indx)})
    axios.post(`${process.env.REACT_APP_API}/dashboard/product/thanksSection/deleteCard`,{id},{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
      toast.success(res.data.message, {position: "top-right"})
      getData(PID)
    })
  }

  function informationsChangeNested(value, name,indx) {
    ChangeHandler({...data, cards: data.cards.map((obj, i) => {
      if(i == indx) {
        return {...obj, [name]:value}
      }
      return obj
    })})
  }


  function submit(e) {
    e.preventDefault()
    setIsLoading(true)
    const formData = new FormData();
    
    formData.append("titleEn",  data.ThanksSection_titleEn||"")
    formData.append("titleAr", data.ThanksSection_titleAr||"")
    formData.append("descriptionEn", data.ThanksSection_descriptionEn||"")
    formData.append("descriptionAr", data.ThanksSection_descriptionAr||"")
    formData.append("buttonNameEn", data.ThanksSection_buttonNameEn||"")
    formData.append("buttonNameAr", data.ThanksSection_buttonNameAr||"")
    data.cards.forEach((d,i) => {
        formData.append(`cards[${i}][labelEn]`, d.labelEn||"")
        formData.append(`cards[${i}][labelAr]`, d.labelAr||"")
        formData.append(`cards[${i}][textEn]`, d.textEn||"")
        formData.append(`cards[${i}][textAr]`, d.textAr||"")
    })
    formData.append("PID", PID)


    /*************GeneralInformationData*************/
    axios.post(`${process.env.REACT_APP_API}/dashboard/product/thanksSection/save`,formData,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
      toast.success(res.data.message, {position: "top-right"})
      setIsLoading(false)
      getData(PID)
    })
  }

  return (
    <Card>
      <CardBody>
        <Row className="mt-3">
          <Col>
            <div className="d-flex">
              <Label>Main Title (English)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="Label"
              name="ThanksSection_titleEn"
              value={
                data["ThanksSection_titleEn"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, ThanksSection_titleEn: e.target.value}
                )
              }
            />
          </Col>
          <Col>
            <div className="d-flex">
              <Label>Main Title (Arabic)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="عنوان"
              dir="rtl"
              name="ThanksSection_titleAr"
              value={
                data["ThanksSection_titleAr"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, ThanksSection_titleAr: e.target.value}
                )
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <div className="d-flex">
              <Label>Description (English)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <textarea
              className="form-control  mb-0"
              rows="5"
              placeholder="Description"
              name="ThanksSection_descriptionEn"
              value={
                data["ThanksSection_descriptionEn"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, ThanksSection_descriptionEn: e.target.value}
                )
              }
            />
          </Col>
          <Col>
            <div className="d-flex">
              <Label>Description (Arabic)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <textarea
              className="form-control  mb-0"
              rows="5"
              placeholder="وصف"
              name="ThanksSection_descriptionAr"
              value={
                data["ThanksSection_descriptionAr"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, ThanksSection_descriptionAr: e.target.value}
                )
              }
              dir="rtl"
            />
          </Col>
        </Row>
        <hr style={{ margin: "20px -20px 20px -20px" }} />
        <Row className="">
          <Col>
            <div className="d-flex">
              <Label>Button Text (English)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="Label"
              name="ThanksSection_buttonNameEn"
              value={
                data["ThanksSection_buttonNameEn"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, ThanksSection_buttonNameEn: e.target.value}
                )
              }
            />
          </Col>
          <Col>
            <div className="d-flex">
              <Label>Button Text (Arabic)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="عنوان"
              dir="rtl"
              name="ThanksSection_buttonNameAr"
              value={
                data["ThanksSection_buttonNameAr"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, ThanksSection_buttonNameAr: e.target.value}
                )
              }
            />
          </Col>
        </Row>
        <hr style={{ margin: "20px -20px 20px -20px" }} />
        <div className="mb-3">
                        {
                           (data.cards || []).map((item,indx)=>(
                                <Fragment key={indx}>
                                    <Row className="mt-3" >
                                        <Col>
                                            <div className='d-flex'>
                                                <Label>
                                                    Label (English)
                                                </Label>
                                                <label className='ms-auto optional-label--color'>(Optional)</label>
                                            </div>
                                            <Input 
                                                className="input_cus"
                                                placeholder="Label"
                                                name='labelEn'
                                                value={item.labelEn}
                                                onChange={e => informationsChangeNested(e.target.value,e.target.name,indx)}
                                            />
                                        </Col>
                                        <Col>
                                            <div className='d-flex'>
                                                <Label>
                                                Label (Arabic)
                                                </Label>
                                                <label className='ms-auto optional-label--color'>(Optional)</label>
                                            </div>
                                            <Input 
                                                className="input_cus"
                                                placeholder="عنوان"
                                                dir="rtl"
                                                name='labelAr'
                                                value={item.labelAr}

                                                onChange={e => informationsChangeNested(e.target.value,e.target.name,indx)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col>
                                            <div className='d-flex'>
                                                <Label>
                                                    Title (English)
                                                </Label>
                                                <label className='ms-auto optional-label--color'>(Optional)</label>
                                            </div>
                                            <Input 
                                                className="input_cus"
                                                placeholder="Label"
                                                name='textEn'
                                                value={item.textEn}
                                                onChange={e => informationsChangeNested(e.target.value,e.target.name,indx)}                                            />
                                        </Col>
                                        <Col>
                                            <div className='d-flex'>
                                                <Label>
                                                Title (Arabic)
                                                </Label>
                                                <label className='ms-auto optional-label--color'>(Optional)</label>
                                            </div>
                                            <Input 
                                                className="input_cus"
                                                placeholder="عنوان"
                                                name='textAr'
                                                dir="rtl"
                                                value={item.textAr}
                                                onChange={e => informationsChangeNested(e.target.value,e.target.name,indx)}                                            />
                                        </Col>
                                    </Row>
                                   
                                        <Button
                                        color="none"
                                        variant="primary"
                                        className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                                        id="unknown-btn"
                                        style={{
                                            color: "#ff3838",
                                            boxShadow: "none",
                                            height: "45px",
                                            borderRadius: 0,
                                        }}
                                        onClick={e => 
                                        deleteCard(indx, item.id)
                                        }
                                        >
                                        <i className="bx bx-trash-alt fs-4 p-0 mr-1"></i>{" "}
                                        Delete
                                        </Button>
    
                                 
                                    
                                        <hr style={{ margin: "0px -20px 20px -20px" }} />
                                 
                                </Fragment>

                            ))
                        }
                    </div>

                    <Button
                        onClick={() => 
                        handleAddRowNested()
                        }
                        color="none"
                        className="mt-2 fs-5 fw-bold p-0 text-left"
                        style={btnStyle}
                    >
                        <i className="bx bx-plus-circle fs-4"></i>{" "}
                        <span>Add Card</span>
                    </Button>
                    <hr style={{ margin: "20px -20px 20px -20px" }} />
        <Row>
          <Col className="d-flex justify-content-end">
            <button
              type="button"
              className="add-project__submit-button"
              onClick={e => submit(e)}
            >
              {isLoading ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </Col>
        </Row>     
      </CardBody>
    </Card>
  )
}

export default ThanksSectionForm
const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0"
}