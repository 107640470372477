import React, { Fragment, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Sticky from "react-sticky-el"
import "./configration.scss"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { setTitle } from "../../store/actions"
import { useHistory } from "react-router-dom"
import Deliverable from "./Components/TermsForm"
import axiosConfig from "axiosConfig"
import TermsForm from "./Components/TermsForm"

const Terms = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [terms, setTerms] = useState([])
  const [verticalActiveTab, setverticalActiveTab] = useState(0)

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }


  


  function getTerms() {
    axiosConfig
      .get(`/dashboard/terms/getTerm`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setTerms(res.data.data)
      })
      .catch(err => {
        
      })
  }

  function clickHandle () {
    let modifiedTerms = [...terms]
    modifiedTerms.push({
      "Category": {
          "id": null,
        },
      "Collections": [
      ]
  })
    setTerms(modifiedTerms)
  }

  
 

  useEffect(() => {
    dispatch(setTitle("Terms"))
    getTerms()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Terms | Layouti</title>
        </MetaTags>
        <Container fluid>
          <Row>
          </Row>
          <Col>
             
                  <Row>
                    <Col md="3">
                      <Sticky topOffset={-300} >
                        <Nav
                          pills
                          className="flex-column justify-content-between navs_aside"
                        > 
                          
                          {(terms || []).map((data, index) => (
                            <Fragment key={index}>
                          
                              <NavItem className="nav___item">
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className={classnames({
                                    "": true,
                                    active: verticalActiveTab === index,
                                    tab_link: true,
                                  })}
                                  onClick={() => {
                                    toggleVertical(index)
                                  }}
                                >
                                  {data?.Category?.mainTitleEn}
                                </NavLink>
                              </NavItem>
                              </Fragment>
                            ))}
                            
                              <NavItem className="nav___item active">
                                <NavLink
                                  style={{ cursor: "pointer" }}
                                  className="add_term"
                                  onClick={() => {
                                    clickHandle()
                                  }}
                                >
                                    <i className='bx bx-plus-circle' /> Add Term
                                  
                                </NavLink>
                              </NavItem>
                            
                          
                          
                        </Nav>
                      </Sticky>
                    </Col>
                    <Col md="9">
                      {(terms || []).map((data, index) => (
                        <Fragment key={index}>
                          <TabContent
                            activeTab={verticalActiveTab}
                            className="text-muted table__content mt-md-0"
                          >
                            
                            
                            <TabPane tabId={index}>
                              <TermsForm
                                termsData={data}
                                getTerms={getTerms}
                                setverticalActiveTab={setverticalActiveTab}
                                index = {index}
                              />
                            </TabPane>
                          
                          
                            
                            
                          </TabContent>
                      </Fragment>
                      ))}

                    </Col>
                  </Row>
                
            
          </Col>
          
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Terms

const navsStyle = {
  cursor: "pointer",
  borderRadius: 0,
}
