import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import classnames from "classnames";
import {
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import Sticky from 'react-sticky-el';
import './configration.scss'
import { useDispatch } from "react-redux"
import {
    setTitle
} from "../../store/actions";
import OurProducts from "./Components/OurProducts";
import Paragraph from "./Components/Paragraph";


const ProductTab = () => {
    const dispatch = useDispatch()
    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };
    useEffect(() => {   
        dispatch(setTitle("Product Tab"))
     
    }, [])

    return (
        <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>Product Tab | Layouti</title>
        </MetaTags>
        <Container fluid>
        <Col>
            <Row>
            <Col md="3">
            <Sticky topOffset={-300} >
                <Nav pills className="flex-column justify-content-between navs_aside">
                    <NavItem  className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "1",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("1");
                        }}
                        >
                            Products 
                        </NavLink>
                    </NavItem>
                    <NavItem  className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "2",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("2");
                        }}
                        >
                            Paragraph 
                        </NavLink>
                    </NavItem>
                    
             
                </Nav>
                </Sticky>
            </Col>
            <Col md="9">
                    <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted table__content mt-4 mt-md-0"
                    >
                    <TabPane tabId="1">
                        <OurProducts />
                    </TabPane>
                    <TabPane tabId="2">
                        <Paragraph />
                    </TabPane>
                    
                    </TabContent>
            </Col>
            </Row>
        </Col>
        </Container>
        </div>
    </React.Fragment>
)
}

export default ProductTab



const navsStyle = { 
    cursor: "pointer", 
    borderRadius: 0,

}