import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import {
    Col,
    Row,
    Container,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";


import Sticky from 'react-sticky-el';
import axios from "axios";
import { useSelector, useDispatch } from "react-redux"
import {
    setTitle
} from "../../../store/actions";

import Avatar from "./Components/Avatar";
import Titles from "./Components/Titles";
import Pharagraphs from "./Components/Pharagraphs";

import './configration.scss'
import axiosConfig from "axiosConfig";

const ContentGenerator = () => {
    const dispatch = useDispatch()
    const history  = useHistory()
    const  user = useSelector(state => state.UserData.user)
    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    const [images, setImages]  = useState([])
    const [titles, setTitles]  = useState([])
    const [desc, setDesc]  = useState([])


    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };
    
    function update(data, type) {
        switch (type) {
            case "image":
                setImages(data)
                break;
            case "imagedelete":
                setImages(imgs => imgs.filter(img => img.id !== data))
                break;
            case "imagedeleteall":
                setImages([])
                break;
            case "title":
                setTitles(data)
                break;
            case "titledelete":
                setTitles(titls => titls.filter(titl => titl.id !== data))
                break;
            case "titledeleteall":
                setTitles([])
                break;
            case "desc":
                setDesc(data)
                break;
            case "descdelete":
                setDesc(titls => titls.filter(titl => titl.id !== data))
                break;
            case "descdeleteall":
                setDesc([])
                break;
            default:
                break;
        }
    }


    useEffect(() => {
        if(user && user.role == 2) {
            history.push('/dashboard')
            return;
        } 
    }, [user])


    useEffect(() => {   
        dispatch(setTitle("Content Generator"))
        axiosConfig.get(`dashboard/uiAvatar/pages/contentGenerator`, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
            }
        })
        .then(res => {
            setImages(res.data.data.Images)
            setTitles(res.data.data.Titles)
            setDesc(res.data.data.Paragraphs)
        }) 
    }, [])

    return (
        <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>Content Generator | Layouti</title>
        </MetaTags>
        <Container fluid>
        <Col>
            <Row> 
            <Col md="3">
            <Sticky topOffset={-300} >
                <Nav pills className="flex-column justify-content-between navs_aside">
                    <NavItem  className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "1",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("1");
                        }}
                        >
                            Images {images?`(${images.length})`:0}
                        </NavLink>
                    </NavItem>

                    <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer", borderRadius: 0}}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "2",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("2");
                        }}
                        >
                            Titles {titles?`(${titles.length})`:0}
                        </NavLink>
                    </NavItem>

                    <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "3",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("3");
                        }}
                        >
                            Paragraphs {desc?`(${desc.length})`:0}
                        </NavLink>
                    </NavItem>
                </Nav>
                </Sticky>
            </Col>
            <Col md="9">
                <TabContent
                activeTab={verticalActiveTab}
                className="text-muted table__content mt-4 mt-md-0"
                >
                    <TabPane tabId="1">
                        <Avatar data={images} update={update}/>
                    </TabPane>
                    <TabPane tabId="2" >
                        <Titles data={titles} update={update}/>
                    </TabPane>
                    <TabPane tabId="3">
                        <Pharagraphs data={desc} update={update}/>
                    </TabPane>
                    </TabContent>
            </Col>
            </Row>
        </Col>
        </Container>
        </div>
    </React.Fragment>
)
}

export default ContentGenerator

const navsStyle = { 
    cursor: "pointer", 
    borderRadius: 0,

}