import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import axiosConfig from "axiosConfig"
import SweetAlert from "react-bootstrap-sweetalert"

const ContactUsInformation = ({sectionData}) => {
  const [rowsMobileNumberItem, setRowsMobileNumberItem] = useState([{ id: 1 }])
  const [rowsEmailAddressItem, setRowsEmailAddressItem] = useState([{ id: 1 }])
  const [rowsWhatsAppNumberItem, setRowsWhatsAppNumberItem] = useState([{ id: 1 }])
  const [rowsCountryAddressItem, setRowsCountryAddressItem] = useState([{ id: 1 }])
  
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [ourAddressEn, setOurAddressEn] = useState("")
  const [ourAddressAr, setOurAddressAr] = useState("")
  const [contactTitleEn, setContactTitleEn] = useState("")
  const [contactTitleAr, setContactTitleAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")
  const [mobileNumberData, setMobileNumberData] = useState([{
    id:1, 
    titleEn: '', 
    titleAr: '', 
    mobile: '', 
    status: 'new', 
}])
  const [emailAddressData, setEmailAddressData] = useState([{
    id:1, 
    titleEn: '', 
    titleAr: '', 
    email: '', 
    status: 'new', 
}])
  const [whatsAppNumberData, setWhatsAppNumberData] = useState([{
    id:1, 
    titleEn: '', 
    titleAr: '', 
    whatsApp: '', 
    status: 'new', 
}])
  const [countryAddressData, setCountryAddressData] = useState([{
    id:1, 
    titleEn: '', 
    titleAr: '', 
    status: 'new', 
}])

  function handleAddRowNestedMobileNumber() {
    const modifiedRows = [...rowsMobileNumberItem]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setRowsMobileNumberItem(modifiedRows)
    setMobileNumberData(data => data.concat({
      id:modifiedRows.length, 
      titleEn: '', 
      titleAr: '', 
      mobile: '', 
      status: 'new', 
    }))
  }

  function handleRemoveRowMobileNumber(id) {
    if (id !== 0) {
      var modifiedRows = [...rowsMobileNumberItem]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setRowsMobileNumberItem(modifiedRows)
      setMobileNumberData(d => d.filter((x,i) => x["id"] !== id))
    }
  }

  function handleChangeMobileData(keyName,keyValue,key) {
    let modifiedData = [...mobileNumberData]
    modifiedData[key][keyName]=keyValue
    setMobileNumberData(modifiedData)
    
  }

  function handleAddRowNestedEmailAddress() {
    const modifiedRows = [...rowsEmailAddressItem]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setRowsEmailAddressItem(modifiedRows)
    setEmailAddressData(data => data.concat({
      id:modifiedRows.length, 
      titleEn: '', 
      titleAr: '', 
      email: '', 
      status: 'new', 
    }))
  }

  function handleRemoveRowEmailAddress(id) {
    if (id !== 0) {
      var modifiedRows = [...rowsEmailAddressItem]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setRowsEmailAddressItem(modifiedRows)
      setEmailAddressData(d => d.filter((x,i) => x["id"] !== id))
    }
  }

  function handleChangeEmailAddressData(keyName,keyValue,key) {
    let modifiedData = [...emailAddressData]
    modifiedData[key][keyName]=keyValue
    setEmailAddressData(modifiedData)
    
  }

  function handleAddRowNestedWhatsAppNumber() {
    const modifiedRows = [...rowsWhatsAppNumberItem]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setRowsWhatsAppNumberItem(modifiedRows)
    setWhatsAppNumberData(data => data.concat({
      id:modifiedRows.length, 
      titleEn: '', 
      titleAr: '', 
      whatsApp: '', 
      status: 'new', 
    }))
  }

  function handleRemoveRowWhatsAppNumber(id) {
    if (id !== 0) {
      var modifiedRows = [...rowsWhatsAppNumberItem]
      //
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setRowsWhatsAppNumberItem(modifiedRows)
      setWhatsAppNumberData(d => d.filter((x,i) => x["id"] !== id))
      //
      //
    }
  }
  function handleChangeWhatsAppNumberData(keyName,keyValue,key) {
    let modifiedData = [...whatsAppNumberData]
    modifiedData[key][keyName]=keyValue
    setWhatsAppNumberData(modifiedData)
    
  }

  function handleAddRowNestedCountryAddress() {
    const modifiedRows = [...rowsCountryAddressItem]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setRowsCountryAddressItem(modifiedRows)
    setCountryAddressData(data => data.concat({
      id:modifiedRows.length, 
      titleEn: '', 
      titleAr: '', 
      status: 'new', 
    }))
  }

  function handleRemoveRowCountryAddress(id) {
    if (id !== 0) {
      var modifiedRows = [...rowsCountryAddressItem]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setRowsCountryAddressItem(modifiedRows)
      setCountryAddressData(d => d.filter((x,i) => x["id"] !== id))
    }
  }
  function handleChangeCountryAddressData(keyName,keyValue,key) {
    let modifiedData = [...countryAddressData]
    modifiedData[key][keyName]=keyValue
    setCountryAddressData(modifiedData)
    
  }
  function deleteCardMobileNumber(key,cardId,status){
    if(status==='old'){
      axiosConfig.post(`/dashboard/contactPage/information/deleteMobileCard`, {id:cardId}, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      }).then(res=>{
        setMsg(`Deleted Successfully`)
        setsuccess_msg(true)
        handleRemoveRowMobileNumber(cardId)
      }).catch(err=>{
        //
        toast.error(err.response.data.message)
      })
    }else{
      handleRemoveRowMobileNumber(cardId)
    }
  }
  function deleteCardEmailAddress(key,cardId,status){
    if(status==='old'){
      axiosConfig.post(`/dashboard/contactPage/information/deleteEmailCard`, {id:cardId}, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      }).then(res=>{
        setMsg(`Deleted Successfully`)
        setsuccess_msg(true)
        handleRemoveRowEmailAddress(cardId)
      }).catch(err=>{
        //
        toast.error(err.response.data.message)
      })
    }else{
      handleRemoveRowEmailAddress(cardId)
    }
  }
  function deleteCardWhatsAppNumber(key,cardId,status){
    if(status==='old'){
      axiosConfig.post(`/dashboard/contactPage/information/deleteWhatsAppCard`, {id:cardId}, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      }).then(res=>{
        setMsg(`Deleted Successfully`)
        setsuccess_msg(true)
        handleRemoveRowWhatsAppNumber(cardId)
      }).catch(err=>{
        //
        toast.error(err.response.data.message)
      })
    }else{
      handleRemoveRowWhatsAppNumber(cardId)
    }
  }
  function deleteCardCountryAddress(key,cardId,status){
    if(status==='old'){
      axiosConfig.post(`/dashboard/contactPage/information/deleteCountryCard`, {id:cardId}, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      }).then(res=>{
        setMsg(`Deleted Successfully`)
        setsuccess_msg(true)
        handleRemoveRowCountryAddress(cardId)
      }).catch(err=>{
        //
        toast.error(err.response.data.message)
      })
    }else{
      handleRemoveRowCountryAddress(cardId)
    }
  }
  
  const [isLoading, setIsLoading] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  function submit() {
    const formData = new FormData()
    const data = {
      titleEn,
      titleAr,
      descriptionEn: descEn,
      descriptionAr: descAr,
      AddressEn: ourAddressEn,
      AddressAr: ourAddressAr,
      informationTitleEn: contactTitleEn,
      informationTitleAr: contactTitleAr,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    mobileNumberData.forEach((card,index)=>{
      formData.append(`mobileCards[${index}][titleEn]`, card.titleEn)
      formData.append(`mobileCards[${index}][titleAr]`, card.titleAr)
      formData.append(`mobileCards[${index}][mobile]`, card.mobile)
    })
    emailAddressData.forEach((card,index)=>{
      formData.append(`emailCards[${index}][titleEn]`, card.titleEn)
      formData.append(`emailCards[${index}][titleAr]`, card.titleAr)
      formData.append(`emailCards[${index}][email]`, card.email)
    })
    whatsAppNumberData.forEach((card,index)=>{
      formData.append(`whatsAppCards[${index}][titleEn]`, card.titleEn)
      formData.append(`whatsAppCards[${index}][titleAr]`, card.titleAr)
      formData.append(`whatsAppCards[${index}][whatsApp]`, card.whatsApp)
    })
    countryAddressData.forEach((card,index)=>{
      formData.append(`countryCards[${index}][titleEn]`, card.titleEn)
      formData.append(`countryCards[${index}][titleAr]`, card.titleAr)
    })

    // formData.append("image", image)
    setIsLoading(true)
    axiosConfig.post(`/dashboard/contactPage/information`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res=>{
      //
       toast.success(res.data.message)
      setsuccess_msg(true)
      setIsLoading(false)
    }).catch(err=>{
      //
      toast.error(err.response.data.message)
      setIsLoading(false)
    })
  }
    
  useEffect(()=>{
    setTitleEn(sectionData?.titleEn)
    setTitleAr(sectionData?.titleAr)
    setDescEn(sectionData?.descriptionEn)
    setDescAr(sectionData?.descriptionAr)
    setOurAddressEn(sectionData?.AddressEn)
    setOurAddressAr(sectionData?.AddressAr)
    setMobileNumberData(sectionData?.mobileCards)
    setEmailAddressData(sectionData?.emailCards)
    setWhatsAppNumberData(sectionData?.whatsAppCards)
    setCountryAddressData(sectionData?.countryCards)
    setContactTitleEn(sectionData?.informationTitleEn)
    setContactTitleAr(sectionData?.informationTitleAr)
    // setImage(sectionData?.image)
    //
  },[sectionData])
  
  return (
    <div>
       {success_msg ? (
        <SweetAlert
          title={msg}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        >
        </SweetAlert>
      ) : null}
      <Form className="border-0 pb-2">
        
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Get In Touch Title (english)</Label>
              <Input
                id="itemnameen"
                name="itemnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={titleEn}
                onChange={e => setTitleEn(e.target.value)}
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Get In Touch Title (arabic)</Label>
              <Input
                id="itemnamear"
                name="itemnamear"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. "
                value={titleAr}
                onChange={e => setTitleAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Get In Touch Description (English)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="Enter your description here"
                value={descEn}
                onChange={e => setDescEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Get In Touch Description (Arabic)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="اكتب وصف هنا"
                value={descAr}
                onChange={e => setDescAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Contact Information Title (English)</Label>
               <Input
                id="itemnameen"
                name="itemnameen"
                type="text"
                className="form-control input_cus"
                placeholder="Enter your description here"
                value={contactTitleEn}
                onChange={e => setContactTitleEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Contact Information Title (Arabic)</Label>
              <Input
                id="itemnamear"
                name="itemnamear"
                type="text"
                className="form-control input_cus"
                placeholder="اكتب وصف هنا"
                value={contactTitleAr}
                onChange={e => setContactTitleAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>

        {(mobileNumberData || []).map((formRow, key) => (
          <Fragment key={key}>
            <hr style={{ margin: "25px -20px 20px -20px" }} />
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Mobile Number Title (English)</Label>
                  <Input
                    id="itemnameen"
                    name="titleEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Focus Keypharse"
                    value={formRow?.titleEn}
                    onChange={e => handleChangeMobileData(e.target.name,e.target.value,key)}
                  />
                </div>
              </Col>

              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Mobile Number Item Title (Arabic)</Label>
                  <Input
                    id="itemnamear"
                    name="titleAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. "
                    value={formRow?.titleAr}
                    onChange={e => handleChangeMobileData(e.target.name,e.target.value,key)}
                    dir="rtl"
                    lang="ar"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="12">
                <div>
                  <Label htmlFor="productdesc">Mobile Number</Label>
                  <Input
                    id="itemnameen"
                    name="mobile"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Focus Keypharse"
                    value={formRow?.mobile}
                    onChange={e => handleChangeMobileData(e.target.name,e.target.value,key)}
                  />
                </div>
              </Col>
              

            </Row>

            {key !== 0 ? (
              <Button
                color="none"
                variant="primary"
                className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                id="unknown-btn"
                style={{
                  color: "#ff3838",
                  boxShadow: "none",
                  height: "45px",
                  borderRadius: 0,
                }}
                onClick={e => {
                  deleteCardMobileNumber(key,formRow.id,formRow?.status=='new'?'new':'old')
                  // handleRemoveRowMobileNumber(formRow.id)
                }}
              >

                <i className="bx bx-trash-alt fs-4 p-0 mr-1" /> Delete
                
              </Button>

            ) : null}
          </Fragment>
        ))}
          <hr style={{ margin: "25px -20px 20px -20px" }} />
          <Button
            onClick={() => {
              handleAddRowNestedMobileNumber()
            }}
            color="none"
            className="mt-2 fs-5 fw-bold p-0 text-left"
            style={btnStyle}
          >
            <i className="bx bx-plus-circle fs-4" /> <span>Add Mobile Number</span>
          </Button>
          

          <hr style={{ margin: "20px -20px 0px -20px" }} />
        {(emailAddressData || []).map((formRow, key) => (
          <Fragment key={key}>
            
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Email Address Title (English)</Label>
                  <Input
                    id="itemnameen"
                    name="titleEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Focus Keypharse"
                    value={formRow?.titleEn}
                    onChange={e => handleChangeEmailAddressData(e.target.name,e.target.value,key)}
                  />
                </div>
              </Col>

              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Email Address Item Title (Arabic)</Label>
                  <Input
                    id="itemnamear"
                    name="titleAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. "
                    value={formRow?.titleAr}
                    onChange={e => handleChangeEmailAddressData(e.target.name,e.target.value,key)}
                    dir="rtl"
                    lang="ar"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="12">
                <div>
                  <Label htmlFor="productdesc">Email Address</Label>
                  <Input
                    id="itemnameen"
                    name="email"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Focus Keypharse"
                    value={formRow?.email}
                    onChange={e => handleChangeEmailAddressData(e.target.name,e.target.value,key)}
                  />
                </div>
              </Col>
              

            </Row>

            {key !== 0 ? (
              <Button
                color="none"
                variant="primary"
                className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                id="unknown-btn"
                style={{
                  color: "#ff3838",
                  boxShadow: "none",
                  height: "45px",
                  borderRadius: 0,
                }}
                onClick={e => {
                  deleteCardEmailAddress(key,formRow.id,formRow?.status=='new'?'new':'old')
                  // handleRemoveRowEmailAddress(formRow.id)
                }}
              >

                <i className="bx bx-trash-alt fs-4 p-0 mr-1" /> Delete
                
              </Button>

            ) : null}

             {
              key!=0?
              <hr style={{ margin: "5px -20px 20px -20px" }} />
              :
              <hr style={{ margin: "25px -20px 20px -20px" }} />
            }
          </Fragment>
        ))}
          
          <Button
            onClick={() => {
              handleAddRowNestedEmailAddress()
            }}
            color="none"
            className="mt-2 fs-5 fw-bold p-0 text-left"
            style={btnStyle}
          >
            <i className="bx bx-plus-circle fs-4" /> <span>Add Email Address</span>
          </Button>
          

          <hr style={{ margin: "20px -20px 0px -20px" }} />
        {(whatsAppNumberData || []).map((formRow, key) => (
          <Fragment key={key}>
            
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">WhatsApp Number Title (English)</Label>
                  <Input
                    id="itemnameen"
                    name="titleEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Focus Keypharse"
                    value={formRow?.titleEn}
                    onChange={e => handleChangeWhatsAppNumberData(e.target.name,e.target.value,key)}
                  />
                </div>
              </Col>

              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">WhatsApp Number Title (Arabic)</Label>
                  <Input
                    id="itemnamear"
                    name="titleAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. "
                    value={formRow?.titleAr}
                    onChange={e => handleChangeWhatsAppNumberData(e.target.name,e.target.value,key)}
                    dir="rtl"
                    lang="ar"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="12">
                <div>
                  <Label htmlFor="productdesc">WhatsApp Number</Label>
                  <Input
                    id="itemnameen"
                    name="whatsApp"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Focus Keypharse"
                    value={formRow?.whatsApp}
                    onChange={e => handleChangeWhatsAppNumberData(e.target.name,e.target.value,key)}
                  />
                </div>
              </Col>
              

            </Row>

            {key !== 0 ? (
              <Button
                color="none"
                variant="primary"
                className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                id="unknown-btn"
                style={{
                  color: "#ff3838",
                  boxShadow: "none",
                  height: "45px",
                  borderRadius: 0,
                }}
                onClick={e => {
                  deleteCardWhatsAppNumber(key,formRow.id,formRow?.status=='new'?'new':'old')
                }}
              >

                <i className="bx bx-trash-alt fs-4 p-0 mr-1" /> Delete
                
              </Button>

            ) : null}
             {
              key!=0?
              <hr style={{ margin: "5px -20px 20px -20px" }} />
              :
              <hr style={{ margin: "25px -20px 20px -20px" }} />
            }
          </Fragment>
        ))}
          
          <Button
            onClick={() => {
              handleAddRowNestedWhatsAppNumber()
            }}
            color="none"
            className="mt-2 fs-5 fw-bold p-0 text-left"
            style={btnStyle}
          >
            <i className="bx bx-plus-circle fs-4" /> <span>Add WhatsApp Number</span>
          </Button>
          <hr style={{ margin: "20px -20px 0px -20px" }} />

          <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Our Address Title (english)</Label>
              <Input
                id="itemnameen"
                name="itemnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={ourAddressEn}
                onChange={e => setOurAddressEn(e.target.value)}
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Our Address Title (arabic)</Label>
              <Input
                id="itemnamear"
                name="itemnamear"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. "
                value={ourAddressAr}
                onChange={e => setOurAddressAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>

        <hr style={{ margin: "20px -20px 0px -20px" }} />
        {(countryAddressData || []).map((formRow, key) => (
          <Fragment key={key}>
            
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Country Address Title (English)</Label>
                  <Input
                    id="itemnameen"
                    name="titleEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Focus Keypharse"
                    value={formRow?.titleEn}
                    onChange={e => handleChangeCountryAddressData(e.target.name,e.target.value,key)}
                  />
                </div>
              </Col>

              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Country Address Title (Arabic)</Label>
                  <Input
                    id="itemnamear"
                    name="titleAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. "
                    value={formRow?.titleAr}
                    onChange={e => handleChangeCountryAddressData(e.target.name,e.target.value,key)}
                    dir="rtl"
                    lang="ar"
                  />
                </div>
              </Col>
            </Row>
        
            {key !== 0 ? (
              <Button
                color="none"
                variant="primary"
                className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                id="unknown-btn"
                style={{
                  color: "#ff3838",
                  boxShadow: "none",
                  height: "45px",
                  borderRadius: 0,
                }}
                onClick={e => {
                  deleteCardCountryAddress(key,formRow.id,formRow?.status=='new'?'new':'old')
                  // handleRemoveRowCountryAddress(formRow.id)
                }}
              >

                <i className="bx bx-trash-alt fs-4 p-0 mr-1" /> Delete
                
              </Button>

            ) : null}
            {
              key!=0?
              <hr style={{ margin: "5px -20px 20px -20px" }} />
              :
              <hr style={{ margin: "25px -20px 20px -20px" }} />
            }
          </Fragment>
        ))}
          
          <Button
            onClick={() => {
              handleAddRowNestedCountryAddress()
            }}
            color="none"
            className="mt-2 fs-5 fw-bold p-0 text-left"
            style={btnStyle}
          >
            <i className="bx bx-plus-circle fs-4" /> <span>Add Country Address</span>
          </Button>
          <hr style={{ margin: "20px -20px 0px -20px" }} />


        <Row className="mt-4">
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0">
            <button type='button' onClick={submit} disabled={isLoading?true:false}>
                {isLoading ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default ContactUsInformation

const cardStyle = {
  borderTop: "2px solid #dedede",
}

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
