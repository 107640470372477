import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import { Controller, useFieldArray } from "react-hook-form"
import { Link } from "react-router-dom"
import {
  Accordion,
  Button,
  Card,
  Col,
  Collapse,
  Form,
  FormFeedback,
  Label,
  Row,
} from "reactstrap"
import ReactLoading from "react-loading"
import classnames from "classnames"
import prettyBytes from "pretty-bytes"
import ProductsCardParagraph from "./ProductsCardParagraph"
import Lightbox from "react-image-lightbox"

function ProductCard({
  productData,
  handleChangeBlogData,
  handleAcceptedFiles,
  col,
  deleteImage,
  t_col1,
  submit,
  deleteCard,
  deleteLoad,
  active,
}) {
  const [isOpen, setisOpen] = useState(false)
  const [photoIndex, setphotoIndex] = useState(0)

  function prevSelectImage(e, id) {
    e.preventDefault()
    setphotoIndex(id)
    setisOpen(!isOpen)
  }

  return (
    <>
      {isOpen ? (
        <Lightbox
          mainSrc={photoIndex}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
      {productData &&
        productData.map((product, index) => (
          <div className="accordion-item" key={product.id}>
            <h2 className="accordion-header" id="headingOne">
              <button
                className={classnames(
                  "accordion-button",
                  "fw-medium",
                  "fw-bold",
                  "fs-5",
                  { collapsed: !col[index] }
                )}
                type="button"
                onClick={() => t_col1(index)}
                style={{ cursor: "pointer" }}
              >
                {product?.titleEn ? product?.titleEn : "Product Title"}
              </button>
            </h2>
            <Collapse isOpen={col[index]} className="accordion-collapse">
              <div className="accordion-body">
                <Form className="border-0 p-0">
                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label htmlFor="metatitle">Logo Image</Label>
                        <div className="mb-3">
                          <input
                            type="file"
                            className="form-control form-control-lg "
                            onChange={e => {
                              handleAcceptedFiles(e.target.files, "logo", index)
                            }}
                            style={{ borderRadius: 0 }}
                          />
                          <FormFeedback className="fs-6">
                            This Fileld Is Required!
                          </FormFeedback>
                        </div>
                      </div>
                    </Col>

                    {product?.logo && (
                      <Row
                        className="align-items-center mb-3"
                        style={{ paddingLeft: "14px", paddingRight: "0px" }}
                      >
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={product?.logo?.name}
                            src={
                              product?.logo?.preview
                                ? product?.logo?.preview
                                : product?.logo
                            }
                            style={{ objectFit: "cover" }}
                          />
                        </Col>

                        <Col>
                          <Link to="#" className="text-muted ">
                            {product?.logo?.name}
                          </Link>
                          <p className="mb-0">
                            <strong>{product?.logo?.formattedSize}</strong>
                          </p>
                        </Col>

                        <Col className="p-0">
                          <div className="image__options">
                            <button
                              className="p-0"
                              type="button"
                              onClick={e =>
                                prevSelectImage(
                                  e,
                                  product?.logo?.preview
                                    ? product?.logo?.preview
                                    : product?.logo
                                )
                              }
                            >
                              <i className="far fa-eye  fs-5 p-0"></i>
                            </button>
                            <button
                              type="button"
                              className="p-0"
                              style={{ color: "#ff3838" }}
                              onClick={e => deleteImage(e, "logo", index)}
                            >
                              <i className="bx bx-trash fs-4"></i>
                            </button>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Row>

                  <Row>
                    <Col>
                      <div className="mb-3">
                        <Label htmlFor="metatitle">Side Image</Label>
                        <div className="mb-3">
                          <input
                            type="file"
                            className="form-control form-control-lg "
                            onChange={e => {
                              handleAcceptedFiles(
                                e.target.files,
                                "slide",
                                index
                              )
                            }}
                            style={{ borderRadius: 0 }}
                          />
                          <FormFeedback className="fs-6">
                            This Fileld Is Required!
                          </FormFeedback>
                        </div>
                      </div>
                    </Col>

                    {product?.slide && (
                      <Row
                        className="align-items-center mb-3"
                        style={{ paddingLeft: "14px", paddingRight: "0px" }}
                      >
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={product?.slide?.name}
                            src={
                              product?.slide?.preview
                                ? product?.slide?.preview
                                : product?.slide
                            }
                            style={{ objectFit: "cover" }}
                          />
                        </Col>

                        <Col>
                          <Link to="#" className="text-muted ">
                            {product?.slide?.name}
                          </Link>
                          <p className="mb-0">
                            <strong>{product?.slide?.formattedSize}</strong>
                          </p>
                        </Col>

                        <Col className="p-0">
                          <div className="image__options">
                            <button
                              className="p-0"
                              type="button"
                              onClick={e =>
                                prevSelectImage(
                                  e,
                                  product?.slide?.preview
                                    ? product?.slide?.preview
                                    : product?.slide
                                )
                              }
                            >
                              <i className="far fa-eye  fs-5 p-0"></i>
                            </button>
                            <button
                              type="button"
                              className="p-0"
                              style={{ color: "#ff3838" }}
                              onClick={e => deleteImage(e, "slide", index)}
                            >
                              <i className="bx bx-trash fs-4"></i>
                            </button>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </Row>

                  <Row className="mb-3">
                    <Col xs="6">
                      <div className="mb-3">
                        <Label htmlFor="titleEn">Title English</Label>
                        <input
                          id="titleEn"
                          type="text"
                          className="form-control input_cus"
                          placeholder="e.g. example.com"
                          name="titleEn"
                          value={product?.titleEn}
                          onChange={e => {
                            handleChangeBlogData(
                              e.target.name,
                              e.target.value,
                              index
                            )
                          }}
                        />
                        <FormFeedback className="fs-6">
                          This Fileld Is Required!
                        </FormFeedback>
                      </div>
                    </Col>
                    <Col xs="6">
                      <div className="mb-3">
                        <Label htmlFor="titleAr">Title Arabic</Label>
                        <input
                          id="titleAr"
                          type="text"
                          className="form-control input_cus"
                          placeholder="عنوان"
                          dir="rtl"
                          name="titleAr"
                          value={product?.titleAr}
                          onChange={e => {
                            handleChangeBlogData(
                              e.target.name,
                              e.target.value,
                              index
                            )
                          }}
                        />
                        <FormFeedback className="fs-6">
                          This Fileld Is Required!
                        </FormFeedback>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col xs="6">
                      <div className="mb-3">
                        <Label htmlFor="titleEn">Sub Title English</Label>
                        <input
                          id="titleEn"
                          type="text"
                          className="form-control input_cus"
                          placeholder="e.g. example.com"
                          name="subTitleEn"
                          value={product?.subTitleEn}
                          onChange={e => {
                            handleChangeBlogData(
                              e.target.name,
                              e.target.value,
                              index
                            )
                          }}
                        />
                        <FormFeedback className="fs-6">
                          This Fileld Is Required!
                        </FormFeedback>
                      </div>
                    </Col>
                    <Col xs="6">
                      <div className="mb-3">
                        <Label htmlFor="titleAr">Sub Title Arabic</Label>
                        <input
                          id="titleAr"
                          type="text"
                          className="form-control input_cus"
                          placeholder="عنوان"
                          dir="rtl"
                          name="subTitleAr"
                          value={product?.subTitleAr}
                          onChange={e => {
                            handleChangeBlogData(
                              e.target.name,
                              e.target.value,
                              index
                            )
                          }}
                        />
                        <FormFeedback className="fs-6">
                          This Fileld Is Required!
                        </FormFeedback>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col>
                      <Label>Description (english)</Label>
                      <textarea
                        className="form-control  mb-0"
                        rows="5"
                        placeholder="Description"
                        name="descriptionEn"
                        value={product?.descriptionEn}
                        onChange={e => {
                          handleChangeBlogData(
                            e.target.name,
                            e.target.value,
                            index
                          )
                        }}
                      />
                    </Col>
                    <Col>
                      <Label>Description (arabic)</Label>
                      <textarea
                        className="form-control  mb-0"
                        rows="5"
                        placeholder="وصف"
                        name="descriptionAr"
                        value={product?.descriptionAr}
                        onChange={e => {
                          handleChangeBlogData(
                            e.target.name,
                            e.target.value,
                            index
                          )
                        }}
                        dir="rtl"
                      />
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col xs="6">
                      <div className="mb-3">
                        <Label htmlFor="reviewLink">Review link</Label>
                        <input
                          id="reviewLink"
                          type="text"
                          className="form-control input_cus"
                          placeholder="e.g. example.com"
                          name="reviewLink"
                          value={product?.reviewLink}
                          onChange={e => {
                            handleChangeBlogData(
                              e.target.name,
                              e.target.value,
                              index
                            )
                          }}
                        />
                        <FormFeedback className="fs-6">
                          This Fileld Is Required!
                        </FormFeedback>
                      </div>
                    </Col>
                    <Col xs="6">
                      <div className="mb-3">
                        <Label htmlFor="downloadLink">Download link</Label>
                        <input
                          id="downloadLink"
                          type="text"
                          className="form-control input_cus"
                          placeholder="e.g. example.com"
                          name="downloadLink"
                          value={product?.downloadLink}
                          onChange={e => {
                            handleChangeBlogData(
                              e.target.name,
                              e.target.value,
                              index
                            )
                          }}
                        />
                        <FormFeedback className="fs-6">
                          This Fileld Is Required!
                        </FormFeedback>
                      </div>
                    </Col>
                  </Row>

                  <hr style={{ margin: "15px -20px 20px -20px" }} />

                  {product?.cards &&
                    product?.cards.map((para, paraIndex) => (
                      <div key={paraIndex}>
                        <Row>
                          <Col xs="6">
                            <div className="mb-3">
                              <Label htmlFor="reviewLink">
                                Title {paraIndex + 1} (English)
                              </Label>
                              <input
                                id="reviewLink"
                                type="text"
                                className="form-control input_cus"
                                placeholder="e.g. example.com"
                                name="titleEn"
                                value={para?.titleEn}
                                onChange={e => {
                                  handleChangeBlogData(
                                    e.target.name,
                                    e.target.value,
                                    index,
                                    paraIndex
                                  )
                                }}
                              />
                              <FormFeedback className="fs-6">
                                This Fileld Is Required!
                              </FormFeedback>
                            </div>
                          </Col>
                          <Col xs="6">
                            <div className="mb-3">
                              <Label htmlFor="downloadLink">
                                Title {paraIndex + 1} (Arabic)
                              </Label>
                              <input
                                id="downloadLink"
                                type="text"
                                placeholder="عنوان"
                                dir="rtl"
                                className="form-control input_cus"
                                name="titleAr"
                                value={para?.titleAr}
                                onChange={e => {
                                  handleChangeBlogData(
                                    e.target.name,
                                    e.target.value,
                                    index,
                                    paraIndex
                                  )
                                }}
                              />
                              <FormFeedback className="fs-6">
                                This Fileld Is Required!
                              </FormFeedback>
                            </div>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col>
                            <Label>Description {paraIndex + 1} (English)</Label>
                            <textarea
                              className="form-control  mb-0"
                              rows="5"
                              placeholder="Description"
                              name="descriptionEn"
                              value={para?.descriptionEn}
                              onChange={e => {
                                handleChangeBlogData(
                                  e.target.name,
                                  e.target.value,
                                  index,
                                  paraIndex
                                )
                              }}
                            />
                          </Col>
                          <Col>
                            <Label>Description {paraIndex + 1} (Arabic)</Label>
                            <textarea
                              className="form-control  mb-0"
                              rows="5"
                              placeholder="وصف"
                              name="descriptionAr"
                              value={para?.descriptionAr}
                              onChange={e => {
                                handleChangeBlogData(
                                  e.target.name,
                                  e.target.value,
                                  index,
                                  paraIndex
                                )
                              }}
                              dir="rtl"
                            />
                          </Col>
                        </Row>
                        <hr style={{ margin: "15px -20px 20px -20px" }} />
                      </div>
                    ))}

                  <Row>
                    <Col className="d-flex justify-content-end gap-2 col pb-2 ">
                      <div className="save__btn top m-0 me-3">
                        <button type="button" onClick={e => submit(e, index)}>
                          {active ? (
                            <ReactLoading
                              type={"spin"}
                              color={"#ffffff"}
                              height={30}
                              width={30}
                            />
                          ) : (
                            "Save"
                          )}
                        </button>
                      </div>
                      <div className="delete__btn top m-0">
                        <button
                          type="button"
                          onClick={e => deleteCard(product?.id)}
                        >
                          {deleteLoad ? (
                            <ReactLoading
                              type={"spin"}
                              color={"#ffffff"}
                              height={30}
                              width={30}
                            />
                          ) : (
                            "Delete Product"
                          )}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Collapse>
          </div>
        ))}
    </>
  )
}

export default ProductCard

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
