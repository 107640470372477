import axios from "axios"
import React, { useEffect, useState } from "react"
import {Card, Col, Input, Label, Row } from "reactstrap"
import Select from "react-select"
import { Link } from "react-router-dom"

const ExportScreenForm = ({
  data,
  ChangeHandler,
  setImgSrc,
  setisOpen,
  handleAcceptedImage
}) => {
  return (
    <>
      <Row className="">
          <Col>
            <div className="d-flex">
              <Label>Main Title (English)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="Label"
              name="DesignAppMobileExportScreen_titleEn"
              value={
                data["DesignAppMobileExportScreen_titleEn"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, DesignAppMobileExportScreen_titleEn: e.target.value}
                )
              }
            />
          </Col>
          <Col>
            <div className="d-flex">
              <Label>Main Title (Arabic)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="عنوان"
              dir="rtl"
              name="DesignAppMobileExportScreen_titleAr"
              value={
                data["DesignAppMobileExportScreen_titleAr"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, DesignAppMobileExportScreen_titleAr: e.target.value}
                )
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <div className="d-flex">
              <Label>Description (English)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <textarea
              className="form-control  mb-0"
              rows="5"
              placeholder="Description"
              name="DesignAppMobileExportScreen_descriptionEn"
              value={
                data["DesignAppMobileExportScreen_descriptionEn"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, DesignAppMobileExportScreen_descriptionEn: e.target.value}
                )
              }
            />
          </Col>
          <Col>
            <div className="d-flex">
              <Label>Description (Arabic)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <textarea
              className="form-control  mb-0"
              rows="5"
              placeholder="وصف"
              name="DesignAppMobileExportScreen_descriptionAr"
              value={
                data["DesignAppMobileExportScreen_descriptionAr"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, DesignAppMobileExportScreen_descriptionAr: e.target.value}
                )
              }
              dir="rtl"
            />
          </Col>
        </Row>

      <Row className="mt-3">
          <Col xs="12">
            <div>
              <div className="d-flex">
                <Label>Image</Label>
                <label className="ms-auto optional-label--color">
                  (Optional)
                </label>
              </div>
              <Input
                type="file"
                name="image"
                className="form-control form-control-lg "
                onChange={e => handleAcceptedImage(e.target.files, "exportscreen",e)}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col>
            <div className="dropzone-previews" id="file-previews">
              {}
              {data["DesignAppMobileExportScreen_image"]? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={data["DesignAppMobileExportScreen_image"]?.name}
                          src={
                            data["DesignAppMobileExportScreen_image"]?.preview
                              ? data["DesignAppMobileExportScreen_image"]?.preview
                              : data["DesignAppMobileExportScreen_image"]
                          }
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {data["DesignAppMobileExportScreen_image"]?.name}
                        </Link>
                        <p className="mb-0">
                            <strong>{data["DesignAppMobileExportScreen_image"]?.formattedSize}</strong>
                        </p>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            type="button"
                            className="p-0"
                            onClick={e =>
                             (setImgSrc(data["DesignAppMobileExportScreen_image"].preview), setisOpen(true))
                            }
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            type="button"
                            style={{ color: "#ff3838" }}
                            onClick={e => (ChangeHandler(
                                {...data, DesignAppMobileExportScreen_image: null}
                            ), e.target.name == "")}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>
    </>
  )
}

export default ExportScreenForm
