/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Form, Label, Input } from "reactstrap"
import "./specification.scss"
import "react-image-lightbox/style.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import axios from "axios"
import SweetAlert from "react-bootstrap-sweetalert"
import ReactLoading from "react-loading"
import { toast } from "react-toastify"

const CoverLetter = props => {
  const [active, setActive] = useState(false)
  const [msg, setMsg] = useState("")
  const [success_msg, setsuccess_msg] = useState(false)
  const [coverLetterData, setCoverLetterData] = useState({
    letterEn: "",
    letterAr: "",
  })

  function submit(e) {
    e.preventDefault()
    setActive(true)
    const formData = new FormData()
    formData.append("letterEn", coverLetterData?.letterEn)
    formData.append("letterAr", coverLetterData?.letterAr)
    axios
      .post(
        `${process.env.REACT_APP_API}/dashboard/365Design/cv/coverLetter`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setActive(false)
        setMsg(`Saved!`)
        setsuccess_msg(true)
      })
      .catch(err => {
        toast.error("Something Went Wrong")
        setActive(false)
      })
  }

  useEffect(() => {
    setCoverLetterData({
      letterEn: props?.data?.coverLetter?.letterEn,
      letterAr: props?.data?.coverLetter?.letterAr,
    })
  }, [props])

  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        {success_msg ? (
          <SweetAlert
            title={msg}
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              setsuccess_msg(false)
            }}
            onCancel={() => {
              setsuccess_msg(false)
            }}
          ></SweetAlert>
        ) : null}
        

        <Form className="border-0">
          <Row>
            <Col>
              <Label>Cover letter (english)</Label>
              <textarea
                className="form-control  mb-0"
                rows="5"
                placeholder="Description"
                name="letterEn"
                value={coverLetterData.letterEn}
                onChange={e =>
                  setCoverLetterData({
                    ...coverLetterData,
                    letterEn: e.target.value,
                  })
                }
              />
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Label>description (arabic)</Label>
              <textarea
                className="form-control  mb-0"
                rows="5"
                placeholder="وصف"
                name="letterAr"
                value={coverLetterData.letterAr}
                onChange={e =>
                  setCoverLetterData({
                    ...coverLetterData,
                    letterAr: e.target.value,
                  })
                }
                dir="rtl"
              />
            </Col>
          </Row>

          <hr style={{ margin: "25px -20px 20px -20px" }} />
          <Row>
            <Col className="d-flex justify-content-end gap-2">
              <div className="save__btn top m-0">
                <button onClick={e => submit(e)}>
                  {active ? (
                    <ReactLoading
                      type={"spin"}
                      color={"#ffffff"}
                      height={30}
                      width={30}
                    />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  )
}

export default CoverLetter

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "green",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}
