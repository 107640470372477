import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import classnames from "classnames";
import {
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import Sticky from 'react-sticky-el';
import './configration.scss'
import axios from "axios";
import { useSelector, useDispatch } from "react-redux"
import {
    setTitle
} from "../../../store/actions";
import { useHistory } from "react-router-dom";


//pages import
import HomePage from "./Pages/HomePage";
import AboutPage from "./Pages/AboutPage";
import ContactUsPage from "./Pages/ContactUsPage";
import TermsConditions from "./Pages/TermsConditions";
import Settings from "./Pages/Settings";

const EtoyAppPages = () => {
    const dispatch = useDispatch()
    const history  = useHistory()
    const user = useSelector(state => state.UserData.user)
    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };


    useEffect(() => {
        if(user && user.role == 2) {
            history.push('/dashboard')
            return;
        } 
    }, [user])



    useEffect(() => {   
        dispatch(setTitle("Etoy App Pages"))
    }, [])

    return (
        <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>Pages | Layouti</title>
        </MetaTags>
        <Container fluid>
        <Col>
            <Row>
            <Col md="3">
            <Sticky topOffset={-300} >
                <Nav pills className="flex-column justify-content-between navs_aside">

                    <NavItem  className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "1",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("1");
                        }}
                        >
                        Home Page 
                        </NavLink>
                    </NavItem>

                    <NavItem  className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "2",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("2");
                        }}
                        >
                        About Page
                        </NavLink>
                    </NavItem>

                    <NavItem  className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "3",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("3");
                        }}
                        >
                        Terms & Conditions Page
                        </NavLink>
                    </NavItem>


                    <NavItem  className="nav___item">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "6",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("6");
                        }}
                        >
                        Contact Us Page
                        </NavLink>
                    </NavItem>
                    <NavItem  className="nav___item">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "7",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("7");
                        }}
                        >
                        Global
                        </NavLink>
                    </NavItem>
                </Nav>
                </Sticky>
            </Col>
            <Col md="9">
                <TabContent
                activeTab={verticalActiveTab}
                className="text-muted table__content mt-4 mt-md-0"
                >
                    <TabPane tabId="1">
                        <HomePage />
                    </TabPane>
                    <TabPane tabId="2" >
                        <AboutPage />
                    </TabPane>
                    <TabPane tabId="3">
                        <TermsConditions />
                    </TabPane>
                    <TabPane tabId="6">
                        <ContactUsPage/>
                    </TabPane>
                    <TabPane tabId="7">
                        <Settings />
                    </TabPane>
                    </TabContent>
            </Col>
            </Row>
        </Col>
        </Container>
        </div>
    </React.Fragment>
)
}

export default EtoyAppPages



const navsStyle = { 
    cursor: "pointer", 
    borderRadius: 0,

}