import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import classnames from "classnames";
import {
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import Admins from './Components/Admins'
import './configration.scss'
import axios from "axios";
import { useSelector, useDispatch } from "react-redux"
import {
    setTitle
} from "../../../store/actions";
import { useHistory } from "react-router-dom"
import axiosConfig from "axiosConfig";

const AdminSettings = () => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.UserData.user)
    const history = useHistory()
    const [admins, setAdmins] = useState([])
    const [categories, setCategories] = useState([])
    const [email, setEmail] = useState(null)
    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    // const [pageData, setPageData] = useState([])
    const [pagination, setPagination] = useState([])
    const [pageNo, setPageNo] = useState(1)

    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };

    function userChanges(user, type) {
        if(type === "active"){
            setActive(users => users.concat(user))
            setUnActive(users => users.filter(u => u.key !== user.key ))
        } else if(type === "unactive"){
            setActive(users => users.filter(u => u.key !== user.key ))
            setUnActive(users => users.concat(user))
        }else if(type === "del") {
            setActive(users => users.filter(u => u.key !== user.key ))
        }else {
            setPending(users => users.filter(u => u.key !== user.key ))
            setUnActive(users => users.concat(user))
        }
    }

    useEffect(() => {
        if(user && user.role == 2) {
            history.push('/')
            return;
        } 
    }, [user])
    function getData (){
        axiosConfig.get(`/dashboard/configurations/admins?page=${pageNo}`,{
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
            }
            }).then(res => {
                
                setAdmins(res.data.data.user.reverse())
                setPagination(res.data.data.pagination)

            }).catch(err=>{
            
            })
    }
    useEffect(()=>{
        dispatch(setTitle("Admin Setting"))
        getData()
    },[])
    useEffect(()=>{
        getData()
    },[pageNo])
    
    // useEffect(() => {
    //     axios.get(`${process.env.REACT_APP_API}/admin/setting`, {
    //         headers: {
    //             'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
    //         }
    //     })
    //     .then(res => {
    //         setCategories(res.data.data.projectcats)
    //         setAdmins(res.data.data.admins)
    //     }) 
    // }, [])
    return (
        <React.Fragment>
            <div>
            <MetaTags>
                <title>Admin Settings</title>
            </MetaTags>
                
                <Admins admin={admins} getData={getData} pagination={pagination} setPageNo={setPageNo}/>
                            
            </div>
        </React.Fragment>
)
}

export default AdminSettings



const navsStyle = { 
    cursor: "pointer", 
    borderRadius: 0,

}
