/* eslint-disable react/prop-types */
import axios from "axios"
import React, { useState, useRef, useEffect } from "react"
import { Card, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import PulseLoader from "react-spinners/PulseLoader"
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import "./specification.scss"
import prettyBytes from "pretty-bytes"
import axiosConfig from "axiosConfig"

const AddTermForm = props => {
  let p = props
  const [isOpen, setisOpen] = useState(false)
  const [mainTitleEn, setMainTitleEn] = useState("")
  const [mainTitleAr, setMainTitleAr] = useState("")
  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)
    
  
  

  function submitHandler() {
    setLoading(true)
    const formData = new FormData()
    let data = {
      mainTitleEn: mainTitleEn,
      mainTitleAr: mainTitleAr,
      id,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    let reqLink = props.term? `update`: `add`
    axiosConfig.post( `dashboard/configurations/terms/${reqLink}`,formData,{
      headers: { 
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
        'Content-Type': 'application/json'
    }
    })
      .then(res => {
        setLoading(false)
        close()
        props.addCategorySuccsess()
        props.clickHandle(null,false)
      }).catch(err=>{
        setLoading(false)
        toast.error(err.response.data.message)
      })
  }
  function close() {
    // document.getElementById("categForm").reset()
    setMainTitleAr("")
    setMainTitleEn("")
    props.clickHandle()
  }

  useEffect(() => {
    if (p.term) {
      setMainTitleAr(p.term.mainTitleAr)
      setMainTitleEn(p.term.mainTitleEn)
      setId(p.term.id)
      return
    }
  }, [p.term])

  return (
    <div className={`overlay_coup ${p.active ? "active" : ""}`}>
      <Form
        id="categForm"
        onSubmit={e => e.preventDefault()}
        style={{ paddingBottom: "70px", height: "350px" }}
      >
        <div className="head_form">
          <div className="log fs-5">
            {/* <i className="fas fa-user fs-5"></i> */}
            Add Term
          </div>
          <button onClick={close}>
            <i className="dripicons dripicons-cross"></i>
          </button>
        </div>
        <div className="inputs__wrap ">
        
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Main Title(English)</Label>
                <Input
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={mainTitleEn}
                  onChange={e => setMainTitleEn(e.target.value)}
                />
                <FormFeedback className="fs-6">
                  This Fileld Is Required!
                </FormFeedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Main Title(Arabic)</Label>
                <Input
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={mainTitleAr}
                  onChange={e => setMainTitleAr(e.target.value)}
                />
                <FormFeedback className="fs-6">
                  This Fileld Is Required!
                </FormFeedback>
              </div>
            </Col>
          </Row>
          
        </div>

        <div className="foot_form">
          <button className="cancel" onClick={close}>
            Cancel
          </button>
          <button className="save" onClick={submitHandler}>
            {loading ? (
              <PulseLoader color={"#ffffff"} loading={true} size={10} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Form>
    </div>
  )
}

export default AddTermForm
