import React, { useEffect,useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import axiosConfig from "axiosConfig"
import SweetAlert from "react-bootstrap-sweetalert"
import { toast } from "react-toastify"

const HeaderContent = ({data}) => {
  const [active, setActive] = useState(false)
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const [favIcon, setFavIcon] = useState(null)
  const [lightLogo, setLightLogo] = useState(null)
  const [darkLogo, setDarkLogo] = useState(null)


  function handleFavIcon(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setFavIcon(Object.values(files)[0])
    e.target.value = ""
  }

  function handleDarkLogo(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setDarkLogo(Object.values(files)[0])
    e.target.value = ""
  }

  function handleLightLogo(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setLightLogo(Object.values(files)[0])
    e.target.value = ""
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function prevSelectImage(e, prev) {
    e.preventDefault()
    setPrev(prev)
    setisOpen(!isOpen)
  }
  function submit(e) {
    setActive(true)
    e.preventDefault()
    const formData = new FormData()
    const data = {
      favIcon,
      lightLogo,
      darkLogo,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }

    axiosConfig.post(`/dashboard/etoy/global/appSetting`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res=>{
      
       toast.success(res.data.message)
      setsuccess_msg(true)
    setActive(false)
    setIsLoading(false)
    }).catch(err=>{
      
      toast.error(err.response.data.message)
      setIsLoading(false)
    })
  }

  useEffect(()=>{
    if(data) {
      setFavIcon({preview: data.favIcon})
      setLightLogo({preview: data.lightLogo})
      setDarkLogo({preview: data.darkLogo})
    }
    

  },[data])

  return (
    <div>
      {success_msg ? (
        <SweetAlert
          title={msg}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        >
        </SweetAlert>
      ) : null}
      {isOpen ? (
        <Lightbox
          mainSrc={prev}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
      <Form className="border-0 pb-2">

      <Row>
          <Col xs="12">
            <div className="">
              <Label htmlFor="productdesc">Fav Icon</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => handleFavIcon(e, e.target.files)}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {favIcon ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={favIcon.name}
                          src={favIcon.preview}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {favIcon.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e => prevSelectImage(e, favIcon.preview)}
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            style={{ color: "#ff3838" }}
                            onClick={e => setFavIcon(null)}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <div className="mt-3">
              <Label htmlFor="productdesc">EtoyApp Light Logo</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => handleLightLogo(e, e.target.files)}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {lightLogo ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={lightLogo.name}
                          src={lightLogo.preview}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {lightLogo.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e => prevSelectImage(e, lightLogo.preview)}
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            style={{ color: "#ff3838" }}
                            onClick={e => setLightLogo(null)}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>
        
        <Row >
          <Col xs="12">
            <div className="mt-3">
              <Label htmlFor="productdesc">EtoyApp Dark Logo</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => handleDarkLogo(e, e.target.files)}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {darkLogo ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={darkLogo.name}
                          src={darkLogo.preview}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {darkLogo.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e => prevSelectImage(e, darkLogo.preview)}
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            style={{ color: "#ff3838" }}
                            onClick={e => setDarkLogo(null)}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>
      </Form>
      <hr style={{ margin: "20px -20px 20px -20px" }} />
      <Row className="mt-2">
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0">
              <button onClick={submit} disabled={isLoading?true:false}>
                {active ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
    </div>
  )
}

export default HeaderContent

