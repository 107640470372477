/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Form,
  FormFeedback,
  Label,
  Row,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import classnames from "classnames"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { useDispatch } from "react-redux"
import { setTitle } from "../../../../../store/actions"
import SweetAlert from "react-bootstrap-sweetalert"
import { useSelector } from "react-redux"
import { Link, useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"
import ProductCard from "./ProductCard"
import prettyBytes from "pretty-bytes"
import * as uuid from "uuid"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"
import ReactLoading from "react-loading"
import "./Products.scss"
import Images from "pages/UiAvatar/ContentGenerator/Components/Forms/Images"

const Products = props => {
  const CARD_MODAL = cardId => {
    return {
      id: cardId,
      titleEn: "",
      titleAr: "",
      descriptionEn: "",
      descriptionAr: "",
    }
  }
  const PRODUCT_MODAL = blogId => {
    return {
      id: blogId,
      logo: null,
      slide: null,
      titleEn: "",
      titleAr: "",
      subTitleEn: "",
      subTitleAr: "",
      descriptionEn: "",
      descriptionAr: "",
      reviewLink: "",
      downloadLink: "",
      cards: [
        CARD_MODAL(uuid.v4()),
        CARD_MODAL(uuid.v4()),
        CARD_MODAL(uuid.v4()),
      ],
      status: "new",
    }
  }

  const dispatch = useDispatch()
  const user = useSelector(state => state.UserData.user)
  const history = useHistory()
  const [isOpen, setisOpen] = useState(false)
  const [photoIndex, setphotoIndex] = useState(0)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [prev, setPrev] = useState(null)
  const [deleteLoad, setDelete] = useState(false)
  const [active, setActive] = useState(false)
  const [image, setImage] = useState([])
  const [productData, setProductData] = useState([PRODUCT_MODAL(uuid.v4())])
  const [col, setCol] = useState([false])
  const [colI, setColI] = useState(false)
  const t_col1 = id => {
    setCol(col =>
      col.map((c, i) => {
        if (i == id) {
          return !c
        }
        return false
      })
    )
  }

  const colThImge = () => {
    setColI(!colI)
  }

  function handleAcceptedFilesThumbnail(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    setImage(Object.values(files)[0])
    e.target.value = ""
  }

  function handleAcceptedFiles(files, keyName, itemIndex) {
    files = [...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    handleChangeBlogData(keyName, files[0], itemIndex)
  }

  function handleChangeBlogData(keyName, keyValue, itemIndex, nestedIndex) {
    let modifiedProductData = [...productData]
    if (nestedIndex + 1) {
      modifiedProductData[itemIndex]["cards"][nestedIndex][keyName] = keyValue
    } else {
      modifiedProductData[itemIndex][keyName] = keyValue
    }
    setProductData(modifiedProductData)
  }

  function deleteImage(e, keyName, itemIndex) {
    e.preventDefault()
    handleChangeBlogData(keyName, null, itemIndex)
  }

  function prevSelectImage(e, id) {
    e.preventDefault()
    setphotoIndex(id)
    setisOpen(!isOpen)
  }

  function handleDeleteCard(cardId) {
    let modifiedBlogData = [...productData]

    modifiedBlogData = modifiedBlogData.filter(image => image.id != cardId)

    setProductData(modifiedBlogData)
  }

  function addNestedBlogRow() {
    let modifiedProductData = [...productData]
    modifiedProductData.push(PRODUCT_MODAL(uuid.v4()))
    setProductData(modifiedProductData)
    setCol(state => state.concat(false))
  }

  function submitImage(e) {
    setActive(true)
    e.preventDefault()
    const formData = new FormData()
    formData.append("image", image)
    axiosConfig
      .post(`/dashboard/365Design/productsStatic`, formData, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setMsg(`Image Updated Successfully`)
        setsuccess_msg(true)
        setActive(false)
      })
      .catch(err => {
        toast.error("Something Went Wrong")
        setActive(false)
      })
  }

  function submit(e, index) {
    e.preventDefault()
    const formData = new FormData()
    // formData.append("image", image[0])
    let product = productData[index]
    formData.append(`cards[${index}][logo]`, product?.logo)
    formData.append(`cards[${index}][slide]`, product?.slide)
    formData.append(`cards[${index}][titleEn]`, product?.titleEn)
    formData.append(`cards[${index}][titleAr]`, product?.titleAr)
    formData.append(`cards[${index}][subTitleEn]`, product?.subTitleEn)
    formData.append(`cards[${index}][subTitleAr]`, product?.subTitleAr)
    formData.append(`cards[${index}][descriptionEn]`, product?.descriptionEn)
    formData.append(`cards[${index}][descriptionAr]`, product?.descriptionAr)
    formData.append(`cards[${index}][reviewLink]`, product?.reviewLink)
    formData.append(`cards[${index}][downloadLink]`, product?.downloadLink)
    product?.cards &&
      product?.cards.forEach((card, cardIndex) => {
        formData.append(
          `cards[${index}][cards][${cardIndex}][titleEn]`,
          card?.titleEn
        )
        formData.append(
          `cards[${index}][cards][${cardIndex}][titleAr]`,
          card?.titleAr
        )
        formData.append(
          `cards[${index}][cards][${cardIndex}][descriptionEn]`,
          card?.descriptionEn
        )
        formData.append(
          `cards[${index}][cards][${cardIndex}][descriptionAr]`,
          card?.descriptionAr
        )
      })
    setActive(true)
    axiosConfig
      .post(`/dashboard/365Design/productsStatic`, formData, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
         toast.success(res.data.message)
        setsuccess_msg(true)
        setActive(false)
      })
      .catch(err => {
        toast.error("Something Went Wrong")
        setActive(false)
      })
  }

  function deleteCard(cardId) {
    setDelete(true)
    axiosConfig
      .post(
        "/dashboard/365Design/productsStatic/deleteCard",
        { id: cardId },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        handleDeleteCard(cardId)
        setDelete(false)
        setMsg("Deleted Successfully")
        setsuccess_msg(true)
      })
      .catch(err => {
        setDelete(false)
        toast.error("Something Went Wrong")
      })
  }

  useEffect(() => {
    if (user && user.role == 2) {
      history.push("/")
      return
    }
  }, [user])

  useEffect(() => {
    dispatch(setTitle("Product"))
  }, [])

  useEffect(() => {
    if (props.sectionData) {
      props.sectionData.image
        ? setImage({ preview: props.sectionData.image })
        : setImage(null)
       
      setProductData(props.sectionData.cards.map(card => ({...card, cards: card.cards.length !== 0? card.cards:[
        CARD_MODAL(uuid.v4()),
        CARD_MODAL(uuid.v4()),
        CARD_MODAL(uuid.v4()),
      ]})))
      props.sectionData.cards.forEach(card => [
        setCol(state => state.concat(false)),
      ])
    }
  }, [props.sectionData])

  return (
    <React.Fragment>
      {isOpen ? (
        <Lightbox
          mainSrc={photoIndex}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
      <div className="">
        <MetaTags>
          <title>Starter Page | Layouti</title>
        </MetaTags>
        <div>
          <Card>
            <CardBody>
              {success_msg ? (
                <SweetAlert
                  title={msg}
                  success
                  confirmBtnBsStyle="success"
                  onConfirm={() => {
                    setsuccess_msg(false)
                  }}
                  onCancel={() => {
                    setsuccess_msg(false)
                  }}
                ></SweetAlert>
              ) : null}
              <div className="accordion" id="accordion">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames(
                        "accordion-button",
                        "fw-medium",
                        "fw-bold",
                        "fs-5",
                        { collapsed: !colI }
                      )}
                      type="button"
                      onClick={() => colThImge()}
                      style={{ cursor: "pointer" }}
                    >
                      thumbnail Image
                    </button>
                  </h2>
                </div>

                <Collapse isOpen={colI} className="accordion-collapse">
                  <div className="accordion-body coll-image">
                    <Form className="border-0 p-0">
                      <input
                        type="file"
                        className="form-control form-control-lg "
                        onChange={e => {
                          handleAcceptedFilesThumbnail(e, e.target.files)
                        }}
                        style={{ borderRadius: 0 }}
                      />
                      <FormFeedback className="fs-6">
                        This Fileld Is Required!
                      </FormFeedback>
                    </Form>

                    {image && (
                      <Row
                        className="align-items-center mt-3"
                        style={{ paddingLeft: "14px", paddingRight: "0px" }}
                      >
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            alt={image?.name}
                            src={image.preview}
                            style={{ objectFit: "cover" }}
                          />
                        </Col>

                        <Col>
                          <Link to="#" className="text-muted ">
                            {image.name}
                          </Link>
                        </Col>

                        <Col className="pe-3">
                          <div className="image__options">
                            <button
                              className="p-0"
                              type="button"
                              onClick={e => prevSelectImage(e, image.preview)}
                            >
                              <i className="far fa-eye  fs-5 p-0"></i>
                            </button>
                            <button
                              type="button"
                              className="p-0"
                              style={{ color: "#ff3838" }}
                              onClick={e => setImage(null)}
                            >
                              <i className="bx bx-trash fs-4"></i>
                            </button>
                          </div>
                        </Col>
                      </Row>
                    )}

                    <hr style={{ margin: "15px -20px 20px -20px" }} />

                    <div className="save__btn top m-0 me-3">
                      <button type="button" onClick={e => submitImage(e)}>
                        {active ? (
                          <ReactLoading
                            type={"spin"}
                            color={"#ffffff"}
                            height={30}
                            width={30}
                          />
                        ) : (
                          "Save"
                        )}
                      </button>
                    </div>
                  </div>
                </Collapse>

                <Row>
                  <Col>
                    <ProductCard
                      handleChangeBlogData={handleChangeBlogData}
                      handleAcceptedFiles={handleAcceptedFiles}
                      productData={productData}
                      col={col}
                      t_col1={t_col1}
                      deleteImage={deleteImage}
                      submit={submit}
                      deleteCard={deleteCard}
                      active={active}
                      deleteLoad={deleteLoad}
                    />
                  </Col>
                </Row>
              </div>
              <div className="">
                <div className="add__btn top m-0 mt-2">
                  <button
                    onClick={() => {
                      addNestedBlogRow()
                    }}
                    className="d-block pl-2 pr-2"
                  >
                    <i className="bx bx-plus-circle"></i> Add Product
                  </button>
                </div>
              </div>
              {isOpen ? (
                <Lightbox
                  mainSrc={prev}
                  enableZoom={false}
                  onCloseRequest={() => {
                    setisOpen(!isOpen)
                  }}
                />
              ) : null}
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Products

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "green",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
