/* eslint-disable react/prop-types */
import React, { useEffect, useLayoutEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import axios from "axios"
import { toast } from "react-toastify"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import ReactLoading from "react-loading"
import prettyBytes from "pretty-bytes"

import { DndContext } from "@dnd-kit/core"
import { arrayMove, SortableContext } from "@dnd-kit/sortable"
import ProductCard from "pages/Pages/Components/Products/ProductCard"
import ProductsForm from "./Forms/ProductsForm"
import ContactsForm from "./Forms/ContactsForm"

const Contacts = props => {
  const [rows2, setrows2] = useState([{ id: 1 }])
  const [active, setActive] = useState(false)
  const [deleteLoad, setDelete] = useState(false)
  const [isOpen, setisOpen] = useState(false)
  const [col, setCol] = useState([false])
  const [titleData, setTitleData] = useState({ titleEn: "", titleAr: "" })
  const [data, setData] = useState([])
  const [news, setNews] = useState(null)
  const [msg, setMsg] = useState("")
  const [prev, setPrev] = useState(null)

  const t_col1 = id => {
    setCol(col =>
      col.map((c, i) => {
        if (i == id) {
          return !c
        }
        return false
      })
    )
  }

  function handleAddRowNested() {
    const modifiedRows = [...rows2]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows2(modifiedRows)
    setData(data =>
      data.concat({ titleEn: "", titleAr: "", type: "", info: "" })
    )
    setCol(state => state.concat(false))
    setNews(modifiedRows.length)
  }

  function handleChange(value, key, type) {
    if (type !== "image") {
      return setData(data =>
        data.map((item, i) => (i == key ? { ...item, [type]: value } : item))
      )
    } else {
      return setData(data =>
        data.map((item, i) =>
          i == key
            ? {
                ...item,
                [type]: Object.assign(value, {
                  preview: URL.createObjectURL(value),
                  formattedSize: prettyBytes(value.size),
                }),
              }
            : item
        )
      )
    }
  }

  function submit(e, key) {
    setActive(true)

    axios
      .post(
        `${process.env.REACT_APP_API}/dashboard/newLink/contact`,
        { ...titleData, Cards: data },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setActive(false)
        toast.success(res.data.message)
        getData()
      })
  }

  let getData = () => {
    axios
      .get(`${process.env.REACT_APP_API}/dashboard/newLink/contact`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setTitleData({
          titleEn: res.data?.data?.titleEn,
          titleAr: res.data?.data?.titleAr,
        })
        setData(res.data?.data?.Cards || [])
        setCol(res.data?.data?.Cards?.map(item => false))
      })
  }

  function deletePage(key) {
      setData(data => data.filter((item, i) => key !== i))    
  }

  useLayoutEffect(() => {
    getData()
  }, [])

  function ShowItem(key, status) {
    axios
      .post(
        `${process.env.REACT_APP_API}/dashboard/footer/ourProducts/active`,
        { id: key, active: status ? 1 : 0 },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        toast.success(res.data.message)
      })
  }

  function handleDrag(event) {
    const { active, over } = event
    if (active.id != over.id) {
      const activeIndex = active.data.current.sortable.index
      const overIndex = over.data.current.sortable.index
      const newArrange = arrayMove(data, activeIndex, overIndex)
      const ids = newArrange.map(item => (item.id ? item.id : null))
      setData(newArrange)
    }
  }

  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        <Card>
          <CardBody>
            {isOpen ? (
              <Lightbox
                mainSrc={prev}
                enableZoom={false}
                onCloseRequest={() => {
                  setisOpen(!isOpen)
                }}
              />
            ) : null}
            <Row className="">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Title (English)</Label>
                  <Input
                    id="brandnameen"
                    name="titleEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Title"
                    value={titleData.titleEn}
                    onChange={e =>
                      setTitleData({
                        ...titleData,
                        ["titleEn"]: e.target.value,
                      })
                    }
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">title (Arabic)</Label>
                  <Input
                    id="brandnamear"
                    name="titleAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. عنوان"
                    value={titleData.titleAr}
                    onChange={e =>
                      setTitleData({
                        ...titleData,
                        ["titleAr"]: e.target.value,
                      })
                    }
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            <hr style={{ margin: "20px -20px 20px -20px" }} />

            <div className="accordion" id="accordion">
              <DndContext onDragEnd={handleDrag}>
                <SortableContext items={data.map((d, i) => i + 1)}>
                  {data.map((formRow, key) => (
                    <ContactsForm
                      data={data}
                      key={key}
                      dataId={key}
                      t_col1={t_col1}
                      col={col}
                      active={active}
                      handleChange={handleChange}
                      deleteLoad={deleteLoad}
                      indexId={key + 1}
                      submit={submit}
                      deletePage={deletePage}
                      ShowItem={ShowItem}
                    />
                  ))}
                </SortableContext>
              </DndContext>
            </div>
            <div className="">
              <div className="add__btn top m-0 mt-2">
                <button onClick={handleAddRowNested}>
                  <i className="bx bx-plus-circle"></i>Add Link
                </button>
              </div>
            </div>
            <hr style={{ margin: "20px -20px 20px -20px" }} />

            <Row>
              <Col className="d-flex justify-content-end gap-2">
                <div className="save__btn top m-0 ">
                  <button type="button" onClick={e => submit()}>
                    {active ? (
                      <ReactLoading
                        type={"spin"}
                        color={"#ffffff"}
                        height={30}
                        width={30}
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default Contacts

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "green",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}
