import React, { useEffect,Fragment, useState } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Collapse, Button
  } from "reactstrap"
  import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { MultiSelect, SelectItem } from "react-multi-select-component";
import axiosConfig from "axiosConfig"
import SweetAlert from "react-bootstrap-sweetalert"
import { toast } from "react-toastify"

const OurLatestWork = ({data}) => {

  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")

  const [active, setActive] = useState(false)
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  function submit(e) {
    setActive(true)
    e.preventDefault()
    const formData = new FormData()
    const data = {
      titleEn:titleEn,
      titleAr:titleAr, 
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
   
    setIsLoading(true)

    axiosConfig.post(`/dashboard/etoy/global/appAds`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res=>{
      
       toast.success(res.data.message)
      setsuccess_msg(true)
    setActive(false)
    setIsLoading(false)
    }).catch(err=>{
      
      toast.error(err.response.data.message)
      setIsLoading(false)
    })
  }
  
  useEffect(()=>{
    if(data) {
      setTitleEn(data?.titleEn)
      setTitleAr(data?.titleAr)
    }
    

  },[data])
  
  return (
    <div>
      {success_msg ? (
        <SweetAlert
          title={msg}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        >
        </SweetAlert>
      ) : null}
      <Form className="border-0 pb-2">
      <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Title (english)</Label>
                  <Input
                    id="itemnameen"
                    name="itemnameen"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Focus Keypharse"
                    value={titleEn}
                    onChange={e => setTitleEn(e.target.value)}
                  />
                </div>
              </Col>

              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Title (arabic)</Label>
                  <Input
                    id="itemnamear"
                    name="itemnamear"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. "
                    value={titleAr}
                    onChange={e => setTitleAr(e.target.value)}
                    dir="rtl"
                    lang="ar"
                  />
                </div>
              </Col>
            </Row>
      
        
       
        <hr style={{ margin: "20px -20px 0px -20px" }} />
        <Row className="mt-4">
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0">
              <button onClick={submit} disabled={isLoading?true:false}>
                {active ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default OurLatestWork



const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
