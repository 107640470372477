import React, { Fragment, useEffect, useState } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Collapse, Button
  } from "reactstrap"
  import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
// import { MultiSelect } from "react-multi-select-component";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { MultiSelect, SelectItem } from "react-multi-select-component";
import { toast } from "react-toastify";
import axiosConfig from "axiosConfig";
import SweetAlert from "react-bootstrap-sweetalert";
// import Select from "react-select/dist/declarations/src/Select";
import Select from "react-select"

const OurLatestWork = ({sectionData,projects}) => {
    const [rows2, setrows2] = useState([{ id: 1 }])
    const [titleEn,setTitleEn] = useState('')
    const [titleAr,setTitleAr] = useState('')
    const [descEn,setDescEn] = useState('')
    const [descAr,setDescAr] = useState('')
    const [multiData, setMultiData] = useState([{
      id:1, 
      tagsEn:[],
      tagsAr: [], 
      descriptionEn: '', 
      descriptionAr: '', 
      category: '',
      projects: [],
      status:'new'
  }])
    const [options,setOptions] = useState([
        { label: "Grapes", value: "1" },
        { label: "Mango", value: "2" },
        { label: "Strawberry", value: "3", disabled: true },
    ]);
 
    function handleAddRowNested() {
      const modifiedRows = [...rows2]
      modifiedRows.push({ id: modifiedRows.length + 1 })
      setrows2(modifiedRows)
      setMultiData(data => data.concat({
        id:modifiedRows.length, 
        tagsEn:[],
        tagsAr: [], 
        descriptionEn: '', 
        descriptionAr: '', 
        category: '',
        projects: [],
        status:'new'
      }))
    }
  
    function handleRemoveRow(id) {
      if (id !== 0) {
        var modifiedRows = [...rows2]
        modifiedRows = modifiedRows.filter(x => x["id"] !== id)
        setrows2(modifiedRows)
      setMultiData(d => d.filter((x,i) => i !== id))

      }
    }

    
  function handleChangeData(keyName,keyValue,key) {
    let modifiedData = [...multiData]
    modifiedData[key][keyName]=keyValue
    setMultiData(modifiedData)
    
  }
  function deleteCard(key,cardId,status){
    //
    if(status==='old'){
      axiosConfig.post(`/dashboard/servicesPage/categories/deleteCard`, {id:cardId}, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      }).then(res=>{
        //
        setMsg(`Deleted Successfully`)
        setsuccess_msg(true)
        handleRemoveRow(key,cardId)
        // setIsLoading(false)
      }).catch(err=>{
        //
        toast.error(err.response.data.message)
        // setIsLoading(false)
      })
    }else{
      handleRemoveRow(key,cardId)
    }

  }
  
    
  const [isLoading, setIsLoading] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  function submit() {
    const formData = new FormData()
    let stopFunction=false
    formData.append(`titleEn`, titleEn)
    formData.append(`titleAr`, titleAr)
    formData.append(`descriptionEn`, descEn)
    formData.append(`descriptionAr`, descAr)
    multiData.forEach((item,index)=>{
      //
      if(item.category==''){
        stopFunction=true
        toast.error(`Please Select Category of Card Number(${index+1})`)
        return;
      }
      formData.append(`cards[${index}][category]`, item.category)
      formData.append(`cards[${index}][descriptionEn]`, item.descriptionEn)
      formData.append(`cards[${index}][descriptionAr]`, item.descriptionAr)
      
      item.projects.length!=0?item.projects.forEach((point,pointIndex)=>{
        //
        formData.append(`cards[${index}][projects][${pointIndex}]`, Number(point.value))
      })
      :formData.append(`cards[${index}][projects]`, [])
      item.tagsEn.length!=0?item.tagsEn.forEach((point,pointIndex)=>{
        formData.append(`cards[${index}][tagsEn][${pointIndex}]`, point)
      })
      :formData.append(`cards[${index}][tagsEn]`, [])

      item.tagsAr.length!=0?item.tagsAr.forEach((point,pointIndex)=>{
        formData.append(`cards[${index}][tagsAr][${pointIndex}]`, point)
      }):formData.append(`cards[${index}][tagsAr]`, [])
    })
    if(stopFunction){
      return
    }else{

      setIsLoading(true)
      axiosConfig.post(`/dashboard/servicesPage/categories`, formData, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      }).then(res=>{
        //
         toast.success(res.data.message)
        setsuccess_msg(true)
        setIsLoading(false)
      }).catch(err=>{
        //
        toast.error(err.response.data.message)
        setIsLoading(false)
      })
    }

  }

  const [categories, setCategories] = useState([])
  function getData (){
    axiosConfig.get(`/dashboard/configurations/layoutiCategoriesServices`,{
      headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
      }
    }).then(res => {
        //
        setCategories(res.data.data)
        
    }).catch(err=>{
      //
    })
  }
  useEffect(()=>{
    getData()
  },[])
  
    
  useEffect(()=>{
    
    if(sectionData){
      let modifiedData  = sectionData[0]?.cards.map ((card, i) => {
        return{
          ...card,
          category: card.category.id,
          projects: card.projects.map(proj => ({label:projects.filter(i => i.id == proj)[0].nameEn,value: parseInt(proj)}))
        }
      })
        modifiedData&&setMultiData(modifiedData)
        sectionData&&setTitleEn(sectionData[0].titleEn)
        sectionData&&setTitleAr(sectionData[0].titleAr)
        sectionData&&setDescEn(sectionData[0].descriptionEn)
        sectionData&&setDescAr(sectionData[0].descriptionAr)
    }
      
    //
  },[sectionData,options])
  useEffect(()=>{
    let modifiedProjects=[]
    projects&&projects?.forEach(item=>{
      modifiedProjects.push({value:item.id,label:item.nameEn})
    })
    setOptions(modifiedProjects)
  },[projects])

  return (
    <div>
      {success_msg ? (
        <SweetAlert
          title={msg}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        >
        </SweetAlert>
      ) : null}
      <Form className="border-0 pb-2">
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (English)</Label>
              <Input
                id="itemnameen"
                name="itemnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={titleEn}
                onChange={e => setTitleEn(e.target.value)}
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (Arabic)</Label>
              <Input
                id="itemnamear"
                name="itemnamear"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. "
                value={titleAr}
                onChange={e => setTitleAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (English)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="Enter your description here"
                name='descriptionEn'
                value={descEn}
                onChange={e => setDescEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (Arabic)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="اكتب وصف هنا"
                name='descriptionAr'
                value={descAr}
                onChange={e => setDescAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>
        
        <hr style={{ margin: "25px -20px 20px -20px" }} />
      {(multiData || []).map((formRow, key) => (
          <Fragment key={key}>
            <Row className="mt-3">
                <Col>
                <Label htmlFor="productdesc">Category</Label>
                <select
                  className="form-select fs-5 input_cus"
                  name='category'
                  value={formRow?.category}
                  onChange={e => handleChangeData(e.target.name,e.target.value,key)}
                >
                  <option disabled value=''>Select Category</option>
                  {
                    categories.length!=0&&categories.map(category=>(
                      <option value={category.id} key={category.id}>{category.nameEn}</option>
                    ))
                  }
                  {/* <option value="1">Branding</option>
                  <option value="2">UI Design</option>
                  <option value="3">In-house projects</option> */}
                </select>
                </Col>
            </Row>

            <Row className="mt-3">
                <Col>
                <Label htmlFor="productdesc">Projects</Label>
                  <Select
                  value={formRow?.projects?formRow?.projects:[]}
                  isMulti={true}
                  onChange={(newTags) => {
                    // setSelected(e)
                      handleChangeData('projects',newTags,key)
                  }}
                  options={options}
                  classNamePrefix="select2-selection"
                />
                </Col>
            </Row>
            
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (English)</Label>
                  <textarea
                    className="form-control  mb-0"
                    id="specification"
                    rows="5"
                    placeholder="Enter your description here"
                    name='descriptionEn'
                    value={formRow?.descriptionEn}
                    onChange={e => handleChangeData(e.target.name,e.target.value,key)}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (Arabic)</Label>
                  <textarea
                    className="form-control  mb-0"
                    id="specification"
                    rows="5"
                    placeholder="اكتب وصف هنا"
                    name='descriptionAr'
                    value={formRow?.descriptionAr}
                    onChange={e => handleChangeData(e.target.name,e.target.value,key)}
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Tags (English)</Label>
                  <ReactTagInput 
                    tags={formRow?.tagsEn?formRow?.tagsEn:[]}
                    editable={true}
                    placeholder="e.g xl" 
                    onChange={newTags => handleChangeData('tagsEn',newTags,key)}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Tags (Arabic)</Label>
                  <ReactTagInput 
                    tags={formRow?.tagsAr?formRow?.tagsAr:[]}
                    editable={true}
                    placeholder="e.g xl" 
                    onChange={newTags => handleChangeData('tagsAr',newTags,key)}
                  />
                </div>
              </Col>
            </Row>
            {key !== 0 ? (
              <Button
                color="none"
                variant="primary"
                className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                id="unknown-btn"
                style={{
                  color: "#ff3838",
                  boxShadow: "none",
                  height: "45px",
                  borderRadius: 0,
                }}
                onClick={e => {
                  deleteCard(key,formRow.id,formRow?.status=='new'?'new':'old')
                }}
              >
                <i className="bx bx-trash-alt fs-4 p-0 mr-1" /> Delete
              </Button>
            ) : null}
            {
              key!=0?
              <hr style={{ margin: "5px -20px 20px -20px" }} />
              :
              <hr style={{ margin: "25px -20px 20px -20px" }} />
            }
          </Fragment>
        ))}
        
        <Button
          onClick={() => {
            handleAddRowNested()
          }}
          color="none"
          className="mt-2 fs-5 fw-bold p-0 text-left"
          style={btnStyle}
        >
          <i className="bx bx-plus-circle fs-4" /> <span>Add Projects</span>
        </Button>
        <hr style={{ margin: "20px -20px 0px -20px" }} />
        <Row className="mt-4">
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0">
            <button type='button' onClick={submit}disabled={isLoading?true:false}> 
                {isLoading ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default OurLatestWork



const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
