/* eslint-disable react/prop-types */
import React, { useEffect, useLayoutEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
} from "reactstrap"
import classnames from "classnames"
import { Link } from "react-router-dom"
import axios from "axios"
import { toast } from "react-toastify"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import ReactLoading from "react-loading";
import prettyBytes from "pretty-bytes"

import { DndContext } from '@dnd-kit/core'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'
import ProductCard from "pages/Pages/Components/Products/ProductCard"
import ProductsForm from "./Forms/ProductsForm"



const OurProducts = props => {
  const [rows2, setrows2] = useState([{ id: 1 }]);
  const [active, setActive] = useState(false)
  const [deleteLoad, setDelete] = useState(false)
  const[productData, setProductData] = useState([])
  const[editCop, setEditCop] = useState(null)
  const [image, setImage] = useState(null)
  const [isOpen, setisOpen] = useState(false);
  const [col, setCol] = useState([false])
  const [data, setData] = useState([])
  const [news, setNews] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false);
  const [msg, setMsg] = useState("");
  const [prev, setPrev] = useState(null)

  const t_col1 = (id) => {
    setCol(col => col.map((c,i) => {
      if(i == id) {
        return !c
      }
      return false
    }))
  };



  function handleAddRowNested() {
    const modifiedRows = [...rows2];
    modifiedRows.push({ id: modifiedRows.length + 1 });
    setrows2(modifiedRows);
    setData(data => data.concat({titleEn: "", titleAr: "", link: "", image:  null}))
    setCol(state => state.concat(false))
    setNews(modifiedRows.length)
  }
  
  function handleRemoveRow(id) {
    if (id !== 0) {
      var modifiedRows = [...rows2];
      modifiedRows = modifiedRows.filter(x => x["id"] !== id);
      setrows2(modifiedRows);
      setData(data => data.filter((d,i) => i != id))
    }
  }

  function handleChange(value, key, type) {
    if ( type !== "image") {
      return setData(data  => data.map((item, i) => i == key? {...item, [type]: value}: item))
    }else { 
      return setData(data  => data.map((item, i) => i == key? {...item, [type]:  Object.assign(value, {
        preview: URL.createObjectURL(value),
        formattedSize: prettyBytes(value.size),
      }) }: item))
    }
    }

  function submit(e, key) {
    e.preventDefault()
    setActive(true) 
    let formData =  new FormData()
    // let d = data[key]

    data.forEach((d, i) => {
      for ( let obj in d) { 
        formData.append(`Cards[${i}][${obj}]`, d[obj])
      }
    })

 
    axios.post(`${process.env.REACT_APP_API}/dashboard/footer/ourProducts`,formData,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    })
    .then(res => {
      setActive(false) 
      toast.success(res.data.message)
      getData()
    })
  }


  let getData =  () => { 
    axios.get(`${process.env.REACT_APP_API}/dashboard/footer/ourProducts`,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
      setData(data => res.data.data)
      setCol(res.data.data.map(d => false))
    })
  }




  function deletePage(key) {
    setDelete(true)
    axios.post(`${process.env.REACT_APP_API}/dashboard/footer/ourProducts/delete`, {id: data[key].id},{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    })
    .then(res => {
      setDelete(false)
      getData()
      toast.success(res.data.message)
    })
  }


  function prevSelectImage(e, prev) {
    e.preventDefault()
    setPrev(prev)
    setisOpen(!isOpen)
  }


  useLayoutEffect(() => {
    getData()
  },[])

  function ShowItem(key, status) {
    axios.post(`${process.env.REACT_APP_API}/dashboard/footer/ourProducts/active`, {id: key, active: status?1:0},{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    })
    .then(res => {
      toast.success(res.data.message)
    })
  }

  function handleDrag(event) {
    const {active,over} =event
    if(active.id!=over.id){
      const activeIndex = active.data.current.sortable.index
      const overIndex = over.data.current.sortable.index
      const  newArrange = arrayMove(data,activeIndex,overIndex)
      const ids = newArrange.map(item => item.id?item.id:null)
      axios.post(`${process.env.REACT_APP_API}/dashboard/footer/ourProducts/arrange`, {ids},{
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        toast.success(res.data.message);
        getData()
      })
    }
  }





  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        <Card>
          <CardBody>
          {isOpen ? (
        <Lightbox
          mainSrc={prev}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
            <div className="accordion" id="accordion">
              <DndContext onDragEnd={handleDrag}>
                  <SortableContext items={data.map((d,i) => i+1)}>
                    {data.map((formRow, key) => (
                        <ProductsForm 
                          data={data} 
                          key={key} 
                          dataId={key} 
                          t_col1={t_col1} 
                          col={col}  
                          active={active} 
                          handleChange={handleChange} 
                          deleteLoad={deleteLoad} 
                          indexId={key+1}
                          submit={submit}
                          deletePage={deletePage}
                          ShowItem={ShowItem}
                        />
                    ))}
                  </SortableContext>
              </DndContext>
              
            </div>
            <div className="">
              <div className="add__btn top m-0 mt-2">
                <button onClick={handleAddRowNested}><i className='bx bx-plus-circle'></i>Add</button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default OurProducts

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "green",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}
