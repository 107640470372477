import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Sticky from "react-sticky-el"
import Admins from "./Components/Admins"
import Category from "./Components/Category"
import EmailShot from "./Components/EmailShot"
import CharacterPdf from "./Components/CharacterPdf"
import "./configration.scss"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { setTitle } from "../../../store/actions"
import { useHistory } from "react-router-dom"
import Deliverable from "./Components/Deliverable"
import ScopeOfWork from "./Components/ScopeOfWork/ScopeOfWork"
import Budget from "./Components/Budget"
import WhatWeNeed from "./Components/WhatWeNeed"
import axiosConfig from "axiosConfig"
import CategoryForLayouti from "./Components/CategoryForLayouti"
import CategoryForFAQ from "./Components/CategoryForFAQ"
import CategoryFor365Design from "./Components/CategoryFor365Design"
import CategoryForLayoutiWork from "./Components/CategoryForLayoutiWork"
import Terms from "./Components/Terms"

const TermsConfigurations = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.UserData.user)
  const history = useHistory()

  const [data, setData] = useState([])
  const [admins, setAdmins] = useState([])
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [email, setEmail] = useState(null)
  const [verticalActiveTab, setverticalActiveTab] = useState("1")

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  function userChanges(user, type) {
    if (type === "active") {
      setActive(users => users.concat(user))
      setUnActive(users => users.filter(u => u.key !== user.key))
    } else if (type === "unactive") {
      setActive(users => users.filter(u => u.key !== user.key))
      setUnActive(users => users.concat(user))
    } else if (type === "del") {
      setActive(users => users.filter(u => u.key !== user.key))
    } else {
      setPending(users => users.filter(u => u.key !== user.key))
      setUnActive(users => users.concat(user))
    }
  }

  useEffect(() => {
    if (user && user.role == 2) {
      history.push("/")
      return
    }
  }, [user])

  const [layoutiCategories, setLayoutiCategories] = useState([])
  const [layoutiCategoriesWorks, setLayoutiCategoriesWorks] = useState([])
  const [layoutiCategoriesFAQ, setLayoutiCategoriesFAQ] = useState([])
  const [design365, setDesign365] = useState([])
  const [scopeOfWork, setScopeOfWork] = useState([])
  const [deliverables, setDeliverables] = useState([])
  const [blogData, setBlogData] = useState([])
  const [terms, setTerms] = useState([])


  function getTerms() {
    axiosConfig
      .get(`/dashboard/configurations/terms`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setTerms(res.data.data)
      })
      .catch(err => {
        
      })
  }

  


  useEffect(() => {
    dispatch(setTitle("configurations"))
    getTerms()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Settings | Layouti</title>
        </MetaTags>
        <Container fluid>
          <Col>
            <Row>
              
              <Col md="12">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted table__content mt-4 mt-md-0"
                >
                   <TabPane tabId="1">
                    <Terms
                        terms={terms}
                        getData={getTerms}
                      />
                  </TabPane>
                  
                </TabContent>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TermsConfigurations

const navsStyle = {
  cursor: "pointer",
  borderRadius: 0,
}
