import axios from "axios"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Input, Label, Row } from "reactstrap"
import ReactLoading from "react-loading"
import { toast } from "react-toastify"
const SeoForm = ({
  data, ChangeHandler, handleAcceptedImage, setImgSrc,setisOpen,PID,
  getData,
}) => {

  const [isLoading, setIsLoading] = useState(false)
  function submit(e) {
    e.preventDefault()
    setIsLoading(true)
    const formData = new FormData();
    
    let d = {
      PID,
      seoTitleEn:data.ProductSEO_seoTitleEn,
      seoTitleAr:data.ProductSEO_seoTitleAr, 
      slugEn:data.ProductSEO_slugEn, 
      slugAr:data.ProductSEO_slugAr, 
      descriptionEn:data.ProductSEO_descriptionEn, 
      descriptionAr:data.ProductSEO_descriptionAr, 
      facebookTitleEn:data.ProductSEO_facebookTitleEn , 
      facebookTitleAr: data.ProductSEO_facebookTitleAr, 
      focusKeypharseEn:data.ProductSEO_focusKeypharseEn,
      focusKeypharseAr:data.ProductSEO_focusKeypharseAr,
      facebookDescriptionEn: data.ProductSEO_facebookDescriptionEn, 
      facebookDescriptionAr: data.ProductSEO_facebookDescriptionAr, 
      // facebookImage: data.ProductSEO_facebookImage.name?data.ProductSEO_facebookImage:data.ProductSEO_facebookImage.preview, 
    }

    if (data.ProductSEO_facebookImage) {
      if (data.ProductSEO_facebookImage.name) {
        formData.append("facebookImage", data.ProductSEO_facebookImage)
      }else {
        formData.append("facebookImage", data.ProductSEO_facebookImage.preview)
      }
    }else {
      formData.append("facebookImage", null)
    } 

    for (let key in d) {
      formData.append(key, d[key])
    }
    /*************GeneralInformationData*************/
    axios.post(`${process.env.REACT_APP_API}/dashboard/product/seo/save`,formData,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
      toast.success(res.data.message, {position: "top-right"})
      setIsLoading(false)
      getData(PID)
    })
  }

  return (
    <Card>
      <CardBody>
      <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Focus Keypharse (english)</Label>
                  <Input
                    id="itemnameen"
                    name="ProductSEO_focusKeypharseEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Focus Keypharse"
                    value={data.ProductSEO_focusKeypharseEn}
                    onChange={e =>
                      ChangeHandler({ ...data, ProductSEO_focusKeypharseEn: e.target.value })
                    }
                  />
                </div>
              </Col>

              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Focus Keypharse (arabic)</Label>
                  <Input
                    id="itemnamear"
                    name="ProductSEO_focusKeypharseAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. "
                    value={data.ProductSEO_focusKeypharseAr}
                    onChange={e =>
                      ChangeHandler({ ...data, ProductSEO_focusKeypharseAr: e.target.value })
                    }
                    dir="rtl"
                    lang="ar"
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Seo Title (english)</Label>
                  <Input
                    id="brandnameen"
                    name="ProductSEO_seoTitleEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Title"
                    value={data.ProductSEO_seoTitleEn}
                    onChange={e =>
                      ChangeHandler({ ...data, ProductSEO_seoTitleEn: e.target.value })
                    }
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Seo Title (arabic)</Label>
                  <Input
                    id="brandnamear"
                    name="ProductSEO_seoTitleAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. عنوان"
                    value={data.ProductSEO_seoTitleAr}
                    onChange={e =>
                      ChangeHandler({ ...data, ProductSEO_seoTitleAr: e.target.value })
                    }
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Slug (english)</Label>
                  <Input
                    id="brandnameen"
                    name="ProductSEO_slugEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Slug"
                    value={data.ProductSEO_slugEn}
                    onChange={e =>
                      ChangeHandler({ ...data, ProductSEO_slugEn: e.target.value })
                    }
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Slug (arabic)</Label>
                  <Input
                    id="brandnamear"
                    name="ProductSEO_slugAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. وصف"
                    value={data.ProductSEO_slugAr}
                    onChange={e =>
                      ChangeHandler({ ...data, ProductSEO_slugAr: e.target.value })
                    }
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (English)</Label>
                  <textarea
                    className="form-control  mb-0"
                    name="ProductSEO_descriptionEn"
                    id="specification"
                    rows="5"
                    placeholder="Enter your description here"
                    value={data.ProductSEO_descriptionEn}
                    onChange={e =>
                      ChangeHandler({ ...data, ProductSEO_descriptionEn: e.target.value })
                    }
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (Arabic)</Label>
                  <textarea
                    className="form-control  mb-0"
                    name="ProductSEO_descriptionAr"
                    id="specification"
                    rows="5"
                    placeholder="اكتب وصف هنا"
                    value={data.ProductSEO_descriptionAr}
                    onChange={e =>
                      ChangeHandler({ ...data, ProductSEO_descriptionAr: e.target.value })
                    }
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <div className="mt-3">
                  <Label htmlFor="productdesc">Facebook Image</Label>
                  <Input
                    type="file"
                    className="form-control form-control-lg "
                    name='facebookImage'
                    onChange={e => handleAcceptedImage(e.target.files, "seo",e)}
                    style={{ borderRadius: 0 }}
                    multiple
                  />
                </div>
              </Col>
              <Col xs="12">
                <div className="dropzone-previews" id="file-previews">
                  {data['ProductSEO_facebookImage']? (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={data['ProductSEO_facebookImage'].name}
                              src={data['ProductSEO_facebookImage'].preview?data['ProductSEO_facebookImage'].preview:data['ProductSEO_facebookImage']}
                              style={{ objectFit: "cover" }}
                            />
                          </Col>
                          <Col>
                            <Link to="#" className="text-muted ">
                              {data['ProductSEO_facebookImage'].name}
                            </Link>
                          </Col>
                          <Col>
                            <div className="image__options">
                              <button
                                className="p-0"
                                type="button"
                                onClick={e =>
                                  (setImgSrc(data["ProductSEO_facebookImage"].preview), setisOpen(true))
                                 }
                              >
                                <i className="far fa-eye  fs-5 p-0"></i>
                              </button>
                              <button
                                style={{ color: "#ff3838" }}
                                type="button"
                                onClick={e => (ChangeHandler(
                                  {...data, ProductSEO_facebookImage: null}
                              ), e.target.name == "")}
                              >
                                <i className="bx bx-trash fs-4"></i>
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Facebook Title (english)</Label>
                  <Input
                    id="brandnameen"
                    name="ProductSEO_facebookTitleEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Title"
                    value={data.ProductSEO_facebookTitleEn}
                    onChange={e =>
                      ChangeHandler({ ...data, ProductSEO_facebookTitleEn: e.target.value })
                    }
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Facebook Title (arabic)</Label>
                  <Input
                    id="brandnamear"
                    name="ProductSEO_facebookTitleAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. عنوان"
                    value={data.ProductSEO_facebookTitleAr}
                    onChange={e =>
                      ChangeHandler({ ...data, ProductSEO_facebookTitleAr: e.target.value })
                    }
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">
                    Facebook Description (English)
                  </Label>
                  <textarea
                    className="form-control  mb-0"
                    name="ProductSEO_facebookDescriptionEn"
                    id="specification"
                    rows="5"
                    placeholder="Enter your description here"
                    value={data.ProductSEO_facebookDescriptionEn}
                    onChange={e =>
                      ChangeHandler({ ...data, ProductSEO_facebookDescriptionEn: e.target.value })
                    }
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Facebook Description (Arabic)</Label>
                  <textarea
                    className="form-control mb-0"
                    name="ProductSEO_facebookDescriptionAr"
                    id="specification"
                    rows="5"
                    placeholder="اكتب وصف هنا"
                    value={data.ProductSEO_facebookDescriptionAr}
                    onChange={e =>
                      ChangeHandler({ ...data, ProductSEO_facebookDescriptionAr: e.target.value })
                    }
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            <hr style={{ margin: "20px -20px 20px -20px" }} />
        <Row>
          <Col className="d-flex justify-content-end">
            <button
              type="button"
              className="add-project__submit-button"
              onClick={e => submit(e)}
            >
              {isLoading ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </Col>
        </Row>     
      </CardBody>
    </Card>
  )
}

export default SeoForm
