import React, { useState, useEffect} from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import axios from "axios"
import { toast } from "react-toastify"
import Lightbox from "react-image-lightbox"

const StepsReach = ({data}) => {
  const [active, setActive] = useState(false)
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [subTitleEn, setsubTitleEn] = useState("")
  const [subTitleAr, setsubTitleAr] = useState("")

  const [icon1, setIcon1] = useState(null) 
  const [titleEn1, setTitleEn1] = useState("")
  const [titleAr1, setTitleAr1] = useState("")
  const [descEn1, setDescEn1] = useState("")
  const [descAr1, setDescAr1] = useState("")

  const [icon2, setIcon2] = useState(null) 
  const [titleEn2, setTitleEn2] = useState("")
  const [titleAr2, setTitleAr2] = useState("")
  const [descEn2, setDescEn2] = useState("")
  const [descAr2, setDescAr2] = useState("")

  const [icon3, setIcon3] = useState(null) 
  const [titleEn3, setTitleEn3] = useState("")
  const [titleAr3, setTitleAr3] = useState("")
  const [descEn3, setDescEn3] = useState("")
  const [descAr3, setDescAr3] = useState("")
  const [prev, setPrev] = useState(null)
  const [isOpen, setisOpen] = useState(false)


  function handleImage(e, files, i) {
    [...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    i === 1?setIcon1(Object.values(files)[0])
    :i === 2?setIcon2(Object.values(files)[0])
    :i === 3? setIcon3(Object.values(files)[0]): null
    e.target.value = ""
  }


  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function prevSelectImage (e,prev) {
    e.preventDefault()
    setPrev(prev)
    setisOpen(!isOpen)
  }


  function submit(e) {
    e.preventDefault()
    setActive(true)
    const formData = new FormData()
    const data = {
      titleEn,
      titleAr, 
      subTitleEn, 
      subTitleAr
    }
    for (let key in data) {
      formData.append(key, data[key])
    }

    formData.append(`cards[0][icon]`, icon1)
    formData.append(`cards[0][titleEn]`, titleEn1)
    formData.append(`cards[0][titleAr]`, titleAr1)
    formData.append(`cards[0][descriptionEn]`, descEn1)
    formData.append(`cards[0][descriptionAr]`, descAr1)
  
    formData.append(`cards[1][icon]`, icon2)
    formData.append(`cards[1][titleEn]`, titleEn2)
    formData.append(`cards[1][titleAr]`, titleAr2)
    formData.append(`cards[1][descriptionEn]`, descEn2)
    formData.append(`cards[1][descriptionAr]`, descAr2)

    formData.append(`cards[2][icon]`, icon3)
    formData.append(`cards[2][titleEn]`, titleEn3)
    formData.append(`cards[2][titleAr]`, titleAr3)
    formData.append(`cards[2][descriptionEn]`, descEn3)
    formData.append(`cards[2][descriptionAr]`, descAr3)

    axios.post(`${process.env.REACT_APP_API}/dashboard/learnUi/stepsReachCards`, formData, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
      },
    }).then(res => {
      toast.success("Data Saved Successfully", {position: "top-right"})
      setActive(false)
    })
  }
  useEffect(() => {
    if(data) {
      setTitleEn(data.titleEn)
      setTitleAr(data.titleAr)
      setsubTitleEn(data.subTitleEn)
      setsubTitleAr(data.subTitleAr)
      
      if(data.cards.length >= 1) {
      
      setIcon1({preview: data.cards[0].icon?data.cards[0].icon:null})
      setTitleEn1(data.cards[0].titleEn?data.cards[0].titleEn:'')
      setTitleAr1(data.cards[0].titleAr?data.cards[0].titleAr:'')
      setDescEn1(data.cards[0].descriptionEn?data.cards[0].descriptionEn:'')
      setDescAr1(data.cards[0].descriptionAr?data.cards[0].descriptionAr:'')
      }
      if(data.cards.length >= 2) {
        setIcon2({preview: data.cards[1].icon?data.cards[1].icon:null})
        setTitleEn2(data.cards[1].titleEn?data.cards[1].titleEn:'')
        setTitleAr2(data.cards[1].titleAr?data.cards[1].titleAr:'')
        setDescEn2(data.cards[1].descriptionEn?data.cards[1].descriptionEn:'')
        setDescAr2(data.cards[1].descriptionAr?data.cards[1].descriptionAr:"")
      }

      if(data.cards.length >= 3) {
      setIcon3({preview: data.cards[2].icon?data.cards[2].icon:null})
      setTitleEn3(data.cards[2].titleEn?data.cards[2].titleEn:'')
      setTitleAr3(data.cards[2].titleAr?data.cards[2].titleAr:'')
      setDescEn3(data.cards[2].descriptionEn?data.cards[2].descriptionEn:'')
      setDescAr3(data.cards[2].descriptionAr?data.cards[2].descriptionAr:'')
      }
    }
    
  }, [data])

  return (
    <div>
      {isOpen ? (
        <Lightbox
          mainSrc={prev}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
      <Form className="border-0 p-0">
        <Row>
          <Col className="">
            <Form className="border-0 pb-2">
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Title Of Steps Cards (english)
                    </Label>
                    <Input
                      id="itemnameen"
                      name="itemnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Title Steps Cards"
                      value={titleEn}
                      onChange={e =>
                        setTitleEn(e.target.value)
                      }
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Title Of Steps Cards (arabic)
                    </Label>
                    <Input
                      id="itemnamear"
                      name="itemnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={titleAr}
                      onChange={e =>
                        setTitleAr(e.target.value)
                      }
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Sub Title Of Steps Cards (english)
                    </Label>
                    <Input
                      id="itemnameen"
                      name="itemnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Sub Title Steps Cards"
                      value={subTitleEn}
                      onChange={e =>
                        setsubTitleEn(e.target.value)
                      }
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Sub Title Of Steps Cards (arabic)
                    </Label>
                    <Input
                      id="itemnamear"
                      name="itemnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={subTitleAr}
                      onChange={e =>
                        setsubTitleAr(e.target.value)
                      }
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>

              <hr style={{ margin: "25px -20px 25px -20px" }} />

              <Row>
                <Col xs="12">
                  <div>
                    <Label htmlFor="productdesc">Icon Of Card1</Label>
                    <Input
                      type="file"
                      className="form-control form-control-lg "
                      onChange={e => handleImage(e, e.target.files,1)}
                      style={{ borderRadius: 0 }}
                      multiple
                    />
                  </div>
                </Col>
                <Col xs="12">
                  <div className="dropzone-previews" id="file-previews">
                    {icon1? (
                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={icon1.name}
                                src={icon1?.preview}
                                style={{ objectFit: "cover" }}
                              />
                            </Col>
                            <Col>
                              <Link to="#" className="text-muted ">
                                {icon1.name}
                              </Link>
                            </Col>
                            <Col>
                              <div className="image__options">
                                <button
                                  className="p-0"
                                  onClick={e =>
                                    prevSelectImage(e, icon1?.preview)
                                  }
                                >
                                  <i className="far fa-eye  fs-5 p-0" />
                                </button>
                                <button
                                  style={{ color: "#ff3838" }}
                                  type={"text"}
                                  onClick={e => setIcon1(null)}
                                >
                                  <i className="bx bx-trash fs-4" />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Title of Card1 (english)
                    </Label>
                    <Input
                      id="itemnameen"
                      name="itemnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Sub Title of Cards"
                      value={titleEn1}
                       onChange={e =>
                        setTitleEn1(e.target.value)
                      }
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Title of Card1 (arabic)</Label>
                    <Input
                      id="itemnamear"
                      name="itemnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={titleAr1}
                      onChange={e =>
                        setTitleAr1(e.target.value)
                      }
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Description Card1 (English)
                    </Label>
                    <textarea
                      className="form-control  mb-0"
                      id="specification"
                      rows="3"
                      placeholder="Enter your description here"
                      value={descEn1}
                      onChange={e => setDescEn1(e.target.value)}
                    />
                  </div>
                </Col>
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Description Card1 (Arabic)
                    </Label>
                    <textarea
                      className="form-control  mb-0"
                      id="specification"
                      rows="3"
                      placeholder="اكتب وصف هنا"
                      value={descAr1}
                      onChange={e => setDescAr1(e.target.value)}
                      dir="rtl"
                    />
                  </div>
                </Col>
              </Row>
              <hr style={{ margin: "25px -20px 25px -20px" }} />

              <Row>
                <Col xs="12">
                  <div>
                    <Label htmlFor="productdesc">Icon Of Card2</Label>
                    <Input
                      type="file"
                      className="form-control form-control-lg "
                      onChange={e => handleImage(e, e.target.files,2)}
                      style={{ borderRadius: 0 }}
                      multiple
                    />
                  </div>
                </Col>
                <Col xs="12">
                  <div className="dropzone-previews" id="file-previews">
                    {icon2? (
                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={icon2.name}
                                src={icon2?.preview}
                                style={{ objectFit: "cover" }}
                              />
                            </Col>
                            <Col>
                              <Link to="#" className="text-muted ">
                                {icon2.name}
                              </Link>
                            </Col>
                            <Col>
                              <div className="image__options">
                                <button
                                  className="p-0"
                                  onClick={e =>
                                    prevSelectImage(e, icon2?.preview)
                                  }
                                >
                                  <i className="far fa-eye  fs-5 p-0" />
                                </button>
                                <button
                                  style={{ color: "#ff3838" }}
                                  type={"text"}
                                  onClick={e => setIcon2(null)}
                                >
                                  <i className="bx bx-trash fs-4" />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Title of Card2 (english)
                    </Label>
                    <Input
                      id="itemnameen"
                      name="itemnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Sub Title of Cards"
                      value={titleEn2}
                       onChange={e =>
                        setTitleEn2(e.target.value)
                      }
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Title of Card2 (arabic)</Label>
                    <Input
                      id="itemnamear"
                      name="itemnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={titleAr2}
                      onChange={e =>
                        setTitleAr2(e.target.value)
                      }
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Description Card2 (English)
                    </Label>
                    <textarea
                      className="form-control  mb-0"
                      id="specification"
                      rows="3"
                      placeholder="Enter your description here"
                      value={descEn2}
                      onChange={e => setDescEn2(e.target.value)}
                    />
                  </div>
                </Col>
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Description Card2 (Arabic)
                    </Label>
                    <textarea
                      className="form-control  mb-0"
                      id="specification"
                      rows="3"
                      placeholder="اكتب وصف هنا"
                      value={descAr2}
                      onChange={e => setDescAr2(e.target.value)}
                      dir="rtl"
                    />
                  </div>
                </Col>
              </Row>
              <hr style={{ margin: "25px -20px 25px -20px" }} />

              <Row>
              <Row>
                <Col xs="12">
                  <div>
                    <Label htmlFor="productdesc">Icon Of Card3</Label>
                    <Input
                      type="file"
                      className="form-control form-control-lg "
                      onChange={e => handleImage(e, e.target.files,3)}
                      style={{ borderRadius: 0 }}
                      multiple
                    />
                  </div>
                </Col>
                <Col xs="12">
                  <div className="dropzone-previews" id="file-previews">
                    {icon3? (
                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                alt={icon3?.name}
                                src={icon3?.preview}
                                style={{ objectFit: "cover" }}
                              />
                            </Col>
                            <Col>
                              <Link to="#" className="text-muted ">
                                {icon3.name}
                              </Link>
                            </Col>
                            <Col>
                              <div className="image__options">
                                <button
                                  className="p-0"
                                  onClick={e =>
                                    prevSelectImage(e, icon3?.preview)
                                  }
                                >
                                  <i className="far fa-eye  fs-5 p-0" />
                                </button>
                                <button
                                  style={{ color: "#ff3838" }}
                                  type={"text"}
                                  onClick={e => setIcon3(null)}
                                >
                                  <i className="bx bx-trash fs-4" />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    ) : null}
                  </div>
                </Col>  
              </Row>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Title of Card3 (english)
                    </Label>
                    <Input
                      id="itemnameen"
                      name="itemnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Sub Title of Cards"
                      value={titleEn3}
                       onChange={e =>
                        setTitleEn3(e.target.value)
                      }
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Title of Card3 (arabic)</Label>
                    <Input
                      id="itemnamear"
                      name="itemnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={titleAr3}
                      onChange={e =>
                        setTitleAr3(e.target.value)
                      }
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Description Card3 (English)
                    </Label>
                    <textarea
                      className="form-control  mb-0"
                      id="specification"
                      rows="3"
                      placeholder="Enter your description here"
                      value={descEn3}
                      onChange={e => setDescEn3(e.target.value)}
                    />
                  </div>
                </Col>
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Description Card3 (Arabic)
                    </Label>
                    <textarea
                      className="form-control  mb-0"
                      id="specification"
                      rows="3"
                      placeholder="اكتب وصف هنا"
                      value={descAr3}
                      onChange={e => setDescAr3(e.target.value)}
                      dir="rtl"
                    />
                  </div>
                </Col>
              </Row>
              <hr style={{ margin: "25px -20px 25px -20px" }} />

            </Form>
          </Col>
        </Row>
      </Form>
      <hr style={{ margin: "25px -20px 20px -20px" }} />

      <Row className="mt-3">
        <Col className="d-flex justify-content-end gap-2">
          <div className="save__btn top m-0">
            <button onClick={e => submit(e)}>
              {active ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default StepsReach
