/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody,Badge, CardTitle, Container} from "reactstrap"
import MetaTags from 'react-meta-tags'

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import "./datatables.scss"
import './specification.scss'
import axios from "axios";
import { toast } from "react-toastify"; 
import Select from 'react-select'
import SweetAlert from "react-bootstrap-sweetalert"

import { useSelector, useDispatch } from "react-redux"
import {
  setTitle,
  updateNotifications
} from "../../../store/actions";
import { useHistory } from "react-router-dom"

const Feedback = (props) => {
    const dispatch = useDispatch()
    const   user = useSelector(state => state.UserData.user)
    const history = useHistory()
    const countries = useSelector(state => state.MainData.countries)
    const users = useSelector(state => state.MainData.users) 
    const [active, setActive] = useState(false)
    const[productData, setProductData] = useState([])
    const[data, setData] = useState([])
    const[reports, setReports] = useState([])
    const[editCop, setEditCop] = useState(null)
    const[id, setId] = useState(null)
    const [success_msg, setsuccess_msg] = useState(false)
    const [msg, setMsg] = useState("")
    const great = [
      "Easy to use",
      "Design and colors",
      "Straightforward workflows",
      "Truthfulness between users",
      "Variety of toys",
      "Earn points and gifts"
    ]

    const okay = [
      "Improve usability",
      "Boost and enhance colors",
      "Complete the workflows",
      "Enhance credibility",
      "Re-implement the functionality",
      "Increase rewards points"
    ]

    const bad = [
      "Unusable application",
      "Confusing design and colors",
      "Incomplete workflows",
      "Lack of credibility",
      "Slow load times",
      "There are no incentives"
    ]


    const columns = [{
      dataField: 'key',
      text: 'Id',
      sort: true,
    }, {
      dataField: 'userName',
      text: 'User Name',
      sort: true
    }, {
      dataField: 'email',
      text: 'Email',
      sort: true
    }, {
      dataField: 'status',
      text: 'Status',
      sort: true
    },{
      dataField: 'request',
      text: 'Request',
      sort: true
    },{
      dataField: 'actions',
      text: 'Actions',
      sort: true
    },{
      dataField: 'created_at',
      text: 'Created On',
      sort: true
    }];

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];

  const pageOptions = {
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
    sizePerPageList: [ {
      text: '10', value: 10
    }, {
      text: '25', value: 25
    }, {
      text: '50', value: 50
    }, {
      text: '75', value: 75
    }, {
      text: '100', value: 100
    }, {
      text: 'All', value: productData.length
    } ]
  }


  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
]

  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search;



  function clickHandle (id) {
    setId({id, lang: "en"});
    setActive(!active)
    setEditCop(null);
  }



  function deleteHandler(id) {
      axios.post(`${process.env.REACT_APP_API}/admin/feedbacks/delete`, {id}, {
        headers: { 
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
        }
      })
      .then(res => {
      })
      setProductData(products => products.filter(prod => prod.key != id))
      setMsg("Deleted!")
      setsuccess_msg(true)
  }


  
  function countryFilter(name) {
    if(name === "All") {
        setProductData(data.map(feed => {return{...feed,
          "status": <span  className={`${feed.satisfied == 1?"greet":feed.satisfied == 2?"okay": "bad"}`}>{feed.satisfied == 1?"Great":feed.satisfied == 2?"Okay": "Bad"}</span>,
          "request": <div className="d-flex gap-1">{feed.satisfied == 1?JSON.parse(feed.value).map(f => <Badge key={f} className="notsatisfied p-2" color="success" pill>{great[f-1]}</Badge>):feed.satisfied == 2?JSON.parse(feed.value).map(f => <Badge key={f} className="notsatisfied p-2" color="primary" pill>{okay[f-1]}</Badge>):JSON.parse(feed.value).map(f => <Badge key={f} className="notsatisfied p-2" color="danger" pill>{bad[f-1]}</Badge>)}</div>,
            actions: <div className="d-flex gap-2">
                        <button style={deleteBtn}  onClick={(e)=>deleteHandler(feed.key)}><i className='bx bx-trash fs-4'></i> Delete</button>
                    </div>
        }}))
        return;
    }
    let report = data.filter(re => re.user.country == name)
    setProductData(report.map(feed => {return{...feed,
      "status": <span  className={`${feed.satisfied == 1?"greet":feed.satisfied == 2?"okay": "bad"}`}>{feed.satisfied == 1?"Greet":feed.satisfied == 2?"Okay": "Bad"}</span>,
      "request": <div className="d-flex gap-1">{feed.satisfied == 1?JSON.parse(feed.value).map(f => <Badge key={f} className="notsatisfied p-2" color="success" pill>{great[f-1]}</Badge>):feed.satisfied == 2?JSON.parse(feed.value).map(f => <Badge key={f} className="notsatisfied p-2" color="primary" pill>{okay[f-1]}</Badge>):JSON.parse(feed.value).map(f => <Badge key={f} className="notsatisfied p-2" color="danger" pill>{bad[f-1]}</Badge>)}</div>,
        actions: <div className="d-flex gap-2">
                    <button style={deleteBtn}  onClick={(e)=>deleteHandler(feed.key)}><i className='bx bx-trash fs-4'></i> Delete</button>
                </div>
    }}))
  }
  // useEffect(() => {
  //   if(user && user.role == 2) {
  //       history.push('/')
  //       return;
  //   }
  //   axios.post(`${process.env.REACT_APP_API}/admin/setUnread`, {key: "4"},{
  //     headers: {
  //         'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
  //     }
  //   }).then(res => {
  //       dispatch(updateNotifications({unreadFeedbackCount: 0}))
  //   })
  // }, [user])  

  useEffect(() => {
    dispatch(setTitle("Feedback"))
    // axios.get(`${process.env.REACT_APP_API}/admin/feedbacks`,  {
    //   headers: {
    //       'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`,
    //   }
    //   })
    //   .then(res => {
    //       setData(res.data.data)
    //       setProductData(res.data.data.map(feed => {return{...feed,
    //         email: feed.user? feed.user.email: "-",
    //         "status": <span  className={`${feed.satisfied == 1?"greet":feed.satisfied == 2?"okay": "bad"}`}>{feed.satisfied == 1?"Great":feed.satisfied == 2?"Okay": "Bad"}</span>,
    //         "request": <div className="d-flex gap-1">{feed.satisfied == 1?feed.value.map(f => <Badge key={f} className="notsatisfied p-2" color="success" pill>{great[f-1]}</Badge>):feed.satisfied == 2?feed.value.map(f => <Badge key={f} className="notsatisfied p-2" color="primary" pill>{okay[f-1]}</Badge>):feed.value.map(f => <Badge key={f} className="notsatisfied p-2" color="danger" pill>{bad[f-1]}</Badge>)}</div>,
    //           actions: <div className="d-flex gap-2">
    //                       <button style={deleteBtn}  onClick={(e)=>deleteHandler(feed.key)}><i className='bx bx-trash fs-4'></i> Delete</button>
    //                   </div>
    //       }}))
    //   })
  }, []) 

  return (
    <React.Fragment>
            <div className="page-content">
            <MetaTags>
                <title>Feedback | Layouti</title>
            </MetaTags>
            <Container fluid>
            <Row className="mb-3">
                  {success_msg ? (
                    <SweetAlert
                        title={msg}
                        success
                        confirmBtnBsStyle="success"
                        onConfirm={() => {
                        setsuccess_msg(false)
                        }}
                        onCancel={() => {
                        setsuccess_msg(false)
                        }}
                    >
                    </SweetAlert>
                ) : null}
                    <Col>
                      <Select 
                            options={countries?[{value:"All", label:"All"}].concat(countries.map(c => {return {value: c.nameEn, label: c.nameEn} })):null} 
                            placeholder="Country"
                            onChange={(e) => countryFilter(e.value)}
                        />
                    </Col> 
                </Row>
            <Row>
            <Col className="col-12">
              <Card >
                <CardBody className="pb-0">
                  
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='key'
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='key'
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col>
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative ">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      className="input_cus"
                                    />
                                    <i className="bx bx-search-alt search-icon fs-3 pt-1" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                              
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"key"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                  {productData.length === 0?<div className="emptyStateTable" style={{
                                      width: '100%',
                                      textAlign: 'center',
                                      fontSize: '16px',
                                      padding: '10px',
                                      paddingTop: '0', paddingBottom: "20px"}}>no data available in table</div>:null}
                                </div>
                              </Col>
                            </Row>

                            {productData.length !== 0?<Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>:null}
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }
                    
                    </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
            </Container>
            </div>
        </React.Fragment>
  )
}

export default Feedback

const deleteBtn = {
  background: "transparent",
  border: "none",
  color:"#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  color:"#000",
  display: "flex",
  alignItems: "center",
  gap: "5px"
}