import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert"
import { toast } from "react-toastify"
import axiosConfig from "axiosConfig"
import prettyBytes from "pretty-bytes"

const ProcessTimeline = ({sectionData}) => {
  const [rows2, setrows2] = useState([{ id: 1 }])
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")

  const [multiData, setMultiData] = useState([{
    id:1, 
    titleEn:"",
    titleAr: '', 
    descriptionEn: '', 
    descriptionAr: '', 
    icon: null,
    status:'new'
}])
  function handleAddRowNested() {
    const modifiedRows = [...rows2]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows2(modifiedRows)
    setMultiData(data => data.concat({
      id:modifiedRows.length, 
      titleEn:"",
      titleAr: '', 
      descriptionEn: '', 
      descriptionAr: '', 
      icon: null,
      status:'new'
    }))
  }

  function handleRemoveRow(id) {
    if (id !== 0) {
      var modifiedRows = [...rows2]
      modifiedRows = modifiedRows.filter(x => x["id"] !== id)
      setrows2(modifiedRows)
      setMultiData(d => d.filter((x,i) => i !== id))

    }
  }

  function handleChangeData(keyName,keyValue,key) {
    let modifiedData = [...multiData]
    modifiedData[key][keyName]=keyValue
    setMultiData(modifiedData)
    
  }

  function handleCoverImage(files,key,event) {
    [...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    handleChangeData('icon',files[0],key)
    event.target.value=null

  }
  
  function deleteCard(key,cardId,status){
    //
    if(status==='old'){
      axiosConfig.post(`/dashboard/servicesPage/processTimeline/deleteCard`, {id:cardId}, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      }).then(res=>{
        //
        setMsg(`Deleted Successfully`)
        setsuccess_msg(true)
        handleRemoveRow(key,cardId)
        // setIsLoading(false)
      }).catch(err=>{
        //
        toast.error(err.response.data.message)
        // setIsLoading(false)
      })
    }else{
      handleRemoveRow(key,cardId)
    }

  }
  
  const [isLoading, setIsLoading] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  function submit() {
    const formData = new FormData()
    const data = {
      titleEn,
      titleAr,
      descriptionEn: descEn,
      descriptionAr: descAr,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    multiData.forEach((item,index)=>{
      formData.append(`cards[${index}][titleEn]`, item.titleEn)
      formData.append(`cards[${index}][titleAr]`, item.titleAr)
      formData.append(`cards[${index}][descriptionEn]`, item.descriptionEn)
      formData.append(`cards[${index}][descriptionAr]`, item.descriptionAr)
      formData.append(`cards[${index}][icon]`, item.icon)
    })
    // formData.append("image", image)
    setIsLoading(true)
    axiosConfig.post(`/dashboard/servicesPage/processTimeline`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res=>{
      //
       toast.success(res.data.message)
      setsuccess_msg(true)
      setIsLoading(false)
    }).catch(err=>{
      //
      toast.error(err.response.data.message)
      setIsLoading(false)
    })
  }
    
  useEffect(()=>{
    setTitleEn(sectionData?.titleEn)
    setTitleAr(sectionData?.titleAr)
    setDescEn(sectionData?.descriptionEn)
    setDescAr(sectionData?.descriptionAr)
    setMultiData(sectionData?.cards)
    //
  },[sectionData])
  return (
    <div>
      {success_msg ? (
        <SweetAlert
          title={msg}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        >
        </SweetAlert>
      ) : null}
      <Form className="border-0 pb-2">
        <Row className="mt-3">
          <h3 className="fs-4 text-black fw-bolder mb-3">Header Content</h3>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (english)</Label>
              <Input
                id="itemnameen"
                name="itemnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={titleEn}
                onChange={e => setTitleEn(e.target.value)}
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (arabic)</Label>
              <Input
                id="itemnamear"
                name="itemnamear"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. "
                value={titleAr}
                onChange={e => setTitleAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-3 d-none">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (English)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="Enter your description here"
                value={descEn}
                onChange={e => setDescEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6 ">
            <div>
              <Label htmlFor="productdesc">Description (Arabic)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="اكتب وصف هنا"
                value={descAr}
                onChange={e => setDescAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>
        
        <hr style={{ margin: "25px -20px 20px -20px" }} />
        {(multiData || []).map((formRow, key) => (
          <Fragment key={key}>
            <Row>
            <h3 className="fs-4 text-black fw-bolder mb-3">
                Card ({key + 1})
              </h3>
              <Col xs="12">
                <div className="mt-3">
                  <Label htmlFor="productdesc">Icon</Label>
                  <Input
                    type="file"
                    className="form-control form-control-lg "
                    onChange={e => handleCoverImage( e.target.files,key,e)}
                    style={{ borderRadius: 0 }}
                    multiple
                  />
                </div>
              </Col>
              <Col xs="12">
                <div className="dropzone-previews" id="file-previews">
                  {formRow?.icon ? (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={formRow?.icon.name}
                              src={formRow?.icon?.preview?formRow?.icon.preview:formRow?.icon}
                              style={{ objectFit: "cover" }}
                            />
                          </Col>
                          <Col>
                            <Link to="#" className="text-muted ">
                              {formRow?.icon.name}
                            </Link>
                          </Col>
                          <Col>
                            <div className="image__options">
                              <button
                                className="p-0"
                                onClick={e =>
                                  prevSelectImage(e, formRow?.icon?.preview?formRow?.icon.preview:formRow?.icon)
                                }
                              >
                                <i className="far fa-eye  fs-5 p-0"></i>
                              </button>
                              <button
                              type='button'
                                style={{ color: "#ff3838" }}
                                onClick={e =>
                                  handleChangeData('icon',null,key,e)
                                }
                              >
                                <i className="bx bx-trash fs-4"></i>
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              
              
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Title (english)</Label>
                  <Input
                    id="itemnameen"
                    name="titleEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Focus Keypharse"
                    value={formRow?.titleEn}
                    onChange={e => handleChangeData(e.target.name,e.target.value,key)}
                  />
                </div>
              </Col>

              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Title (arabic)</Label>
                  <Input
                    id="itemnamear"
                    name="titleAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. "
                    value={formRow?.titleAr}
                    onChange={e => handleChangeData(e.target.name,e.target.value,key)}
                    dir="rtl"
                    lang="ar"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (english)</Label>
                  {/* <Input
                    id="itemnameen"
                    name="descriptionEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Focus Keypharse"
                    value={formRow?.descriptionEn}
                    onChange={e => handleChangeData(e.target.name,e.target.value,key)}
                  /> */}
                  <textarea
                    className="form-control  mb-0"
                    name="descriptionEn"
                    id="descriptionEn"
                    rows="5"
                    placeholder="Enter your description here"
                    value={formRow?.descriptionEn}
                    onChange={e => handleChangeData(e.target.name,e.target.value,key)}
                  />
                </div>
              </Col>

              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (arabic)</Label>
                  {/* <Input
                    id="itemnamear"
                    name="descriptionAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. "
                    value={formRow?.descriptionAr}
                    onChange={e => handleChangeData(e.target.name,e.target.value,key)}
                    dir="rtl"
                    lang="ar"
                  /> */}
                  <textarea
                    className="form-control  mb-0"
                    name="descriptionAr"
                    id="specification"
                    rows="5"
                    placeholder="اكتب وصف هنا"
                    value={formRow?.descriptionAr}
                    onChange={e => handleChangeData(e.target.name,e.target.value,key)}
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            
            
            {key !== 0 ? (
              <Button
                color="none"
                variant="primary"
                className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                id="unknown-btn"
                style={{
                  color: "#ff3838",
                  boxShadow: "none",
                  height: "45px",
                  borderRadius: 0,
                }}
                onClick={e => {
                  deleteCard(key,formRow.id,formRow?.status=='new'?'new':'old')
                }}
              >
                <i className="bx bx-trash-alt fs-4 p-0 mr-1" /> Delete
              </Button>
            ) : null}
            {
              key!=0?
              <hr style={{ margin: "5px -20px 20px -20px" }} />
              :
              <hr style={{ margin: "25px -20px 20px -20px" }} />
            }
          </Fragment>
        ))}
        
        <Button
          onClick={() => {
            handleAddRowNested()
          }}
          color="none"
          className="mt-2 fs-5 fw-bold p-0 text-left"
          style={btnStyle}
        >
          <i className="bx bx-plus-circle fs-4" /> <span>Add Projects</span>
        </Button>
        <hr style={{ margin: "20px -20px 0px -20px" }} />
      </Form>
      <Row className="mt-2">
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0">
              <button type='button' onClick={submit} disabled={isLoading?true:false}>
                {isLoading ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
    </div>
  )
}

export default ProcessTimeline

const cardStyle = {
  borderTop: "2px solid #dedede",
}

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
