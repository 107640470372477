import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Sticky from "react-sticky-el"
import "./configration.scss"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { setTitle } from "../../../store/actions"
import { useHistory } from "react-router-dom"
import axiosConfig from "axiosConfig"
import CategoryFor365Design from "./Components/CategoryFor365Design"
import WebsiteSetting from "./Components/WebsiteSetting"

const DesignOptions = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.UserData.user)
  const history = useHistory()

  const [data, setData] = useState([])
  const [admins, setAdmins] = useState([])
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [email, setEmail] = useState(null)
  const [verticalActiveTab, setverticalActiveTab] = useState("1")

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  

  useEffect(() => {
    if (user && user.role == 2) {
      history.push("/")
      return
    }
  }, [user])

  const [design365, setDesign365] = useState([])


  
  function get365DesignData() {
    axiosConfig
      .get(`/dashboard/365Design/category`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setDesign365(res.data.data)
      })
      .catch(err => {
      })
  }
  
  

  useEffect(() => {
    dispatch(setTitle("configurations"))
    get365DesignData()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Settings | Layouti</title>
        </MetaTags>
        <Container fluid>
          <Col>
            <Row>
              <Col md="3">
                <Sticky topOffset={-300} >
                  <Nav
                    pills
                    className="flex-column justify-content-between navs_aside"
                  >
                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "1",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("1")
                        }}
                      >
                        Categories for 365 Design
                      </NavLink>
                    </NavItem>

                    
                  </Nav>
                  <Nav
                    pills
                    className="flex-column justify-content-between navs_aside"
                  >
                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "2",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("2")
                        }}
                      >
                        Website Settings
                      </NavLink>
                    </NavItem>

                    
                  </Nav>
                </Sticky>
              </Col>
              <Col md="9">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted table__content mt-4 mt-md-0"
                >
                  
                  <TabPane tabId="1">
                    <CategoryFor365Design
                      categories={design365}
                      getData={get365DesignData}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <WebsiteSetting
                    />
                  </TabPane>
                  
                  
                </TabContent>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DesignOptions

const navsStyle = {
  cursor: "pointer",
  borderRadius: 0,
}
