import React, { Fragment, useState, useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import axios from "axios"
import { toast } from "react-toastify"

const Testimonials = ({data}) => {
  const [active, setActive] = useState(false)
  const [rows2, setrows2] = useState([{ id: 1 }])
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")
  const [prev, setPrev] = useState(null)
  const [isOpen, setisOpen] = useState(false)

  const [multiData, setMultiData] = useState([{
      id:1, 
      nameEn:"",
      nameAr: '', 
      jobTitleEn:'', 
      jobTitleAr: '', 
      descriptionEn: '', 
      descriptionAr: '', 
      image: null
  }])

  function prevSelectImage(e, prev) {
    e.preventDefault()
    setPrev(prev)
    setisOpen(!isOpen)
  }
  function handleAddRowNested() {
    const modifiedRows = [...rows2]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows2(modifiedRows)
    setMultiData(data => data.concat({
    id:modifiedRows.length, 
    nameEn:"",
    nameAr: '', 
    jobTitleEn:'', 
    jobTitleAr: '', 
    descriptionEn: '', 
    descriptionAr: '', 
    image: null}))
  }

  function handleRemoveRow(id, i) {
    if (id !== 0) {
      
      setrows2(r => r.filter((x,i) => i !== id))
      setMultiData(d => d.filter((x,i) => i !== id))
    }
    axios.post(`${process.env.REACT_APP_API}/dashboard/learnUi/testimonials/deleteCard`, {id: i}, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
      },
    }).then(res => {
      
    })
  }

 
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }




  function multiChange(e, key, type) {
    setMultiData(points =>
      points.map((data, index) => {
        if (index === key && type === "nameEn") {
          return { ...data, nameEn: e.target.value }
        }  else if (index === key && type === "nameAr") {
          return { ...data, nameAr: e.target.value }
        } else if (index === key && type === "jobTitleEn") {
          return { ...data, jobTitleEn: e.target.value }
        } else if (index === key && type === "jobTitleAr") {
          return { ...data, jobTitleAr: e.target.value }
        } else if (index === key && type === "descriptionEn") {
          return { ...data, descriptionEn: e.target.value }
        } else if (index === key && type === "descriptionAr") {
          return { ...data, descriptionAr: e.target.value }
        } else if (index === key && type === "image") {
          return { ...data, image: Object.assign(e, {
            preview: URL.createObjectURL(e),
            formattedSize: formatBytes(e.size),
          }) }
        } 
        return data
      })
    )
  }


  function prevSelectImage(e, prev) {
    e.preventDefault()
    setPrev(prev)
    setisOpen(!isOpen)
  }

  function submit(e) {
    e.preventDefault()
    setActive(true)
    const formData = new FormData()
    const data = {
      titleEn,
      titleAr, 
      descriptionEn: descEn, 
      descriptionAr: descAr,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    multiData.forEach((data, i) => {
      formData.append(`cards[${i}][nameEn]`, data.nameEn)
      formData.append(`cards[${i}][nameAr]`, data.nameAr)
      formData.append(`cards[${i}][jobTitleEn]`, data.jobTitleEn)
      formData.append(`cards[${i}][jobTitleAr]`, data.jobTitleAr)
      formData.append(`cards[${i}][descriptionEn]`, data.descriptionEn)
      formData.append(`cards[${i}][descriptionAr]`, data.descriptionAr)
      formData.append(`cards[${i}][image]`, data.image)
    })
    axios.post(`${process.env.REACT_APP_API}/dashboard/learnUi/testimonials`, formData, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
      },
    }).then(res => {
      toast.success("Data Saved Successfully", {position: "top-right"})
      setActive(false)
    })
  }
  useEffect(() => {
    if(data) {
      setTitleEn(data.titleEn)
      setTitleAr(data.titleAr)
      setDescEn(data.descriptionEn)
      setDescAr(data.descriptionAr)
      setrows2(data.cards)
      setMultiData(data.cards.map(d => {return{...d, image: d.image ? {preview: d.image} : null}}))
    }
    
  }, [data])
  return (
    <div>
      {isOpen ? (
        <Lightbox
          mainSrc={prev}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
      <Form className="border-0">
        <Row className="mt-3">
          <h3 className="fs-4 text-black fw-bolder mb-3">Header Content</h3>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (english)</Label>
              <Input
                id="itemnameen"
                name="itemnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={titleEn}
                onChange={e => setTitleEn(e.target.value)}
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (arabic)</Label>
              <Input
                id="itemnamear"
                name="itemnamear"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. "
                value={titleAr}
                onChange={e => setTitleAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (English)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="Enter your description here"
                value={descEn}
                onChange={e => setDescEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (Arabic)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="اكتب وصف هنا"
                value={descAr}
                onChange={e => setDescAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>
        <hr style={{ margin: "25px -20px 20px -20px" }} />
        {(rows2 || []).map((formRow, key) => (
          <Fragment key={key}>
            <Row className="mt-4">
              <h3 className="fs-4 text-black fw-bolder mb-3">
                Card ({key + 1})
              </h3>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Name (english)</Label>
                  <Input
                    id="itemnameen"
                    name="itemnameen"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Focus Keypharse"
                    value={multiData[key].nameEn}
                    onChange={e => multiChange(e, key, "nameEn")}
                  />
                </div>
              </Col>

              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Name (arabic)</Label>
                  <Input
                    id="itemnamear"
                    name="itemnamear"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. "
                    value={multiData[key].nameAr}
                    onChange={e => multiChange(e, key, "nameAr")}
                    dir="rtl"
                    lang="ar"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Job Title (english)</Label>
                  <Input
                    id="itemnameen"
                    name="itemnameen"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Focus Keypharse"
                    value={multiData[key].jobTitleEn}
                    onChange={e => multiChange(e, key, "jobTitleEn")}
                  />
                </div>
              </Col>

              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Job Title (arabic)</Label>
                  <Input
                    id="itemnamear"
                    name="itemnamear"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. "
                    value={multiData[key].jobTitleAr}
                    onChange={e =>  multiChange(e, key, "jobTitleAr")}
                    dir="rtl"
                    lang="ar"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (English)</Label>
                  <textarea
                    className="form-control  mb-0"
                    id="specification"
                    rows="5"
                    value={multiData[key].descriptionEn}
                 
                    onChange={e =>  multiChange(e, key, "descriptionEn")}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (Arabic)</Label>
                  <textarea
                    className="form-control  mb-0"
                    id="specification"
                    rows="5"
                    placeholder="اكتب وصف هنا"
                    value={multiData[key].descriptionAr}
                    onChange={e => multiChange(e, key, "descriptionAr")}
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <div className="mt-3">
                  <Label htmlFor="productdesc">Image</Label>
                  <Input
                    type="file"
                    className="form-control form-control-lg "
                    onChange={e => multiChange(e.target.files[0], key, "image")}
                    style={{ borderRadius: 0 }}
                  />
                </div>
              </Col>
              <Col xs="12">
                <div className="dropzone-previews" id="file-previews">
                  {multiData[key].image ? (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={multiData[key].image.name}
                              src={multiData[key].image.preview}
                              style={{ objectFit: "cover" }}
                            />
                          </Col>
                          <Col>
                            <Link to="#" className="text-muted ">
                              {multiData[key].image.name}
                            </Link>
                          </Col>
                          <Col>
                            <div className="image__options">
                              <button
                                className="p-0"
                                onClick={e =>
                                  prevSelectImage(e, multiData[key].image.preview)
                                }
                              >
                                <i className="far fa-eye  fs-5 p-0"></i>
                              </button>
                              <button
                                style={{ color: "#ff3838" }}
                                onClick={e =>
                                  setMultiData(d => d.map((d,i) => i== key?{...d, image:null}: d))
                                }
                              >
                                <i className="bx bx-trash fs-4"></i>
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  ) : null}
                </div>
              </Col>
            </Row>
            {key !== 0 ? (
              <Button
                color="none"
                variant="primary"
                className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                id="unknown-btn"
                style={{
                  color: "#ff3838",
                  boxShadow: "none",
                  height: "45px",
                  borderRadius: 0,
                }}
                onClick={e => {
                  handleRemoveRow(key,multiData[key].id)
                }}
              >
                <i className="bx bx-trash-alt fs-4 p-0 mr-1" /> Delete
              </Button>
            ) : null}
            {
                    key!=0?
                    <hr style={{ margin: "5px -20px 20px -20px" }} />
                    :
                    <hr style={{ margin: "25px -20px 20px -20px" }} />
                  }
          </Fragment>
        ))}
        
        <Button
          onClick={() => {
            handleAddRowNested()
          }}
          color="none"
          className="mt-2 fs-5 fw-bold p-0 text-left"
          style={btnStyle}
        >
          <i className="bx bx-plus-circle fs-4" /> <span>Add Projects</span>
        </Button>
        <hr style={{ margin: "25px -20px 20px -20px" }} />
      </Form>
      <Row >
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0 mb-2">
              <button onClick={e => submit(e)} >
                {active ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
    </div>
  )
}

export default Testimonials

const cardStyle = {
  borderTop: "2px solid #dedede",
}

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
