import React, { useDebugValue, useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Col, Row } from "reactstrap"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import "./datatables.scss"
import axios from "axios"
import { useDispatch } from "react-redux"
import { setTitle } from "../../../store/actions"
import AdminPagination from "../../Utilities/AdminPagination"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"

const Registration = () => {
  const dispatch = useDispatch()
  const [productData, setProductData] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const [filter, setFilter] = useState("")
  const [totalCount, setTotalCount] = useState(0)

  const sizePerPage = 10
  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },{
      dataField: "fullName",
      text: "Full Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email Address",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Mobile Phone",
      sort: true,
    },
    {
      dataField: "package",
      text: "Package",
      sort: true,
    },
    {
      dataField: "country",
      text: "Country",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "CreatedOn",
      sort: true,
    },
    {
      dataField: "actions",
      text: "Actions",
      sort: true,
    },
  ]

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 10,
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
  }
  const onPageHandler = (type, { page }) => {
    setPageNo(page)
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: productData.length },
  ]

  const { SearchBar } = Search

  function deleteContact(id) {
    
    axiosConfig
      .post(
        `/dashboard/learnUi/reservation/delete`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setProductData(prods => prods.filter(p => p.id !== id))
      })
      .catch(err => {
        toast.error("Something went wrong")
      })
  }

  useEffect(() => {
    axiosConfig
      .get(`/dashboard/learnUi/reservation`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setProductData(
          res.data.data.reservation.map(d => {
            return {
              ...d,
              actions: (
                <button style={deleteBtn} onClick={() => deleteContact(d.id)}>
                  <i className="bx bx-trash fs-4"> </i>Delete{" "}
                </button>
              ),
            }
          })
        )
      })
    setTotalCount(1)
  }, [])
  useEffect(() => {
    dispatch(setTitle("Learn UI Registration"))
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Registration | Layouti{" "}
          </title>{" "}
        </MetaTags>{" "}
        <Container fluid>
          <Card>
            <CardBody>
              <AdminPagination
                data={productData}
                page={pageNo}
                setFilter={setFilter}
                keyFiled="id"
                buttonText="Add Product"
                url="/ecommerce-add-product"
                sizePerPage={sizePerPage}
                totalSize={totalCount}
                onTableChange={onPageHandler}
                columns={columns}
                none={true}
              />{" "}
            </CardBody>{" "}
          </Card>{" "}
        </Container>{" "}
      </div>{" "}
    </React.Fragment>
  )
}

export default Registration

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "#000",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}
