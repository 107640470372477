import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Sticky from "react-sticky-el"
import "./configration.scss"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { setTitle } from "../../../store/actions"
import { useHistory } from "react-router-dom"

//pages import
import HomePage from "./Pages/HomePage"
import AboutPage from "./Pages/AboutPage"
import WorksPage from "./Pages/WorksPage"
import ServicesPage from "./Pages/ServicesPage"
import Things100Page from "./Pages/Things100Page"
import ContactUsPage from "./Pages/ContactUsPage"

const LayoutiPages = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(state => state.UserData.user)
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  useEffect(() => {
    if (user && user.role == 2) {
      history.push("/dashboard")
      return
    }
  }, [user])

  useEffect(() => {
    dispatch(setTitle("Layouti Pages"))
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Pages | Layouti</title>
        </MetaTags>
        <Container fluid>
          <Col>
            <Row>
              <Col md="3">
                <Sticky topOffset={-300}>
                  <Nav
                    pills
                    className="flex-column justify-content-between navs_aside"
                  >
                    <NavItem className="nav___item border_bottom">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "1",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("1")
                        }}
                      >
                        Home Page
                      </NavLink>
                    </NavItem>

                    <NavItem className="nav___item border_bottom">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "2",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("2")
                        }}
                      >
                        About Page
                      </NavLink>
                    </NavItem>

                    <NavItem className="nav___item border_bottom">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "3",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("3")
                        }}
                      >
                        Works Page
                      </NavLink>
                    </NavItem>

                    <NavItem className="nav___item border_bottom">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "4",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("4")
                        }}
                      >
                        Services Page
                      </NavLink>
                    </NavItem>

                    <NavItem className="nav___item border_bottom">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "5",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("5")
                        }}
                      >
                        100 Things Page
                      </NavLink>
                    </NavItem>

                    <NavItem className="nav___item border_bottom">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "6",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("6")
                        }}
                      >
                        Contact Us Page
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Sticky>
              </Col>
              <Col md="9">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted table__content mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <HomePage />
                  </TabPane>
                  <TabPane tabId="2">
                    <AboutPage />
                  </TabPane>
                  <TabPane tabId="3">
                    <WorksPage />
                  </TabPane>
                  <TabPane tabId="4">
                    <ServicesPage />
                  </TabPane>
                  <TabPane tabId="5">
                    <Things100Page />
                  </TabPane>
                  <TabPane tabId="6">
                    <ContactUsPage />
                  </TabPane>
                 
                </TabContent>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default LayoutiPages

