import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import SweetAlert from "react-bootstrap-sweetalert"
import { toast } from "react-toastify"
import axiosConfig from "axiosConfig"

const CheckOutClient = ({sectionData}) => {
  const [active, setActive] = useState(false)
  const [rows2, setrows2] = useState([{ id: 1 }])
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")
  const [prev, setPrev] = useState(null)
  const [isOpen, setisOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")

  const [multiData, setMultiData] = useState([
    {
      id: 1,
      titleEn: "",
      titleAr: "",
      descEn: "",
      descAr: "",
      image: null,
      status:'new'
    },
  ])

  function prevSelectImage(e, prev) {
    e.preventDefault()
    setPrev(prev)
    setisOpen(!isOpen)
  }
  function handleAddRowNested() {
    const modifiedRows = [...rows2]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows2(modifiedRows)
    setMultiData(data =>
      data.concat({
        id: modifiedRows.length,
        titleEn: "",
        titleAr: "",
        descEn: "",
        descAr: "",
        image: null,
        status:'new'
      })
    )
  }
  function deleteCard(key,cardId,status){
    ////
    if(status==='old'){
      axiosConfig.post(`/dashboard/aboutPage/checkOutOurClients/deleteCard`, {id:cardId}, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      }).then(res=>{
        ////
        setMsg(`Deleted Successfully`)
        setsuccess_msg(true)
        handleRemoveRow(key,cardId)
        // setIsLoading(false)
      }).catch(err=>{
        ////
        toast.error(err.response.data.message)
        // setIsLoading(false)
      })
    }else{
      handleRemoveRow(key,cardId)
    }

  }

  function handleRemoveRow(id,cardId) {
    if (id !== 0) {
     
      setrows2(r => r.filter((x, i) => i !== id))
      setMultiData(d => d.filter((x, i) => i !== id))
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function multiChange(e, key, type,event) {
    setMultiData(points =>
      points.map((data, index) => {
        if (index === key && type === "titleEn") {
          return { ...data, titleEn: e.target.value }
        } else if (index === key && type === "titleAr") {
          return { ...data, titleAr: e.target.value }
        } else if (index === key && type === "descEn") {
          return { ...data, descEn: e.target.value }
        } else if (index === key && type === "descAr") {
          return { ...data, descAr: e.target.value }
        } else if (index === key && type === "descriptionEn") {
          return { ...data, descriptionEn: e.target.value }
        } else if (index === key && type === "descriptionAr") {
          return { ...data, descriptionAr: e.target.value }
        } else if (index === key && type === "image") {
          return {
            ...data,
            image: Object.assign(e, {
              preview: URL.createObjectURL(e),
              formattedSize: formatBytes(e.size),
            }),
          }
        }
        return data
      })
    )
    if (type === "image"){
      event.target.value=null
    }
  }

  function prevSelectImage(e, prev) {
    e.preventDefault()
    setPrev(prev)
    setisOpen(!isOpen)
  }
  

  function submit() {
    const formData = new FormData()
    const data = {
      titleEn,
      titleAr,
      descriptionEn: descEn,
      descriptionAr: descAr,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    multiData.forEach((item,index)=>{
      formData.append(`cards[${index}][industryEn]`, item.titleEn)
      formData.append(`cards[${index}][industryAr]`, item.titleAr)
      formData.append(`cards[${index}][descriptionEn]`, item.descEn)
      formData.append(`cards[${index}][descriptionAr]`, item.descAr)
      formData.append(`cards[${index}][image]`, item.image)
    })
    setIsLoading(true)
    axiosConfig.post(`/dashboard/aboutPage/checkOutOurClients`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res=>{
      ////
       toast.success(res.data.message)
      setsuccess_msg(true)
      setIsLoading(false)
    }).catch(err=>{
      ////
      toast.error(err.response.data.message)
      setIsLoading(false)
    })
  }
    
  useEffect(()=>{
    setTitleEn(sectionData?.titleEn)
    setTitleAr(sectionData?.titleAr)
    setDescEn(sectionData?.descriptionEn)
    setDescAr(sectionData?.descriptionAr)
    // setImage(sectionData?.image)
    // setMultiData(sectionData?.cards)
    let modifiedCards=sectionData?.cards&&[...sectionData?.cards]
    modifiedCards=modifiedCards&&modifiedCards.map(val=>{
      return{
        id:val.id,
        descEn: val.descriptionEn,
        descAr: val.descriptionAr,
        titleEn: val.industryEn,
        titleAr: val.industryAr,
        image:val.image
      }
    })
    setMultiData(modifiedCards)
    ////
  },[sectionData])

  return (
    <div>
      {success_msg ? (
        <SweetAlert
          title={msg}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        >
        </SweetAlert>
      ) : null}
      {isOpen ? (
        <Lightbox
          mainSrc={prev}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
      <Form className="border-0 pb-2">
        <Row className="mt-3">
          <h3 className="fs-4 text-black fw-bolder mb-3">Header Content</h3>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (english)</Label>
              <Input
                id="itemnameen"
                name="itemnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={titleEn}
                onChange={e => setTitleEn(e.target.value)}
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (arabic)</Label>
              <Input
                id="itemnamear"
                name="itemnamear"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. "
                value={titleAr}
                onChange={e => setTitleAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (English)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="Enter your description here"
                value={descEn}
                onChange={e => setDescEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (Arabic)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="اكتب وصف هنا"
                value={descAr}
                onChange={e => setDescAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>
        <hr style={{ margin: "25px -20px 20px -20px" }} />
        {(multiData || []).map((formRow, key) => (
          <Fragment key={key}>
            
            <Row className="mt-3">
            <h3 className="fs-4 text-black fw-bolder mb-3">
                Card ({key + 1})
              </h3>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (English)</Label>
                  <textarea
                    className="form-control  mb-0"
                    id="specification"
                    rows="5"
                    placeholder="Enter your description here"
                    value={formRow?.descEn}
                    onChange={e => multiChange(e, key, "descEn")}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (Arabic)</Label>
                  <textarea
                    className="form-control  mb-0"
                    id="specification"
                    rows="5"
                    placeholder="اكتب وصف هنا"
                    value={formRow?.descAr}
                    onChange={e => multiChange(e, key, "descAr")}
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
            
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">industry (english)</Label>
                  <Input
                    id="itemnameen"
                    name="itemnameen"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Focus Keypharse"
                    value={formRow?.titleEn}
                    onChange={e => multiChange(e, key, "titleEn")}
                  />
                </div>
              </Col>

              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">industry (arabic)</Label>
                  <Input
                    id="itemnamear"
                    name="itemnamear"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. "
                    value={formRow?.titleAr}
                    onChange={e => multiChange(e, key, "titleAr")}
                    dir="rtl"
                    lang="ar"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <div className="mt-3">
                  <Label htmlFor="productdesc">Image</Label>
                  <Input
                    type="file"
                    className="form-control form-control-lg "
                    onChange={e => multiChange(e.target.files[0], key, "image",e)}
                    style={{ borderRadius: 0 }}
                  />
                </div>
              </Col>
              <Col xs="12">
                <div className="dropzone-previews" id="file-previews">
                  {formRow?.image ? (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={formRow?.image.name}
                              src={formRow?.image.preview?formRow?.image.preview:formRow?.image}
                              style={{ objectFit: "cover" }}
                            />
                          </Col>
                          <Col>
                            <Link to="#" className="text-muted ">
                              {formRow?.image.name}
                            </Link>
                          </Col>
                          <Col>
                            <div className="image__options">
                              <button
                                className="p-0"
                                onClick={e =>
                                  prevSelectImage(
                                    e,
                                    formRow?.image.preview?formRow?.image.preview:formRow?.image
                                  )
                                }
                              >
                                <i className="far fa-eye  fs-5 p-0"></i>
                              </button>
                              <button
                                style={{ color: "#ff3838" }}
                                onClick={e =>
                                  setMultiData(d =>
                                    d.map((d, i) =>
                                      i == key ? { ...d, image: null } : d
                                    )
                                  )
                                }
                              >
                                <i className="bx bx-trash fs-4"></i>
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  ) : null}
                </div>
              </Col>
            </Row>
            {key !== 0 ? (
              <Button
                color="none"
                variant="primary"
                className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                id="unknown-btn"
                style={{
                  color: "#ff3838",
                  boxShadow: "none",
                  height: "45px",
                  borderRadius: 0,
                }}
                onClick={e => {
                  deleteCard(key,formRow.id,formRow?.status=='new'?'new':'old')
                }}
              >
                <i className="bx bx-trash-alt fs-4 p-0 mr-1" /> Delete
              </Button>
            ) : null}
            {
                key!=0?
                <hr style={{ margin: "5px -20px 20px -20px" }} />
                :
                <hr style={{ margin: "25px -20px 20px -20px" }} />
              }
          </Fragment>
        ))}
        
        <Button
          onClick={() => {
            handleAddRowNested()
          }}
          color="none"
          className="mt-2 fs-5 fw-bold p-0 text-left"
          style={btnStyle}
        >
          <i className="bx bx-plus-circle fs-4" /> <span>Add Projects</span>
        </Button>
        <hr style={{ margin: "20px -20px 0px -20px" }} />
      </Form>
      <Row className="mt-2">
        <Col className="d-flex justify-content-end gap-2">
          <div className="save__btn top m-0">
            <button type='button' onClick={submit}>
                {isLoading ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default CheckOutClient

const cardStyle = {
  borderTop: "2px solid #dedede",
}

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
