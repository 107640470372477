import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Sticky from "react-sticky-el"
import "./configration.scss"
import { useDispatch } from "react-redux"
import { setTitle } from "../../store/actions"
import Contact from "./Components/OurProducts"
import OurProducts from "./Components/OurProducts"
import InHouseProject from "./Components/InHouseProject"
import SocialMedia from "./Components/SocialMedia"
import LayoutiDetails from "./Components/LayoutiDetails"
import Contacts from "./Components/Contacts"

const LinksSettings = () => {
  const dispatch = useDispatch()
  const [verticalActiveTab, setverticalActiveTab] = useState("1")
  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  useEffect(() => {
    dispatch(setTitle("Links"))
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Layouti | Links</title>
        </MetaTags>
        <Container fluid>
          <Col>
            <Row>
              <Col md="3">
                <Sticky topOffset={-300}>
                  <Nav
                    pills
                    className="flex-column justify-content-between navs_aside"
                  >
                    <NavItem className="nav___item border_bottom">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "1",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("1")
                        }}
                      >
                        Header Content
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav___item border_bottom">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "2",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("2")
                        }}
                      >
                        Contact Information
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav___item border_bottom">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "3",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("3")
                        }}
                      >
                        Social Media Links
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Sticky>
              </Col>
              <Col md="9">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted table__content mt-4 mt-md-0"
                >
                  <TabPane tabId="1">
                    <LayoutiDetails />
                  </TabPane>
                  <TabPane tabId="2">
                    <Contacts />
                  </TabPane>
                  <TabPane tabId="3">
                    <OurProducts />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default LinksSettings

const navsStyle = {
  cursor: "pointer",
  borderRadius: 0,
}
