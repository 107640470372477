import React, { useEffect,useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import axiosConfig from "axiosConfig"
import SweetAlert from "react-bootstrap-sweetalert"
import { toast } from "react-toastify"



const HeaderContent = ({data}) => {
  const [active, setActive] = useState(false)
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const [color, setColor] = useState("")
  const [titleOneEn, setTitleOneEn] = useState("")
  const [titleOneAr, setTitleOneAr] = useState("")
  const [titleTwoEn, setTitleTwoEn] = useState("")
  const [titleTwoAr, setTitleTwoAr] = useState("")
  const [titleThreeEn, setTitleThreeEn] = useState("")
  const [titleThreeAr, setTitleThreeAr] = useState("")
  const [availabilityTitleEn, setAvailabilityTitleEn] = useState("")
  const [availabilityTitleAr, setAvailabilityTitleAr] = useState("")
  const [availabilityIOSIcon, setAvailabilityIOSIcon] = useState(null)
  const [availabilityIOSLink, setAvailabilityIOSLink] = useState("")
  const [availabilityAndroidIcon, setAvailabilityAndroidIcon] = useState(null)
  const [availabilityAndroidLink, setAvailabilityAndroidLink] = useState("")
  const [topLeftImage, setTopLeftImage] = useState(null)
  const [topRightImage, setTopRightImage] = useState(null)
  const [bottomLeftImage, setBottomLeftImage] = useState(null)
  const [bottomRightImage, setBottomRightImage] = useState(null)
  const [bottomImage, setBottomImage] = useState(null)


  const colorStyle = {
    borderRadius: 0,
    fontSize: "18px",
    height: "45px"
  }
  function handleAvailabilityIOSIcon(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setAvailabilityIOSIcon(Object.values(files)[0])
    e.target.value = ""
  }
  function handleAvailabilityAndroidIcon(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setAvailabilityAndroidIcon(Object.values(files)[0])
    e.target.value = ""
  }
  function handleTopLeftImage(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setTopLeftImage(Object.values(files)[0])
    e.target.value = ""
  }
  function handleTopRightImage(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setTopRightImage(Object.values(files)[0])
    e.target.value = ""
  }
  function handleBottomLeftImage(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setBottomLeftImage(Object.values(files)[0])
    e.target.value = ""
  }
  function handleBottomRightImage(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setBottomRightImage(Object.values(files)[0])
    e.target.value = ""
  }
  function handleBottomImage(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setBottomImage(Object.values(files)[0])
    e.target.value = ""
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function prevSelectImage(e, prev) {
    e.preventDefault()
    setPrev(prev)
    setisOpen(!isOpen)
  }
  function submit(e) {
    setActive(true)
    e.preventDefault()
    const formData = new FormData()
    const data = {
      color,
      titleOneEn,
      titleOneAr,
      titleTwoEn,
      titleTwoAr,
      titleThreeEn,
      titleThreeAr,
      availabilityTitleEn,
      availabilityTitleAr,
      availabilityIOSIcon,
      availabilityIOSLink,
      availabilityAndroidIcon,
      availabilityAndroidLink,
      topLeftImage,
      topRightImage,
      bottomLeftImage,
      bottomRightImage,
      bottomImage
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    


    axiosConfig.post(`/dashboard/etoy/homePage/headerContent`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res=>{
       toast.success(res.data.message)
      setsuccess_msg(true)
      setIsLoading(false)
     setActive(false)
    }).catch(err=>{
      toast.error(err.response.data.message)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if(data) {
      setColor(data.color)
      setTitleOneEn(data.titleOneEn)
      setTitleOneAr(data.titleOneAr)
      setTitleTwoEn(data.titleTwoEn)
      setTitleTwoAr(data.titleTwoAr)
      setTitleThreeEn(data.titleThreeEn)
      setTitleThreeAr(data.titleThreeAr)
      setAvailabilityTitleEn(data.availabilityTitleEn)
      setAvailabilityTitleAr(data.availabilityTitleAr)
      setAvailabilityIOSIcon({preview: data.availabilityIOSIcon})
      setAvailabilityIOSLink(data.availabilityIOSLink)
      setAvailabilityAndroidIcon({preview: data.availabilityAndroidIcon})
      setAvailabilityAndroidLink(data.availabilityAndroidLink)
      setTopLeftImage({preview: data.topLeftImage})
      setTopRightImage({preview: data.topRightImage})
      setBottomLeftImage({preview: data.bottomLeftImage})
      setBottomRightImage({preview: data.bottomRightImage})
      setBottomImage({preview: data.bottomImage})
           
    }
    
  }, [data])

  return (
    <div>
      {success_msg ? (
        <SweetAlert
          title={msg}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        >
        </SweetAlert>
      ) : null}
      {isOpen ? (
        <Lightbox
          mainSrc={prev}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
      <Form className="border-0 pb-2">
      <Row className="">
          <Col sm="12">
            <Label htmlFor="productdesc">Color</Label>
            <div className="d-flex">
              <Input type="color" onChange={(e) => setColor(e.target.value)} value={color}/>
              <Input type="text" className="color__name" value={color} onChange={(e) => setColor(e.target.value)} style={colorStyle} />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (1) (english)</Label>
              <Input
                id="itemnameen"
                name="itemnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={titleOneEn}
                onChange={e => setTitleOneEn(e.target.value)}
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (1) (arabic)</Label>
              <Input
                id="itemnamear"
                name="itemnamear"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. "
                value={titleOneAr}
                onChange={e => setTitleOneAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (2) (english)</Label>
              <Input
                id="itemnameen"
                name="itemnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={titleTwoEn}
                onChange={e => setTitleTwoEn(e.target.value)}
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (2) (arabic)</Label>
              <Input
                id="itemnamear"
                name="itemnamear"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. "
                value={titleTwoAr}
                onChange={e => setTitleTwoAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (3) (english)</Label>
              <Input
                id="itemnameen"
                name="itemnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={titleThreeEn}
                onChange={e => setTitleThreeEn(e.target.value)}
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (3) (arabic)</Label>
              <Input
                id="itemnamear"
                name="itemnamear"
                type="text" 
                className="form-control input_cus"
                placeholder="مثال. "
                value={titleThreeAr}
                onChange={e => setTitleThreeAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Availability Title (english)</Label>
              <Input
                id="itemnameen"
                name="itemnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={availabilityTitleEn}
                onChange={e => setAvailabilityTitleEn(e.target.value)}
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Availability Title (arabic)</Label>
              <Input
                id="itemnamear"
                name="itemnamear"
                type="text" 
                className="form-control input_cus"
                placeholder="مثال. "
                value={availabilityTitleAr}
                onChange={e => setAvailabilityTitleAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
        <Col sm="6">
        <Row>
          <Col xs="12">
            <div className="">
              <Label htmlFor="productdesc">Availability IOS Icon</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => handleAvailabilityIOSIcon(e, e.target.files)}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {availabilityIOSIcon ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={availabilityIOSIcon.name}
                          src={availabilityIOSIcon.preview}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {availabilityIOSIcon.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e => prevSelectImage(e, availabilityIOSIcon.preview)}
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            style={{ color: "#ff3838" }}
                            onClick={e => setAvailabilityIOSIcon(null)}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Availability IOS Link</Label>
              <Input
                id="itemnameen"
                name="itemnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={availabilityIOSLink}
                onChange={e => setAvailabilityIOSLink(e.target.value)}
              />
            </div>
          </Col>

          
        </Row>
        <Row className="mt-3">
        <Col sm="6">
        <Row>
          <Col xs="12">
            <div className="">
              <Label htmlFor="productdesc">Availability Android Icon</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => handleAvailabilityAndroidIcon(e, e.target.files)}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {availabilityAndroidIcon ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={availabilityAndroidIcon.name}
                          src={availabilityAndroidIcon.preview}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {availabilityAndroidIcon.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e => prevSelectImage(e, availabilityAndroidIcon.preview)}
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            style={{ color: "#ff3838" }}
                            onClick={e => setAvailabilityAndroidIcon(null)}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Availability Android Link</Label>
              <Input
                id="itemnameen"
                name="itemnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={availabilityAndroidLink}
                onChange={e => setAvailabilityAndroidLink(e.target.value)}
              />
            </div>
          </Col>

          
        </Row>
        
        <Row>
          <Col xs="12">
            <div className="mt-3">
              <Label htmlFor="productdesc">Top Left Image</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => handleTopLeftImage(e, e.target.files)}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {topLeftImage ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={topLeftImage.name}
                          src={topLeftImage.preview}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {topLeftImage.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e => prevSelectImage(e, topLeftImage.preview)}
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            style={{ color: "#ff3838" }}
                            onClick={e => setTopLeftImage(null)}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <div className="mt-3">
              <Label htmlFor="productdesc">Top Right Image</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => handleTopRightImage(e, e.target.files)}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {topRightImage ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={topRightImage.name}
                          src={topRightImage.preview}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {topRightImage.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e => prevSelectImage(e, topRightImage.preview)}
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            style={{ color: "#ff3838" }}
                            onClick={e => setTopRightImage(null)}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <div className="mt-3">
              <Label htmlFor="productdesc">Bottom Left Image</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => handleBottomLeftImage(e, e.target.files)}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {bottomLeftImage ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={bottomLeftImage.name}
                          src={bottomLeftImage.preview}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {bottomLeftImage.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e => prevSelectImage(e, bottomLeftImage.preview)}
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            style={{ color: "#ff3838" }}
                            onClick={e => setBottomLeftImage(null)}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <div className="mt-3">
              <Label htmlFor="productdesc">Bottom Right Image</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => handleBottomRightImage(e, e.target.files)}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {bottomRightImage ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={bottomRightImage.name}
                          src={bottomRightImage.preview}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {bottomRightImage.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e => prevSelectImage(e, bottomRightImage.preview)}
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            style={{ color: "#ff3838" }}
                            onClick={e => setBottomRightImage(null)}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <div className="mt-3">
              <Label htmlFor="productdesc">Bottom Image</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => handleBottomImage(e, e.target.files)}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {bottomImage ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={bottomImage.name}
                          src={bottomImage.preview}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {bottomImage.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e => prevSelectImage(e, bottomImage.preview)}
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            style={{ color: "#ff3838" }}
                            onClick={e => setBottomImage(null)}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>
      </Form>
      <hr style={{ margin: "20px -20px 20px -20px" }} />
      
      <Row className="mt-2">
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0">
              <button onClick={e => submit(e)}>
                {active ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
    </div>
  )
}

export default HeaderContent

