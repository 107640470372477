import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import { withRouter, Link, useHistory } from "react-router-dom"
import * as Yup from "yup"
import { useFormik } from "formik"
import profile from "assets/images/profile-img.png"
import { useSelector, useDispatch } from "react-redux"
import { getUserData } from "../../../store/actions"
import ReactLoading from "react-loading"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"

const Login = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const mainData = useSelector(state => state.MainData.data)

  const [loading, setLoading] = useState(false)

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      setLoading(true)
      axiosConfig
        .post(`${process.env.REACT_APP_API}/dashboard/login`, values, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(response => {
          setLoading(false)
          var UID = JSON.stringify(response.data.data.UID)
          var UDeveloper = JSON.stringify(response.data.data.UDeveloper)
          sessionStorage.setItem("UID", UID)
          sessionStorage.setItem("UDeveloper", UDeveloper)
          localStorage.setItem("authUser", JSON.stringify(response.data.data))
          dispatch(getUserData(response.data.data))
          history.push("/dashboard")
        })
        .catch(error => {
          toast.error(error.response.data.message)
          setLoading(false)
        })
    },
  })

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  return (
    <React.Fragment>
      <MetaTags>
        <title>Layouti Design | Login</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="" style={{ background: "var(--main-color)" }}>
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="" style={{ color: "#fff" }}>
                          Welcome Back !
                        </h5>
                        <p style={{ color: "#fff" }}>
                          Sign in to continue to Layouti.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={mainData?.favIcon}
                            alt=""
                            className="rounded-circle "
                            width={"100%"}
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal border-0"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>
                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block d-flex justify-content-center"
                          type="submit"
                          style={login}
                        >
                          {loading ? (
                            <ReactLoading
                              type={"spin"}
                              color={"#ffffff"}
                              height={30}
                              width={30}
                            />
                          ) : (
                            "Log In"
                          )}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}

const login = {
  background: "var(--main-color)",
  color: "var(--text-color)",
  height: "45px",
  borderRadius: "0",
  fontSize: "16px",
  boxShadow: "none",
  border: "none",
}
