import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"

const Courses = () => {
  const [rows2, setrows2] = useState([{ id: 1 }])
  const [active, setActive] = useState(false)
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState(null)
  const [subTitleEn, setSubTitleEn] = useState("")
  const [subTitleAr, setSubTitleAr] = useState("")
  const [image, setImage] = useState(null)
  const [years,setYears] =useState([])
  const [currentWork , setCurrentWork] =useState(-1)

  

  let multiDataObjectModal=(itemId)=>{
    return {
            id: itemId,
            titleEn: '',
            titleAr: '',
            corseDate: '',

    }
  }


  const [multiData, setMultiData] = useState([multiDataObjectModal(1)])


  function handleAddRowNested() {
    const modifiedRows = [...rows2]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows2(modifiedRows)

    let modifyData = [...multiData]
    modifyData.push(multiDataObjectModal(modifiedRows.length))
    setMultiData(modifyData)
  }

  function handleRemoveRow(id) {
    if (id !== 0) {
      setrows2(r => r.filter((x,i) => i !== id))
      setMultiData(d => d.filter((x,i) => i !== id))
    }
  }

  function pointsChange (e, key, type) {
    let modifyData = [...multiData]
    modifyData[key][type] =e.target.value
    setMultiData(modifyData)
  }

  let changeCurrentWork=(e,key)=>{
    let id = e.target.checked==true ? key : -1
    setCurrentWork(id)
  }

  function submit(e) {
    e.preventDefault()
    const formData = new FormData()
    const data = {
      positionEn,
      positionAr,
      positionCountry,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    formData.append("image", image)
    multiData.forEach((point, i) => {
      formData.append(`point[${i}][positionEn]`, point.positionEn)
      formData.append(`point[${i}][positionAr]`, point.positionAr)
      formData.append(`point[${i}][positionCountry]`, point.positionCountry)
    })
    axios.post(`${process.env.REACT_APP_API}/admin/`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).authToken
        }`,
      },
    })
  }

  let makeListOfYears=()=>{
    let list=[]
    let currentYear = new Date().getFullYear();    
    let earliestYear = 1970;     
    while (currentYear >= earliestYear) {      
      list.push({text:currentYear,value:currentYear})
      currentYear -= 1;    
    }
    setYears(list)
  }

  useEffect(()=>{
    makeListOfYears()
  },[])
  return (
    <div>
      {isOpen ? (
        <Lightbox
          mainSrc={prev}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}

        <Form className="border-0">
          {(rows2 || []).map((formRow, key) => (
            <Fragment key={'row'+key}>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Title (English)
                    </Label>
                    <Input
                      id="interestEn"
                      name="interestEn"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Position title"
                      value={multiData[key].interestEn}
                      onChange={e => pointsChange(e, key, "interestEn")}
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                    Title (Arabic)
                    </Label>
                    <Input
                      id="interestEn"
                      name="interestEn"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={multiData[key].interestEn}
                      onChange={e => pointsChange(e, key, "interestEn")}
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div>
                    <Label htmlFor="productdesc">
                      Date
                    </Label>
                    <select 
                    className="form-select"
                    name="corseDate"
                    value={multiData[key].corseDate}
                    onChange={e => pointsChange(e, key, "corseDate")}
                    >
                      {
                        years && years.map(year=>(
                          <option value={year.value} key={'start' +year.value}>{year.value}</option>
                        ))
                      }
                    </select>
                  </div>
                </Col>

              </Row>
            
          {/* <hr style={{ margin: "20px -20px 20px -20px" }} /> */}

              {key !== 0 ? (
                <Button
                  color="none"
                  variant="primary"
                  className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                  id="unknown-btn"
                  style={{
                    color: "#ff3838",
                    boxShadow: "none",
                    height: "45px",
                    borderRadius: 0,
                  }}
                  onClick={e => {
                    handleRemoveRow(key)
                  }}
                >
                  <i className="bx bx-trash-alt fs-4 p-0 mr-1" /> Delete Course
                </Button>

              ) : null}
               {
                    key!=0?
                    <hr style={{ margin: "5px -20px 20px -20px" }} />
                    :
                    <hr style={{ margin: "25px -20px 20px -20px" }} />
                }
            </Fragment>
          ))}

          <Button
            onClick={() => {
              handleAddRowNested()
            }}
            color="none"
            className=" fs-5 fw-bold p-0 text-left"
            style={btnStyle}
          >
            <i className="bx bx-plus-circle fs-4" /> <span>Add Course</span>
          </Button>
          <hr style={{ margin: "20px -20px 20px -20px" }} />
        </Form>
      <Row>
        <Col className="d-flex justify-content-end gap-2">
          <div className="save__btn top m-0">
            <button>
              {active ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Courses

const cardStyle = {
  borderTop: "2px solid #dedede",
}

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
