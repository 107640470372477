/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Form, Label, Input, } from "reactstrap"
import {Link} from "react-router-dom"
import './specification.scss'
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert"
import ReactLoading from "react-loading";
import prettyBytes from "pretty-bytes";
import axiosConfig from "axiosConfig";

const Design365 = ({sectionData}) => {
        const [active, setActive] = useState(false)
        const [msg, setMsg] = useState("")
        const [success_msg, setsuccess_msg] = useState(false)
        const [isLoading, setIsLoading] = useState(false)
        const [lgImage, setLgImage] = useState(null)
        const [mobileImage, setMobileLgImage] = useState(null)
        const [isOpen, setisOpen] = useState(false);
        const [titleEn, setTitleEn] = useState('')
        const [titleAr, setTitleAr] = useState('')
        const [descEn, setDescEn] = useState('')
        const [descAr, setDescAr] = useState('')
        const [smallDescEn, setSmallDescEn] = useState('')
        const [smallDescAr, setSmallDescAr] = useState('')

        function handleThumbFiles(e, files) {
            [...files].map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: prettyBytes(file.size)
                })
            )
            setLgImage(Object.values(files)[0])
            e.target.value = '';
        }

        function handleMobileFiles(e, files) {
            [...files].map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: prettyBytes(file.size)
                })
            )
            setMobileLgImage(Object.values(files)[0])
            e.target.value = '';
        }
    
    function submit(e) {
        e.preventDefault()
        let formData = new FormData();
        formData.append('titleEn',titleEn)
        formData.append('titleAr',titleAr)
        formData.append('descriptionEn',descEn)
        formData.append('descriptionAr',descAr)
        formData.append('smallDescriptionEn',smallDescEn)
        formData.append('smallDescriptionAr',smallDescAr)
        formData.append('image',lgImage)
        formData.append('mobileImage',mobileImage)
        setIsLoading(true)
        axiosConfig.post(`/dashboard/365Design/headerContent`, formData, {
            headers: {
                Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("authUser")).accessToken
                }`,
            },
        }).then(res=>{
             toast.success(res.data.message)
            setsuccess_msg(true)
            setIsLoading(false)
        }).catch(err=>{
            toast.error(err.response.data.message)
            setIsLoading(false)
        })
    }
    

    useEffect(() => {
            setTitleEn(sectionData?.titleEn)
            setTitleAr(sectionData?.titleAr)
            setDescEn(sectionData?.descriptionEn)
            setDescAr(sectionData?.descriptionAr)
            setSmallDescEn(sectionData?.smallDescriptionEn)
            setSmallDescAr(sectionData?.smallDescriptionAr)
            setLgImage(sectionData?.image)
            setMobileLgImage(sectionData?.mobileImage)
    }, [sectionData]) 

    return (
            <React.Fragment>
                <div className="container-fluid p-0">
                <Card>
                    <CardBody>
                    {success_msg ? (
                        <SweetAlert
                        title={msg}
                        success
                        confirmBtnBsStyle="success"
                        onConfirm={() => {
                            setsuccess_msg(false)
                        }}
                        onCancel={() => {
                            setsuccess_msg(false)
                        }}
                        >
                            </SweetAlert>
                        ) : null}
                    {isOpen ? (
                    <Lightbox
                        mainSrc={lgImage?.preview}
                        enableZoom={false}
                        onCloseRequest={() => {
                        setisOpen(!isOpen);
                        }}
                    />
                    ) : null}
                    
                    <Form className="border-0">
                        <Row>
                            <Col>
                                <Label>Title (english)</Label>
                                <Input 
                                    className="input_cus"
                                    placeholder="Title"
                                    value={titleEn}
                                    onChange={e => setTitleEn(e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Label>Title (arabic)</Label>
                                <Input 
                                    className="input_cus"
                                    placeholder="عنوان"
                                    dir="rtl"
                                    value={titleAr}
                                    onChange={e => setTitleAr(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <Label>description (english)</Label>
                                <textarea
                                    className="form-control  mb-0"
                                    rows="5"
                                    placeholder="Description"
                                    value={descEn}
                                    onChange={e => setDescEn(e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Label>description (arabic)</Label>
                                <textarea
                                    className="form-control  mb-0"
                                    rows="5"
                                    placeholder="وصف"
                                    value={descAr}
                                    onChange={e => setDescAr(e.target.value)}
                                    dir="rtl"
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <Label>small description (english)</Label>
                                <textarea
                                    className="form-control  mb-0"
                                    rows="5"
                                    placeholder="Description"
                                    value={smallDescEn}
                                    onChange={e => setSmallDescEn(e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Label>small description (arabic)</Label>
                                <textarea
                                    className="form-control  mb-0"
                                    rows="5"
                                    placeholder="وصف"
                                    value={smallDescAr}
                                    onChange={e => setSmallDescAr(e.target.value)}
                                    dir="rtl"
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs="12">
                                <div >
                                    <Label htmlFor="productdesc" >
                                        Cover Image
                                    </Label>
                                    <Input
                                        type="file"
                                        className="form-control form-control-lg "
                                        onChange={e => handleThumbFiles(e, e.target.files)}
                                        style={{borderRadius: 0}}
                                        multiple
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="dropzone-previews" id="file-previews">
                                    {lgImage?
                                        <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        >
                                            <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                <img
                                                    data-dz-thumbnail=""
                                                    height="80"
                                                    className="avatar-sm rounded bg-light"
                                                    alt={lgImage.name}
                                                    src={lgImage.preview?lgImage.preview:lgImage}
                                                    style={{ objectFit: "cover" }}
                                                />
                                                </Col>
                                                <Col>
                                                <Link to="#" className="text-muted ">
                                                    {lgImage.name}
                                                </Link>
                                                
                                                </Col>
                                                <Col>
                                                <div className="image__options">
                                                    <button
                                                    className="p-0"
                                                    type="text" 
                                                    onClick={e => (e.preventDefault(),setisOpen(true))}
                                                    >
                                                    <i className="far fa-eye  fs-5 p-0"></i>
                                                    </button>
                                                    <button
                                                    style={{ color: "#ff3838" }}
                                                    type="text" 
                                                    onClick={e => (e.preventDefault(),setLgImage(null))}
                                                    >
                                                    <i className="bx bx-trash fs-4"></i>
                                                    </button>
                                                </div>
                                                </Col>
                                            </Row>
                                            </div>
                                        </Card>:null
                                        
                                    }
                                    </div>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col xs="12">
                                <div >
                                    <Label htmlFor="productdesc" >
                                        Mobile Image
                                    </Label>
                                    <Input
                                        type="file"
                                        className="form-control form-control-lg "
                                        onChange={e => handleMobileFiles(e, e.target.files)}
                                        style={{borderRadius: 0}}
                                        multiple
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="dropzone-previews" id="file-previews">
                                    {mobileImage?
                                        <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        >
                                            <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                <img
                                                    data-dz-thumbnail=""
                                                    height="80"
                                                    className="avatar-sm rounded bg-light"
                                                    alt={mobileImage.name}
                                                    src={mobileImage.preview?mobileImage.preview:mobileImage}
                                                    style={{ objectFit: "cover" }}
                                                />
                                                </Col>
                                                <Col>
                                                <Link to="#" className="text-muted ">
                                                    {mobileImage.name}
                                                </Link>
                                                
                                                </Col>
                                                <Col>
                                                <div className="image__options">
                                                    <button
                                                    className="p-0"
                                                    type="text" 
                                                    onClick={e => (e.preventDefault(),setisOpen(true))}
                                                    >
                                                    <i className="far fa-eye  fs-5 p-0"></i>
                                                    </button>
                                                    <button
                                                    style={{ color: "#ff3838" }}
                                                    type="text" 
                                                    onClick={e => (e.preventDefault(),setLgImage(null))}
                                                    >
                                                    <i className="bx bx-trash fs-4"></i>
                                                    </button>
                                                </div>
                                                </Col>
                                            </Row>
                                            </div>
                                        </Card>:null
                                        
                                    }
                                    </div>
                            </Col>
                        </Row>

                        <hr style={{ margin: "25px -20px 20px -20px" }} />

                        <Row>
                            <Col className="d-flex justify-content-end gap-2">
                                <div className="save__btn top m-0">
                                <button type='button' onClick={submit} disabled={isLoading?true:false}>
                                    {isLoading ? (
                                    <ReactLoading
                                        type={"spin"}
                                        color={"#ffffff"}
                                        height={30}
                                        width={30}
                                    />
                                    ) : (
                                    "Save"
                                    )}
                                </button>
                                </div>
        
                            </Col>
                        </Row>
              </Form>
            </CardBody>
          </Card>      
        </div>
    </React.Fragment>
  )
}

export default Design365

const deleteBtn = {
  background: "transparent",
  border: "none",
  color:"#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  color:"green",
  display: "flex",
  alignItems: "center",
  gap: "5px"
}