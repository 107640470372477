/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import { useHistory } from "react-router-dom"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "./datatables.scss"
import "./specification.scss"
import axios from "axios"
import SweetAlert from "react-bootstrap-sweetalert"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { useSelector, useDispatch } from "react-redux"
import { setTitle, getProjects } from "../../../store/actions"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"

const Projects = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [active, setActive] = useState(false)
  const [addActive, setAddActive] = useState(false)
  const [productData, setProductData] = useState([])
  const [avatar, setAvatar] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState("")

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "image",
      text: "Image",
      sort: true,
    },
    {
      dataField: "nameEn",
      text: "Project Name (English)",
      sort: true,
    },
    {
      dataField: "category",
      text: "Category",
      sort: true,
    },
    {
      dataField: "createdByEn",
      text: "Created By (English)",
      sort: true,
    },
    {
      dataField: "availabilityEn",
      text: "Availability Programs (English)",
      sort: true,
    },
    {
      dataField: "likes",
      text: "# of Likes",
      sort: true,
    },
    {
      dataField: "viwers",
      text: "# of Viewers",
      sort: true,
    },
    {
      dataField: "downloads",
      text: "# of Downloads",
      sort: true,
    },
    {
      dataField: "clicks",
      text: "# of Get in Touch",
      sort: true,
    },
    {
      dataField: "date",
      text: "Date of Project",
      sort: true,
    },
    {
      dataField: "price",
      text: "Price",
      sort: true,
    },
    {
      dataField: "actions",
      text: "Actions",
      sort: true,
    },
    {
      dataField: "createdOn",
      text: "Created On",
      sort: true,
    },
  ]

  const pageOptions = {
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "75",
        value: 75,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "All",
        value: productData.length,
      },
    ],
  }

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const { SearchBar } = Search

  function setOpen(img) {
    setisOpen(true)
    setPrev(img)
  }

  function clickHandle(id) {
    if (id) {
      return history.push(`/365design/editproject/${id}`)
    }
    history.push(`/365design/addproject`)
  }

  function deleteAvatar(id) {
    axiosConfig
      .post(
        `/dashboard/365Design/projets/delete`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setProductData(products => products.filter(prod => prod.id != id))
        setMsg("Deleted!")
        setsuccess_msg(true)
      })
      .catch(err => {
        toast.error("Something went wrong")
      })
  }



  useEffect(() => {
    dispatch(setTitle("Projects"))
    axiosConfig
      .get(`/dashboard/365Design/projets?perPage=200&page=1`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setProductData(
          res.data.data.projcts.map(avatar => {
            return {
              ...avatar,
              image: (
                <img
                  src={avatar.image}
                  onClick={e => setOpen(e.target.src)}
                  className="usernametabl"
                  alt=""
                />
              ),
              nameEn: avatar.nameEn ? (
                avatar.nameEn
              ) : (
                <span className="fs-4">-</span>
              ),
              category: avatar.category ? (
                avatar.category.titleEn
              ) : (
                <span className="fs-4">-</span>
              ),
              createdByEn: avatar.ceatedByEn ? (
                avatar.ceatedByEn
              ) : (
                <span className="fs-4">-</span>
              ),
              availabilityEn: avatar.availabilityProgramsEn ? (
                avatar.availabilityProgramsEn
              ) : (
                <span className="fs-4">-</span>
              ),
              likes: avatar.likes ? avatar.likes : <span className="fs-4">-</span>,
              viwers: avatar.viwers ? avatar.viwers : <span className="fs-4">-</span>,
              downloads: avatar.downloads ? avatar.downloads : <span className="fs-4">-</span>,
              clicks: avatar.clicks ? avatar.clicks : <span className="fs-4">-</span>,
              date: avatar.date ? avatar?.date: <span className="fs-4">-</span>,
              price:
                avatar.state == "free" ? (
                  "free"
                ) : avatar.state != "free" ? (
                  `$ ${avatar.price} `
                ) : (
                  <span className="fs-4">-</span>
                ),
              actions: (
                <div className="d-flex">
                  <button
                    style={editBtn}
                    onClick={() => clickHandle(avatar.id)}
                  >
                    <i
                      className="bx bx-edit fs-4 pr-1"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Edit
                  </button>
                  <button
                    style={deleteBtn}
                    onClick={e => deleteAvatar(avatar.id)}
                  >
                    <i
                      className="bx bx-trash fs-4"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Delete
                  </button>
                </div>
              ),
              createdOn:avatar.createdOn ? avatar.createdOn : <span className="fs-4">-</span>,
            }
          })
        )
      }).catch(err => {
        toast.error("Something went wrong")
      })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>365 Design | Layouti</title>
        </MetaTags>
        <div className="container-fluid p-0">
          {success_msg ? (
            <SweetAlert
              title={msg}
              success
              confirmBtnBsStyle="success"
              onConfirm={() => {
                setsuccess_msg(false)
              }}
              onCancel={() => {
                setsuccess_msg(false)
              }}
            ></SweetAlert>
          ) : null}
          {isOpen ? (
            <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
                setisOpen(!isOpen)
              }}
            />
          ) : null}
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody className="pb-0">
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col>
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative ">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      className="input_cus"
                                    />
                                    <i className="bx bx-search-alt search-icon mt-1 fs-3" />
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className="save__btn top">
                                  <button onClick={() => clickHandle(null)}>
                                    <i className="bx bx-plus-circle fs-4"></i>{" "}
                                    Add Project
                                  </button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                  {productData.length === 0 ? (
                                    <div
                                      className="emptyStateTable"
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        padding: "10px",
                                        paddingTop: "0",
                                        paddingBottom: "20px",
                                      }}
                                    >
                                      no data available in table
                                    </div>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>

                            {productData.length !== 0 ? (
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            ) : null}
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Projects

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}
