/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Table } from "reactstrap"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import { useHistory } from "react-router-dom"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "./datatables.scss"
import "./specification.scss"
import axios from "axios"
import SweetAlert from "react-bootstrap-sweetalert"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { useSelector, useDispatch } from "react-redux"
import { setTitle, getProjects } from "../../../store/actions"
import axiosConfig from "axiosConfig"
import { toast,  } from "react-toastify"
import ReactDragListView from "react-drag-listview/lib/index.js"
import Toggle from 'rsuite/Toggle';
import 'rsuite/dist/rsuite.min.css';

const Products = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [active, setActive] = useState(false)
  const [addActive, setAddActive] = useState(false)
  const [productData, setProductData] = useState([])
  const [pagination, setPagination] = useState([])
  const [pageNo, setPageNo] = useState(1)

  const [avatar, setAvatar] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState("")

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "img",
      text: "Image",
      sort: true,
    },
    {
      dataField: "projectName",
      text: "Project Name",
      sort: true,
    },
    {
      dataField: "templateDesign",
      text: "Template Design",
      sort: true,
    },
    {
      dataField: "launchProject",
      text: "Launch Project",
      sort: true,
    },
    {
      dataField: "category",
      text: "Category",
      sort: true,
    },
    {
      dataField: "scope",
      text: "Scope",
      sort: true,
    },
    {
      dataField: "color",
      text: "Color",
      sort: true,
    },
    {
      dataField: "Viewers",
      text: "Viewers",
      sort: true,
    },
    {
      dataField: "actions",
      text: "Actions",
      sort: true,
    },
    {
      dataField: "created_at",
      text: "Created on",
      sort: true,
    },
  ]

  const pageOptions = {
    totalSize: pagination.total, // replace later with size(customers),
    custom: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "75",
        value: 75,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "All",
        value: pagination.total,
      },
    ],
  }

  // Select All Button operation
  const selectRow = {
    mode: "",
  }

  const { SearchBar } = Search

  function setOpen(img) {
    setisOpen(true)
    setPrev(img)
  }

  function clickHandle(id) {
    if (id) {
      return history.push(`/layouti/addProduct?id=${id}`)
    }
    history.push(`/layouti/addProduct`)
  }

  function deleteAvatar(id) {
    axios
      .post(
        `${process.env.REACT_APP_API}/admin/projects/delete`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).authToken
            }`,
          },
        }
      )
      .then(res => {})
    setProductData(products => products.filter(prod => prod.id != id))
    setMsg("Deleted!")
    setsuccess_msg(true)
  }
  function deleteProject(id) {
    axiosConfig.post(`/dashboard/product/delete`,{ id },{
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }).then(res=>{
          if(res?.response?.data){
          toast.error(err.response.data.message)
        }else{
          
          setMsg(`Deleted Successfully`)
          setsuccess_msg(true)
          setProductData(products => products.filter(prod => prod.id != id))
          getData()
          }
        }).catch(err=>{
          toast.error(err.response.data.message)
        })
  }
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const formData = new FormData()
      const modifiedData = [...productData]
      const item = modifiedData.splice(fromIndex, 1)[0]
      modifiedData.splice(toIndex, 0, item)
      setProductData(modifiedData)

      modifiedData.forEach((prod,i) => {
        formData.append(`ids[${i}]`, prod.id)
      })

      axios
      .post(
        `${process.env.REACT_APP_API}/dashboard/product/arrange`, formData,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        toast.success(res.data.message, {position: "top-right", theme:"light"})
      })
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  }


  const ShowItem = (itemID,status) => {
    var resStatus = 0;
    status == true ?  resStatus = 1 : resStatus = 0; 
    axiosConfig.post(`${process.env.REACT_APP_API}/dashboard/product/generalInformation/show`, {
      'id' : itemID,
      'status' : resStatus
    }, {
      headers: { 
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`
      }
    })
    .then(res => {
      getData()
    })
  }


  function getData(){
    axiosConfig.get(`/dashboard/product`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
      },
    })
    .then(res => {
      setProductData(
        res.data.data.map(project => {
          return {    
            ...project,
            show: <Toggle size="md" checked={project.status === 1?true:false} onChange={(value) => {ShowItem(project.id,value)}}/>,
            img: <img src={project?.thumbnailImage}style={imageStyle} alt=""/>,
            projectName: project?.ProjectName?.nameEn ? (project?.ProjectName?.nameEn) : (<span className="fs-4">-</span>),
            launchProject: project?.launch ? (project?.launch) : (<span className="fs-4">-</span>),
            templateDesign: project?.template == 2? "Branding":"Design App",
            scope: project?.ScopeOfWork?.scope?.titleEn||(<span className="fs-4">-</span>),
            category: project?.Category? project?.Category.map(cat => <span key={cat.id} style={{backgroundColor: 'rgb(216 216 216)',display: "block",
            color: 'rgb(0, 0, 0)',
            borderRadius: '45px',
            padding: '5px 20px',
            margin: '0px 2px',
            marginBottom: "5px",
            display: 'inline-block'}}>{cat.nameEn}</span>) : (<span className="fs-4">-</span>),
            color: project?.color ? (<span className={`me-2`} style={{backgroundColor: project?.color, width: '30px',
              height: '30px',
              borderRadius: '50%',
              border: '1px solid #bdbdbd',
              display: 'inline-block'}}></span>) 
              : (<span className="fs-4">-</span>),
              created_at: project?.created_at ? ( project?.created_at) : (<span className="fs-4">-</span>),
              actions: (
              <div className="d-flex">
                <button
                  style={editBtn}
                  onClick={() => clickHandle(project.id)}
                >
                  <i
                    className="bx bx-edit fs-4 pr-1"
                    style={{ marginRight: "10px" }}
                  ></i>{" "}
                  Edit
                </button>
                <button
                  style={deleteBtn}
                  onClick={e => deleteProject(project.id)}
                >
                  <i
                    className="bx bx-trash fs-4"
                    style={{ marginRight: "10px" }}
                  ></i>{" "}
                  Delete
                </button>
              </div>
            ),
          }
        })
      )
    })
  }
  useEffect(() => {
    dispatch(setTitle("Project"))
  }, [])
  const onPageHandler =  (type, { page }) => {
    setPageNo(page)
  }
  
  useEffect(() => {
    getData()
  }, [pageNo])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Project | Layouti</title>
        </MetaTags>
        <div className="container-fluid p-0">
          {success_msg ? (
            <SweetAlert
              title={msg}
              success
              confirmBtnBsStyle="success"
              onConfirm={() => {
                setsuccess_msg(false)
              }}
              onCancel={() => {
                setsuccess_msg(false)
              }}
            ></SweetAlert>
          ) : null}
          {isOpen ? (
            <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
                setisOpen(!isOpen)
              }}
            />
          ) : null}
          
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody className="pb-0">
                <Row>
                  <div className="save__btn top mb-4">
                    <button onClick={() =>  history.push('/layouti/addProduct')}>
                      <i className="bx bx-plus-circle fs-4" /> Add Project
                    </button>
                  </div>
                </Row>
                <div className="table-responsive">
                  <ReactDragListView {...dragProps} enableScroll={true}>
                    <Table className="table  mb-0">
                      <thead>
                        <tr>
                          <th style={tdStyle}>ID</th>
                          <th style={tdStyle}>Show</th>
                          <th style={tdStyle}>Image</th>
                          <th style={tdStyle}>Project Name</th>
                          <th style={tdStyle}>Template Design</th>
                          <th style={tdStyle}>Launch Project</th>
                          <th style={tdStyle}>Category</th>
                          <th style={tdStyle}>Scope</th>
                          <th style={tdStyle}>Color</th>
                          <th style={tdStyle}>Viewers</th>
                          <th style={tdStyle}>ِActions</th>
                        </tr>
                      </thead>
                      <tbody className="table-responsive">
                        {(productData || [])?.map((item, index) => (
                          <tr key={index}>
                            <td style={tdStyle}>{item.id}</td>
                            <td style={tdStyle}>{item.show}</td>
                            <td style={tdStyle}>{item.img}</td>
                            <td style={tdStyle}>{item.projectName}</td>
                            <td style={tdStyle}>{item.templateDesign}</td>
                            <td style={tdStyle}>{item.launchProject}</td>
                            <td style={tdStyle}>{item.category}</td>
                            <td style={tdStyle}>{item.scope}</td>
                            <td style={tdStyle}>{item.color}</td>
                            <td style={tdStyle}>{item.Viewers}</td>
                            <td style={tdStyle}>{item.actions}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </ReactDragListView>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Products

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const imageStyle = { 
  width: "60px",
  height: "60px",
  display: "block",
  borderRadius: "50%"
}
const tdStyle = { 
  whiteSpace: "nowrap",
  verticalAlign: "middle"
}