import React, { useLayoutEffect, useState } from "react"
import { Row, Col, Card, CardBody, Form, Label, Input } from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import prettyBytes from "pretty-bytes"
import Lightbox from "react-image-lightbox"
import axios from "axios"
import { toast } from "react-toastify"

const LayoutiDetails = () => {
  const [active, setActive] = useState(false)
  const [prev, setPrev] = useState(null)
  const [isOpen, setisOpen] = useState(false)

  const [data, setData] = useState({
    descriptionEn: "",
    descriptionAr: "",
    titleEn: "",
    titleAr: "",
    image: "",
    bg_image: "",
  })

  function prevSelectImage(e, img) {
    e.preventDefault()
    setPrev(img)
    setisOpen(true)
  }

  function submit() {
    let formData = new FormData()
    setActive(true)

    for (let key in data) {
      formData.append(key, data[key])
    }

    axios
      .post(`${process.env.REACT_APP_API}/dashboard/newLink/header`, formData, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setActive(false)
        toast.success(res.data.message)
        getData()
      })
  }

  let getData = () => {
    axios
      .get(`${process.env.REACT_APP_API}/dashboard/newLink/header`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setData(res.data.data)
      })
  }

  useLayoutEffect(() => {
    getData()
  }, [])

  return (
    <Card>
      <CardBody>
        <div>
          {isOpen ? (
            <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
                setisOpen(!isOpen)
              }}
            />
          ) : null}
          <Form className="border-0 pb-2">
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Title (English)</Label>
                  <Input
                    id="brandnameen"
                    name="titleEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Title"
                    value={data.titleEn}
                    onChange={e =>
                      setData({ ...data, [e.target.name]: e.target.value })
                    }
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">title (Arabic)</Label>
                  <Input
                    id="brandnamear"
                    name="titleAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. عنوان"
                    value={data.titleAr}
                    onChange={e =>
                      setData({ ...data, [e.target.name]: e.target.value })
                    }
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (English)</Label>
                  <Input
                    id="brandnameen"
                    name="descriptionEn"
                    type="textarea"
                    className="form-control"
                    placeholder="e.g. Title"
                    value={data.descriptionEn}
                    onChange={e =>
                      setData({ ...data, [e.target.name]: e.target.value })
                    }
                    rows="6"
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (Arabic)</Label>
                  <Input
                    id="brandnamear"
                    name="descriptionAr"
                    type="textarea"
                    className="form-control"
                    placeholder="مثال. عنوان"
                    value={data.descriptionAr}
                    onChange={e =>
                      setData({ ...data, [e.target.name]: e.target.value })
                    }
                    dir="rtl"
                    rows="6"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="12">
                <div className="">
                  <Label htmlFor="productdesc">Image</Label>
                  <Input
                    type="file"
                    className="form-control form-control-lg "
                    onChange={e =>
                      setData({
                        ...data,
                        ["image"]: Object.assign(e.target.files[0], {
                          preview: URL.createObjectURL(e.target.files[0]),
                          formattedSize: prettyBytes(e.target.files[0].size),
                        }),
                      })
                    }
                    style={{ borderRadius: 0 }}
                    multiple
                  />
                </div>
              </Col>
              <Col xs="12 ">
                <div className="dropzone-previews" id="file-previews">
                  {data.image ? (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={data.image.name}
                              src={data.image.preview || data.image}
                              style={{ objectFit: "cover" }}
                            />
                          </Col>
                          <Col>
                            <Link to="#" className="text-muted ">
                              {data.image.name}
                            </Link>
                          </Col>
                          <Col>
                            <div className="image__options">
                              <button
                                className="p-0"
                                onClick={e =>
                                  prevSelectImage(
                                    e,
                                    data.image.preview || data.image
                                  )
                                }
                              >
                                <i className="far fa-eye  fs-5 p-0"></i>
                              </button>
                              <button
                                type="button"
                                style={{ color: "#ff3838" }}
                                onClick={e => setData({ ...data, image: null })}
                              >
                                <i className="bx bx-trash fs-4"></i>
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="12">
                <div className="">
                  <Label htmlFor="productdesc">Background Image</Label>
                  <Input
                    type="file"
                    className="form-control form-control-lg "
                    onChange={e =>
                      setData({
                        ...data,
                        ["bg_image"]: Object.assign(e.target.files[0], {
                          preview: URL.createObjectURL(e.target.files[0]),
                          formattedSize: prettyBytes(e.target.files[0].size),
                        }),
                      })
                    }
                    style={{ borderRadius: 0 }}
                    multiple
                  />
                </div>
              </Col>
              <Col xs="12 mb-3">
                <div className="dropzone-previews" id="file-previews">
                  {data.bg_image ? (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={data.bg_image.name}
                              src={data.bg_image.preview || data.bg_image}
                              style={{ objectFit: "cover" }}
                            />
                          </Col>
                          <Col>
                            <Link to="#" className="text-muted ">
                              {data.bg_image.name}
                            </Link>
                          </Col>
                          <Col>
                            <div className="image__options">
                              <button
                                className="p-0"
                                onClick={e =>
                                  prevSelectImage(
                                    e,
                                    data.bg_image.preview || data.bg_image
                                  )
                                }
                              >
                                <i className="far fa-eye  fs-5 p-0"></i>
                              </button>
                              <button
                                type="button"
                                style={{ color: "#ff3838" }}
                                onClick={e =>
                                  setData({ ...data, bg_image: null })
                                }
                              >
                                <i className="bx bx-trash fs-4"></i>
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  ) : null}
                </div>
              </Col>
            </Row>

            <hr style={{ margin: "20px -20px 20px -20px" }} />

            <Row>
              <Col className="d-flex justify-content-end gap-2">
                <div className="save__btn top m-0 ">
                  <button type="button" onClick={e => submit()}>
                    {active ? (
                      <ReactLoading
                        type={"spin"}
                        color={"#ffffff"}
                        height={30}
                        width={30}
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </CardBody>
    </Card>
  )
}

export default LayoutiDetails
