import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect, useDispatch, useSelector } from "react-redux"
import { authProtectedRoutes, publicRoutes } from "./routes"
import Authmiddleware from "./routes/route"
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import "./assets/scss/theme.scss"
import fakeBackend from "./helpers/AuthType/fakeBackend"
import axios from "axios"
import { getMainData } from "store/actions"
import { MetaTags } from "react-meta-tags"

fakeBackend()

const App = props => {
  const dispatch = useDispatch()
  const mainData = useSelector(state => state.MainData.data)
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  useEffect(() => {
    axios
      .get(
        "https://services.layouti.com/api/dashboard/configurations/dashboardSetting"
      )
      .then(res => {
        dispatch(getMainData(res.data.data))
        document.documentElement.style.setProperty(
          "--main-color",
          res.data.data.buttonColor
        )
        document.documentElement.style.setProperty(
          "--side-color",
          res.data.data.sideBarColor
        )
        document.documentElement.style.setProperty(
          "--text-color",
          res.data.data.textSideBarColor
        )
      })
  }, [])
  return (
    <React.Fragment>
      <MetaTags>
        <link rel="icon" href={mainData?.favIcon} />
      </MetaTags>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
