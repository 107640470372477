import React, { useEffect, useState } from "react"
import { Row, Col, Card, Form, Label } from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import prettyBytes from "pretty-bytes"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"
const HeaderContent = ({ sectionData }) => {
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")
  const [image, setImage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState(null)

  function handleCoverImage(files, e) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    setImage(files[0])
    e.target.value = null
  }

  function prevSelectImage(e, prev) {
    e.preventDefault()
    setPrev(prev)
    setisOpen(!isOpen)
  }

  function submit() {
    const formData = new FormData()
    const data = {
      titleEn,
      titleAr,
      descriptionEn: descEn,
      descriptionAr: descAr,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    formData.append("image", image)
    setIsLoading(true)
    axiosConfig
      .post(`/dashboard/homePage/headerContent`, formData, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        toast.success(res.data.message)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
        toast.success(err.response.data.message)
      })
  }

  useEffect(() => {
    setTitleEn(sectionData?.titleEn)
    setTitleAr(sectionData?.titleAr)
    setDescEn(sectionData?.descriptionEn)
    setDescAr(sectionData?.descriptionAr)
    setImage(sectionData?.image)
  }, [sectionData])
  return (
    <div>
      {isOpen ? (
        <Lightbox
          mainSrc={prev}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
      <Form className="border-0 pb-2">
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (english)</Label>
              <input
                id="itemnameen"
                name="itemnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={titleEn}
                onChange={e => setTitleEn(e.target.value)}
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (arabic)</Label>
              <input
                id="itemnamear"
                name="itemnamear"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. "
                value={titleAr}
                onChange={e => setTitleAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (English)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="Enter your description here"
                value={descEn}
                onChange={e => setDescEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (Arabic)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="اكتب وصف هنا"
                value={descAr}
                onChange={e => setDescAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <div className="mt-3">
              <Label htmlFor="productdesc">Cover Image</Label>
              <input
                type="file"
                className="form-control form-control-lg "
                onChange={e => {
                  handleCoverImage(e.target.files, e)
                }}
                style={{ borderRadius: 0 }}
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {image && (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={image.name}
                          src={image.preview ? image.preview : image}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {image.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            type="button"
                            onClick={e =>
                              prevSelectImage(
                                e,
                                image.preview ? image.preview : image
                              )
                            }
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            style={{ color: "#ff3838" }}
                            onClick={() => setImage(null)}
                            type="button"
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              )}
            </div>
          </Col>
        </Row>
      </Form>
      <hr style={{ margin: "20px -20px 20px -20px" }} />
      <Row className="mt-2">
        <Col className="d-flex justify-content-end gap-2">
          <div className="save__btn top m-0">
            <button
              type="button"
              onClick={submit}
              disabled={isLoading ? true : false}
            >
              {isLoading ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default HeaderContent
