/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Collapse,
} from "reactstrap"
import "react-image-lightbox/style.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import classNames from "classnames"
// import HeaderContent from "../Components/ServicesPage/HeaderContent"
import LearnAboutLayouti from "../Components/ServicesPage/LearnAboutLayouti"
import ProcessTimeline from "../Components/ServicesPage/ProcessTimeline"
// import OurLatestWork from "../Components/ServicesPage/OurLatestWork"
import HeaderContent from "../Components/100ThingsPage/HeaderContent"
import OurLatestWork from "../Components/100ThingsPage/OurLatestWork"
import axiosConfig from "axiosConfig"

const Things100Page = () => {
  const [col, setCol] = useState([
    { id: 0, status: false },
    { id: 1, status: false },
    { id: 2, status: false },
    { id: 3, status: false },
    { id: 4, status: false },
    { id: 5, status: false },
    { id: 6, status: false },
    { id: 7, status: false },
  ])

  function ChangeColState(id) {
    setCol(col =>
      col.map(col => {
        if (col.id === id) {
          return { ...col, status: !col.status }
        }
        return { ...col, status: false }
      })
    )
  }
  const [pageData, setPageData] = useState([])
  function getData (){
    axiosConfig.get(`/dashboard/thingsPage`,{
      headers: {
          'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
      }
    }).then(res => {
        //
        setPageData(res.data.data)
    }).catch(err=>{
      //
    })
  }
  useEffect(()=>{
    getData()
  },[])

  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        <Card>
          <CardBody>
            <div className="accordion" id="accordion">
              
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classNames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[1].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(1)}
                    style={{ cursor: "pointer" }}
                  >
                    Header Content
                  </button>
                </h2>
                <Collapse isOpen={col[1].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <HeaderContent sectionData={pageData?.HeaderContent}/>
                  </div>
                </Collapse>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classNames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[4].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(4)}
                    style={{ cursor: "pointer" }}
                  >
                      Opportunity always exists
                  </button>
                </h2>
                <Collapse isOpen={col[4].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <OurLatestWork sectionData={pageData?.ThingsOpportunityAlwaysExists}/>
                  </div>
                </Collapse>
              </div>

            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default Things100Page

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "green",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}
