/* eslint-disable react/prop-types */
import axios from 'axios';
import React, {useState, useRef, useEffect} from 'react';
import { Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";
import "@pathofdev/react-tag-input/build/index.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import '../specification.scss'
import axiosConfig from 'axiosConfig';


const FemalePictureFrom = (props) => {
    const [isOpen, setisOpen] = useState(false);
    const [loading, setLoading] = useState(false)    
    const [file, setFile] = useState(null);
    
    const close = (type) => {
        document.querySelector('#imageForm').reset()
        props.clickHandle(null, false, type)
    }

    function handleAcceptedFiles(files) {
        [...files].map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )
        setFile(files)
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    function submitHandler() {
        setLoading(true)
        let formData = new FormData()
        let data = {
            gender: "male",
        }
        for ( let key in data ) {
            formData.append(key, data[key]);
        }
        let images = Object.values(file)
        images.forEach(f => {
            formData.append("Avatars[]", f);
        })
        
        axiosConfig.post(`/dashboard/uiAvatar/uiGenerator/femaleImages/import`, formData, {
            headers: {
              'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
            }
        })
        .then(res => {
            setLoading(false)
            if(res.data.error) {
                
                return;
            }
            setFile(null)
            close(true)
            props.update(res.data.data,"imagesfemale")
        })
    }

    return(
        <div className={`overlay_coup ${props.active? "active":""}`}>
            {isOpen ? (
            <Lightbox
                mainSrc={image.preview}
                enableZoom={false}
                onCloseRequest={() => {
                setisOpen(!isOpen);
                }}
            />
            ) : null}
            <Form id='imageForm' onSubmit={(e) => e.preventDefault()} style={{paddingBottom: "70px", height: "240px"}}>
                <div className='head_form'>
                    <div className='log'>
                    <i className='bx bx-images fs-3'></i>
                        Add Female Pictures
                    </div>
                    <button onClick={() => close(false)}><i className='dripicons dripicons-cross'></i></button>
                </div>
                <div className='inputs__wrap '>
                    <Row>
                        <Col>
                            <div className="mb-3" >
                                <Label htmlFor="productdesc">
                                File
                                </Label>
                                <input
                                    type="file"
                                    className="form-control form-control-lg "
                                    //value={file}
                                    onChange={e => handleAcceptedFiles(e.target.files)}
                                    style={{borderRadius: 0}}
                                    multiple
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='foot_form'>
                    <button className='cancel' onClick={() => close(false)}>Cancel</button>
                    <button className='save' onClick={submitHandler}>{loading?<PulseLoader color={"#ffffff"} loading={true}  size={10} />:"Send"}</button>
                </div>
            </Form>
        </div>
    );
};

export default FemalePictureFrom;
