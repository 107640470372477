import axios from "axios"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap"
import ReactLoading from "react-loading"
import { toast } from "react-toastify"
const BrandingForm = ({
  data, ChangeHandler, handleAcceptedImage, setImgSrc,setisOpen, PID, getData
}) => {
  const [isLoading, setIsLoading] = useState(false)

  function handleAddRowNested(type) {
    type === "card"&&ChangeHandler({...data, cards: data.cards.concat({titleEn: "", titleAr:"",descriptionEn:"",descriptionAr:""})})
    type === "image"&&ChangeHandler({...data, images: data.images.concat({images:null})})
  }

  function deleteCard(indx, type, id) {
    if(type === "card") {
      ChangeHandler({...data, cards: data.cards.filter((c,i) => i !== indx)})
      axios.post(`${process.env.REACT_APP_API}/dashboard/product/branding/deleteCard`,{id},{
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      }).then(res => {
        toast.success(res.data.message, {position: "top-right"})
        getData(PID)
      })
    }
    if(type === "image") {
      ChangeHandler({...data, images: data.images.filter((c,i) => i !== indx)})
      axios.post(`${process.env.REACT_APP_API}/dashboard/product/branding/deleteImage`,{id},{
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      }).then(res => {
        toast.success(res.data.message, {position: "top-right"})
        getData(PID)
      })
    }
  }

  function deleteImageNested(indx) {
    ChangeHandler({...data, images: data.images.map((obj, i) => {
      if(i == indx) {
        return {image:null}
      }
      return obj
    })}) 
  }


  function informationsChangeNested(value, name,indx) {
    ChangeHandler({...data, cards: data.cards.map((obj, i) => {
      if(i == indx) {
        return {...obj, [name]:value}
      }
      return obj
    })})
  }
  function submit(e) {
    e.preventDefault()
    setIsLoading(true)
    const formData = new FormData();
    
   
    /*************Branding*************/ 
      data.cards.forEach((d,i) => {
        formData.append(`cards[${i}][titleEn]`, d.titleEn||"")
        formData.append(`cards[${i}][titleAr]`, d.titleAr||"")
        formData.append(`cards[${i}][descriptionEn]`, d.descriptionEn||"")
        formData.append(`cards[${i}][descriptionAr]`, d.descriptionAr||"")
      })
      data.images.forEach((d,i) => {
          formData.append(`Images[${i}][image]`, d.image)
      })
    /*************Branding*************/
    formData.append("PID", PID)


    /*************GeneralInformationData*************/
    axios.post(`${process.env.REACT_APP_API}/dashboard/product/branding/save`,formData,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
      toast.success(res.data.message, {position: "top-right"})
      setIsLoading(false)
      getData(PID)
    })
  }
  return (
    <Card>
      <CardBody>
      {data.cards.map((card, indx) => (
          <div key={indx}>
            <Row className="mt-3">
              <Col>
                <div className="d-flex">
                  <Label>Title (English)</Label>
                  <label className="ms-auto optional-label--color">
                    (Optional)
                  </label>
                </div>
                <Input
                  className="input_cus"
                  placeholder="Label"
                  name="titleEn"
                  value={card["titleEn"]}
                  onChange={e =>
                    informationsChangeNested(e.target.value, e.target.name,indx)
                  }
                />
              </Col>
              <Col>
                <div className="d-flex">
                  <Label>Title (Arabic)</Label>
                  <label className="ms-auto optional-label--color">
                    (Optional)
                  </label>
                </div>
                <Input
                  className="input_cus"
                  placeholder="عنوان"
                  dir="rtl"
                  name="titleAr"
                  value={card["titleAr"]}
                  onChange={e =>
                    informationsChangeNested(e.target.value, e.target.name,indx)
                  }
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <div className="d-flex">
                  <Label>Description (English)</Label>
                  <label className="ms-auto optional-label--color">
                    (Optional)
                  </label>
                </div>
                <textarea
                  className="form-control  mb-0"
                  rows="5"
                  placeholder="Description"
                  name="descriptionEn"
                  value={card["descriptionEn"]}
                  onChange={e =>
                    informationsChangeNested(e.target.value, e.target.name,indx)
                  }
                />
              </Col>
              <Col>
                <div className="d-flex">
                  <Label>Description (Arabic)</Label>
                  <label className="ms-auto optional-label--color">
                    (Optional)
                  </label>
                </div>
                <textarea
                  className="form-control  mb-0"
                  rows="5"
                  placeholder="وصف"
                  name="descriptionAr"
                  value={card["descriptionAr"]}
                  onChange={e =>
                    informationsChangeNested(e.target.value, e.target.name,indx)
                  }
                  dir="rtl"
                />
              </Col>
            </Row>
           <Button
              color="none"
              variant="primary"
              className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
              id="unknown-btn"
              style={{
                color: "#ff3838",
                boxShadow: "none",
                height: "45px",
                borderRadius: 0,
              }}
              onClick={e => {
                deleteCard(indx, "card", card.id)
              }}
            >
              <i className="bx bx-trash-alt fs-4 p-0 mr-1"></i> Delete
            </Button>
           
                <hr style={{ margin: "5px -20px 20px -20px" }} />
          </div>
        ))}
        <Button
          onClick={() => handleAddRowNested("card")}
          color="none"
          className="mt-2 fs-5 fw-bold p-0 text-left"
          style={btnStyle}
        >
          <i className="bx bx-plus-circle fs-4"></i> <span>Add Card</span>
        </Button>
        <hr style={{ margin: "20px -20px 20px -20px" }} />

        {data.images.map((card, indx) => (
          <div key={indx}>
            <Row className="mt-3">
              <Col xs="12">
                <div>
                  <div className="d-flex">
                    <Label>Image</Label>
                    <label className="ms-auto optional-label--color">
                      (Optional)
                    </label>
                  </div>
                  <Input
                    type="file"
                    name="image"
                    className="form-control form-control-lg "
                    onChange={e =>
                      handleAcceptedImage(e.target.files, "branding", e, indx)
                    }
                    style={{ borderRadius: 0 }}
                    multiple
                  />
                </div>
              </Col>
              <Col>
                <div className="dropzone-previews" id="file-previews">
                  {card["image"] ? (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={card["image"]?.name}
                              src={
                                card["image"]?.preview
                                  ? card["image"]?.preview
                                  : card["image"]
                              }
                              style={{ objectFit: "cover" }}
                            />
                          </Col>
                          <Col>
                            <Link to="#" className="text-muted ">
                              {card["image"]?.name}
                            </Link>
                          </Col>
                          <Col>
                            <div className="image__options">
                              <button
                                type="button"
                                className="p-0"
                                onClick={e =>
                                  (setImgSrc(card["image"].preview), setisOpen(true))
                                }
                              >
                                <i className="far fa-eye  fs-5 p-0"></i>
                              </button>
                              <button
                                type="button"
                                style={{ color: "#ff3838" }}
                                onClick={e =>
                                  deleteImageNested(
                                    indx
                                  )
                                }
                              >
                         
                                <i className="bx bx-trash fs-4"></i>
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  ) : null}
                </div>
              </Col>
            </Row>

          <Button
              color="none"
              variant="primary"
              className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
              id="unknown-btn"
              style={{
                color: "#ff3838",
                boxShadow: "none",
                height: "45px",
                borderRadius: 0,
              }}
              onClick={e => {
                deleteCard(indx,"image", card.image.id)
              }}
            >
              <i className="bx bx-trash-alt fs-4 p-0 mr-1"></i> Delete
            </Button>
            
                <hr style={{ margin: "5px -20px 20px -20px" }} />
             
          </div>
        ))}
        <Button
          onClick={() => handleAddRowNested("image")}
          color="none"
          className="mt-2 fs-5 fw-bold p-0 text-left"
          style={btnStyle}
        >
          <i className="bx bx-plus-circle fs-4"></i> <span>Add Image</span>
        </Button>

        <hr style={{ margin: "20px -20px 20px -20px" }} />
        <Row>
          <Col className="d-flex justify-content-end">
            <button
              type="button"
              className="add-project__submit-button"
              onClick={e => submit(e)}
            >
              {isLoading ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </Col>
        </Row>   
      </CardBody>
    </Card>
  )
}

export default BrandingForm

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
