import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import classnames from "classnames";
import {
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import Sticky from 'react-sticky-el';
import './configration.scss'
import axios from "axios";
import { useSelector, useDispatch } from "react-redux"
import {
    setTitle
} from "../../../store/actions";
import { useHistory } from "react-router-dom";
import SeoComponent from "../SeoComponent";


//pages import

const UiSeoPages = () => {
    const dispatch = useDispatch()
    const history  = useHistory()
    const user = useSelector(state => state.UserData.user)
    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };
    const [data, setData] = useState(null) 


    useEffect(() => {
        if(user && user.role == 2) {
            history.push('/dashboard')
            return;
        } 
    }, [user])

  
    useEffect(() => {   
        dispatch(setTitle("UI Avatar SEO"))
        axios.get(`${process.env.REACT_APP_API}/dashboard/uiAvatar/seo`, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
            }
        })
        .then(res => {
            setData(res.data.data)
        }) 
    }, [])

    return (
        <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>Pages | Layouti</title>
        </MetaTags>
        <Container fluid>
        <Col>
            <Row>
            <Col md="3">
            <Sticky topOffset={-300} >
                <Nav pills className="flex-column justify-content-between navs_aside">

                    <NavItem  className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "1",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("1");
                        }}
                        >
                        Ui Avatar
                        </NavLink>
                    </NavItem>

                    <NavItem  className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "2",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("2");
                        }}
                        >
                        Content Generator
                        </NavLink>
                    </NavItem>
                </Nav>
                </Sticky>
            </Col>
            <Col md="9">
                <TabContent
                activeTab={verticalActiveTab}
                className="text-muted table__content mt-4 mt-md-0"
                >
                    <TabPane tabId="1">
                        <SeoComponent page="saveUiGenerator" data={data?.UiGenerator}/>
                    </TabPane>
                    <TabPane tabId="2" >
                        <SeoComponent page="saveContentGenerator"data={data?.ContentGenerator}/>
                    </TabPane>
                </TabContent>
            </Col>
            </Row>
        </Col>
        </Container>
        </div>
    </React.Fragment>
)
}

export default UiSeoPages



const navsStyle = { 
    cursor: "pointer", 
    borderRadius: 0,

}