import classNames from 'classnames';
import React, { useState } from 'react'
import { MetaTags } from 'react-meta-tags';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import Sticky from 'react-sticky-el';
import { Col, Container, Nav, NavItem, Row, TabContent, TabPane } from 'reactstrap';
import ReactLoading from "react-loading"
import GeneralInformationForm from './Components/GeneralInformationForm';
import Lightbox from 'react-image-lightbox';
import ProjectNameForm from './Components/ProjectNameForm';
import ProjectInformationForm from './Components/ProjectInformationForm';
import TeamMember from './Components/TeamMember';
import InDepthForm from './Components/InDepthForm';
import ScopeOfWorkForm from './Components/ScopeOfWorkForm';
import axios from 'axios';
import OurClientsForm from './Components/OurClientsForm';
import ThanksSectionForm from './Components/ThanksSectionForm';
import SeoForm from './Components/SeoForm';
import BrandingForm from './Components/BrandingForm';
import DesignAppForm from './Components/DesignAppForm';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import './style.scss'
const AddProject = () => {
    const history  = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };
    const [isOpen, setisOpen] = useState(false)
    const [imgSrc, setImgSrc] = useState('')
    const [PID, setPID] = useState(null)
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery();
    const itemId = query.get("id");



    //Handel Forms Data

    //General Information Data
    const [generalInformationData, setGeneralInformationData] = useState({
        category: "",
        designTemplate: "", 
        launchProject: "", 
        attachment:null, 
        thumbnail:null, 
        color: ""
    })

    //Project Name Data
    const [projectNameData, setProjectNameData] = useState({
        ProjectName_nameEn: "",
        ProjectName_nameAr: "", 
        ProjectName_slogenEn: "", 
        ProjectName_slogenAr: "", 
        ProjectName_descriptionEn: "",
        ProjectName_descriptionAr: ""
    })

    //Project Information Data
    const [projectInformationData, setProjectInformationData] = useState([{
        labelEn: "",
        labelAr: "", 
        titleEn: "", 
        titleAr: "", 
    }])
  
    
    //Team Members Data
    const [teamMemberData, setTeamMemberData] = useState([{
        labelEn: "",
        labelAr: "", 
        memberNameEn: "", 
        memberNameAr: "", 
    }])
    
    //InDepth Data
    const [inDepthData, setIndepthData] = useState({
        titleEn: "",
        titleAr: "",
        cards: [{image:null, category: "", headLineEn: "", headLineAr:"",descriptionEn:"",descriptionAr:""}]  
    })

    //Scope Of Work Data
    const [scopeOfWorkData , setScopeOfWorkData] = useState({
        ScopeOfWork_titleEn: "",
        ScopeOfWork_titleAr: "", 
        ScopeOfWork_descriptionEn: "", 
        ScopeOfWork_descriptionAr: "", 
        ScopeOfWork_category: "", 
    })


    //Scope Of Work Data
    const [ourClientsData , setOurClientsData] = useState({
        OurClients_userNameEn: "",
        OurClients_userNameAr: "", 
        OurClients_positionEn: "", 
        OurClients_positionAr: "", 
        OurClients_descriptionEn: "", 
        OurClients_descriptionAr: "", 
        OurClients_image: null, 
    })
    
    //Scope Of Work Data
    const [thanksSectionData , setThanksSectionData] = useState({
        ThanksSection_titleEn: "",
        ThanksSection_titleAr: "", 
        ThanksSection_descriptionEn: "", 
        ThanksSection_descriptionAr: "", 
        ThanksSection_buttonNameEn: "", 
        ThanksSection_buttonNameAr: "", 
        cards: [{labelEn:"",labelAr:"", textEn:"",textAr:""}], 
    })
    
      //SEO Data
      const [seoData , setSeoData] = useState({
        ProductSEO_seoTitleEn: "",
        ProductSEO_seoTitleAr: "", 
        ProductSEO_slugEn: "", 
        ProductSEO_slugAr: "", 
        ProductSEO_descriptionEn: "", 
        ProductSEO_descriptionAr: "", 
        ProductSEO_facebookTitleEn: "", 
        ProductSEO_facebookTitleAr: "", 
        ProductSEO_focusKeypharseEn:"",
        ProductSEO_focusKeypharseAr:"",
        ProductSEO_facebookDescriptionEn: "", 
        ProductSEO_facebookDescriptionAr: "", 
        ProductSEO_facebookImage: null, 
    })
    //Branding Data
    const [brandingData, setBrandingData] = useState({
        cards: [{titleEn: "", titleAr: "", descriptionEn:"", descriptionAr:""}],
        images: [{image:null}],
    })

    //Design App Data

    //Introducing
    const [introducingData, setIntroducingData] = useState({
        DesignAppIntorducing_titleEn: "",
        DesignAppIntorducing_titleAr: "",
        DesignAppIntorducing_descriptionEn: "",
        DesignAppIntorducing_descriptionAr: "",
    })
    
    //taskDescriptionData
    const [taskDescriptionData, setTaskDescriptionData] = useState({
        DesignAppTaskDescription_titleEn: "",
        DesignAppTaskDescription_titleAr: "",
        DesignAppTaskDescription_descriptionEn: "",
        DesignAppTaskDescription_descriptionAr: "",
    })

    //deliverablesData
    const [deliverablesData, setDeliverablesData] = useState({
        DesignAppDeliverables_titleEn: "",
        DesignAppDeliverables_titleAr: "",
        DesignAppDeliverables_deliverables: [],
        DesignAppDeliverables_colors: ""
    })

    //design app image image
    const [designAppImageImage, setDesignAppImageImage] = useState({DesignAppImage_image: null})


    // design App Project Info Data
    const [designAppProjectInfo, setDesignAppProjectInfo] = useState([{
        labelEn: "",
        labelAr: "", 
        textEn: "", 
        textAr: "", 
    }])
    

    //what Is Proj Data
     const [whatIsProjData, setWhatIsProjData] = useState({
        DesignAppWhatIsTheProject_titleEn: "",
        DesignAppWhatIsTheProject_titleAr: "",
        DesignAppWhatIsTheProject_descriptionEn: "",
        DesignAppWhatIsTheProject_descriptionAr: "",
    })

    //Challenges Data
     const [challengesData, setChallengesData] = useState({
        DesignAppChallenges_titleEn: "",
        DesignAppChallenges_titleAr: "",
        DesignAppChallenges_descriptionEn: "",
        DesignAppChallenges_descriptionAr: "",
        DesignAppChallenges_color: "",
        DesignAppChallenges_staticColor: "",
        DesignAppChallenges_hoverColor: "",
        cards: [{titleEn: "", titleAr: "", descriptionEn: "", descriptionAr: ""}]
    })

    //letsCheckData
    const [letsCheckData, setLetsCheckData] = useState({
        DesignAppLetCheck_titleEn:"",
        DesignAppLetCheck_titleAr:"",
        DesignAppLetCheck_image:null,
    })

    //sectionsData
    const [sectionsData, setSectionsData] = useState([{titleEn: "", titleAr: "", descriptionEn:"", descriptionAr:""}])

    //persona data
    const [personaData, setPersonaData] = useState([{titleEn: "", titleAr: "", descriptionEn:"", descriptionAr:"",otherTitleEn: "", otherTitleAr: "", otherDescriptionEn:"", otherDescriptionAr:"",image: null}])
   
    //sections two data
    const [sectionsTwoData, setSectionsTwoData] = useState([{titleEn: "", titleAr: "", descriptionEn: "", descriptionAr:"",image: null}])
    
    //mobile app data
    const [mobileAppsData, setMobileAppsData] = useState({
        DesignAppMobileApps_titleEn: "", 
        DesignAppMobileApps_titleAr: "",
        DesignAppMobileApps_descriptionEn:"",
        DesignAppMobileApps_descriptionAr: "",
        cards:[{image: null}]
    })
    
    //export screen
    const [exportScreenData, setExportScreenData] = useState({
        DesignAppMobileExportScreen_titleEn:"",
        DesignAppMobileExportScreen_titleAr:"",
        DesignAppMobileExportScreen_descriptionEn:"",
        DesignAppMobileExportScreen_descriptionAr:"",
        DesignAppMobileExportScreen_image:null,
    })
    //imagesSection
    const [imagesSectionData, setImagesSectionData] = useState({
        DesignAppImagesSection_titleEn:"",
        DesignAppImagesSection_titleAr:"",
        DesignAppImagesSection_descriptionEn:"",
        DesignAppImagesSection_descriptionAr:"",
        DesignAppImagesSection_image:null,
    })

    //Results
    const [resultData, setResultData] = useState({
        DesignAppResults_titleEn: "",
        DesignAppResults_titleAr: "",
        DesignAppResults_descriptionEn: "",
        DesignAppResults_descriptionAr: "",
        DesignAppResults_color: "",
        DesignAppResults_staticColor: "",
        DesignAppResults_hoverColor: "",
        cards: [{titleEn: "", titleAr: "", descriptionEn: "", descriptionAr: ""}]
    })

    /**************handel images***********************/
    function handleAcceptedImage(files, name,e, key) {
        const  file = [...files].map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )[0]
        e.target.value = ""
            
        name === "generalAttach"&&setGeneralInformationData(data => ({...data, attachment: file})) 
        name === "generalThumb"&&setGeneralInformationData(data => ({...data, thumbnail: file})) 
        name === "indepth"&&setIndepthData(data => ({...data, cards: data.cards.map((obj, i) => {
            if(i == key) {
              return {...obj, image:file}
            }
            return obj
        })})) 
        name === "ourclient"&&setOurClientsData(data => ({...data, OurClients_image: file})) 
        name === "seo"&&setSeoData(data => ({...data, ProductSEO_facebookImage: file})) 
        name === "exportscreen"&&setExportScreenData(data => ({...data, DesignAppMobileExportScreen_image: file})) 
        name === "imagessection"&&setImagesSectionData(data => ({...data, DesignAppImagesSection_image: file})) 
        name === "branding"&&setBrandingData(data => ({...data, images: data.images.map((obj, i) => {
            if(i == key) {
                return {image:file}
            }
            return obj
        })}))
        //design App 
        name === "designappimageimage"&&setDesignAppImageImage(data => ({DesignAppImage_image: file})) 
        name === "letscheck"&&setLetsCheckData(data => ({...data, DesignAppLetCheck_image: file})) 
        name === "persona"&&setPersonaData(data => data.map((obj, i) => {
            if(i == key) {
                return {...obj, image:file}
            }
            return obj
        }))
        name === "sectiontwo"&&setSectionsTwoData(data => data.map((obj, i) => {
            if(i == key) {
                return {...obj, image:file}
            }
            return obj
        }))
        name === "mobileapps"&&setMobileAppsData(data => ({...data, cards: data.cards.map((obj, i) => {
            if(i == key) {
                return {image:file}
            }
            return obj
        })}))
    }
    /****************handel images***********************/



    // handel images size formate
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }



   

    function getData (id) {
        axios.post(`${process.env.REACT_APP_API}/dashboard/product/find`,{id},{
            headers: {
            Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
            },
        }).then(res => { 
            let data = res.data.data;
            setGeneralInformationData({
                category: data.Category.map(category => ({label: category.nameEn, value: category.id})),
                designTemplate: data.template||"", 
                launchProject: data.launch||"", 
                attachment:data.image?{preview: data.image}:null, 
                thumbnail:data.thumbnailImage?{preview: data.thumbnailImage}:null, 
                color: data.color
            })
            setProjectNameData({
                ProjectName_nameEn: data.ProjectName?.nameEn||"",
                ProjectName_nameAr: data.ProjectName?.nameAr||"", 
                ProjectName_slogenEn: data.ProjectName?.slogenEn||"", 
                ProjectName_slogenAr: data.ProjectName?.slogenAr||"", 
                ProjectName_descriptionEn: data.ProjectName?.descriptionEn||"",
                ProjectName_descriptionAr: data.ProjectName?.descriptionAr||""
            })
            setProjectInformationData(data.ProjectInformation)
            setTeamMemberData(data.TeamMembers)
            setIndepthData(data.InDepth?data.InDepth:{
                titleEn: "",
                titleAr: "",
                cards: [{image:null, category: "", headLineEn: "", headLineAr:"",descriptionEn:"",descriptionAr:""}]  
            })
            setScopeOfWorkData({
                ScopeOfWork_titleEn: data.ScopeOfWork?.titleEn||"",
                ScopeOfWork_titleAr: data.ScopeOfWork?.titleAr||"", 
                ScopeOfWork_descriptionEn: data.ScopeOfWork?.descriptionEn||"", 
                ScopeOfWork_descriptionAr: data.ScopeOfWork?.descriptionAr||"", 
                ScopeOfWork_category: data.ScopeOfWork?.scope?.id||"", 
            })
            setOurClientsData({
                OurClients_userNameEn: data.OurClients?.userNameEn||"",
                OurClients_userNameAr: data.OurClients?.userNameAr||"",
                OurClients_positionEn: data.OurClients?.positionEn||"",
                OurClients_positionAr: data.OurClients?.positionAr||"",
                OurClients_descriptionEn: data.OurClients?.descriptionEn||"",
                OurClients_descriptionAr: data.OurClients?.descriptionAr||"",
                OurClients_image: data.OurClients?.image?{preview: data.OurClients.image}:null,
            })
            setThanksSectionData({
                cards: data.ThanksSection?.cards?data.ThanksSection.cards: [],
                ThanksSection_titleEn: data.ThanksSection?.titleEn||"",
                ThanksSection_titleAr: data.ThanksSection?.titleAr||"",
                ThanksSection_descriptionEn: data.ThanksSection?.descriptionEn||"",
                ThanksSection_descriptionAr: data.ThanksSection?.descriptionAr||"",
                ThanksSection_buttonNameEn: data.ThanksSection?.buttonNameEn||"",
                ThanksSection_buttonNameAr: data.ThanksSection?.buttonNameAr||"",
            })
            setSeoData({
                ProductSEO_seoTitleEn: data.SEO?.seoTitleEn||"",
                ProductSEO_seoTitleAr: data.SEO?.seoTitleAr||"",
                ProductSEO_descriptionEn: data.SEO?.descriptionEn||"",
                ProductSEO_descriptionAr: data.SEO?.descriptionAr||"",
                ProductSEO_slugEn: data.SEO?.slugEn||"",
                ProductSEO_slugAr: data.SEO?.slugAr||"",
                ProductSEO_focusKeypharseEn: data.SEO?.focusKeypharseEn||"",
                ProductSEO_focusKeypharseAr: data.SEO?.focusKeypharseAr||"",
                ProductSEO_facebookTitleEn: data.SEO?.facebookTitleEn||"",
                ProductSEO_facebookTitleAr: data.SEO?.facebookTitleAr||"",
                ProductSEO_facebookDescriptionEn: data.SEO?.facebookDescriptionEn||"",
                ProductSEO_facebookDescriptionAr: data.SEO?.facebookDescriptionAr||"",
                ProductSEO_facebookImage: data.SEO?.facebookImage?{preview:data.SEO.facebookImage}:null,
            })

            if(data.template == 1) {
                setIntroducingData({
                    DesignAppIntorducing_titleEn: data.TemplateBody.DesignAppIntorducing?.titleEn||"",
                    DesignAppIntorducing_titleAr: data.TemplateBody.DesignAppIntorducing?.titleAr||"",
                    DesignAppIntorducing_descriptionEn: data.TemplateBody.DesignAppIntorducing?.descriptionEn||"",
                    DesignAppIntorducing_descriptionAr: data.TemplateBody.DesignAppIntorducing?.descriptionAr||"",
                })
                setTaskDescriptionData({
                    ...data.TemplateBody.DesignAppTaskDescription,
                    DesignAppTaskDescription_titleEn: data.TemplateBody.DesignAppTaskDescription?.titleEn||"",
                    DesignAppTaskDescription_titleAr: data.TemplateBody.DesignAppTaskDescription?.titleAr||"",
                    DesignAppTaskDescription_descriptionEn: data.TemplateBody.DesignAppTaskDescription?.descriptionEn||"",
                    DesignAppTaskDescription_descriptionAr: data.TemplateBody.DesignAppTaskDescription?.descriptionAr||"",
                })
                setDeliverablesData({
                        DesignAppDeliverables_titleEn: data.TemplateBody.DesignAppDeliverables?.titleEn||"",
                        DesignAppDeliverables_titleAr: data.TemplateBody.DesignAppDeliverables?.titleAr||"",
                        DesignAppDeliverables_deliverables: data.TemplateBody.DesignAppDeliverables?.deliverables?data.TemplateBody.DesignAppDeliverables.deliverables.map(d => ({label: d.titleEn, value: d.id})):[],
                        DesignAppDeliverables_colors: data.TemplateBody.DesignAppDeliverables?.colors||""
                })
                setDesignAppImageImage({
                    DesignAppImage_image: data.TemplateBody.DesignAppImage?.image?{preview: data.TemplateBody.DesignAppImage.image}:null
                })
                setDesignAppProjectInfo(data.TemplateBody.DesignAppProjectInfo)
                setWhatIsProjData({
                    DesignAppWhatIsTheProject_titleEn: data.TemplateBody.DesignAppWhatIsTheProject?.titleEn||"",
                    DesignAppWhatIsTheProject_titleAr: data.TemplateBody.DesignAppWhatIsTheProject?.titleAr||"",
                    DesignAppWhatIsTheProject_descriptionEn: data.TemplateBody.DesignAppWhatIsTheProject?.descriptionEn||"",
                    DesignAppWhatIsTheProject_descriptionAr: data.TemplateBody.DesignAppWhatIsTheProject?.descriptionAr||"",
                })
                setChallengesData({
                    ...data.TemplateBody.DesignAppChallenges,
                    DesignAppChallenges_titleEn: data.TemplateBody.DesignAppChallenges?.titleEn||"",
                    DesignAppChallenges_titleAr: data.TemplateBody.DesignAppChallenges?.titleAr||"",
                    DesignAppChallenges_descriptionEn: data.TemplateBody.DesignAppChallenges?.descriptionEn||"",
                    DesignAppChallenges_descriptionAr: data.TemplateBody.DesignAppChallenges?.descriptionAr||"",
                    DesignAppChallenges_color: data.TemplateBody.DesignAppChallenges?.color||"",
                    DesignAppChallenges_staticColor: data.TemplateBody.DesignAppChallenges?.staticColor||"",
                    DesignAppChallenges_hoverColor: data.TemplateBody.DesignAppChallenges?.hoverColor||"",
                    cards: data.TemplateBody.DesignAppChallenges?.cards||[],
                })
                setLetsCheckData({
                    DesignAppLetCheck_titleEn:data.TemplateBody.DesignAppLetCheck?.titleEn||"",
                    DesignAppLetCheck_titleAr: data.TemplateBody.DesignAppLetCheck?.titleAr||"",
                    DesignAppLetCheck_image:data.TemplateBody.DesignAppLetCheck?.image?{preview: data.TemplateBody.DesignAppLetCheck.image}:null,
                })
                setSectionsData(data.TemplateBody.DesignAppSections)
                setPersonaData(data.TemplateBody.DesignAppPersona.map(d => ({...d, image: d.image?{preview: d.image}:null})))
                setSectionsTwoData(data.TemplateBody.DesignAppSectionsTwo.map(d => ({...d, image: d.image?{preview: d.image}:null})))
                setMobileAppsData({
                    DesignAppMobileApps_titleEn: data.TemplateBody.DesignAppMobileApps?.titleEn||"", 
                    DesignAppMobileApps_titleAr: data.TemplateBody.DesignAppMobileApps?.titleAr||"",
                    DesignAppMobileApps_descriptionEn:data.TemplateBody.DesignAppMobileApps?.descriptionEn||"",
                    DesignAppMobileApps_descriptionAr: data.TemplateBody.DesignAppMobileApps?.descriptionAr||"",
                    cards:data.TemplateBody.DesignAppMobileApps?data.TemplateBody.DesignAppMobileApps.cards.map(d => ({id:d.id, image: d.image?{preview: d.image}:null})):[{image:null}]
                })
                setExportScreenData({
                    DesignAppMobileExportScreen_titleEn:data.TemplateBody.DesignAppMobileExportScreen?.titleEn||"",
                    DesignAppMobileExportScreen_titleAr:data.TemplateBody.DesignAppMobileExportScreen?.titleAr||"",
                    DesignAppMobileExportScreen_descriptionEn:data.TemplateBody.DesignAppMobileExportScreen?.descriptionEn||"",
                    DesignAppMobileExportScreen_descriptionAr:data.TemplateBody.DesignAppMobileExportScreen?.descriptionAr||"",
                    DesignAppMobileExportScreen_image: data.TemplateBody.DesignAppMobileExportScreen?.image?{preview: data.TemplateBody.DesignAppMobileExportScreen.image}:null,
                })
                setImagesSectionData({
                    DesignAppImagesSection_titleEn:data.TemplateBody.DesignAppImagesSection?.titleEn||"",
                    DesignAppImagesSection_titleAr:data.TemplateBody.DesignAppImagesSection?.titleAr||"",
                    DesignAppImagesSection_descriptionEn:data.TemplateBody.DesignAppImagesSection?.descriptionEn||"",
                    DesignAppImagesSection_descriptionAr:data.TemplateBody.DesignAppImagesSection?.descriptionAr||"",
                    DesignAppImagesSection_image:data.TemplateBody.DesignAppImagesSection?{preview: data.TemplateBody.DesignAppImagesSection.image}:null,
                })
                setResultData({
                    ...data.TemplateBody.DesignAppResults,
                    DesignAppResults_titleEn: data.TemplateBody.DesignAppResults?.titleEn||"",
                    DesignAppResults_titleAr: data.TemplateBody.DesignAppResults?.titleAr||"",
                    DesignAppResults_descriptionEn: data.TemplateBody.DesignAppResults?.descriptionEn||"",
                    DesignAppResults_descriptionAr: data.TemplateBody.DesignAppResults?.descriptionAr||"",
                    DesignAppResults_color: data.TemplateBody.DesignAppResults?.color||"",
                    DesignAppResults_staticColor: data.TemplateBody.DesignAppResults?.staticColor||"",
                    DesignAppResults_hoverColor: data.TemplateBody.DesignAppResults?.hoverColor||"",
                    cards:data.TemplateBody.DesignAppResults?.cards||[],
                })
            }
            if(data.template == 2) {
                
                setBrandingData({
                    cards: data.TemplateBody.BodyBranding,
                    images:  data.TemplateBody.BodyBrandingImages.map(img => ({image: {id: img.id, preview: img.image}})),
                })
            }
        })
    }


    /*************Handle Edit******************/
        useEffect(() => {
            if (itemId) {
                getData(itemId)
                setPID(itemId)
            }
        }, [itemId])
    /*************Handle Edit******************/


  return (
    <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>Add Project | Layouti</title>
        </MetaTags>
        <Container fluid>
        {isOpen ? (
            <Lightbox
              mainSrc={imgSrc}
              enableZoom={false}
              onCloseRequest={() => {
                setisOpen(!isOpen)
              }}
            />
          ) : null}
        <Col>
            <Row>
                <Col md="3">
                    <Sticky topOffset={-300}>
                        <Nav pills className="flex-column justify-content-between navs_aside">

                            <NavItem className="nav___item nav_-_item border_bottom">
                                <div
                                style={{ cursor: "pointer" }}
                                className={classNames({
                                    "": true,
                                    active: verticalActiveTab === "1",
                                    "tab_link":true
                                })}
                                onClick={() => {
                                    toggleVertical("1");
                                }}
                                >
                                    General Information
                                </div>
                            </NavItem>
                            
                            <NavItem className="nav___item border_bottom">
                                <div
                                style={{ cursor: "pointer" }}
                                className={classNames({
                                    "": true,
                                    active: verticalActiveTab === "2",
                                    "tab_link":true
                                })}
                                onClick={() => {
                                    toggleVertical("2");
                                }}
                                >
                                    Project Name
                                </div>
                            </NavItem>

                            <NavItem className="nav___item border_bottom">
                                <div
                                style={{ cursor: "pointer" }}
                                className={classNames({
                                    "": true,
                                    active: verticalActiveTab === "3",
                                    "tab_link":true
                                })}
                                onClick={() => {
                                    toggleVertical("3");
                                }}
                                >
                                    Project Information
                                </div>
                            </NavItem>
                            
                            <NavItem className="nav___item border_bottom">
                                <div
                                style={{ cursor: "pointer" }}
                                className={classNames({
                                    "": true,
                                    active: verticalActiveTab === "4",
                                    "tab_link":true
                                })}
                                onClick={() => {
                                    toggleVertical("4");
                                }}
                                >
                                Team Member
                                </div>
                            </NavItem>

                            <NavItem className="nav___item border_bottom">
                                <div
                                style={{ cursor: "pointer" }}
                                className={classNames({
                                    "": true,
                                    active: verticalActiveTab === "5",
                                    "tab_link":true
                                })}
                                onClick={() => {
                                    toggleVertical("5");
                                }}
                                >
                                    In Depth
                                </div>
                            </NavItem>

                            <NavItem className="nav___item border_bottom">
                                <div
                                style={{ cursor: "pointer" }}
                                className={classNames({
                                    "": true,
                                    active: verticalActiveTab === "6",
                                    "tab_link":true
                                })}
                                onClick={() => {
                                    toggleVertical("6");
                                }}
                                >
                                    Scope Of Work
                                </div>
                            </NavItem>

                            {generalInformationData.designTemplate == 2&&<NavItem className="nav___item border_bottom">
                                <div
                                style={{ cursor: "pointer" }}
                                className={classNames({
                                    "": true,
                                    active: verticalActiveTab === "10",
                                    "tab_link":true
                                })}
                                onClick={() => {
                                    toggleVertical("10");
                                }}
                                >
                                    Branding
                                </div>
                            </NavItem>}
                            {generalInformationData.designTemplate == 1&&<NavItem className="nav___item border_bottom">
                                <div
                                style={{ cursor: "pointer" }}
                                className={classNames({
                                    "": true,
                                    active: verticalActiveTab === "11",
                                    "tab_link":true
                                })}
                                onClick={() => {
                                    toggleVertical("11");
                                }}
                                >
                                    Design app
                                </div>
                            </NavItem>}

                            <NavItem className="nav___item border_bottom">
                                <div
                                style={{ cursor: "pointer" }}
                                className={classNames({
                                    "": true,
                                    active: verticalActiveTab === "7",
                                    "tab_link":true
                                })}
                                onClick={() => {
                                    toggleVertical("7");
                                }}
                                >
                                    Our Clients
                                </div>
                            </NavItem>

                            <NavItem className="nav___item border_bottom">
                                <div
                                style={{ cursor: "pointer" }}
                                className={classNames({
                                    "": true,
                                    active: verticalActiveTab === "8",
                                    "tab_link":true
                                })}
                                onClick={() => {
                                    toggleVertical("8");
                                }}
                                >
                                    Thanks Section
                                </div>
                            </NavItem>
                            <NavItem className="nav___item border_bottom">
                                <div
                                style={{ cursor: "pointer" }}
                                className={classNames({
                                    "": true,
                                    active: verticalActiveTab === "9",
                                    "tab_link":true
                                })}
                                onClick={() => {
                                    toggleVertical("9");
                                }}
                                >
                                    SEO
                                </div>
                            </NavItem>
                        </Nav>
                    </Sticky>
                </Col>


                <Col md="9">
                    <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted table__content mt-4 mt-md-0"
                    >
                        <TabPane tabId="1">
                            <GeneralInformationForm 
                                data={generalInformationData} 
                                ChangeHandler={setGeneralInformationData} 
                                handleAcceptedImage={handleAcceptedImage} 
                                setImgSrc={setImgSrc} 
                                setisOpen={setisOpen}
                                setPID={setPID}
                                PID={PID} 
                            />
                        </TabPane>
                        <TabPane tabId="2">
                            <ProjectNameForm 
                                data={projectNameData} 
                                ChangeHandler={setProjectNameData}
                                PID={PID} 
                            />
                        </TabPane>
                        <TabPane tabId="3">
                            <ProjectInformationForm 
                                data={projectInformationData} 
                                ChangeHandler={setProjectInformationData} 
                                PID={PID} 
                                getData={getData}
                            />
                        </TabPane>
                        <TabPane tabId="4">
                            <TeamMember 
                                data={teamMemberData} 
                                ChangeHandler={setTeamMemberData} 
                                PID={PID} 
                                getData={getData}
                            />
                        </TabPane>
                        <TabPane tabId="5">
                            <InDepthForm 
                                data={inDepthData} 
                                ChangeHandler={setIndepthData}
                                setImgSrc={setImgSrc} 
                                setisOpen={setisOpen}
                                handleAcceptedImage={handleAcceptedImage} 
                                PID={PID} 
                                getData={getData}
                            />
                        </TabPane>
                        <TabPane tabId="6">
                            <ScopeOfWorkForm 
                                data={scopeOfWorkData} 
                                ChangeHandler={setScopeOfWorkData}
                                PID={PID} 
                                getData={getData}
                            />
                        </TabPane>
                        <TabPane tabId="7">
                            <OurClientsForm
                                data={ourClientsData} 
                                ChangeHandler={setOurClientsData}
                                setImgSrc={setImgSrc} 
                                setisOpen={setisOpen}
                                handleAcceptedImage={handleAcceptedImage}
                                PID={PID} 
                                getData={getData}
                            />
                        </TabPane>
                        <TabPane tabId="8">
                            <ThanksSectionForm
                                data={thanksSectionData} 
                                ChangeHandler={setThanksSectionData}
                                PID={PID} 
                                getData={getData}
                            />
                        </TabPane>
                        <TabPane tabId="9">
                            <SeoForm
                                data={seoData} 
                                ChangeHandler={setSeoData}
                                setImgSrc={setImgSrc} 
                                setisOpen={setisOpen}
                                handleAcceptedImage={handleAcceptedImage} 
                                PID={PID} 
                                getData={getData}
                            />
                        </TabPane>
                        <TabPane tabId="10">
                            <BrandingForm
                                data={brandingData} 
                                ChangeHandler={setBrandingData}
                                setImgSrc={setImgSrc} 
                                setisOpen={setisOpen}
                                handleAcceptedImage={handleAcceptedImage} 
                                PID={PID} 
                                getData={getData}
                            />
                        </TabPane>

                        <TabPane tabId="11">
                            <DesignAppForm
                                data={{
                                    introducingData,
                                    taskDescriptionData, 
                                    deliverablesData,
                                    designAppImageImage, 
                                    designAppProjectInfo,
                                    whatIsProjData,
                                    challengesData,
                                    letsCheckData,
                                    sectionsData,
                                    personaData,
                                    sectionsTwoData,
                                    mobileAppsData,
                                    exportScreenData,
                                    imagesSectionData,
                                    resultData,
                                    PID
                                }} 
                                ChangeHandler={{
                                    setIntroducingData,
                                    setTaskDescriptionData, 
                                    setDeliverablesData,
                                    setDesignAppImageImage,
                                    setDesignAppProjectInfo,
                                    setWhatIsProjData,
                                    setChallengesData,
                                    setLetsCheckData,
                                    setSectionsData,
                                    setPersonaData,
                                    setSectionsTwoData,
                                    setMobileAppsData,
                                    setExportScreenData,
                                    setImagesSectionData,
                                    setResultData,
                                    getData
                                }}
                                setImgSrc={setImgSrc} 
                                setisOpen={setisOpen}
                                handleAcceptedImage={handleAcceptedImage} 
                            />
                        </TabPane>

                    </TabContent>
                </Col>
            </Row>


            {/* {save && Cancel Buttons} */}
            <Row className="submit__btn footer fixed fixed-bottom">
                <Col lg={10} md={10} className='d-flex'>
                <button type='button' className="cancel mr-3" onClick={() => history.goBack("/layouti/Project")}>
                    cancel
                </button>
                {" "}
                
                {/* <button type='button' className='add-project__submit-button' onClick={(e) => submit(e)}>
                    {isLoading ? (
                    <ReactLoading
                        type={"spin"}
                        color={"#ffffff"}
                        height={30}
                        width={30}
                    />
                    ) : (
                    "Save"
                    )}
                </button> */}
                </Col>
          </Row>
        </Col>
        </Container>
        </div>
    </React.Fragment>
  )
}

export default AddProject