import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import classnames from "classnames";
import {
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import Sticky from 'react-sticky-el';
import axios from "axios";
import { useSelector, useDispatch } from "react-redux"
import {
    setTitle
} from "../../../store/actions";
import { useHistory } from "react-router-dom";

import MalePicture from "./Components/MalePicture";
import FemalePicture from "./Components/FemalePicture";
import Male from "./Components/Male";
import Female from "./Components/Female";
import Addresses from "./Components/Addresses";
import Position from "./Components/Position";

import './configration.scss'
import axiosConfig from "axiosConfig";


const UiGenerator = () => {
    const dispatch = useDispatch()
    const history  = useHistory()
    const  user = useSelector(state => state.UserData.user)
    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    const [male, setMale] = useState([])
    const [female, setFemale] = useState([])
    const [emails, setEmails] = useState([])
    const [phones, setPhones] = useState([])
    const [addresses, setAddresses] = useState([])
    const [positions, setPositions] = useState([])
    const [dates, setDates] = useState([])
    const [maleimages, setMaleImages] = useState([])
    const [femaleimages, setFemaleImages] = useState([])
    
    const toggleVertical = tab => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };
    
    function update(data, type) {
        switch (type) {
            case "imagesmale":
                setMaleImages(data)
                break;
            case "imagesfemale":
                setFemaleImages(data)
                break;
            case "male":
                setMale(data)
                break;
            case "female":
                setFemale(data)
                break;
            case "position":
                setPositions(data)
                break;
            case "address":
                setAddresses(data)
                break;
            case "deleteMaleImg":
                setMaleImages(imgs => imgs.filter(img => img.id !== data))
                break;
            case "deleteMaleImgAll":
                setMaleImages([])
                break;
            case "deleteFemaleImg":
                setFemaleImages(imgs => imgs.filter(img => img.id !== data))
                break;
            case "deleteFemaleImgAll":
                setFemaleImages([])
                break;
            case "deletemale":
                setMale(names => names.filter(name => name.id !== data))
                break;
            case "deletemaleall":
                setMale([])
                break;
            case "deletefemale":
                setFemale(names => names.filter(name => name.id !== data))
                break;
            case "deletefemaleall":
                setFemale([])
                break;
            case "deleteaddress":
                setAddresses(adds => adds.filter(add => add.id !== data))
                break;
            case "deleteaddressall":
                setAddresses([])
                break;
            case "deleteposition":
                setPositions(poss => poss.filter(pos => pos.id !== data))
                break;
            case "deletepositionall":
                setPositions([])
                break;
            default:
                break;
        }
    }



    useEffect(() => {
        if(user && user.role == 2) {
            history.push('/dashboard')
            return;
        } 
    }, [user])

    useEffect(() => {   
        dispatch(setTitle("Ui Generator"))
        axiosConfig.get(`dashboard/uiAvatar/pages/uiGenerator`, {
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
            }
        })
        .then(res => {
            setMale(res.data.data?.MaleNames)
            setFemale(res.data.data?.FemaleNames)
            setPositions(res.data.data?.Positions)
            setAddresses(res.data.data?.Address)
            setMaleImages(res.data.data?.MalePicture)
            setFemaleImages(res.data.data?.FemalePictures)
        }) 
    }, [])

    return (
        <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>Ui Generator | Layouti</title>
        </MetaTags>
        <Container fluid>
        <Col>
            <Row>
            <Col md="3">
            <Sticky topOffset={-300} >
                <Nav pills className="flex-column justify-content-between navs_aside">
                    <NavItem  className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "1",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("1");
                        }}
                        >
                            Male Picture {maleimages?`(${maleimages.length})`:0}
                        </NavLink>
                    </NavItem>
                    
                    <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "6",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("6");
                        }}
                        >
                            Female Picture {femaleimages?`(${femaleimages.length})`:0}
                        </NavLink>
                    </NavItem>

                    <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer", borderRadius: 0}}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "2",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("2");
                        }}
                        >
                            Male Names {male?`(${male.length})`:0}
                        </NavLink>
                    </NavItem>

                    <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer", borderRadius: 0}}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "5",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("5");
                        }}
                        >
                            Female Names{female?`(${female.length})`:0}
                        </NavLink>
                    </NavItem>
                    
                    <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "3",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("3");
                        }}
                        >
                            Addresses {addresses?`(${addresses.length})`:0}
                        </NavLink>
                    </NavItem>
                    <NavItem className="nav___item border_bottom">
                        <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                            "": true,
                            active: verticalActiveTab === "4",
                            "tab_link":true
                        })}
                        onClick={() => {
                            toggleVertical("4");
                        }}
                        >
                            Positions {positions?`(${positions.length})`:0}
                        </NavLink>
                    </NavItem>
                </Nav>
                </Sticky>
            </Col>
            <Col md="9">
                <TabContent
                activeTab={verticalActiveTab}
                className="text-muted table__content mt-4 mt-md-0"
                >
                        <TabPane tabId="1">
                            <MalePicture data={maleimages} update={update}/> 
                        </TabPane>
                        <TabPane tabId="6">
                            <FemalePicture data={femaleimages} update={update}/> 
                        </TabPane>
                        <TabPane tabId="2" >
                            <Male data={male} update={update}/>
                        </TabPane>
                        <TabPane tabId="3">
                            <Addresses data={addresses} update={update}/>
                        </TabPane>
                        <TabPane tabId="4">
                            <Position data={positions} update={update}/>
                        </TabPane>
                        <TabPane tabId="5">
                            <Female data={female} update={update}/>
                        </TabPane>
                    </TabContent>
            </Col>
            </Row>
        </Col>
        </Container>
        </div>
    </React.Fragment>
)
}

export default UiGenerator

const navsStyle = { 
    cursor: "pointer", 
    borderRadius: 0,

}