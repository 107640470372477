/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Form, Label, Input, FormFeedback,Collapse, Button } from "reactstrap"
import classnames from "classnames";

import {Link} from "react-router-dom"

import '../specification.scss'
import { toast } from "react-toastify";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from "axios";

import SweetAlert from "react-bootstrap-sweetalert"
import ReactLoading from "react-loading";
import prettyBytes from "pretty-bytes";
import ScopeOfWorkItem from "./ScopeOfWorkItem";
import axiosConfig from "axiosConfig";

    
const ScopeOfWork = (props) => {
    const SCOPE_ITEM_MODAL = (itemId)=>{
        return {
            id:itemId,
            titleEn: "",
            titleAr: "",
            descriptionEn: "",
            descriptionAr: "",
            icon:[],
            status:'new'
        }
    }
    const SCOPE_MODAL=(scopeId)=>{
        return {
            id:scopeId,
            titleEn: "",
            titleAr: "",
            cards:[SCOPE_ITEM_MODAL(1)],
            status:'new'
        }
    }
    
    const [active, setActive] = useState(false)
    const [msg, setMsg] = useState("")
    const [success_msg, setsuccess_msg] = useState(false)
    const [isOpen, setisOpen] = useState(false);
    const [prev, setPrev] = useState(null);
    const [links, setLinks] = useState([SCOPE_MODAL(1)])
    const [activeCollapse,setActiveCollapse]=useState(-1)

    function handleFiles(e, key,index) {
        const modifiedLinks = [...links];
        let acceptedFiles =e.target.files;
        [...acceptedFiles].map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: prettyBytes(file.size)
            })
        )
        modifiedLinks[key].cards[index].icon=Object.values(acceptedFiles)[0]
        setLinks(modifiedLinks)
        e.target.value = '';
    }
    function deleteImage(key,index){
        let modifiedLinks =[...links]
        modifiedLinks[key].cards[index].icon=[]
        setLinks(modifiedLinks)
    }

    function prevSelectImage(e, img) {
        e.preventDefault()
        setPrev(img)
        setisOpen(true)
    }

    function handleAddRowNested() {
        const modifiedLinks = [...links]
        modifiedLinks.push(SCOPE_MODAL(modifiedLinks.length + 1))
        setLinks(modifiedLinks)
    }

    function handleAddRowItemNested(key) {
        const modifiedLinks = [...links]
        modifiedLinks[key].cards.push(SCOPE_ITEM_MODAL(modifiedLinks[key].cards.length + 1))
        setLinks(modifiedLinks)
    }

    function deleteCard(key,cardId,status){
        
        if(status==='old'){
            axiosConfig.post(`/dashboard/configurations/scopes/delete`, {id:cardId}, {
                headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("authUser")).accessToken
                }`,
                },
            }).then(res=>{
                
                setMsg(`Deleted Successfully`)
                setsuccess_msg(true)
                handleRemoveRow(key,cardId)
                // setIsLoading(false)
            }).catch(err=>{
                
                toast.error(err.response.data.message)
                // setIsLoading(false)
            })
            }else{
            handleRemoveRow(key,cardId)
            }
    }
    function deleteCardItem(key,cardId,status,index){
        
        if(status==='old'){
            axiosConfig.post(`/dashboard/configurations/scopes/deleteCard`, {id:cardId}, {
                headers: {
                Authorization: `Bearer ${
                    JSON.parse(localStorage.getItem("authUser")).accessToken
                }`,
                },
            }).then(res=>{
                
                setMsg(`Deleted Successfully`)
                setsuccess_msg(true)
                handleRemoveItemRow(key,index)
                // setIsLoading(false)
            }).catch(err=>{
                
                toast.error(err.response.data.message)
                // setIsLoading(false)
            })
            }else{
            handleRemoveItemRow(key,index)
            }
    }

    function handleRemoveRow(id) {
        
        if (id !== 0) {
            let modifiedLinks = [...links]
            modifiedLinks = modifiedLinks.filter((x,index) => index !== id)
            setLinks(modifiedLinks)
        }
    }
    function handleRemoveItemRow(id,key) {
        // 
        if (key !== 0) {
            let modifiedLinks = [...links]
            modifiedLinks[id].cards = modifiedLinks[id].cards.filter((x,index) => index !== key)
            setLinks(modifiedLinks)
        }
    }

    function informationsChange(e, key, type) {
        let modifiedLinks =[...links]
        modifiedLinks[key][type]=e.target.value
        setLinks(modifiedLinks)
    }
    function informationsChangeItem(e, key, type,index) {
        let modifiedLinks =[...links]
        modifiedLinks[key].cards[index][type]=e.target.value
        setLinks(modifiedLinks)
    }    
    
    function submit(e) {
        e.preventDefault();
        setActive(true)
        const formData = new FormData();
            // for (let key in data) {
            // formData.append(key, data[key])
            // }

        links.forEach((link,index) => {
            formData.append(`scopes[${index}][titleEn]`, link.titleEn);
            formData.append(`scopes[${index}][titleAr]`, link.titleAr);
            // formData.append(`titleEn[${index}]`, link.titleEn);
            // formData.append(`titleAr[${index}]`, link.titleAr);
            link.cards.forEach((card,cardIndex)=>{
                formData.append(`scopes[${index}][cards][${cardIndex}][titleEn]`, card.titleEn);
                formData.append(`scopes[${index}][cards][${cardIndex}][titleAr]`, card.titleAr);
                formData.append(`scopes[${index}][cards][${cardIndex}][descriptionEn]`, card.descriptionEn);
                formData.append(`scopes[${index}][cards][${cardIndex}][descriptionAr]`, card.descriptionAr);
                formData.append(`scopes[${index}][cards][${cardIndex}][icon]`, card.icon);
            })
        }); 
        

        axiosConfig.post(`/dashboard/configurations/scopes/add`,formData ,{
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
            }
        })
        .then(res => {
            
            setActive(false)
            // if(res.data.error.length!=0){
            //     toast.error('Please Fill All Fields')
            //     return;
            // }
            setMsg(`Saved!`)
            setsuccess_msg(true)
        }).catch(err=>{
            setActive(false)
            toast.error(err.response.data.message)
        })
    }
    useEffect(()=>{
        
    setLinks(props.scopeOfWork)
    },[props.scopeOfWork])

    return (
            <React.Fragment>
                <div className="container-fluid p-0">
                <Card>
                    <CardBody>
                    {success_msg ? (
                        <SweetAlert
                        title={msg}
                        success
                        confirmBtnBsStyle="success"
                        onConfirm={() => {
                            setsuccess_msg(false)
                        }}
                        onCancel={() => {
                            setsuccess_msg(false)
                        }}
                        >
                            </SweetAlert>
                        ) : null}
                    {isOpen ? (
                    <Lightbox
                        mainSrc={prev}
                        enableZoom={false}
                        onCloseRequest={() => {
                        setisOpen(!isOpen);
                        }}
                    />
                    ) : null} 
                    <Form className="border-0">
                            <Row>
                                <Col>
                                    <div className="inner-repeater">
                                        <div className="accordion" id="accordion">
                                            {(links || []).map((formRow, key) => (
                                                <ScopeOfWorkItem 
                                                key={key} 
                                                links={links} 
                                                linkIndex={key} 
                                                handleRemoveRow={handleRemoveRow}
                                                informationsChange={informationsChange}
                                                handleFiles={handleFiles}
                                                deleteImage={deleteImage}
                                                prevSelectImage={prevSelectImage}
                                                setActiveCollapse={setActiveCollapse}
                                                activeCollapse={activeCollapse}
                                                handleAddRowItemNested={handleAddRowItemNested}
                                                deleteCard={deleteCard}
                                                deleteCardItem={deleteCardItem}
                                                handleRemoveItemRow={handleRemoveItemRow}
                                                informationsChangeItem={informationsChangeItem}
                                                />
                                            ))}
                                        </div>
                                        <hr style={{ margin: "25px -20px 20px -20px" }} />

                                        <Button
                                            onClick={() => {
                                            handleAddRowNested()
                                            }}
                                            color="none"
                                            className="mt-2 fs-5 fw-bold p-0 text-left"
                                            style={btnStyle}
                                        >
                                            <i className="bx bx-plus-circle fs-4"></i>{" "}
                                            <span>Add scope of work</span>
                                        </Button>
                                        
                                    </div>
                                </Col>
                            </Row>

                        <hr style={{ margin: "25px -20px 20px -20px" }} />
                        <Row>
                            <Col className="d-flex justify-content-end gap-2">
                                <div className="save__btn top m-0">
                                    <button onClick={(e) => submit(e)}>{active?<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />:"Save"}</button>
                                </div>
        
                            </Col>
                        </Row>
            </Form>
            </CardBody>
        </Card>      
        </div>
    </React.Fragment>
  )
}

export default ScopeOfWork

const btnStyle = {
    boxShadow: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    paddingLeft: "0",
}


const deleteBtn = {
    background: "transparent",
    border: "none",
    color:"#D91414",
    display: "flex",
    alignItems: "center",
    gap: "5px"
}

const editBtn = {
    background: "transparent",
    border: "none",
    color:"green",
    display: "flex",
    alignItems: "center",
    gap: "5px"
}