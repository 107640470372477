import axios from "axios"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Input, Label, Row } from "reactstrap"
import ReactLoading from "react-loading"
import { toast } from "react-toastify"
const OurClientsForm = ({
  data, ChangeHandler, handleAcceptedImage, setImgSrc,setisOpen, PID, getData
}) => {
  const [isLoading, setIsLoading] = useState(false)

  function submit(e) {
    e.preventDefault()
    setIsLoading(true)
    const formData = new FormData()

    let d = {
      userNameEn:  data.OurClients_userNameEn,
      userNameAr:  data.OurClients_userNameAr, 
      positionEn:  data.OurClients_positionEn, 
      positionAr:  data.OurClients_positionAr, 
      descriptionEn: data.OurClients_descriptionEn, 
      descriptionAr: data.OurClients_descriptionAr, 
      // image: data.OurClients_image.name?data.OurClients_image:data.OurClients_image.preview, 
      PID
    }
    
    if (data.OurClients_image) {
      if (data.OurClients_image.name) {
        formData.append("image", data.OurClients_image)
      }else {
        formData.append("image", data.OurClients_image.preview)
      }
    }else {
      formData.append("image", null)
    }
    for (let key in d) {
      formData.append(key, d[key])
    }
    axios.post(`${process.env.REACT_APP_API}/dashboard/product/ourClients/save`,formData,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
      toast.success(res.data.message, {position: "top-right"})
      setIsLoading(false)
      getData(PID)
    })
  }

  return (
    <Card>
      <CardBody>
        <Row className="mt-3">
          <Col>
            <div className="d-flex">
              <Label>User Name (English)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="Label"
              name="OurClients_userNameEn"
              value={
                data["OurClients_userNameEn"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, OurClients_userNameEn: e.target.value}
                )
              }
            />
          </Col>
          <Col>
            <div className="d-flex">
              <Label>User Name (Arabic)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="عنوان"
              dir="rtl"
              name="OurClients_userNameAr"
              value={
                data["OurClients_userNameAr"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, OurClients_userNameAr: e.target.value}
                )
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <div className="d-flex">
              <Label>Position (English)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="Label"
              name="OurClients_positionEn"
              value={
                data["OurClients_positionEn"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, OurClients_positionEn: e.target.value}
                )
              }
            />
          </Col>
          <Col>
            <div className="d-flex">
              <Label>Position (Arabic)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="عنوان"
              dir="rtl"
              name="OurClients_positionAr"
              value={
                data["OurClients_positionAr"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, OurClients_positionAr: e.target.value}
                )
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <div className="d-flex">
              <Label>Description (English)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <textarea
              className="form-control  mb-0"
              rows="5"
              placeholder="Description"
              name="OurClients_descriptionEn"
              value={
                data["OurClients_descriptionEn"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, OurClients_descriptionEn: e.target.value}
                )
              }
            />
          </Col>
          <Col>
            <div className="d-flex">
              <Label>Description (Arabic)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <textarea
              className="form-control  mb-0"
              rows="5"
              placeholder="وصف"
              name="OurClients_descriptionAr"
              value={
                data["OurClients_descriptionAr"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, OurClients_descriptionAr: e.target.value}
                )
              }
              dir="rtl"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs="12">
            <div>
              <div className="d-flex">
                <Label>Background Image</Label>
                <label className="ms-auto optional-label--color">
                  (Optional)
                </label>
              </div>
              <Input
                type="file"
                name="image"
                className="form-control form-control-lg "
                onChange={e => handleAcceptedImage(e.target.files, "ourclient",e)}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col>
            <div className="dropzone-previews" id="file-previews">
              {data["OurClients_image"]? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={data["OurClients_image"]?.name}
                          src={
                            data["OurClients_image"]?.preview
                              ? data["OurClients_image"]?.preview
                              : data["OurClients_image"]
                          }
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {data["OurClients_image"]?.name}
                        </Link>
                        <p className="mb-0">
                            <strong>{data["OurClients_image"]?.formattedSize}</strong>
                        </p>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            type="button"
                            className="p-0"
                            onClick={e =>
                                (setImgSrc(data["OurClients_image"].preview), setisOpen(true))
                            }
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            type="button"
                            style={{ color: "#ff3838" }}
                            onClick={e => (ChangeHandler(
                                {...data, OurClients_image: null}
                            ))}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>
        <hr style={{ margin: "20px -20px 20px -20px" }} />
        <Row>
          <Col className="d-flex justify-content-end">
            <button
              type="button"
              className="add-project__submit-button"
              onClick={e => submit(e)}
            >
              {isLoading ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </Col>
        </Row>   
      </CardBody>
    </Card>
  )
}

export default OurClientsForm
