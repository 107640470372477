import React from 'react'
import { Row, Card, CardBody, Col } from 'reactstrap'

const Tabs = ({addComponentHandler}) => {
  return (
    <Row>
      <Col>
        <Card className='cursor-pointer' role='button' onClick={() => addComponentHandler("Title")}>
          <CardBody className='p-3 py-3'> 
            <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.66992 7.17003V5.35003C2.66992 4.20003 3.59992 3.28003 4.73992 3.28003H19.2599C20.4099 3.28003 21.3299 4.21003 21.3299 5.35003V7.17003" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12 20.7201V4.11011" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8.06055 20.72H15.9405" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <p className='m-0 card_title mt-2'>Header</p>
          </CardBody>
        </Card>
      </Col>
      <Col>
        <Card className='cursor-pointer' role='button' onClick={() => addComponentHandler("Paragraph")}>
          <CardBody className='p-3  py-3'> 
            <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3 4.5H21" stroke="#292D32"  strokeWidth="1.5"  strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M3 9.5H21" stroke="#292D32"  strokeWidth="1.5"  strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M3 14.5H21" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M3 19.5H21" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <p className='m-0 card_title mt-2'>Description</p>
          </CardBody>
        </Card>
      </Col>
      {/* <Col >
        <Card className='cursor-pointer' onClick={() => addComponentHandler("ImportantParagraph")}>
          <CardBody className='p-3  py-3'> 
            <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 4.5H21" stroke="#292D32"     strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 9.5H12.47" stroke="#292D32"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 14.5H21" stroke="#292D32"    strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3 19.5H12.47" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <p className='m-0 card_title mt-2'>Important Description</p>

          </CardBody>
        </Card>
      </Col> */}
      {/* <Col>
        <Card className='cursor-pointer' onClick={() => addComponentHandler("Images")}>
          <CardBody className='p-3  py-3'> 
            <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 22C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M9 10C7.89543 10 7 9.10457 7 8C7 6.89543 7.89543 6 9 6C10.1046 6 11 6.89543 11 8C11 9.10457 10.1046 10 9 10Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M2.66992 18.9501L7.59992 15.6401C8.38992 15.1101 9.52992 15.1701 10.2399 15.7801L10.5699 16.0701C11.3499 16.7401 12.6099 16.7401 13.3899 16.0701L17.5499 12.5001C18.3299 11.8301 19.5899 11.8301 20.3699 12.5001L21.9999 13.9001" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <p className='m-0 card_title mt-2'>Image</p>

          </CardBody>
        </Card>
      </Col> */}
    </Row>
  )
}

export default Tabs