import axios from "axios"
import React, { Fragment, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap"
import ReactLoading from "react-loading"
import { toast } from "react-toastify"
const InDepthForm = ({ data, ChangeHandler, handleAcceptedImage,setImgSrc, setisOpen,getData, PID }) => {
  const [isLoading, setIsLoading] = useState(false)
  
  function handleAddRowNested() {
    ChangeHandler({...data, cards: data.cards.concat({image:null, category: "", headLineEn: "", headLineAr:"",descriptionEn:"",descriptionAr:""})})
  }

  function deleteCard(indx, id) {
    ChangeHandler({...data, cards: data.cards.filter((c,i) => i !== indx)})
    axios.post(`${process.env.REACT_APP_API}/dashboard/product/inDepth/deleteCard`,{id},{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
      toast.success(res.data.message, {position: "top-right"})
      getData(PID)
    })
  }

  function informationsChangeNested(value, name,indx) {
    ChangeHandler({...data, cards: data.cards.map((obj, i) => {
      if(i == indx) {
        return {...obj, [name]:value}
      }
      return obj
    })})
  }
  function deleteImageNested(indx) {
    ChangeHandler({...data, cards: data.cards.map((obj, i) => {
      if(i == indx) {
        return {...obj, image:null}
      }
      return obj
  })}) 
  }
  function submit(e) {
    e.preventDefault()
    setIsLoading(true)
    const formData = new FormData();
    
    formData.append("titleEn", data.titleEn)
    formData.append("titleAr", data.titleAr)
    data.cards.forEach((d,i) => {
        formData.append(`cards[${i}][image]`, d.image)
        formData.append(`cards[${i}][headLineEn]`, d.headLineEn||"")
        formData.append(`cards[${i}][headLineAr]`, d.headLineAr||"")
        formData.append(`cards[${i}][descriptionEn]`, d.descriptionEn||"")
        formData.append(`cards[${i}][descriptionAr]`, d.descriptionAr||"")
        formData.append(`cards[${i}][category]`, d.category||"")
    })
    formData.append("PID", PID)


    /*************GeneralInformationData*************/
    axios.post(`${process.env.REACT_APP_API}/dashboard/product/inDepth/save`,formData,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
      toast.success(res.data.message, {position: "top-right"})
      setIsLoading(false)
      getData(PID)
    })
  }

  return (
    <Card>
      <CardBody>
        <Row className="mt-3">
          <Col>
            <div className="d-flex">
              <Label>Main Title (English)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="Label"
              name="titleEn"
              value={data.titleEn}
              onChange={e =>
                ChangeHandler({ ...data, titleEn: e.target.value })
              }
            />
          </Col>
          <Col>
            <div className="d-flex">
              <Label>Main Title (Arabic)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="عنوان"
              dir="rtl"
              name="titleAr"
              value={data.titleAr}
              onChange={e =>
                ChangeHandler({ ...data, titleAr: e.target.value })
              }
            />
          </Col>
        </Row>
        <hr style={{ margin: "20px -20px 20px -20px" }} />
        {data.cards.map((card, indx) => (
          <div key={indx}>
            <Row className="mt-3">
              <Col xs="12">
                <div>
                  <div className="d-flex">
                    <Label>Attachement Image</Label>
                    <label className="ms-auto optional-label--color">
                      (Optional)
                    </label>
                  </div>
                  <Input
                    type="file"
                    name="image"
                    className="form-control form-control-lg "
                    onChange={e =>
                      handleAcceptedImage(e.target.files, "indepth", e, indx)
                    }
                    style={{ borderRadius: 0 }}
                    multiple
                  />
                </div>
              </Col>
              <Col>
                <div className="dropzone-previews" id="file-previews">
                  {card["image"] ? (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={card["image"]?.name}
                              src={
                                card["image"]?.preview
                                  ? card["image"]?.preview
                                  : card["image"]
                              }
                              style={{ objectFit: "cover" }}
                            />
                          </Col>
                          <Col>
                            <Link to="#" className="text-muted ">
                              {card["image"]?.name}
                            </Link>
                          </Col>
                          <Col>
                            <div className="image__options">
                              <button
                                type="button"
                                className="p-0"
                                onClick={e =>
                                  (setImgSrc(card["image"].preview), setisOpen(true))
                                }
                              >
                                <i className="far fa-eye  fs-5 p-0"></i>
                              </button>
                              <button
                                type="button"
                                style={{ color: "#ff3838" }}
                                onClick={e =>
                                  deleteImageNested(
                                   indx
                                  )
                                }
                              >
                                <i className="bx bx-trash fs-4"></i>
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  ) : null}
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <div className="d-flex">
                  <Label>Category</Label>
                  <label className="ms-auto optional-label--color">
                    (Optional)
                  </label>
                </div>
                <select
                  className="form-select fs-5 input_cus"
                  name="category"
                  value={card["category"]}
                  onChange={e =>
                    informationsChangeNested(e.target.value, e.target.name,indx)
                  }
                >
                  <option value="">Select</option>
                  <option value="1">First</option>
                  <option value="2">Second</option>
                  <option value="3">Third</option>
                  <option value="4">Fourth</option>
                </select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <div className="d-flex">
                  <Label>Head Line (English)</Label>
                  <label className="ms-auto optional-label--color">
                    (Optional)
                  </label>
                </div>
                <Input
                  className="input_cus"
                  placeholder="Label"
                  name="headLineEn"
                  value={card["headLineEn"]}
                  onChange={e =>
                    informationsChangeNested(e.target.value, e.target.name,indx)
                  }
                />
              </Col>
              <Col>
                <div className="d-flex">
                  <Label>Head Line (Arabic)</Label>
                  <label className="ms-auto optional-label--color">
                    (Optional)
                  </label>
                </div>
                <Input
                  className="input_cus"
                  placeholder="عنوان"
                  dir="rtl"
                  name="headLineAr"
                  value={card["headLineAr"]}
                  onChange={e =>
                    informationsChangeNested(e.target.value, e.target.name,indx)
                  }
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <div className="d-flex">
                  <Label>Description (English)</Label>
                  <label className="ms-auto optional-label--color">
                    (Optional)
                  </label>
                </div>
                <textarea
                  className="form-control  mb-0"
                  rows="5"
                  placeholder="Description"
                  name="descriptionEn"
                  value={card["descriptionEn"]}
                  onChange={e =>
                    informationsChangeNested(e.target.value, e.target.name,indx)
                  }
                />
              </Col>
              <Col>
                <div className="d-flex">
                  <Label>Description (Arabic)</Label>
                  <label className="ms-auto optional-label--color">
                    (Optional)
                  </label>
                </div>
                <textarea
                  className="form-control  mb-0"
                  rows="5"
                  placeholder="وصف"
                  name="descriptionAr"
                  value={card["descriptionAr"]}
                  onChange={e =>
                    informationsChangeNested(e.target.value, e.target.name,indx)
                  }
                  dir="rtl"
                />
              </Col>
            </Row>
           <Button
              color="none"
              variant="primary"
              className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
              id="unknown-btn"
              style={{
                color: "#ff3838",
                boxShadow: "none",
                height: "45px",
                borderRadius: 0,
              }}
              onClick={e => {
                deleteCard(indx, card.id)
              }}
            >
              <i className="bx bx-trash-alt fs-4 p-0 mr-1"></i> Delete
            </Button>
        
                <hr style={{ margin: "5px -20px 20px -20px" }} />
              
          </div>
        ))}
        <Button
          onClick={() => handleAddRowNested()}
          color="none"
          className="mt-2 fs-5 fw-bold p-0 text-left"
          style={btnStyle}
        >
          <i className="bx bx-plus-circle fs-4"></i> <span>Add Card</span>
        </Button>
        <hr style={{ margin: "20px -20px 20px -20px" }} />
        <Row>
          <Col className="d-flex justify-content-end">
            <button
              type="button"
              className="add-project__submit-button"
              onClick={e => submit(e)}
            >
              {isLoading ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </Col>
        </Row>     
      </CardBody>
    </Card>
  )
}

export default InDepthForm
const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
