import React, { Fragment, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"

const Questions = () => {
  const [active, setActive] = useState(false)
  const [rows2, setrows2] = useState([{ id: 1 }])
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [subTitleEn, setSubTitleEn] = useState("")
  const [subTitleAr, setSubTitleAr] = useState("")
  const [multiData, setMultiData] = useState([{
    id:1, 
    questionEn:"",
    questionAr: '', 
    answerEn:'', 
    answerAr: '',  
  }])

  function handleAddRowNested() {
    const modifiedRows = [...rows2]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows2(modifiedRows)
    setMultiData(data => data.concat({
    id:modifiedRows.length, 
    questionEn:"",
    questionAr: '', 
    answerEn:'', 
    answerAr: ''}))
  }

  function handleRemoveRow(id) {
    if (id !== 0) {
      setrows2(r => r.filter((x,i) => i !== id))
      setMultiData(d => d.filter((x,i) => i !== id))
    }
  }
  function multiChange(e, key, type) {
    setMultiData(points =>
      points.map((data, index) => {
        if (index === key && type === "qen") {
          return { ...data, questionEn: e.target.value }
        }  else if (index === key && type === "qar") {
          return { ...data, questionAr: e.target.value }
        } else if (index === key && type === "ansen") {
          return { ...data, answerEn: e.target.value }
        } else if (index === key && type === "ansar") {
          return { ...data, answerAr: e.target.value }
        } 
        return data
      })
    )
  }

  function submit(e) {
    e.preventDefault()
    const formData = new FormData()
    const data = {
      nameEn,
      nameAr, 
      subTitleEn, 
      subTitleAr
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    multiData.forEach((data, i) => {
      formData.append(`questions[${i}][questionEn]`, data.questionEn)
      formData.append(`questions[${i}][questionAr]`, data.questionAr)
      formData.append(`questions[${i}][answerEn]`, data.answerEn)
      formData.append(`questions[${i}][answerAr]`, data.answerAr)
      
    })
    axios.post(`${process.env.REACT_APP_API}/admin/`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).authToken
        }`,
      },
    })
  }


  return (
    <div>
            <Form className="border-0">
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Title Header (english)</Label>
                    <Input
                      id="itemnameen"
                      name="itemnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Title Header"
                      value={titleEn}
                      onChange={e => setTitleEn(e.target.value)}
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Title Header (arabic)</Label>
                    <Input
                      id="itemnamear"
                      name="itemnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={titleAr}
                      onChange={e => setTitleAr(e.target.value)}
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Sub Title (english)</Label>
                    <Input
                      id="brandnameen"
                      name="brandnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Sub Title"
                      value={subTitleEn}
                      onChange={e => setSubTitleEn(e.target.value)}
                    />
                  </div>
                </Col>
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Sub Title (arabic)</Label>
                    <Input
                      id="brandnamear"
                      name="brandnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={subTitleAr}
                      onChange={e => setSubTitleAr(e.target.value)}
                      dir="rtl"
                    />
                  </div>
                </Col>
              </Row>
              <hr style={{ margin: "25px -20px 25px -20px" }} />
              {(rows2 || []).map((formRow, key) => (
                <Fragment key={key}>
                  
                  <Row>
                    <Col sm="6">
                      <div>
                        <Label htmlFor="productdesc">Questions (english)</Label>
                        <Input
                          id="itemnameen"
                          name="itemnameen"
                          type="text"
                          className="form-control input_cus"
                          placeholder="e.g. Title Header"
                          value={multiData[key].questionEn}
                          onChange={e =>
                            multiChange(e,key, "qen")
                          }
                        />
                      </div>
                    </Col>

                    <Col sm="6">
                      <div>
                        <Label htmlFor="productdesc">Questions (arabic)</Label>
                        <Input
                          id="itemnamear"
                          name="itemnamear"
                          type="text"
                          className="form-control input_cus"
                          placeholder="مثال. "
                          value={multiData[key].questionAr}
                          onChange={e =>
                            multiChange(e,key, "qar")
                          }
                          dir="rtl"
                          lang="ar"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col sm="6">
                      <div>
                        <Label htmlFor="productdesc">Answer (English)</Label>
                        <textarea
                          className="form-control  mb-0"
                          id="specification"
                          rows="3"
                          placeholder="Enter your Answer here"
                          value={multiData[key].answerEn}
                          onChange={e =>
                            multiChange(e,key, "ansen")
                          }
                        />
                      </div>
                    </Col>
                    <Col sm="6">
                      <div>
                        <Label htmlFor="productdesc">Answer (Arabic)</Label>
                        <textarea
                          className="form-control  mb-0"
                          id="specification"
                          rows="3"
                          placeholder="اكتب وصف هنا"
                          value={multiData[key].answerAr}
                          onChange={e =>
                            multiChange(e,key, "ansar")
                          }
                          dir="rtl"
                        />
                      </div>
                    </Col>
                  </Row>
                  {key !== 0 ? (
                    <Button
                      color="none"
                      variant="primary"
                      className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                      id="unknown-btn"
                      style={{
                        color: "#ff3838",
                        boxShadow: "none",
                        height: "45px",
                        borderRadius: 0,
                      }}
                      onClick={e => {
                        handleRemoveRow(key)
                      }}
                    >
                      <i className="bx bx-trash-alt fs-4 p-0 mr-1" /> Delete
                    </Button>
                  ) : null}
                  {
                    key!=0?
                    <hr style={{ margin: "5px -20px 20px -20px" }} />
                    :
                    <hr style={{ margin: "25px -20px 20px -20px" }} />
                  }
                </Fragment>
              ))}
              
              <Button
                onClick={() => {
                  handleAddRowNested()
                }}
                color="none"
                className="mt-2 fs-5 fw-bold p-0 text-left"
                style={btnStyle}
              >
                <i className="bx bx-plus-circle fs-4" />{" "}
                <span>Add Information</span>
              </Button>
              <hr style={{ margin: "20px -20px 20px -20px" }} />
            </Form>

      <Row>
        <Col className="d-flex justify-content-end gap-2">
          <div className="save__btn top m-0">
            <button>
              {active ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Questions

const cardStyle = {
  borderTop: "2px solid #dedede",
}

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
