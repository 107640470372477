import React, { useLayoutEffect, useState } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
  } from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import prettyBytes from "pretty-bytes"
import Lightbox from "react-image-lightbox"
import axios from "axios"
import { toast } from "react-toastify"

const ColorFooter = () => {
  const [active, setActive] = useState(false)
  const [prev, setPrev] = useState(null);
  const [isOpen, setisOpen] = useState(false)
  
  const [data, setData] = useState({
    firstColor: "#ffffff",
    meddleColor: "#ffffff",
    lastColor: "#ffffff",
    dividerColor: "#ffffff",
    fontColor: "#ffffff"
  })


  function prevSelectImage(e, img) {
    e.preventDefault()
    setPrev(img)
    setisOpen(true)
  }


  function submit() {
    let formData = new FormData()
    setActive(true)

    for (let key in data) {
      formData.append(key, data[key])
    }

    axios.post(`${process.env.REACT_APP_API}/dashboard/footer/footerColors`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
      setActive(false)
      toast.success(res.data.message)
      getData()
    })
  }

  let getData =  () => { 
    axios.get(`${process.env.REACT_APP_API}/dashboard/footer/footerColors`,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
      setData(res.data.data)
    })
  }


  useLayoutEffect(() => {
    getData()
  },[])


  return (
    <Card>
      <CardBody>
      <div>
           {isOpen ? (
          <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
              setisOpen(!isOpen);
              }}
          />
          ) : null} 
        <Form  className="border-0 pb-2">
          <Row className="mb-1">
            <Col>
              <div className="d-flex">
                <Label htmlFor="colorID">First Color</Label>
              </div>
              <div className="d-flex">
                <input
                  type="color"
                  className="form-control colorInput"
                  name="firstColor"
                  value={data.firstColor}
                  onChange={e => setData({...data, [e.target.name]: e.target.value})}
                />
                <Input
                  type="text"
                  className="color__name rounded-0"
                  name="firstColor"
                  value={data.firstColor}
                  onChange={e => setData({...data, [e.target.name]: e.target.value})}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <div className="d-flex">
                <Label htmlFor="colorID">Meddle Color</Label>
              </div>
              <div className="d-flex">
                <input
                  type="color"
                  className="form-control colorInput"
                  name="meddleColor"
                  value={data.meddleColor}
                  onChange={e => setData({...data, [e.target.name]: e.target.value})}
                />
                <Input
                  type="text"
                  className="color__name rounded-0"
                  name="meddleColor"
                  value={data.meddleColor}
                  onChange={e => setData({...data, [e.target.name]: e.target.value})}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <div className="d-flex">
                <Label htmlFor="colorID">last Color</Label>
              </div>
              <div className="d-flex">
                <input
                  type="color"
                  className="form-control colorInput"
                  name="lastColor"
                  value={data.lastColor}
                  onChange={e => setData({...data, [e.target.name]: e.target.value})}
                />
                <Input
                  type="text"
                  className="color__name rounded-0"
                  name="lastColor"
                  value={data.lastColor}
                  onChange={e => setData({...data, [e.target.name]: e.target.value})}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <div className="d-flex">
                <Label htmlFor="colorID">Divider Color</Label>
              </div>
              <div className="d-flex">
                <input
                  type="color"
                  className="form-control colorInput"
                  name="dividerColor"
                  value={data.dividerColor}
                  onChange={e => setData({...data, [e.target.name]: e.target.value})}
                />
                <Input
                  type="text"
                  className="color__name rounded-0"
                  name="dividerColor"
                  value={data.dividerColor}
                  onChange={e => setData({...data, [e.target.name]: e.target.value})}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <div className="d-flex">
                <Label htmlFor="colorID">Font Color</Label>
              </div>
              <div className="d-flex">
                <input
                  type="color"
                  className="form-control colorInput"
                  name="fontColor"
                  value={data.fontColor}
                  onChange={e => setData({...data, [e.target.name]: e.target.value})}
                />
                <Input
                  type="text"
                  className="color__name rounded-0"
                  name="fontColor"
                  value={data.fontColor}
                  onChange={e => setData({...data, [e.target.name]: e.target.value})}
                />
              </div>
            </Col>
          </Row>

        <hr style={{ margin: "20px -20px 20px -20px" }} />

        <Row>
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0 ">
              <button type="button" onClick={e => submit()}>
                {active ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
      </CardBody>
    </Card>
    
  )
}

export default ColorFooter
