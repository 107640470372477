import axios from "axios"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Input, Label, Row } from "reactstrap"
import ReactLoading from "react-loading"
import { toast } from "react-toastify"
import Select from "react-select"

const GeneralInformationForm = ({
  data,
  ChangeHandler,
  handleAcceptedImage,
  setImgSrc,
  setisOpen,
  setPID,
  PID
}) => {
  const [layoutiCategories, setLayoutiCategories] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  
  function submit(e) {
    e.preventDefault()
    setIsLoading(true)
    let formData = new FormData()
    /*************GeneralInformationData*************/
    const genData = {
      ...data,
      template: data.designTemplate,
      launch: data.launchProject,
      image: data.attachment,
      thumbnailImage: data.thumbnail,
    }
    delete genData.attachment
    delete genData.thumbnail
    delete genData.designTemplate
    delete genData.launchProject
    delete genData.category

    for (let key in genData) {
      formData.append(key, genData[key])
    }
    
    if(PID) { 
      formData.append("key", PID)
    }

    data.category.forEach((cat,i) => {
      formData.append(`categories[${i}][category]`, cat.value)
    })
    
    /*************GeneralInformationData*************/
    axios.post(`${process.env.REACT_APP_API}/dashboard/product/generalInformation/save`,formData,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
      toast.success("General Informations Saved Successfully", {position: "top-right"})
      setIsLoading(false)
      setPID(res.data.data.PID)
    })
  }

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API}/dashboard/configurations/layoutiCategories`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setLayoutiCategories(res.data.data)
      })
  }, [])

  return (
    <Card>
      <CardBody>
        <Row>
          <Col>
            <div className="mb-3">
              <Label htmlFor="productdesc">Category</Label>
              {/* <select
                isMulti={true}

                className="form-select fs-5 input_cus"
                name="category"
                value={data["category"]}
                onChange={e =>
                  ChangeHandler({ ...data, category: e.target.value })
                }
              >
                <option value="">Select</option>
                {layoutiCategories.map(category => (
                  <option value={category.id} key={category.id}>
                    {category.nameEn}
                  </option>
                ))}
              </select> */}

              <Select
              isMulti={true}
              name="category"
              value={data["category"]}
              onChange={e =>
                ChangeHandler({ ...data, category: e})
              }
              options={layoutiCategories.map(category => ({label: category.nameEn, value: category.id}))}
              className="select2-selection"
              style={{height: "auto !important"}}
            />

            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="mb-3">
              <div className="d-flex">
                <Label>Design template</Label>
                <label className="ms-auto optional-label--color">
                  (Optional)
                </label>
              </div>
              <select
                className="form-select fs-5 input_cus"
                name="designTemplate"
                value={data["designTemplate"]}
                onChange={e =>
                  ChangeHandler({ ...data, designTemplate: e.target.value })
                }
              >
                <option value="">Select</option>
                <option value="1">Design Apps</option>
                <option value="2">Branding</option>
              </select>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="mb-3">
              <div className="d-flex">
                <Label>Launch Project</Label>
                <label className="ms-auto optional-label--color">
                  (Optional)
                </label>
              </div>
              <Input
                className="input_cus"
                placeholder="Label"
                name="launch"
                value={data["launchProject"]}
                onChange={e =>
                  ChangeHandler({ ...data, launchProject: e.target.value })
                }
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs="12">
            <div>
              <div className="d-flex">
                <Label>Attachement Image</Label>
                <label className="ms-auto optional-label--color">
                  (Optional)
                </label>
              </div>
              <Input
                type="file"
                name="image"
                className="form-control form-control-lg "
                onChange={e =>
                  handleAcceptedImage(e.target.files, "generalAttach", e)
                }
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col>
            <div className="dropzone-previews" id="file-previews">
              {data["attachment"] ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={data["attachment"]?.name}
                          src={
                            data["attachment"]?.preview
                              ? data["attachment"]?.preview
                              : data["attachment"]
                          }
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {data["attachment"]?.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{data["attachment"]?.formattedSize}</strong>
                        </p>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            type="button"
                            className="p-0"
                            onClick={e => (
                              setImgSrc(data["attachment"].preview),
                              setisOpen(true)
                            )}
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            type="button"
                            style={{ color: "#ff3838" }}
                            onClick={e => (
                              ChangeHandler({ ...data, attachment: null }),
                              e.target.name == ""
                            )}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs="12">
            <div>
              <div className="d-flex">
                <Label>Thumbnail Image</Label>
                <label className="ms-auto optional-label--color">
                  (Optional)
                </label>
              </div>
              <Input
                type="file"
                name="image"
                className="form-control form-control-lg "
                onChange={e =>
                  handleAcceptedImage(e.target.files, "generalThumb", e)
                }
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col>
            <div className="dropzone-previews" id="file-previews">
              {data["thumbnail"] ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={data["thumbnail"]?.name}
                          src={
                            data["thumbnail"]?.preview
                              ? data["thumbnail"]?.preview
                              : data["thumbnail"]
                          }
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {data["thumbnail"]?.name}
                        </Link>
                        <p className="mb-0">
                          <strong>{data["thumbnail"]?.formattedSize}</strong>
                        </p>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            type="button"
                            className="p-0"
                            onClick={e => (
                              setImgSrc(data["thumbnail"].preview),
                              setisOpen(true)
                            )}
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            type="button"
                            style={{ color: "#ff3838" }}
                            onClick={e =>
                              ChangeHandler({ ...data, thumbnail: null })
                            }
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>

        <Row className="mb-1">
          <Col>
            <div className="d-flex">
              <Label htmlFor="colorID">Color</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <div className="d-flex">
              <input
                type="color"
                className="form-control colorInput"
                name="backGroundColor"
                value={data["color"]}
                onChange={e =>
                  ChangeHandler({ ...data, color: e.target.value })
                }
              />
              <Input
                type="text"
                className="color__name rounded-0"
                name="backGroundColor"
                value={data["color"]}
                onChange={e =>
                  ChangeHandler({ ...data, color: e.target.value })
                }
              />
            </div>
          </Col>
        </Row>
        <hr style={{ margin: "20px -20px 20px -20px" }} />
        <Row>
          <Col className="d-flex justify-content-end">
            <button
              type="button"
              className="add-project__submit-button"
              onClick={e => submit(e)}
            >
              {isLoading ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default GeneralInformationForm
