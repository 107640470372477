/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { useDispatch } from "react-redux"
import { setTitle } from "../../../store/actions"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import BlogCardParagraph from "./BlogCardParagraph"
import classnames from "classnames"
import ReactLoading from "react-loading"
import SweetAlert from "react-bootstrap-sweetalert"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  FormFeedback,
  Label,
  Row,
  CardBody,
  Container,
  Input,
} from "reactstrap"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"
import * as uuid from "uuid"
import prettyBytes from "pretty-bytes"
import SeoComponent from "./SeoComponent"

const Blogs = props => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.UserData.user)
  const history = useHistory()
  const [isOpen, setisOpen] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [prev, setPrev] = useState(null)
  const [blogCategoryData, setBlogCategoryData] = useState([])
  const [authors, setAuthors] = useState([])
  const PARAGRAPH_MODAL = paraId => {
    return {
      id: paraId,
      titleEn: "",
      titleAr: "",
      descriptionEn: "",
      descriptionAr: "",
      cards: [],
      status: "new",
    }
  }
  const BLOG_MODAL = blogId => {
    return {
      id: blogId,
      category: "",
      author: "",
      titleEn: "",
      titleAr: "",
      images: [],
      image: [],
      paragraphs: [PARAGRAPH_MODAL(uuid.v4())],
      seoData: {
        focusKeypharseEn: "",
        focusKeypharseAr: "",
        seoTitleEn: "",
        seoTitleAr: "",
        descriptionEn: "",
        descriptionAr: "",
        slugEn: "",
        slugAr: "",
        facebookTitleEn: "",
        facebookTitleAr: "",
        facebookDescriptionEn: "",
        facebookDescriptionAr: "",
        facebookImage: null,
      },
      status: "new",
    }
  }
  const [blogData, setBlogData] = useState([])

  function handleAcceptedFiles(files, itemIndex) {
    let oldImage = [...blogData[itemIndex]["images"]]
    //
    files = [...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    handleChangeBlogData("images", [...oldImage, ...files], itemIndex)
  }
  function handleAcceptedFilesForSingleImage(files, itemIndex) {
    files = [...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    handleChangeBlogData("image", files[0], itemIndex)
  }
  function deleteSelectImage(itemIndex, imageId, status) {
    let modifiedBlogData = [...blogData]
    if (status == "old") {
      modifiedBlogData[itemIndex]["images"] = modifiedBlogData[itemIndex][
        "images"
      ].filter(image => image.id != imageId)
    } else {
      modifiedBlogData[itemIndex]["images"].splice(imageId, 1)
    }
    setBlogData(modifiedBlogData)
  }
  function deleteSelectImagePara(itemIndex, status, paraIndex, imageId) {
    let modifiedBlogData = [...blogData]

    if (status == "old") {
      modifiedBlogData[itemIndex]["paragraphs"][paraIndex]["cards"] =
        modifiedBlogData[itemIndex]["paragraphs"][paraIndex]["cards"].filter(
          image => image.id != imageId
        )
    } else {
      modifiedBlogData[itemIndex]["paragraphs"][paraIndex]["cards"].splice(
        imageId,
        1
      )
    }
    setBlogData(modifiedBlogData)
  }
  function addNestedBlogRow() {
    let modifiedBlogData = [...blogData]
    modifiedBlogData.push(BLOG_MODAL(uuid.v4()))
    setBlogData(modifiedBlogData)
    setCol(state => state.concat(false))
  }
  function addNestedBlogRowPara(index) {
    let modifiedBlogData = [...blogData]

    modifiedBlogData[index].paragraphs.push(PARAGRAPH_MODAL(uuid.v4()))
    setBlogData(modifiedBlogData)
  }
  function removeNestedBlogRow(blogId) {
    let modifiedBlogData = [...blogData]
    modifiedBlogData = modifiedBlogData.filter(blog => blog.id != blogId)
    setBlogData(modifiedBlogData)
  }
  function handleChangeBlogData(keyName, keyValue, itemIndex) {
    let modifiedBlogData = [...blogData]
    modifiedBlogData[itemIndex][keyName] = keyValue
    setBlogData(modifiedBlogData)
  }
  function handleChangeBlogParaData(keyName, keyValue, itemIndex, paraIndex) {
    let modifiedBlogData = [...blogData]
    modifiedBlogData[itemIndex]["paragraphs"][paraIndex][keyName] = keyValue
    setBlogData(modifiedBlogData)
  }
  function handleChangeBlogSeoData(keyName, keyValue, itemIndex) {
    let modifiedBlogData = [...blogData]
    modifiedBlogData[itemIndex]["seoData"][keyName] = keyValue
    setBlogData(modifiedBlogData)
  }
  function removeNestedBlogRowPara(index, paraId) {
    let modifiedBlogData = [...blogData]
    modifiedBlogData[index].paragraphs = modifiedBlogData[
      index
    ].paragraphs.filter(para => para.id != paraId)
    setBlogData(modifiedBlogData)
  }

  const [active, setActive] = useState(false)
  const [deleteLoad, setDelete] = useState(false)
  const [col, setCol] = useState([false])
  const t_col1 = id => {
    setCol(col =>
      col.map((c, i) => {
        if (i == id) {
          return !c
        }
        return false
      })
    )
    //
  }
  const [photoIndex, setphotoIndex] = useState(0)

  function prevSelectImage(e, id) {
    e.preventDefault()
    setphotoIndex(id)
    setisOpen(!isOpen)
  }
  function submit(e, index, status) {
    e.preventDefault()
    setActive(true)
    const formData = new FormData()
    let blog = blogData[index]
    if (status == "old") {
      formData.append(`id`, blog?.id)
    }
    formData.append(`category`, blog?.category)
    formData.append(`author`, blog?.author)
    formData.append(`titleEn`, blog?.titleEn)
    formData.append(`titleAr`, blog?.titleAr)
    formData.append(`image`, blog?.image)
    blog.images &&
      blog.images.forEach((image, index) => {
        formData.append(`Images[${index}][image]`, image)
      })
    blog.paragraphs &&
      blog.paragraphs.forEach((paragraph, index) => {
        formData.append(`Paragraphs[${index}][titleEn]`, paragraph.titleEn)
        formData.append(`Paragraphs[${index}][titleAr]`, paragraph.titleAr)
        formData.append(
          `Paragraphs[${index}][descriptionEn]`,
          paragraph.descriptionEn
        )
        formData.append(
          `Paragraphs[${index}][descriptionAr]`,
          paragraph.descriptionAr
        )
        paragraph.cards &&
          paragraph.cards.forEach((image, imageIndex) => {
            formData.append(
              `Paragraphs[${index}][ParagraphImages][${imageIndex}][image]`,
              image
            )
          })
      })
    formData.append(`seo_focusKeypharseEn`, blog.seoData.focusKeypharseEn)
    formData.append(`seo_focusKeypharseAr`, blog.seoData.focusKeypharseAr)
    formData.append(`seo_seoTitleEn`, blog.seoData.seoTitleEn)
    formData.append(`seo_seoTitleAr`, blog.seoData.seoTitleAr)
    formData.append(`seo_slugEn`, blog.seoData.slugEn)
    formData.append(`seo_slugAr`, blog.seoData.slugAr)
    formData.append(`seo_descriptionEn`, blog.seoData.descriptionEn)
    formData.append(`seo_descriptionAr`, blog.seoData.descriptionAr)
    formData.append(`seo_facebookImage`, blog.seoData.facebookImage)
    formData.append(`seo_facebookTitleEn`, blog.seoData.facebookTitleEn)
    formData.append(`seo_facebookTitleAr`, blog.seoData.facebookTitleAr)
    formData.append(
      `seo_facebookDescriptionEn`,
      blog.seoData.facebookDescriptionEn
    )
    formData.append(
      `seo_facebookDescriptionAr`,
      blog.seoData.facebookDescriptionAr
    )

    axiosConfig
      .post(`dashboard/blog/add`, formData, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
          "Content-Type": "application/json",
        },
      })
      .then(res => {
        setActive(false)
        setMsg("Updated Successfully")
        setsuccess_msg(true)
      })
      .catch(err => {
        setActive(false)
        toast.error("Something went wrong")
      })
  }
  function getAuthors() {
    axiosConfig
      .get(`/dashboard/blog/author`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        //
        setAuthors(res.data.data)
      })
      .catch(err => {})
  }
  function getBlogData() {
    axiosConfig
      .get(`/dashboard/blog/category`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        //
        setBlogCategoryData(res.data.data)
      })
      .catch(err => {})
  }
  function getBlogsData() {
    axiosConfig
      .get(`/dashboard/blog`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        let modififedBlog = res.data.data
        setBlogData(data =>
          modififedBlog.map(blog => {
            setCol(state => state.concat(false))
            return {
              id: blog.id,
              category: blog.category.id,
              author: blog.author?.id,
              titleEn: blog.titleEn,
              titleAr: blog.titleAr,
              image: blog?.image,
              images: blog.Images ? blog.Images : [],
              paragraphs: blog.Paragraphs ? blog.Paragraphs : [],
              seoData: blog["SEO"],
            }
          })
        )
      })
      .catch(err => {})
  }
  function deleteImage(status, imageId, itemIndex) {
    if (status === "old") {
      setDelete(true)
      axiosConfig
        .post(
          "dashboard/blog/deleteImage",
          { id: imageId },
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("authUser")).accessToken
              }`,
            },
          }
        )
        .then(res => {
          setDelete(false)
          setMsg("Deleted Successfully")
          setsuccess_msg(true)

          deleteSelectImage(itemIndex, imageId, status)
        })
        .catch(err => {
          setDelete(false)

          toast.error("Something Went Wrong")
        })
    } else {
      deleteSelectImage(itemIndex, imageId, status)
    }
  }
  function deleteImagePara(status, imageId, itemIndex, paraIndex) {
    if (status === "old") {
      setDelete(true)
      axiosConfig
        .post(
          "dashboard/blog/deleteParagraphImage",
          { id: imageId },
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("authUser")).accessToken
              }`,
            },
          }
        )
        .then(res => {
          setDelete(false)
          setMsg("Deleted Successfully")
          setsuccess_msg(true)
          deleteSelectImagePara(itemIndex, status, paraIndex, imageId)
        })
        .catch(err => {
          setDelete(false)

          toast.error("Something Went Wrong")
        })
    } else {
      deleteSelectImagePara(itemIndex, status, paraIndex, imageId)
    }
  }
  function deleteCard(status, cardId) {
    if (status === "old") {
      setDelete(true)
      axiosConfig
        .post(
          "dashboard/blog/delete",
          { id: cardId },
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("authUser")).accessToken
              }`,
            },
          }
        )
        .then(res => {
          setDelete(false)
          setMsg("Deleted Successfully")
          setsuccess_msg(true)
          setBlogData(data => data.filter(d => d.id !== cardId))
        })
        .catch(err => {
          setDelete(false)

          toast.error("Something Went Wrong")
        })
    } else {
      deleteSelectImage(cardId)
    }
  }
  function deleteCardParagraph(status, cardId, itemId) {
    if (status === "old") {
      setDelete(true)
      axiosConfig
        .post(
          "dashboard/blog/deleteParagraph",
          { id: itemId },
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("authUser")).accessToken
              }`,
            },
          }
        )
        .then(res => {
          setDelete(false)
          setMsg("Deleted Successfully")
          setsuccess_msg(true)

          removeNestedBlogRowPara(cardId, itemId)
        })
        .catch(err => {
          setDelete(false)

          toast.error("Something Went Wrong")
        })
    } else {
      removeNestedBlogRowPara(cardId, itemId)
    }
  }
  useEffect(() => {
    getBlogsData()
    getBlogData()
    getAuthors()
  }, [])

  useEffect(() => {
    if (user && user.role == 2) {
      history.push("/")
      return
    }
  }, [user])

  useEffect(() => {
    dispatch(setTitle("Blogs"))
  }, [])

  return (
    <React.Fragment>
      {isOpen ? (
        <Lightbox
          mainSrc={photoIndex}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
      <div className="page-content">
        <MetaTags>
          <title>Starter Page | Layouti</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <CardBody>
              {success_msg ? (
                <SweetAlert
                  title={msg}
                  success
                  confirmBtnBsStyle="success"
                  onConfirm={() => {
                    setsuccess_msg(false)
                  }}
                  onCancel={() => {
                    setsuccess_msg(false)
                  }}
                ></SweetAlert>
              ) : null}
              <div className="accordion" id="accordion">
                {blogData &&
                  blogData.map((blog, index) => (
                    <div className="accordion-item" key={blog.id}>
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className={classnames(
                            "accordion-button",
                            "fw-medium",
                            "fw-bold",
                            "fs-5",
                            { collapsed: !col[index] }
                          )}
                          type="button"
                          onClick={() => t_col1(index)}
                          style={{ cursor: "pointer" }}
                        >
                          {blog?.titleEn ? blog?.titleEn : "Blog Title"}
                        </button>
                      </h2>
                      <Collapse
                        isOpen={col[index]}
                        className="accordion-collapse"
                      >
                        <div className="accordion-body">
                          <Form className="border-0 p-0">
                            <Row>
                              <Col xs="6">
                                <Label htmlFor="productdesc">Category</Label>
                                <select
                                  className="form-select fs-5 input_cus"
                                  name="category"
                                  value={blog?.category}
                                  onChange={e => {
                                    handleChangeBlogData(
                                      e.target.name,
                                      e.target.value,
                                      index
                                    )
                                  }}
                                >
                                  <option>Select Category</option>
                                  {blogCategoryData &&
                                    blogCategoryData.map(category => (
                                      <option
                                        value={category.id}
                                        key={category.id}
                                      >
                                        {category.nameEn}
                                      </option>
                                    ))}
                                </select>
                              </Col>
                              <Col xs="6">
                                <div className="mb-3">
                                  <Label htmlFor="productdesc">Author</Label>
                                  <select
                                    className="form-select fs-5 input_cus"
                                    name="author"
                                    value={blog?.author}
                                    onChange={e => {
                                      handleChangeBlogData(
                                        e.target.name,
                                        e.target.value,
                                        index
                                      )
                                    }}
                                  >
                                    <option value="" disabled>
                                      Select Author
                                    </option>
                                    {authors &&
                                      authors.map(author => (
                                        <option
                                          value={author.id}
                                          key={author.id}
                                        >
                                          {author.nameEn}
                                        </option>
                                      ))}
                                  </select>
                                  <FormFeedback className="fs-6">
                                    This Fileld Is Required!
                                  </FormFeedback>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xs="6">
                                <div className="mb-3">
                                  <Label htmlFor="titleEn">Title English</Label>
                                  <input
                                    id="titleEn"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g. example.com"
                                    name="titleEn"
                                    value={blog?.titleEn}
                                    onChange={e => {
                                      handleChangeBlogData(
                                        e.target.name,
                                        e.target.value,
                                        index
                                      )
                                    }}
                                  />
                                  <FormFeedback className="fs-6">
                                    This Fileld Is Required!
                                  </FormFeedback>
                                </div>
                              </Col>
                              <Col xs="6">
                                <div className="mb-3">
                                  <Label htmlFor="titleAr">Title Arabic</Label>
                                  <input
                                    id="titleAr"
                                    type="text"
                                    className="form-control input_cus"
                                    placeholder="e.g. example.com"
                                    name="titleAr"
                                    value={blog?.titleAr}
                                    onChange={e => {
                                      handleChangeBlogData(
                                        e.target.name,
                                        e.target.value,
                                        index
                                      )
                                    }}
                                  />
                                  <FormFeedback className="fs-6">
                                    This Fileld Is Required!
                                  </FormFeedback>
                                </div>
                              </Col>
                            </Row>

                            <Row className="mb-3">
                              <Col>
                                <div className="mb-3">
                                  <Label htmlFor="metatitle">
                                    thumbnail Image
                                  </Label>
                                  <div className="mb-3">
                                    <input
                                      type="file"
                                      className="form-control form-control-lg"
                                      name="image"
                                      onChange={e => {
                                        handleAcceptedFilesForSingleImage(
                                          e.target.files,
                                          index
                                        )
                                      }}
                                      style={{ borderRadius: 0 }}
                                    />
                                    <FormFeedback className="fs-6">
                                      This Fileld Is Required!
                                    </FormFeedback>
                                  </div>
                                </div>
                              </Col>

                              {blog?.image && (
                                <Row
                                  className="align-items-center"
                                  style={{
                                    paddingLeft: "14px",
                                    paddingRight: "0px",
                                  }}
                                >
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={blog?.image?.name}
                                      src={
                                        blog?.image?.preview
                                          ? blog?.image?.preview
                                          : blog?.image
                                      }
                                      style={{ objectFit: "cover" }}
                                    />
                                  </Col>

                                  <Col>
                                    <Link to="#" className="text-muted ">
                                      {blog?.image?.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>
                                        {blog?.image?.formattedSize}
                                      </strong>
                                    </p>
                                  </Col>

                                  <Col className="p-0">
                                    <div className="image__options">
                                      <button
                                        className="p-0"
                                        type="button"
                                        onClick={e =>
                                          prevSelectImage(
                                            e,
                                            blog?.image?.preview
                                              ? blog?.image?.preview
                                              : blog?.image
                                          )
                                        }
                                      >
                                        <i className="far fa-eye  fs-5 p-0"></i>
                                      </button>
                                      <button
                                        type="button"
                                        className="p-0"
                                        style={{ color: "#ff3838" }}
                                        onClick={e =>
                                          handleChangeBlogData(
                                            "image",
                                            null,
                                            index
                                          )
                                        }
                                      >
                                        <i className="bx bx-trash fs-4"></i>
                                      </button>
                                    </div>
                                  </Col>
                                </Row>
                              )}
                            </Row>

                            <Row className="mb-3">
                              <Col>
                                <div className="d-flex justify-content-between">
                                  <Label className="fs-5">slide images</Label>
                                </div>
                                <Form className=" pb-0 border-0">
                                  <Input
                                    type="file"
                                    className="form-control form-control-lg "
                                    onChange={e =>
                                      handleAcceptedFiles(e.target.files, index)
                                    }
                                    style={{ borderRadius: 0 }}
                                    multiple
                                  />
                                  {/* <Dropzone
                                    onDrop={acceptedFiles => {
                                      handleAcceptedFiles(
                                        acceptedFiles,
                                        index
                                      )
                                    }}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div className="dropzone">
                                        <div
                                          className="dz-message needsclick"
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <div className="dz-message needsclick">
                                            <div className="mb-3">
                                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                                            </div>
                                            <h4>
                                              Drop files here or click to
                                              upload.
                                            </h4>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone> */}

                                  <div
                                    className={`dropzone-previews ${
                                      blog?.images.length != 0 && "mt-3"
                                    }`}
                                    id="file-previews"
                                  >
                                    {blog?.images &&
                                      blog?.images.map(
                                        (blogImage, blogImageIndex) => {
                                          return (
                                            <Card
                                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                              key={blogImageIndex + "-file"}
                                            >
                                              <div className="p-2">
                                                <Row className="align-items-center">
                                                  <Col className="col-auto">
                                                    <img
                                                      data-dz-thumbnail=""
                                                      height="80"
                                                      className="avatar-sm rounded bg-light"
                                                      alt={blogImage?.name}
                                                      src={
                                                        blogImage?.preview
                                                          ? blogImage?.preview
                                                          : blogImage?.image
                                                      }
                                                      style={{
                                                        objectFit: "cover",
                                                      }}
                                                    />
                                                  </Col>
                                                  <Col>
                                                    <Link
                                                      to="#"
                                                      className="text-muted "
                                                    >
                                                      {blogImage?.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                      <strong>
                                                        {
                                                          blogImage?.formattedSize
                                                        }
                                                      </strong>
                                                    </p>
                                                  </Col>
                                                  <Col>
                                                    <div className="image__options">
                                                      <button
                                                        type="button"
                                                        className="p-0"
                                                        onClick={e =>
                                                          prevSelectImage(
                                                            e,
                                                            blogImage?.preview
                                                              ? blogImage?.preview
                                                              : blogImage?.image
                                                          )
                                                        }
                                                      >
                                                        <i className="far fa-eye  fs-5 p-0" />
                                                      </button>
                                                      <button
                                                        type="button"
                                                        style={{
                                                          color: "#ff3838",
                                                        }}
                                                        onClick={e =>
                                                          deleteImage(
                                                            blogImage?.id
                                                              ? "old"
                                                              : "new",
                                                            blogImage?.id
                                                              ? blogImage?.id
                                                              : blogImageIndex,
                                                            index
                                                          )
                                                        }
                                                      >
                                                        <i className="bx bx-trash fs-4" />
                                                      </button>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </div>
                                            </Card>
                                          )
                                        }
                                      )}
                                  </div>
                                </Form>
                              </Col>
                            </Row>

                            <BlogCardParagraph
                              handleChangeBlogParaData={
                                handleChangeBlogParaData
                              }
                              paragraphData={blog.paragraphs}
                              index={index}
                              deleteCardParagraph={deleteCardParagraph}
                              deleteImagePara={deleteImagePara}
                            />

                            <>
                              <hr style={{ margin: "5px -20px 20px -20px" }} />
                              <Button
                                onClick={() => {
                                  addNestedBlogRowPara(index)
                                }}
                                color="none"
                                className="mt-2 fs-5 fw-bold p-0 text-left"
                                style={btnStyle}
                              >
                                <i className="bx bx-plus-circle fs-4" />{" "}
                                <span>Add Paragraph</span>
                              </Button>
                              <hr style={{ margin: "20px -20px 0px -20px" }} />
                            </>
                            <h3 className="fs-4 text-black fw-bolder mb-3 mt-3">
                              SEO
                            </h3>
                            <SeoComponent
                              seoData={blog.seoData}
                              handleChangeBlogSeoData={handleChangeBlogSeoData}
                              itemIndex={index}
                            />

                            <Row>
                              <Col className="d-flex justify-content-end gap-2 col pt-1 pb-2 ">
                                <div className="save__btn top m-0 me-3">
                                  <button
                                    type="button"
                                    onClick={e =>
                                      submit(
                                        e,
                                        index,
                                        blog?.status ? blog?.status : "old"
                                      )
                                    }
                                  >
                                    {active ? (
                                      <ReactLoading
                                        type={"spin"}
                                        color={"#ffffff"}
                                        height={30}
                                        width={30}
                                      />
                                    ) : (
                                      "Save"
                                    )}
                                  </button>
                                </div>
                                <div className="delete__btn top m-0">
                                  <button
                                    type="button"
                                    onClick={e =>
                                      deleteCard(
                                        blog?.status == "new" ? "new" : "old",
                                        blog.id
                                      )
                                    }
                                  >
                                    {deleteLoad ? (
                                      <ReactLoading
                                        type={"spin"}
                                        color={"#ffffff"}
                                        height={30}
                                        width={30}
                                      />
                                    ) : (
                                      "Delete Blog"
                                    )}
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </Collapse>
                    </div>
                  ))}
              </div>
              <div className="">
                <div className="add__btn top m-0 mt-2">
                  <button
                    onClick={() => {
                      addNestedBlogRow()
                    }}
                    className="d-block pl-2 pr-2"
                  >
                    <i className="bx bx-plus-circle"></i> Add Blog
                  </button>
                </div>
              </div>
              {isOpen ? (
                <Lightbox
                  mainSrc={prev}
                  enableZoom={false}
                  onCloseRequest={() => {
                    setisOpen(!isOpen)
                  }}
                />
              ) : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Blogs

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "green",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
