import axios from "axios"
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Input, Label, Row } from "reactstrap"
import ReactLoading from "react-loading"
import { toast } from "react-toastify"
const ProjectNameForm = ({
  data,
  ChangeHandler,
  PID
}) => {
  const [isLoading, setIsLoading] = useState(false)

  function submit(e) {
    e.preventDefault()
    setIsLoading(true)
    
    let d =  {
      nameEn: data.ProjectName_nameEn,
      nameAr: data.ProjectName_nameAr, 
      slogenEn: data.ProjectName_slogenEn, 
      slogenAr: data.ProjectName_slogenAr, 
      descriptionEn: data.ProjectName_descriptionEn,
      descriptionAr: data.ProjectName_descriptionAr,
      PID
    }
    
    /*************GeneralInformationData*************/
    axios.post(`${process.env.REACT_APP_API}/dashboard/product/projectName/save`,d,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
      toast.success(res.data.message, {position: "top-right"})
      setIsLoading(false)
    })
  }

  return (
    <Card>
      <CardBody>
        <Row className="mt-3">
          <Col>
            <div className="d-flex">
              <Label>Title (English)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="Label"
              name="nameEn"
              value={
                data["ProjectName_nameEn"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, ProjectName_nameEn: e.target.value}
                )
              }
            />
          </Col>
          <Col>
            <div className="d-flex">
              <Label>Title (Arabic)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="عنوان"
              dir="rtl"
              name="nameAr"
              value={
                data["ProjectName_nameAr"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, ProjectName_nameAr: e.target.value}
                )
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <div className="d-flex">
              <Label>Slogan (English)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="Label"
              name="slogenEn"
              value={
                data["ProjectName_slogenEn"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, ProjectName_slogenEn: e.target.value}
                )
              }
            />
          </Col>
          <Col>
            <div className="d-flex">
              <Label>Slogan (Arabic)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="عنوان"
              dir="rtl"
              name="slogenAr"
              value={
                data["ProjectName_slogenAr"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, ProjectName_slogenAr: e.target.value}
                )
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <div className="d-flex">
              <Label>Description (English)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <textarea
              className="form-control  mb-0"
              rows="5"
              placeholder="Description"
              name="descriptionEn"
              value={
                data["ProjectName_descriptionEn"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, ProjectName_descriptionEn: e.target.value}
                )
              }
            />
          </Col>
          <Col>
            <div className="d-flex">
              <Label>Description (Arabic)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <textarea
              className="form-control  mb-0"
              rows="5"
              placeholder="وصف"
              name="descriptionAr"
              value={
                data["ProjectName_descriptionAr"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, ProjectName_descriptionAr: e.target.value}
                )
              }
              dir="rtl"
            />
          </Col>
        </Row>
        <hr style={{ margin: "20px -20px 20px -20px" }} />
        <Row>
          <Col className="d-flex justify-content-end">
            <button
              type="button"
              className="add-project__submit-button"
              onClick={e => submit(e)}
            >
              {isLoading ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default ProjectNameForm
