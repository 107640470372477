import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import Select from "react-select"
import axios from "axios"
import { toast } from "react-toastify"

const HightlitsDesign = ({ data }) => {
  const [active, setActive] = useState(false)
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [subTitleEn, setSubTitleEn] = useState("")
  const [subTitleAr, setSubTitleAr] = useState("")
  const [highLights, setHighLights] = useState([])
  const [multiData, setMultiData] = useState([])

  let arr =[];
  multiData?.map(l => {
    let ind = { label: l.name, value: l.id }
    arr.push(ind)
  })
  const optionGroup = [
    {
      options:arr
    },
  ];


  function submit(e) {
    setActive(true)
    e.preventDefault()
    const formData = new FormData()
    const data = {
      titleEn,
      titleAr,
      subTitleEn,
      subTitleAr,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    highLights.forEach((point, i) => {
      formData.append(`highlits[${i}]`, point.value)
    })
    axios
      .post(
        `${process.env.REACT_APP_API}/dashboard/learnUi/highlitsDesign`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        toast.success("Data Saved Successfully", { position: "top-right" })
        setActive(false)
      })
  }
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API}/dashboard/learnUi/highlitsDesign/projects`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        // 
        setMultiData(res.data.data)
      })

    if (data) {
      setTitleEn(data.titleEn)
      setTitleAr(data.titleAr)
      setSubTitleEn(data.subTitleEn)
      setSubTitleAr(data.subTitleAr)
      let arr2 = []
      data?.highlits?.forEach(val => {
        multiData?.forEach(op => {
          if(val == op.id){
            // return { label: op.name, value: op.value}
            let xx = op
            let y =  { label: xx.name, value: xx.id}
            // 
            arr2.push(y)
          }
        })
      })
      // 
      setHighLights(arr2)
    }
  }, [data])

  return (
    <div>
      <Form className="border-0 p-0">
        <Row>
          <Col className="">
            <Form className="border-0">
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Title Of Hightlight (english)
                    </Label>
                    <Input
                      id="itemnameen"
                      name="itemnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Title Attend"
                      value={titleEn}
                      onChange={e => setTitleEn(e.target.value)}
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Title Of What Offer (arabic)
                    </Label>
                    <Input
                      id="itemnamear"
                      name="itemnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={titleAr}
                      onChange={e => setTitleAr(e.target.value)}
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Sub Title Of Hightlight (english)
                    </Label>
                    <Input
                      id="itemnameen"
                      name="itemnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Sub Title Attend"
                      value={subTitleEn}
                      onChange={e => setSubTitleEn(e.target.value)}
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Sub Title Of What Offer (arabic)
                    </Label>
                    <Input
                      id="itemnamear"
                      name="itemnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={subTitleAr}
                      onChange={e => setSubTitleAr(e.target.value)}
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div>
                    <Label htmlFor="productdesc">Multi Select HighLight</Label>
                    <Select
                      value={highLights}
                      isMulti={true}
                      onChange={e => {
                        setHighLights(e)
                      }}
                      options={optionGroup}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Form>
      <hr style={{ margin: "25px -20px 20px -20px" }} />
      <Row>
        <Col className="d-flex justify-content-end gap-2">
          <div className="save__btn top m-0">
            <button onClick={e => submit(e)}>
              {active ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default HightlitsDesign

const cardStyle = {
  borderTop: "2px solid #dedede",
}

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
