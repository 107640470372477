import axios from "axios"
import React, { useEffect, useState } from "react"
import {Card, Col, Input, Label, Row } from "reactstrap"
import Select from "react-select"
import { Link } from "react-router-dom"

const ImageForm = ({
  data,
  ChangeHandler,
  setImgSrc,
  setisOpen,
  handleAcceptedImage
}) => {
  return (
    <>
      <Row className="mb-3">
          <Col xs="12">
            <div>
              <div className="d-flex">
                <Label>Image</Label>
                <label className="ms-auto optional-label--color">
                  (Optional)
                </label>
              </div>
              <Input
                type="file"
                name="image"
                className="form-control form-control-lg "
                onChange={e => handleAcceptedImage(e.target.files, "designappimageimage",e)}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col>
            <div className="dropzone-previews" id="file-previews">
              {data["DesignAppImage_image"]? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={data["DesignAppImage_image"]?.name}
                          src={
                            data["DesignAppImage_image"]?.preview
                              ? data["DesignAppImage_image"]?.preview
                              : data["DesignAppImage_image"]
                          }
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {data["DesignAppImage_image"]?.name}
                        </Link>
                        <p className="mb-0">
                            <strong>{data["DesignAppImage_image"]?.formattedSize}</strong>
                        </p>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            type="button"
                            className="p-0"
                            onClick={e =>
                             (setImgSrc(data["DesignAppImage_image"].preview), setisOpen(true))
                            }
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            type="button"
                            style={{ color: "#ff3838" }}
                            onClick={e => (ChangeHandler(
                                {DesignAppImage_image: null}
                            ), e.target.name == "")}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>
    </>
  )
}

export default ImageForm
