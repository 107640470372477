import React, { Fragment } from "react"
import { Button, Col, Input, Label, Row } from "reactstrap"

const IntroducingForm = ({ data, ChangeHandler }) => {

    function handleAddRowNested() {
        ChangeHandler({...data, cards: data.cards.concat({titleEn: "", titleAr: "", pointEn: "", pointAr: ""})})
    }
    
    function deleteCard(indx) {
        ChangeHandler({...data, cards: data.cards.filter((c,i) => i !== indx)})
    }
    
    function informationsChangeNested(value, name,indx) {
    ChangeHandler({...data, cards: data.cards.map((obj, i) => {
        if(i == indx) {
        return {...obj, [name]:value}
        }
        return obj
    })})
    }


  return (
    <>
      <Row className="">
        <Col>
          <div className="d-flex">
            <Label>Title (English)</Label>
            <label className="ms-auto optional-label--color">(Optional)</label>
          </div>
          <Input
            className="input_cus"
            placeholder="Label"
            name="DesignAppIntorducing_titleEn"
            value={data["DesignAppIntorducing_titleEn"]}
            onChange={e =>
              ChangeHandler({
                ...data,
                DesignAppIntorducing_titleEn: e.target.value,
              })
            }
          />
        </Col>
        <Col>
          <div className="d-flex">
            <Label>Title (Arabic)</Label>
            <label className="ms-auto optional-label--color">(Optional)</label>
          </div>
          <Input
            className="input_cus"
            placeholder="عنوان"
            dir="rtl"
            name="DesignAppIntorducing_titleAr"
            value={data["DesignAppIntorducing_titleAr"]}
            onChange={e =>
              ChangeHandler({
                ...data,
                DesignAppIntorducing_titleAr: e.target.value,
              })
            }
          />
        </Col>
      </Row>

      <Row className="mt-3">
          <Col>
            <div className="d-flex">
              <Label>Description (English)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <textarea
              className="form-control  mb-0"
              rows="5"
              placeholder="Description"
              name="DesignAppIntorducing_descriptionEn"
              value={
                data["DesignAppIntorducing_descriptionEn"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, DesignAppIntorducing_descriptionEn: e.target.value}
                )
              }
            />
          </Col>
          <Col>
            <div className="d-flex">
              <Label>Description (Arabic)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <textarea
              className="form-control  mb-0"
              rows="5"
              placeholder="وصف"
              name="DesignAppIntorducing_descriptionAr"
              value={
                data["DesignAppIntorducing_descriptionAr"]
              }
              onChange={e =>
                  ChangeHandler(
                  {...data, DesignAppIntorducing_descriptionAr: e.target.value}
                )
              }
              dir="rtl"
            />
          </Col>
        </Row>
    </>
  )
}

export default IntroducingForm
const btnStyle = {
    boxShadow: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    paddingLeft: "0"
  }