/* eslint-disable react/prop-types */
import axios from 'axios';
import React, {useState, useRef, useEffect} from 'react';
import { Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import PulseLoader from "react-spinners/PulseLoader";
import "@pathofdev/react-tag-input/build/index.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import * as xlsx from 'xlsx'

import '../specification.scss'
import axiosConfig from 'axiosConfig';


const FemaleForm = (props) => {
    const [isOpen, setisOpen] = useState(false);
    const [loading, setLoading] = useState(false)    
    const [gender, setGender] = useState('');
    const [file, setFile] = useState('');
    
    const close = (type) => {
        document.querySelector('#NamesForm').reset()
        props.clickHandle(null, false, type)
        setGender('')
        setFile(null)
    }

    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet);
                setFile(json)
            };
            reader.readAsArrayBuffer(e.target.files[0]);
        }
    }

    function submitHandler() {
        setLoading(true)
        let formData = new FormData()
        formData.append("file", file);
        
        axiosConfig.post(`/dashboard/uiAvatar/uiGenerator/femaleNames/import`,formData, {
            headers: {
              'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
            }
          })
        .then(res => {
            setLoading(false)
            if(res.data.error) {
                
                return;
            }
            setGender('')
            setFile(null)
            close(true)
            props.update(res.data.data,"female")
        })
    }

    return(
        <div className={`overlay_coup ${props.active? "active":""}`}>
            {isOpen ? (
            <Lightbox
                mainSrc={image.preview}
                enableZoom={false}
                onCloseRequest={() => {
                setisOpen(!isOpen);
                }}
            />
            ) : null}
            <Form id='NamesForm' onSubmit={(e) => e.preventDefault()} style={{paddingBottom: "70px", height: "240px"}}>
                <div className='head_form'>
                    <div className='log'>
                    <i className='bx bxs-user-badge fs-3'></i>
                        Add Female Names
                    </div>
                    <button onClick={() => close(false)}><i className='dripicons dripicons-cross'></i></button>
                </div>
                <div className='inputs__wrap '>
                    <Row>
                        <Col>
                            <div className="mb-3" >
                                <Label htmlFor="productdesc">
                                File
                                </Label>
                                <Input
                                    type="file"
                                    className="form-control form-control-lg "
                                    onChange={e => setFile(e.target.files[0])}
                                    style={{borderRadius: 0}}
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='foot_form'>
                    <button className='cancel' onClick={() => close(false)}>Cancel</button>
                    <button className='save' onClick={submitHandler}>{loading?<PulseLoader color={"#ffffff"} loading={true}  size={10} />:"Send"}</button>
                </div>
            </Form>
        </div>
    );
};

export default FemaleForm;
