import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import classnames from "classnames"
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import Sticky from "react-sticky-el"
import Admins from "./Components/Admins"
import Category from "./Components/Category"
import EmailShot from "./Components/EmailShot"
import CharacterPdf from "./Components/CharacterPdf"
import "./configration.scss"
import axios from "axios"
import { useSelector, useDispatch } from "react-redux"
import { setTitle } from "../../../store/actions"
import { useHistory } from "react-router-dom"
import Deliverable from "./Components/Deliverable"
import ScopeOfWork from "./Components/ScopeOfWork/ScopeOfWork"
import Budget from "./Components/Budget"
import WhatWeNeed from "./Components/WhatWeNeed"
import axiosConfig from "axiosConfig"
import CategoryForLayouti from "./Components/CategoryForLayouti"
import CategoryForFAQ from "./Components/CategoryForFAQ"
import CategoryFor365Design from "./Components/CategoryFor365Design"
import CategoryForLayoutiWork from "./Components/CategoryForLayoutiWork"
import Terms from "./Components/Terms"

const Options = () => {
  const dispatch = useDispatch()
  const user = useSelector(state => state.UserData.user)
  const history = useHistory()

  const [data, setData] = useState([])
  const [admins, setAdmins] = useState([])
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [email, setEmail] = useState(null)
  const [verticalActiveTab, setverticalActiveTab] = useState("1")

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  function userChanges(user, type) {
    if (type === "active") {
      setActive(users => users.concat(user))
      setUnActive(users => users.filter(u => u.key !== user.key))
    } else if (type === "unactive") {
      setActive(users => users.filter(u => u.key !== user.key))
      setUnActive(users => users.concat(user))
    } else if (type === "del") {
      setActive(users => users.filter(u => u.key !== user.key))
    } else {
      setPending(users => users.filter(u => u.key !== user.key))
      setUnActive(users => users.concat(user))
    }
  }

  useEffect(() => {
    if (user && user.role == 2) {
      history.push("/")
      return
    }
  }, [user])

  const [layoutiCategories, setLayoutiCategories] = useState([])
  const [layoutiCategoriesWorks, setLayoutiCategoriesWorks] = useState([])
  const [layoutiCategoriesFAQ, setLayoutiCategoriesFAQ] = useState([])
  const [design365, setDesign365] = useState([])
  const [scopeOfWork, setScopeOfWork] = useState([])
  const [deliverables, setDeliverables] = useState([])
  const [blogData, setBlogData] = useState([])
  const [terms, setTerms] = useState([])


  function getTerms() {
    axiosConfig
      .get(`/dashboard/configurations/terms`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setTerms(res.data.data)
      })
      .catch(err => {
        
      })
  }

  
  function getLayoutiData() {
    axiosConfig
      .get(`/dashboard/configurations/layoutiCategoriesServices`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        
        setLayoutiCategories(res.data.data)
      })
      .catch(err => {
        
      })
  }
  function get365DesignData() {
    axiosConfig
      .get(`/dashboard/365Design/category`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setDesign365(res.data.data)
      })
      .catch(err => {
        
      })
  }
  
  function getLayoutiDataWorks() {
    axiosConfig
      .get(`/dashboard/configurations/layoutiCategories`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        
        setLayoutiCategoriesWorks(res.data.data)
      })
      .catch(err => {
        
      })
  }
  function getFaqData() {
    axiosConfig
      .get(`/dashboard/configurations/layoutiCategoriesFaqs`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        
        setLayoutiCategoriesFAQ(res.data.data)
      })
      .catch(err => {
        
      })
  }
  
  function getScopeOfWork() {
    axiosConfig
      .get(`/dashboard/configurations/scopes`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        
        setScopeOfWork(res.data.data)
      })
      .catch(err => {
        
      })
  }
  function getDeliverables() {
    axiosConfig
      .get(`/dashboard/configurations/deliverable`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        
        setDeliverables(res.data.data)
      })
      .catch(err => {
        
      })
  }

  useEffect(() => {
    dispatch(setTitle("configurations"))
    getLayoutiDataWorks()
    get365DesignData()
    getLayoutiData()
    getFaqData()
    getScopeOfWork()
    getDeliverables()
    getTerms()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Settings | Layouti</title>
        </MetaTags>
        <Container fluid>
          <Col>
            <Row>
              <Col md="3">
                <Sticky topOffset={-300} >
                  <Nav
                    pills
                    className="flex-column justify-content-between navs_aside"
                  >
                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "1",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("1")
                        }}
                      >
                        Categories for 365 Design
                      </NavLink>
                    </NavItem>

                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "8",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("8")
                        }}
                      >
                        Categories For Layouti Services
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "18",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("18")
                        }}
                      >
                        Categories For Layouti Work
                      </NavLink>
                    </NavItem>

                    

                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "10",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("10")
                        }}
                      >
                        Categories For FAQ
                      </NavLink>
                    </NavItem>

                    

                   
                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "16",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("16")
                        }}
                      >
                        Budget - contact Us
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "17",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("17")
                        }}
                      >
                        Services - contact Us
                      </NavLink>
                    </NavItem>

                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "12",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("12")
                        }}
                      >
                        Scope of work
                      </NavLink>
                    </NavItem>

                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "13",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("13")
                        }}
                      >
                        Deliverable
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav___item">
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          "": true,
                          active: verticalActiveTab === "14",
                          tab_link: true,
                        })}
                        onClick={() => {
                          toggleVertical("14")
                        }}
                      >
                        Terms
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Sticky>
              </Col>
              <Col md="9">
                <TabContent
                  activeTab={verticalActiveTab}
                  className="text-muted table__content mt-4 mt-md-0"
                >
                  
                  <TabPane tabId="1">
                    <CategoryFor365Design
                      categories={design365}
                      getData={get365DesignData}
                    />
                  </TabPane>
                  {/* layouti */}
                  <TabPane tabId="8">
                    <CategoryForLayouti
                      categories={layoutiCategories}
                      getData={getLayoutiData}
                    />
                  </TabPane>
                  {/* layouti w*/}
                  <TabPane tabId="18">
                    <CategoryForLayoutiWork
                      categories={layoutiCategoriesWorks}
                      getData={getLayoutiDataWorks}
                    />
                  </TabPane>
                  
                  {/* // faq */}
                  <TabPane tabId="10">
                    <CategoryForFAQ
                      categories={layoutiCategoriesFAQ}
                      getData={getFaqData}
                    />
                  </TabPane>
                  
                  <TabPane tabId="16">
                    <Budget categories={categories} />
                  </TabPane>
                  <TabPane tabId="17">
                    <WhatWeNeed categories={categories} />
                  </TabPane>
                  <TabPane tabId="12">
                    <ScopeOfWork scopeOfWork={scopeOfWork} />
                  </TabPane>
                  <TabPane tabId="13">
                    <Deliverable
                      deliverables={deliverables}
                      getData={getDeliverables}
                    />
                  </TabPane>

                 
                   <TabPane tabId="14">
                    <Terms
                        terms={terms}
                        getData={getTerms}
                      />
                  </TabPane>
                  
                </TabContent>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Options

const navsStyle = {
  cursor: "pointer",
  borderRadius: 0,
}
