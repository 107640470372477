import React, { useLayoutEffect, useState } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
  } from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import prettyBytes from "pretty-bytes"
import Lightbox from "react-image-lightbox"
import axios from "axios"
import { toast } from "react-toastify"

const Paragraph = () => {
  const [active, setActive] = useState(false)
  const [prev, setPrev] = useState(null);
  const [isOpen, setisOpen] = useState(false)
  
  const [data, setData] = useState({
    descriptionEn: "",
    descriptionAr: "",
    rightDescriptionEn: "",
    rightDescriptionAr: "",
    rightLink: "",
    leftDescriptionEn: "",
    leftDescriptionAr: "",
    leftLink: "",
  })



  function submit() {
    let formData = new FormData()
    setActive(true)

    for (let key in data) {
      formData.append(key, data[key])
    }

    axios.post(`${process.env.REACT_APP_API}/dashboard/navbar/ourProductsStatic`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
      setActive(false)
      toast.success(res.data.message)
      getData()
    })
  }

  let getData =  () => { 
    axios.get(`${process.env.REACT_APP_API}/dashboard/navbar/ourProductsStatic`,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
      setData(res.data.data)
    })
  }


  useLayoutEffect(() => {
    getData()
  },[])


  return (
    <Card>
      <CardBody>
      <div>
           {isOpen ? (
          <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
              setisOpen(!isOpen);
              }}
          />
          ) : null} 
      <Form className="border-0 pb-2">
      
       

        

       
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (English)</Label>
              <Input
                id="brandnameen"
                name="descriptionEn"
                type="textarea"
                className="form-control "
                placeholder="e.g. Title"
                value={data.descriptionEn}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
                rows="6"
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (Arabic)</Label>
              <Input
                id="brandnamear"
                name="descriptionAr"
                type="textarea"
                className="form-control "
                placeholder="مثال. عنوان"
                value={data.descriptionAr}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
                dir="rtl"
                rows="6"
              />
            </div>
          </Col>
        </Row>

        <hr style={{ margin: "20px -20px 20px -20px" }} />
        <h2 style={{ margin: "20px 0px" }}>Left Paragraph</h2>
        <hr style={{ margin: "20px -20px 20px -20px" }} />
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (English)</Label>
              <Input
                id="brandnameen"
                name="leftDescriptionEn"
                type="textarea"
                className="form-control "
                placeholder="e.g. Title"
                value={data.leftDescriptionEn}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
                rows="6"
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (Arabic)</Label>
              <Input
                id="brandnamear"
                name="leftDescriptionAr"
                type="textarea"
                className="form-control "
                placeholder="مثال. عنوان"
                value={data.leftDescriptionAr}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
                dir="rtl"
                rows="6"
              />
            </div>
          </Col>
        </Row>
        <Row>
         <Col>
          <div className="mb-3 mt-3">
            <Label htmlFor="productdesc">Link</Label>
              <Input
                id="title"
                name="leftLink"
                type="text"
                className="form-control input_cus"
                placeholder="e.g.link"
                value={data.leftLink}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
              />
                              
          </div>
        </Col>
      </Row>

      <hr style={{ margin: "20px -20px 20px -20px" }} />
        <h2 style={{ margin: "20px 0px" }}>Right Paragraph</h2>
        <hr style={{ margin: "20px -20px 20px -20px" }} />
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (English)</Label>
              <Input
                id="brandnameen"
                name="rightDescriptionEn"
                type="textarea"
                className="form-control "
                placeholder="e.g. Title"
                value={data.rightDescriptionEn}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
                rows="6"
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (Arabic)</Label>
              <Input
                id="brandnamear"
                name="rightDescriptionAr"
                type="textarea"
                className="form-control "
                placeholder="مثال. عنوان"
                value={data.rightDescriptionAr}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
                dir="rtl"
                rows="6"
              />
            </div>
          </Col>
        </Row>
        <Row>
         <Col>
          <div className="mb-3 mt-3">
            <Label htmlFor="productdesc">Link</Label>
              <Input
                id="title"
                name="rightLink"
                type="text"
                className="form-control input_cus"
                placeholder="e.g.link"
                value={data.rightLink}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
              />
                              
          </div>
        </Col>
      </Row>
                         

       

        <hr style={{ margin: "20px -20px 20px -20px" }} />

        <Row>
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0 ">
              <button type="button" onClick={e => submit()}>
                {active ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
      </CardBody>
    </Card>
    
  )
}

export default Paragraph
