import React, { useLayoutEffect, useState } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
  } from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import prettyBytes from "pretty-bytes"
import Lightbox from "react-image-lightbox"
import axios from "axios"
import { toast } from "react-toastify"

const LayoutiDetails = () => {
  const [active, setActive] = useState(false)
  const [prev, setPrev] = useState(null);
  const [isOpen, setisOpen] = useState(false)
  
  const [data, setData] = useState({
    logo: null,
    nameEn: "",
    nameAr: "",
    sloganEn: "",
    sloganAr: "",
    descriptionEn: "",
    descriptionAr: "",
    copyWriterEn: "",
    copyWriterAr: "",
    createdByEn: "",
    createdByAr: "",
    titleEn: "",
    titleAr: "",
    subDescriptionEn: "",
    subDescriptionAr: "",
  })


  function prevSelectImage(e, img) {
    e.preventDefault()
    setPrev(img)
    setisOpen(true)
  }


  function submit() {
    let formData = new FormData()
    setActive(true)

    for (let key in data) {
      formData.append(key, data[key])
    }

    axios.post(`${process.env.REACT_APP_API}/dashboard/footer/footerContent`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
      setActive(false)
      toast.success(res.data.message)
      getData()
    })
  }

  let getData =  () => { 
    axios.get(`${process.env.REACT_APP_API}/dashboard/footer/footerContent`,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
      setData(res.data.data)
    })
  }


  useLayoutEffect(() => {
    getData()
  },[])


  return (
    <Card>
      <CardBody>
      <div>
           {isOpen ? (
          <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
              setisOpen(!isOpen);
              }}
          />
          ) : null} 
      <Form className="border-0 pb-2">
      <Row>
          <Col xs="12">
            <div className="">
              <Label htmlFor="productdesc">Light Header</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => setData({...data, ["lightHeader"]: Object.assign(e.target.files[0], {
                  preview: URL.createObjectURL(e.target.files[0]),
                  formattedSize: prettyBytes(e.target.files[0].size),
                })})}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12 mb-3">
            <div className="dropzone-previews" id="file-previews">
              {data.lightHeader? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={data.lightHeader.name}
                          src={data.lightHeader.preview||data.lightHeader}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {data.lightHeader.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e =>
                              prevSelectImage(e, data.lightHeader.preview||data.lightHeader)
                            }
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                          type="button"
                            style={{ color: "#ff3838" }}
                            onClick={e =>  setData({...data, lightHeader: null})}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
      </Row>
      <Row>
          <Col xs="12">
            <div className="">
              <Label htmlFor="productdesc">Dark Header</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => setData({...data, ["darkHeader"]: Object.assign(e.target.files[0], {
                  preview: URL.createObjectURL(e.target.files[0]),
                  formattedSize: prettyBytes(e.target.files[0].size),
                })})}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12 mb-3">
            <div className="dropzone-previews" id="file-previews">
              {data.darkHeader? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={data.darkHeader.name}
                          src={data.darkHeader.preview||data.darkHeader}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {data.darkHeader.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e =>
                              prevSelectImage(e, data.darkHeader.preview||data.darkHeader)
                            }
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                          type="button"
                            style={{ color: "#ff3838" }}
                            onClick={e =>  setData({...data, darkHeader: null})}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
      </Row>
      
      <Row>
          <Col xs="12">
            <div className="">
              <Label htmlFor="productdesc">Light Mobile</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => setData({...data, ["lightMobile"]: Object.assign(e.target.files[0], {
                  preview: URL.createObjectURL(e.target.files[0]),
                  formattedSize: prettyBytes(e.target.files[0].size),
                })})}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12 mb-3">
            <div className="dropzone-previews" id="file-previews">
              {data.lightMobile? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={data.lightMobile.name}
                          src={data.lightMobile.preview||data.lightMobile}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {data.lightMobile.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e =>
                              prevSelectImage(e, data.lightMobile.preview||data.lightMobile)
                            }
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                          type="button"
                            style={{ color: "#ff3838" }}
                            onClick={e =>  setData({...data, lightMobile: null})}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
      </Row>
      <Row>
          <Col xs="12">
            <div className="">
              <Label htmlFor="productdesc">Dark Mobile</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => setData({...data, ["darkMobile"]: Object.assign(e.target.files[0], {
                  preview: URL.createObjectURL(e.target.files[0]),
                  formattedSize: prettyBytes(e.target.files[0].size),
                })})}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12 mb-3">
            <div className="dropzone-previews" id="file-previews">
              {data.darkMobile? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={data.darkMobile.name}
                          src={data.darkMobile.preview||data.darkMobile}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {data.darkMobile.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e =>
                              prevSelectImage(e, data.darkMobile.preview||data.darkMobile)
                            }
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                          type="button"
                            style={{ color: "#ff3838" }}
                            onClick={e =>  setData({...data, darkMobile: null})}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
      </Row>
      <Row>
          <Col xs="12">
            <div className="">
              <Label htmlFor="productdesc">Footer Logo</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => setData({...data, ["logo"]: Object.assign(e.target.files[0], {
                  preview: URL.createObjectURL(e.target.files[0]),
                  formattedSize: prettyBytes(e.target.files[0].size),
                })})}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {data.logo? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={data.logo.name}
                          src={data.logo.preview||data.logo}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {data.logo.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e =>
                              prevSelectImage(e, data.logo.preview||data.logo)
                            }
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                          type="button"
                            style={{ color: "#ff3838" }}
                            onClick={e =>  setData({...data, logo: null})}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
      </Row>
       

        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Website Name (English)</Label>
              <Input
                id="brandnameen"
                name="nameEn"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Title"
                value={data.nameEn}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Website Name (Ar)</Label>
              <Input
                id="brandnamear"
                name="nameAr"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. عنوان"
                value={data.nameAr}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Slogan (English)</Label>
              <Input
                id="brandnameen"
                name="sloganEn"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Title"
                value={data.sloganEn}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Slogan (Arabic)</Label>
              <Input
                id="brandnamear"
                name="sloganAr"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. عنوان"
                value={data.sloganAr}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (English)</Label>
              <Input
                id="brandnameen"
                name="descriptionEn"
                type="textarea"
                className="form-control "
                placeholder="e.g. Title"
                value={data.descriptionEn}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
                rows="6"
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (Arabic)</Label>
              <Input
                id="brandnamear"
                name="descriptionAr"
                type="textarea"
                className="form-control "
                placeholder="مثال. عنوان"
                value={data.descriptionAr}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
                dir="rtl"
                rows="6"
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Copywriter (English)</Label>
              <Input
                id="brandnameen"
                name="copyWriterEn"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Title"
                value={data.copyWriterEn}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Copywriter (Arabic)</Label>
              <Input
                id="brandnamear"
                name="copyWriterAr"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. عنوان"
                value={data.copyWriterAr}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Created by (English)</Label>
              <Input
                id="brandnameen"
                name="createdByEn"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Title"
                value={data.createdByEn}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Created by (Arabic)</Label>
              <Input
                id="brandnamear"
                name="createdByAr"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. عنوان"
                value={data.createdByAr}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>

        <hr style={{ margin: "20px -20px 20px -20px" }} />


        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (English)</Label>
              <Input
                id="brandnameen"
                name="titleEn"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Title"
                value={data.titleEn}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">title (Arabic)</Label>
              <Input
                id="brandnamear"
                name="titleAr"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. عنوان"
                value={data.titleAr}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (English)</Label>
              <Input
                id="brandnameen"
                name="subDescriptionEn"
                type="textarea"
                className="form-control"
                placeholder="e.g. Title"
                value={data.subDescriptionEn}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
                rows="6"
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (Arabic)</Label>
              <Input
                id="brandnamear"
                name="subDescriptionAr"
                type="textarea"
                className="form-control"
                placeholder="مثال. عنوان"
                value={data.subDescriptionAr}
                onChange={e => setData({...data, [e.target.name]: e.target.value})}
                dir="rtl"
                rows="6"
              />
            </div>
          </Col>
        </Row>


        <hr style={{ margin: "20px -20px 20px -20px" }} />

        <Row>
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0 ">
              <button type="button" onClick={e => submit()}>
                {active ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
      </CardBody>
    </Card>
    
  )
}

export default LayoutiDetails
