import React, { Fragment, useEffect, useState } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Collapse, Button
  } from "reactstrap"
  import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
// import { MultiSelect } from "react-multi-select-component";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import { MultiSelect, SelectItem } from "react-multi-select-component";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";
import axiosConfig from "axiosConfig";
import SweetAlert from "react-bootstrap-sweetalert";
// import Select from "react-select/dist/declarations/src/Select";

const ContactFAQ = ({sectionData}) => {
    const [rows2, setrows2] = useState([{ id: 1 }])
    const [titleEn, setTitleEn] = useState("")
    const [titleAr, setTitleAr] = useState("")
    const [descEn, setDescEn] = useState("")
    const [descAr, setDescAr] = useState("")

    const [multiData, setMultiData] = useState([{
      id:1, 
      questionEn: '', 
      questionAr: '', 
      category: [], 
      answerEn: '', 
      answerAr: '', 
      status: 'new', 
  }])
  
    function handleAddRowNested() {
      const modifiedRows = [...rows2]
      modifiedRows.push({ id: modifiedRows.length + 1 })
      setrows2(modifiedRows)
      setMultiData(data => data.concat({
        id:modifiedRows.length, 
        questionEn: '', 
        questionAr: '', 
        category: '', 
        answerEn: '', 
        answerAr: '', 
        status: 'new', 
      }))
    }
    function deleteCard(key,cardId,status){
      //
      if(status==='old'){
        axiosConfig.post(`/dashboard/contactPage/FAQs/deleteCard`, {id:cardId}, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }).then(res=>{
          //
          setMsg(`Deleted Successfully`)
          setsuccess_msg(true)
          handleRemoveRow(key,cardId)
          // setIsLoading(false)
        }).catch(err=>{
          //
          toast.error(err.response.data.message)
          // setIsLoading(false)
        })
      }else{
        handleRemoveRow(key,cardId)
      }
    }
  
    function handleRemoveRow(id) {
      if (id !== 0) {
        var modifiedRows = [...rows2]
        modifiedRows = modifiedRows.filter(x => x["id"] !== id)
        setrows2(modifiedRows)
        setMultiData(d => d.filter((x,i) => i !== id))
      }
    }
    
  function handleChangeData(keyName,keyValue,key) {
    let modifiedData = [...multiData]
    modifiedData[key][keyName]=keyValue
    setMultiData(modifiedData)
    
  }
  const [isLoading, setIsLoading] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  function submit() {
    const formData = new FormData()
    const data = {
      titleEn,
      titleAr,
      descriptionEn: descEn,
      descriptionAr: descAr,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    multiData.forEach((card,index)=>{
      formData.append(`cards[${index}][questionEn]`, card.questionEn)
      formData.append(`cards[${index}][questionAr]`, card.questionAr)
      formData.append(`cards[${index}][category]`, card.category)
      formData.append(`cards[${index}][answerEn]`, card.answerEn)
      formData.append(`cards[${index}][answerAr]`, card.answerAr)
    })
    // formData.append("image", image)
    setIsLoading(true)
    axiosConfig.post(`/dashboard/contactPage/FAQs`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res=>{
      //
       toast.success(res.data.message)
      setsuccess_msg(true)
      setIsLoading(false)
    }).catch(err=>{
      //
      toast.error(err.response.data.message)
      setIsLoading(false)
    })
  }
    
  useEffect(()=>{
    setTitleEn(sectionData?.titleEn)
    setTitleAr(sectionData?.titleAr)
    setDescEn(sectionData?.descriptionEn)
    setDescAr(sectionData?.descriptionAr)
    setMultiData(sectionData?.cards)
    //
  },[sectionData])

  const [layoutiCategoriesFAQ, setLayoutiCategoriesFAQ] = useState([])

  function getFaqData (){
    axiosConfig.get(`/dashboard/configurations/layoutiCategoriesFaqs`,{
        headers: {
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
        }
    }).then(res => {
        //
        setLayoutiCategoriesFAQ(res.data.data)
    }).catch(err=>{
    //
    })
}
useEffect(()=>{
  getFaqData()
},[])

  return (
    <div>
      {success_msg ? (
        <SweetAlert
          title={msg}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        >
        </SweetAlert>
      ) : null}
      <Form className="border-0 pb-2">
        <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Title (english)</Label>
                  <Input
                    id="itemnameen"
                    name="itemnameen"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Focus Keypharse"
                    value={titleEn}
                    onChange={e => setTitleEn(e.target.value)}
                  />
                </div>
              </Col>

              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Title (arabic)</Label>
                  <Input
                    id="itemnamear"
                    name="itemnamear"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. "
                    value={titleAr}
                    onChange={e => setTitleAr(e.target.value)}
                    dir="rtl"
                    lang="ar"
                  />
                </div>
              </Col>
        </Row>

        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (English)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="Enter your description here"
                value={descEn}
                onChange={e => setDescEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (Arabic)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="اكتب وصف هنا"
                value={descAr}
                onChange={e => setDescAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>
        <hr style={{ margin: "25px -20px 20px -20px" }} />
      {(multiData || []).map((formRow, key) => (
          <Fragment key={key}>
            <Row className="mt-3">
                <Col sm="12">
                  <div>
                    <Label htmlFor="productdesc">Category</Label>
                    <select
                      className="form-select fs-5 input_cus"
                      name="category"
                      value={formRow?.category ||""}
                      onChange={e => handleChangeData(e.target.name,e.target.value,key)}
                    >
                      <option >Select Category</option>
                      {
                        layoutiCategoriesFAQ&&layoutiCategoriesFAQ.map(item=>(
                          <option value={item.id} key={item.id}>{item.nameEn}</option>
                        ))
                      }
                      {/* <option value="1">Branding</option>
                      <option value="2">UI Design</option>
                      <option value="3">In-house projects</option> */}
                    </select>
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Question (English)</Label>
                    <Input
                      id="itemnameen"
                      name="questionEn"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Focus Keypharse"
                      value={formRow?.questionEn}
                      onChange={e => handleChangeData(e.target.name,e.target.value,key)}
                    />
                  </div>
                </Col>
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">Question (Arabic)</Label>
                    <Input
                      id="itemnameen"
                      name="questionAr"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Focus Keypharse"
                      value={formRow?.questionAr}
                      onChange={e => handleChangeData(e.target.name,e.target.value,key)}
                    />
                  </div>
                </Col>
              </Row>

              

              <Row className="mt-3">
                <Col sm="12">
                  <div>
                    <Label htmlFor="productdesc">Answer (English)</Label>
                    <ReactQuill
                          id="itemnameen"
                          name="itemnameen"
                          value={formRow?.answerEn}
                          onChange={data => handleChangeData('answerEn',data,key)}
                          modules={modulesQuill}
                          formats={formatsQuill}
                        />
                  </div>
                </Col>
              </Row>

                <Row className="mt-3">
                  <Col sm="12">
                    <div>
                      <Label htmlFor="productdesc">Answer (Arabic)</Label>
                      
                        <ReactQuill
                          id="itemnameen"
                          name="itemnameen"
                          value={formRow?.answerAr}
                          onChange={data => handleChangeData('answerAr',data,key)}
                          modules={modulesQuill}
                          formats={formatsQuill}
                        />
                    </div>
                  </Col>
                </Row>
          

            {key !== 0 ? (
              <Button
                color="none"
                variant="primary"
                className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                id="unknown-btn"
                style={{
                  color: "#ff3838",
                  boxShadow: "none",
                  height: "45px",
                  borderRadius: 0,
                }}
                onClick={e => {
                  deleteCard(key,formRow.id,formRow?.status=='new'?'new':'old')
                }}
              >
                <i className="bx bx-trash-alt fs-4 p-0 mr-1" /> Delete
              </Button>
            ) : null}
            {
              key!=0?
              <hr style={{ margin: "5px -20px 20px -20px" }} />
              :
              <hr style={{ margin: "25px -20px 20px -20px" }} />
            }
          </Fragment>
        ))}
        

        <Button
          onClick={() => {
            handleAddRowNested()
          }}
          color="none"
          className="mt-2 fs-5 fw-bold p-0 text-left"
          style={btnStyle}
        >
          <i className="bx bx-plus-circle fs-4" /> <span>Add FAQ</span>
        </Button>
        <hr style={{ margin: "20px -20px 0px -20px" }} />

        <Row className="mt-4">
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0">
            <button type='button' onClick={submit} disabled={isLoading?true:false}>
                {isLoading ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default ContactFAQ



const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}


const modulesQuill = {
  toolbar: {
    container: [
      [{ "header": [1, 2, 3, 4, 5, 6, false] }],
      [{ "font": [] }],
      [{ "align": [] }],
      ["bold", "italic", "underline"],
      [{ "list": "ordered" }, { "list": "bullet" }, { "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", "custom-color"] }, { "background": [] }, "link", "emoji"]
    ],
    handlers: {
      "color": function(value) {
        if (value === "custom-color") value = window.prompt("Enter Hex Color Code")
        this.quill.format("color", value)
      }
    }
  },
  keyboard: {
    bindings: {
      tab: false,
      custom: {
        key: 13,
        shiftKey: true,
        handler: function(range, context) {
          this.quill.setSelection(range.index, "silent")
          this.quill.insertText(range.index, "\n", "user")
          this.quill.setSelection(range.index + 1, "silent")
          this.quill.format("linebreak", true, "user")
        }
      },
      handleEnter: {
        key: 13,
        handler: function(range, context) {
          this.quill.setSelection(range.index, "silent")
          this.quill.insertText(range.index, "\n", "user")
          this.quill.setSelection(range.index + 1, "silent")
          this.quill.format("linebreak", true, "user")
        }
      }
    }
  }
}

const formatsQuill = [
  "header", "font", "size",
  "bold", "italic", "underline", "strike", "blockquote",
  "list", "bullet", "indent", "align",
  "link", "image", "background", "color", "emoji"
]