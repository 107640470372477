import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link, Redirect, useHistory } from "react-router-dom"

// users
import user1 from "../../../assets/images/admin2.png"
import { functions } from "lodash"
import axios from "axios"
import { useSelector } from "react-redux";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const userData = useSelector(state => state.UserData.user)
  const history = useHistory()
  const [menu, setMenu] = useState(false)
  const [username, setusername] = useState("Yaser Nazzal")
  const [img, setImg] = useState("")
  

  useEffect(() => {
    if(userData) {
      setusername(userData.name)
      setImg(userData.image)
    }else {
      const ud = JSON.parse(localStorage.getItem("authUser")) 
      setusername(ud.name)
      setImg(ud.image)
    }
  }, [userData])

  function logoutUser(e) {
    e.preventDefault();
    
    axios.post(`${process.env.REACT_APP_API}/logout`, {}, {
      headers: { 
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`
      }
    })
    .then(function () {
      localStorage.removeItem("authUser");
      history.push('/login')
    })
    .catch(function (error) {
      
    });
  } 

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={img}
            alt="Header Avatar"
            style={{height: "46px", width: "46px", background: "none", padding: 0}}
          />
          <span className="d-none d-xl-inline-block ms-2 me-1 fs-5" style={{color: "#000000"}}>{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block ml-1"/>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"/>
            {props.t("Edit Profile")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-edit-alt font-size-16 align-middle me-1"/>
            {props.t("Terms & Conditions")}{" "}
          </DropdownItem> */}
          
          
          <a onClick={(e) => logoutUser(e)} href="" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"/>
            <span>{props.t("Logout")}</span>
          </a>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
