/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Card, CardBody,  Col,  Container, FormFeedback, Label, Row } from "reactstrap"
import MetaTags from 'react-meta-tags';

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useDispatch } from "react-redux"
import {
    setTitle
} from "../../../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom"
import { useForm } from "react-hook-form";
import ProductCard from "./ProductCard";
import prettyBytes from "pretty-bytes";
import * as uuid from 'uuid'

const Blogs = (props) => {
    const {register ,getValues ,control ,watch ,setValue} =useForm()
    const [appendData,setAppendData]= useState('')
    // const [isOpen, setisOpen] = useState(false)
    const CARD_MODAL =(cardId)=>{
        return{
            id:cardId,
            titleEn:'',
            titleAr:'',
            descriptionEn:'',
            descriptionAr:'',
        }
    }
    const PRODUCT_MODAL=(blogId)=>{
        return{
            id:blogId,
            logo:null,
            slide:null,
            titleEn:'',
            titleAr:'',
            subTitleEn:'',
            subTitleAr:'',
            descriptionEn:'',
            descriptionAr:'',
            reviewLink:'',
            downloadLink:'',
            cards:[CARD_MODAL(uuid.v4())],
        }
    }

    const dispatch = useDispatch()
    const user = useSelector(state => state.UserData.user)
    const history = useHistory()
    // const countries = useSelector(state => state.MainData.countries)
    const [isOpen, setisOpen] = useState(false);
  const [photoIndex, setphotoIndex] = useState(0)
  const [success_msg, setsuccess_msg] = useState(false);
    const [msg, setMsg] = useState("");
    const [prev, setPrev]  = useState(null) 
    const [image ,setImage] =useState([])
    const [productData,setProductData]=useState(([PRODUCT_MODAL(uuid.v4())]))

    
    function handleAcceptedFilesThumbnail(files, key,e) {
        files =[...files].map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: prettyBytes(file.size)
            })
        )
        setImage(files)
    }
    
    function prevSelectImage(e, id) {
        e.preventDefault()
        setphotoIndex(id)
        setisOpen(!isOpen)
      }

        useEffect(() => {
            if(user && user.role == 2) {
                history.push('/')
                return;
            } 
        }, [user])
        
        useEffect(() => {
            dispatch(setTitle("Product"))
        }, []) 
        function addNestedBlogRow(){
            let modifiedProductData = [...productData]
            modifiedProductData.push(PRODUCT_MODAL(uuid.v4()))
            setProductData(modifiedProductData)
            // setCol(state => state.concat(false))
        }
        function handleChangeBlogData(keyName,keyValue,itemIndex){
            let modifiedProductData = [...productData]
            modifiedProductData[itemIndex][keyName] =keyValue
            setProductData(modifiedProductData)
        }
      

return (
    <React.Fragment>
         {isOpen ? (
                    <Lightbox
                    mainSrc={photoIndex}
                    enableZoom={false}
                    onCloseRequest={() => {
                        setisOpen(!isOpen)
                    }}
                    />
                ) : null}
        <div className="">
            <MetaTags>
                <title>Starter Page | Layouti</title>
            </MetaTags>
            <div>
                <Card >
                    <CardBody>
                        {success_msg ? (
                            <SweetAlert
                                title={msg}
                                success
                                confirmBtnBsStyle="success"
                                onConfirm={() => {
                                setsuccess_msg(false)
                                }}
                                onCancel={() => {
                                setsuccess_msg(false)
                                }}
                            >
                            </SweetAlert>
                        ) : null}
                        <div className="accordion" id="accordion">
                        <Row>
                                <Col>
                                    <div className="mb-3">
                                        <Label htmlFor="metatitle">thumbnail Image</Label>
                                        <div className="mb-3" >
                                            <input 
                                                type="file"
                                                className="form-control form-control-lg "
                                                {...register(`product.thumbnailImage`,{
                                                    onChange:e => handleAcceptedFilesThumbnail(e.target.files,e)
                                                })}
                                                style={{borderRadius: 0}}
                                            />
                                            <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                        </div>
                                    </div>
                                </Col>

                                {image.length!=0&&
                                    <Row className="align-items-center" style={{paddingLeft: "14px", paddingRight: "0px"}}>
                                        <Col className="col-auto">
                                            <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={image[0].name}
                                                src={image[0].preview?image[0].preview:image[0]}
                                                style={{objectFit: "cover"}}
                                            />
                                        </Col>

                                        <Col>
                                            <Link
                                                to="#"
                                                className="text-muted "
                                            >
                                                {image[0].name}
                                            </Link>
                                            <p className="mb-0">
                                                <strong>{image[0].formattedSize}</strong>
                                            </p>
                                        </Col>

                                        <Col className='p-0'>
                                            <div className="image__options">
                                                <button className="p-0" 
                                                type='button'
                                                onClick={(e) => prevSelectImage(e, image[0].preview?image[0].preview:image[0])}
                                                >
                                                    <i className="far fa-eye  fs-5 p-0"></i>
                                                </button>
                                                <button 
                                                type='button'
                                                className='p-0' 
                                                style={{color: "#ff3838"}} 
                                                onClick={(e) => setImage('')}
                                                >
                                                    <i className='bx bx-trash fs-4'></i>
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>}

                            </Row>
                            <ProductCard 
                            handleChangeBlogData={handleChangeBlogData}
                            productData={productData} />
                        </div>
                        <div className="">
                            <div className="add__btn top m-0 mt-2">
                                <button 
                                    onClick={()=>{addNestedBlogRow()}} 
                                    className="d-block pl-2 pr-2"> 
                                    <i className='bx bx-plus-circle'></i> Add Product
                                </button>
                            </div>
                        </div>
                        {isOpen ? (
                        <Lightbox
                            mainSrc={prev}
                            enableZoom={false}
                            onCloseRequest={() => {
                            setisOpen(!isOpen);
                            }}
                        />
                        ) : null}
                        
                    </CardBody>
                </Card>      
            </div>
        </div>
    </React.Fragment>
    )
}

export default Blogs

const deleteBtn = {
    background: "transparent",
    border: "none",
    color:"#D91414",
    display: "flex",
    alignItems: "center",
    gap: "5px"
}

const editBtn = {
    background: "transparent",
    border: "none",
    color:"green",
    display: "flex",
    alignItems: "center",
    gap: "5px"
}



const btnStyle = {
    boxShadow: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    paddingLeft: "0",
  }
  