import axios from "axios"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Input, Label, Row } from "reactstrap"
import ReactLoading from "react-loading"
import { toast } from "react-toastify"
const ScopeOfWorkForm = ({ data, ChangeHandler, PID, getData }) => {
  const [scopeOfWork, setScopeOfWork] = useState([])
  const [isLoading, setIsLoading] = useState(false)


  function submit(e) {
    e.preventDefault()
    setIsLoading(true)
    let formData = { 
      titleEn: data.ScopeOfWork_titleEn,
      titleAr: data.ScopeOfWork_titleAr,
      descriptionEn: data.ScopeOfWork_descriptionEn,
      descriptionAr: data.ScopeOfWork_descriptionAr,
      category: data.ScopeOfWork_category,
      PID
    }
    axios.post(`${process.env.REACT_APP_API}/dashboard/product/scopeOfWork/save`,formData,{
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res => {
      toast.success(res.data.message, {position: "top-right"})
      setIsLoading(false)
      getData(PID)
    })
  }



  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/dashboard/configurations/scopes`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setScopeOfWork(res.data.data)
      })
      .catch(err => {
        
      })
  }, [])

  return (
    <Card>
      <CardBody>
        <Row className="mt-3">
          <Col>
            <div className="d-flex">
              <Label>Title (English)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="Label"
              name="nameEn"
              value={data["ScopeOfWork_titleEn"]}
              onChange={e =>
                ChangeHandler({ ...data, ScopeOfWork_titleEn: e.target.value })
              }
            />
          </Col>
          <Col>
            <div className="d-flex">
              <Label>Title (Arabic)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="عنوان"
              dir="rtl"
              name="nameAr"
              value={data["ScopeOfWork_titleAr"]}
              onChange={e =>
                ChangeHandler({ ...data, ScopeOfWork_titleAr: e.target.value })
              }
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <div className="d-flex">
              <Label>Description (English)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <textarea
              className="form-control  mb-0"
              rows="5"
              placeholder="Description"
              name="descriptionEn"
              value={data["ScopeOfWork_descriptionEn"]}
              onChange={e =>
                ChangeHandler({
                  ...data,
                  ScopeOfWork_descriptionEn: e.target.value,
                })
              }
            />
          </Col>
          <Col>
            <div className="d-flex">
              <Label>Description (Arabic)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <textarea
              className="form-control  mb-0"
              rows="5"
              placeholder="وصف"
              name="descriptionAr"
              value={data["ScopeOfWork_descriptionAr"]}
              onChange={e =>
                ChangeHandler({
                  ...data,
                  ScopeOfWork_descriptionAr: e.target.value,
                })
              }
              dir="rtl"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <div className="mb-3">
              <div className="d-flex">
                <Label>Category</Label>
                <label className="ms-auto optional-label--color">
                  (Optional)
                </label>
              </div>
              <select
                className="form-select fs-5 input_cus"
                name="scope"
                value={data["ScopeOfWork_category"]}
                onChange={e =>
                  ChangeHandler({
                    ...data,
                    ScopeOfWork_category: e.target.value,
                  })
                }
              >
                <option value="">Select</option>
                {scopeOfWork.map(category => (
                  <option value={category.id} key={category.id}>
                    {category.titleEn}
                  </option>
                ))}
              </select>
            </div>
          </Col>
        </Row>
        <hr style={{ margin: "20px -20px 20px -20px" }} />
        <Row>
          <Col className="d-flex justify-content-end">
            <button
              type="button"
              className="add-project__submit-button"
              onClick={e => submit(e)}
            >
              {isLoading ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </Col>
        </Row>   
      </CardBody>
    </Card>
  )
}

export default ScopeOfWorkForm
