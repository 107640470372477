import axios from "axios"
import React, { Fragment, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap"

const ResultForm = ({ data, ChangeHandler, PID, getData }) => {
  
  function handleAddRowNested() {
      ChangeHandler({...data, cards: data.cards.concat({titleEn: "", titleAr: "", descriptionEn: "", descriptionAr: "", staticColor: "", hoverColor: ""})})
  }

  function deleteCard(indx, id) {
      ChangeHandler({...data, cards: data.cards.filter((c,i) => i !== indx)})
      axios.post(`${process.env.REACT_APP_API}/dashboard/product/designapp/deleteDesignAppResults`,{id},{
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      }).then(res => {
        toast.success(res.data.message, {position: "top-right"})
        getData(PID)
      })
  }

  function informationsChangeNested(value, name,indx) {
  ChangeHandler({...data, cards: data.cards.map((obj, i) => {
      if(i == indx) {
      return {...obj, [name]:value}
      }
      return obj
  })})
  }
  
  return (
    <>
    <Row className="">
        <Col>
          <div className="d-flex">
            <Label>Main Title (English)</Label>
            <label className="ms-auto optional-label--color">(Optional)</label>
          </div>
          <Input
            className="input_cus"
            placeholder="Label"
            name="DesignAppResults_titleEn"
            value={data["DesignAppResults_titleEn"]}
            onChange={e =>
              ChangeHandler({
                ...data,
                DesignAppResults_titleEn: e.target.value,
              })
            }
          />
        </Col>
        <Col>
          <div className="d-flex">
            <Label>Main Title (Arabic)</Label>
            <label className="ms-auto optional-label--color">(Optional)</label>
          </div>
          <Input
            className="input_cus"
            placeholder="عنوان"
            dir="rtl"
            name="DesignAppResults_titleAr"
            value={data["DesignAppResults_titleAr"]}
            onChange={e =>
              ChangeHandler({
                ...data,
                DesignAppResults_titleAr: e.target.value,
              })
            }
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <div className="d-flex">
            <Label>Description (English)</Label>
            <label className="ms-auto optional-label--color">(Optional)</label>
          </div>
          <textarea
            className="form-control  mb-0"
            rows="5"
            placeholder="Description"
            name="DesignAppResults_descriptionEn"
            value={data["DesignAppResults_descriptionEn"]}
            onChange={e =>
              ChangeHandler({
                ...data,
                DesignAppResults_descriptionEn: e.target.value,
              })
            }
          />
        </Col>
        <Col>
          <div className="d-flex">
            <Label>Description (Arabic)</Label>
            <label className="ms-auto optional-label--color">(Optional)</label>
          </div>
          <textarea
            className="form-control  mb-0"
            rows="5"
            placeholder="وصف"
            name="DesignAppResults_descriptionAr"
            value={data["DesignAppResults_descriptionAr"]}
            onChange={e =>
              ChangeHandler({
                ...data,
                DesignAppResults_descriptionAr: e.target.value,
              })
            }
            dir="rtl"
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <div className="d-flex">
            <Label htmlFor="colorID">Background Color</Label>
            <label className="ms-auto optional-label--color">(Optional)</label>
          </div>
          <div className="d-flex">
            <input
              type="color"
              className="form-control colorInput"
              name="DesignAppResults_color"
              value={data["DesignAppResults_color"]}
              onChange={e =>
                ChangeHandler({
                  ...data,
                  DesignAppResults_color: e.target.value,
                })
              }
            />
            <Input
              type="text"
              className="color__name rounded-0"
              name="DesignAppResults_color"
              value={data["DesignAppResults_color"]}
              onChange={e =>
                ChangeHandler({
                  ...data,
                  DesignAppResults_color: e.target.value,
                })
              }
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <div className="d-flex">
            <Label htmlFor="colorID">Static Color</Label>
            <label className="ms-auto optional-label--color">(Optional)</label>
          </div>
          <div className="d-flex">
            <input
              type="color"
              className="form-control colorInput"
              name="DesignAppResults_staticColor"
              value={data["DesignAppResults_staticColor"]}
              onChange={e =>
                ChangeHandler({
                  ...data,
                  DesignAppResults_staticColor: e.target.value,
                })
              }
            />
            <Input
              type="text"
              className="color__name rounded-0"
              name="DesignAppResults_staticColor"
              value={data["DesignAppResults_staticColor"]}
              onChange={e =>
                ChangeHandler({
                  ...data,
                  DesignAppResults_staticColor: e.target.value,
                })
              }
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <div className="d-flex">
            <Label htmlFor="colorID">Hover Color</Label>
            <label className="ms-auto optional-label--color">(Optional)</label>
          </div>
          <div className="d-flex">
            <input
              type="color"
              className="form-control colorInput"
              name="DesignAppResults_hoverColor"
              value={data["DesignAppResults_hoverColor"]}
              onChange={e =>
                ChangeHandler({
                  ...data,
                  DesignAppResults_hoverColor: e.target.value,
                })
              }
            />
            <Input
              type="text"
              className="color__name rounded-0"
              name="DesignAppResults_hoverColor"
              value={data["DesignAppResults_hoverColor"]}
              onChange={e =>
                ChangeHandler({
                  ...data,
                  DesignAppResults_hoverColor: e.target.value,
                })
              }
            />
          </div>
        </Col>
      </Row>
      <hr style={{ margin: "20px -20px 20px -20px" }} />

      <div className="">
        {data.cards.map((item, indx) => (
          <Fragment key={indx}>
            <h3 className="fw-bolder text-dark">Card ({indx + 1})</h3>
            <Row className="mt-3 ">
              <Col>
                <div className="d-flex">
                  <Label>Main Title (English)</Label>
                  <label className="ms-auto optional-label--color">
                    (Optional)
                  </label>
                </div>
                <Input
                  className="input_cus"
                  placeholder="Label"
                  name="titleEn"
                  value={item.titleEn}
                  onChange={e =>
                    informationsChangeNested(
                      e.target.value,
                      e.target.name,
                      indx
                    )
                  }
                />
              </Col>
              <Col>
                <div className="d-flex">
                  <Label>Main Title (Arabic)</Label>
                  <label className="ms-auto optional-label--color">
                    (Optional)
                  </label>
                </div>
                <Input
                  className="input_cus"
                  placeholder="عنوان"
                  dir="rtl"
                  name="titleAr"
                  value={item.titleAr}
                  onChange={e =>
                    informationsChangeNested(
                      e.target.value,
                      e.target.name,
                      indx
                    )
                  }
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <div className="d-flex">
                  <Label>Description (English)</Label>
                  <label className="ms-auto optional-label--color">
                    (Optional)
                  </label>
                </div>
                <textarea
                  className="form-control  mb-0"
                  rows="5"
                  placeholder="Description"
                  name="descriptionEn"
                  value={item.descriptionEn}
                  onChange={e =>
                    informationsChangeNested(
                      e.target.value,
                      e.target.name,
                      indx
                    )
                  }
                />
              </Col>
              <Col>
                <div className="d-flex">
                  <Label>Description (Arabic)</Label>
                  <label className="ms-auto optional-label--color">
                    (Optional)
                  </label>
                </div>
                <textarea
                  className="form-control  mb-0"
                  rows="5"
                  placeholder="وصف"
                  name="descriptionAr"
                  value={item.descriptionAr}
                  onChange={e =>
                    informationsChangeNested(
                      e.target.value,
                      e.target.name,
                      indx
                    )
                  }
                  dir="rtl"
                />
              </Col>
            </Row>
            
           
              <Button
                color="none"
                variant="primary"
                className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                id="unknown-btn"
                style={{
                  color: "#ff3838",
                  boxShadow: "none",
                  height: "45px",
                  borderRadius: 0,
                }}
                onClick={e => deleteCard(indx, item.id)}
              >
                <i className="bx bx-trash-alt fs-4 p-0 mr-1"></i> Delete
              </Button>
          
          
              <hr style={{ margin: "5px -20px 20px -20px" }} />
           
          </Fragment>
        ))}
      </div>

      <Button
        onClick={() => handleAddRowNested()}
        color="none"
        className="mt-2 fs-5 fw-bold p-0 text-left"
        style={btnStyle}
      >
        <i className="bx bx-plus-circle fs-4"></i> <span>Add Point</span>
      </Button>
    </>
  )
}

export default ResultForm

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
