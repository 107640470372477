import React, {useEffect, useState} from 'react'
import { Row, Col,CardBody, Form, Label, Input, Card } from "reactstrap"
import ReactLoading from "react-loading"
import axios from "axios"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"


const WebsiteSetting = () => {
    const [active, setActive] = useState(false)
    const [titleEn, setTitleEn] = useState("")
    const [titleAr, setTitleAr] = useState("")
    const [lightColor, setLightColor] = useState("")
    const [darkColor, setDarkColor] = useState("")
    const [lightImage, setLightImage] = useState(null)
    const [darkImage, setDarkImage] = useState(null)
    const [favicon, setFavicon] = useState(null)
    const [prev, setPrev] = useState(null)
    const [isOpen, setisOpen] = useState(false)


    function handleImage(e, files, type) {
        [...files].map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          })
        )
        type == "light" && setLightImage(Object.values(files)[0])
        type == "dark" && setDarkImage(Object.values(files)[0])
        type == "favicon" && setFavicon(Object.values(files)[0])
        
        e.target.value = ""
      }

      function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
      }
      function prevSelectImage(e, prev) {
        e.preventDefault()
        setPrev(prev)
        setisOpen(!isOpen)
      }

      function submit(e) {
        
        e.preventDefault()
        setActive(true)
        const formData = new FormData()
        const data = {
          titleEn,
          titleAr,
          lightColor,
          darkColor,
          darkImage,
          lightImage,
          favIcon: favicon
        }
        for (let key in data) {
          formData.append(key, data[key])
        }
       
        axios
          .post(
            `${process.env.REACT_APP_API}/dashboard/configurations/staticPages/navbarContent`,
            formData,
            {
              headers: { 
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
              }
            }
          )
          .then(res => {
            setActive(false)
            toast.success(res.data.message)
          })
          .catch(err => {
            setActive(false)
            toast.error("Something went wrong")
          })
      }
    
      


    useEffect(() => {
        axios.get(
            `${process.env.REACT_APP_API}/dashboard/configurations/staticPages/navbarContent`,
       
            {
              headers: { 
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
              }
            }
          ).then(res => {
            
            setTitleEn(res.data.data.titleEn)
            setTitleAr(res.data.data.titleAr)
            setLightColor(res.data.data.lightColor)
            setDarkColor(res.data.data.darkColor)
            setDarkImage({preview: res.data.data.darkImage})
            setLightImage({preview: res.data.data.lightImage})
            setFavicon({preview: res.data.data.favIcon})
          })
    }, [])

  return (
    <Card>
        <CardBody>
        {isOpen ? (
            <Lightbox
            mainSrc={prev}
            enableZoom={false}
            onCloseRequest={() => {
                setisOpen(!isOpen)
            }}
            />
        ) : null}
        <Form className="border-0 pb-2">
        <Row className="">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Main Title (english)</Label>
              <Input
                id="itemnameen"
                name="titleEn"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={titleEn}
                onChange={e => setTitleEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Main Title (arabic)</Label>
              <Input
                id="itemnamear"
                name="titleAr"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. "
                value={titleAr}
                onChange={e => setTitleAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row> 
        <Row className="mt-3">
          <Col>
            <div className="d-flex">
              <Label htmlFor="colorID">Light Color</Label>
            </div>
            <div className="d-flex">
              <input
                type="color"
                className="form-control colorInput"
                name="backGroundColor"
                value={lightColor}
                onChange={e =>
                  setLightColor(e.target.value)
                }
              />
              <Input
                type="text"
                className="color__name rounded-0"
                name="backGroundColor"
                value={lightColor}
                onChange={e =>
                  setLightColor(e.target.value)
                }
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <div className="d-flex">
              <Label htmlFor="colorID">Dark Color</Label>
            </div>
            <div className="d-flex">
              <input
                type="color"
                className="form-control colorInput"
                name="backGroundColor"
                value={darkColor}
                onChange={e =>
                  setDarkColor(e.target.value)
                }
              />
              <Input
                type="text"
                className="color__name rounded-0"
                name="backGroundColor"
                value={darkColor}
                onChange={e =>
                  setDarkColor(e.target.value)
                }
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <div className="mt-3">
              <Label htmlFor="productdesc">Logo Light</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => handleImage(e, e.target.files, "light")}
                style={{ borderRadius: 0 }}
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {lightImage ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={lightImage.name}
                          src={lightImage.preview}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {lightImage.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e => prevSelectImage(e, lightImage.preview)}
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            type="button"
                            style={{ color: "#ff3838" }}
                            onClick={e => setLightImage(null)}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs="12">
            <div className="mt-3">
              <Label htmlFor="productdesc">Logo Dark</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => handleImage(e, e.target.files, "dark")}
                style={{ borderRadius: 0 }}
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {darkImage ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={darkImage.name}
                          src={darkImage.preview}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {darkImage.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e => prevSelectImage(e, darkImage.preview)}
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            type="button"
                            style={{ color: "#ff3838" }}
                            onClick={e => setDarkImage(null)}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs="12">
            <div className="mt-3">
              <Label htmlFor="productdesc">FavIcon</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => handleImage(e, e.target.files, "favicon")}
                style={{ borderRadius: 0 }}
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {favicon ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={favicon.name}
                          src={favicon.preview}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {favicon.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e => prevSelectImage(e, favicon.preview)}
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            type="button"
                            style={{ color: "#ff3838" }}
                            onClick={e => setFavicon(null)}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>
        
        
       
      </Form>
      <hr style={{ margin: "20px -20px 20px -20px" }} />
      <Row className="mt-2">
        <Col className="d-flex justify-content-end gap-2">
          <div className="save__btn top m-0">
            <button onClick={submit}>
              {active ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </Col>
      </Row>
        </CardBody>
    </Card>
  )
}

export default WebsiteSetting