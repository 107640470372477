import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/General/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/General/Authentication/Login"

// Dashboard
import Dashboard from "../pages/General/Dashboard/index"

//Notifications
// import Notifications from '../pages/Notifications/Notifications'

//Feedback
import Feedback from "../pages/General/Feedback/Feedback"

import UiGenerator from "pages/UiAvatar/UiGenerator/UiGenerator"

import ContentGenerator from "pages/UiAvatar/ContentGenerator/ContentGenerator"

import Projects from "pages/Design365/Projects/Projects"

import AddProject from "pages/Design365/Projects/Components/Forms/AddProject.jsx"
import AddProduct from "pages/Layouti/LayoutiProjectForms/AddProject"

//Options
import Options from "../pages/Settings/Options/Options"

//Advertises
import Advertises from "../pages/General/Advertises/Advertises"

//Pages
import Pages from "../pages/Pages/Pages"

//Layouti Pages
import LayoutiPages from "../pages/Layouti/LayoutiPages/LayoutiPages"

//Layouti Products
import Products from "../pages/Layouti/Products/Products"

//Layouti Pages
import SayHello from "../pages/Layouti/SayHello/SayHello"

//Layouti Pages
import EtoyAppPages from "../pages/EtoyApp/EtoyAppPages/EtoyAppPages"

//Layouti Pages
import ContactUs from "../pages/EtoyApp/ContactUs/ContactUsEtoy"

//Seo
import Seo from "../pages/General/Seo/Seo"

import EditProject from "pages/Design365/Projects/Components/Forms/EditProject"
import EditProduct from "pages/Layouti/Products/Components/Forms/EditProduct"

import Blogs from "pages/General/Blogs/Blogs"
import OtionsSettings from "pages/Settings/AdminSettings/OtionsSettings"
import Pages365 from "pages/Design365/Pages/Pages365"
import PagesAvatar from "pages/UiAvatar/Pages/PagesAvatar"
import HireUs from "pages/Layouti/HireUs/HireUs"
import ContactUs365 from "pages/Design365/ContactUs/ContactUs365"
import ContactUsEtoy from "pages/EtoyApp/ContactUs/ContactUsEtoy"
import ResourcesPages from "pages/Resources/ResourcesPages"
import InnerPageResourceTable from "pages/Resources/InnerPageResourceTable"
import InnerPageLayoutiResourceTable from "pages/Resources/InnerPageLayoutiResourceTable"
import InnerPagePendingResourceTable from "pages/Resources/InnerPagePendingResourceTable"
import AddInnerPageResourses from "pages/Resources/AddInnerPageResource"
import EditInnerPageResource from "pages/Resources/EditInnerPageResource"
import EditInnerPendingPageResource from "pages/Resources/EditInnerPendingPageResource"

import LayoutiSeoPages from "pages/SEO/LayoutiSeoPages/LayoutiSeoPages"
import Design365SeoPages from "pages/SEO/Design365SeoPages/Design365SeoPages"
import PagesUiLearn from "pages/UILearn/Pages/PagesUiLearn"
import PagesUXLearn from "pages/UXLearn/Pages/PagesUXLearn"
import PagesEbook from "pages/Ebook/Pages/PagesEbook"
import Registration from "pages/UILearn/Registration/Registration"
import SeoPage from "pages/Resources/SeoPage/SeoPage"
import UiSeoPages from "pages/UiAvatar/Seo/UiSeoPages"
import UiAvatarOptions from "pages/UiAvatar/Options/UiAvatarOptions"

import LayoutiOptions from "pages/Layouti/Options/LayoutiOptions"
import DesignOptions from "pages/Design365/Options/DesignOptions"
import Terms from "pages/Terms/Terms"
import TermsConfigurations from "pages/Terms/Options/TermsConfigurations"
import FooterSettings from "pages/FooterSettings/FooterSettings"
import ProductTab from "pages/OurProducts/ProductTab"
import LinksSettings from "../pages/LinksSettings/LinksSettings"
const authProtectedRoutes = [
  //dashboard
  { path: "/dashboard", component: Dashboard },

  //profile
  { path: "/profile", component: UserProfile },

  //uigenerator
  { path: "/uiavatar/uigenerator", component: UiGenerator },

  //ContentGenerator
  { path: "/uiavatar/contentgenerator", component: ContentGenerator },

  //seo
  { path: "/uiavatar/seo", component: LayoutiSeoPages },

  { path: "/uiavatar/configurations", component: UiAvatarOptions },

  //ContentGenerator
  { path: "/uiavatar/pages", component: PagesAvatar },

  //uilearn
  { path: "/uilearn/pages", component: PagesUiLearn },

  { path: "/uilearn/registration", component: Registration },
  //uxlearn
  { path: "/uxlearn/pages", component: PagesUXLearn },
  //ebook
  { path: "/ebook/pages", component: PagesEbook },

  //Design365
  { path: "/365design", component: Projects },

  //Design365
  { path: "/365design/pages", component: Pages365 },
  //Design365
  { path: "/365design/contactus", component: ContactUs365 },

  //Design365
  { path: "/365design/addproject", component: AddProject },

  //Design365
  { path: "/365design/editproject/:id", component: EditProject },
  { path: "/365design/configurations", component: DesignOptions },

  //Feedback
  { path: "/feedback", component: Feedback },

  //Reports
  { path: "/configurations", component: Options },

  //Pages
  { path: "/pages", component: Pages },

  { path: "/links", component: LinksSettings },

  //Pages
  { path: "/layouti/layoutipages", component: LayoutiPages },

  //Pages
  { path: "/layouti/Project", component: Products },

  //Pages
  { path: "/layouti/editProduct/:id", component: EditProduct },

  //Pages
  { path: "/layouti/addProduct", component: AddProduct },
  { path: "/layouti/options", component: LayoutiOptions },
  { path: "/layouti/addProduct/:id", component: AddProduct },

  //Pages
  { path: "/layouti/sayhello", component: SayHello },

  //Pages
  { path: "/layouti/hireus", component: HireUs },

  //Pages
  { path: "/etoyapp/etoyapppages", component: EtoyAppPages },
  //Pages
  { path: "/resources/resourcesPages", component: ResourcesPages },
  { path: "/resources/seo", component: SeoPage },
  {
    path: "/resources/detailsPage/pending",
    component: InnerPagePendingResourceTable,
  },

  { path: "/resources", component: InnerPageResourceTable },
  { path: "/resources/layouti", component: InnerPageLayoutiResourceTable },
  { path: "/resources/detailsPage/Add", component: AddInnerPageResourses },
  { path: "/resources/detailsPage/Edit", component: EditInnerPageResource },
  {
    path: "/resources/detailsPage/EditPending",
    component: EditInnerPendingPageResource,
  },

  //Pages
  { path: "/etoyapp/contactus", component: ContactUsEtoy },

  //Advertises
  { path: "/Advertises", component: Advertises },

  //Blogs
  { path: "/blogs", component: Blogs },
  //Blogs
  { path: "/adminsetting", component: OtionsSettings },

  //SEO Pages
  { path: "/seo/layouti", component: UiSeoPages },

  { path: "/seo/365design", component: Design365SeoPages },
  //Terms
  { path: "/terms", component: Terms },

  { path: "/terms/Configurations", component: TermsConfigurations },
  { path: "/footersetting", component: FooterSettings },
  { path: "/layouti/ProductTab", component: ProductTab },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [{ path: "/login", component: Login }]

export { publicRoutes, authProtectedRoutes }
