import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import { useEffect } from "react"
import prettyBytes from "pretty-bytes"
import Lightbox from "react-image-lightbox"

const SeoComponent = ({ seoData, handleChangeBlogSeoData, itemIndex }) => {
  const [active, setActive] = useState(false)
  const [isOpen, setisOpen] = useState(false)
  const [photoIndex, setphotoIndex] = useState(0)
  // const [seoData,setSeoData]=useState({
  //   focusKeypharseEn:'',
  //   focusKeypharseAr:'',
  //   seoTitleEn:'',
  //   seoTitleAr:'',
  //   descriptionEn:'',
  //   descriptionAr:'',
  //   slugEn:'',
  //   slugAr:'',
  //   facebookTitleEn:'',
  //   facebookTitleAr:'',
  //   facebookDescriptionEn:'',
  //   facebookDescriptionAr:'',
  //   facebookImage:null,
  // })
  function handleChangeData(keyName, keyValue) {
    setSeoData(prevValue => {
      return {
        ...prevValue,
        [keyName]: keyValue,
      }
    })
  }

  function handleFiles(e) {
    let acceptedFiles = e.target.files
    ;[...acceptedFiles].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    handleChangeBlogSeoData(
      "facebookImage",
      Object.values(acceptedFiles)[0],
      itemIndex
    )
    e.target.value = ""
  }
  function deleteImage(e) {
    e.preventDefault()
    handleChangeBlogSeoData("facebookImage", null,itemIndex)
    // handleChangeData("image", null)
  }
  function prevSelectImage(e, id) {
    e.preventDefault()
    setphotoIndex(id)
    setisOpen(!isOpen)
  }
  return (
    <div>
      {isOpen ? (
        <Lightbox
          mainSrc={photoIndex}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}

      <Row className="mt-3">
        <Col sm="6">
          <div>
            <Label htmlFor="productdesc">Focus Keypharse (english)</Label>
            <Input
              id="itemnameen"
              name="focusKeypharseEn"
              type="text"
              className="form-control input_cus"
              placeholder="e.g. Focus Keypharse"
              value={seoData?.focusKeypharseEn}
              onChange={e =>
                handleChangeBlogSeoData(
                  e.target.name,
                  e.target.value,
                  itemIndex
                )
              }
            />
          </div>
        </Col>

        <Col sm="6">
          <div>
            <Label htmlFor="productdesc">Focus Keypharse (arabic)</Label>
            <Input
              id="itemnamear"
              name="focusKeypharseAr"
              type="text"
              className="form-control input_cus"
              placeholder="مثال. "
              value={seoData?.focusKeypharseAr}
              onChange={e =>
                handleChangeBlogSeoData(
                  e.target.name,
                  e.target.value,
                  itemIndex
                )
              }
              dir="rtl"
              lang="ar"
            />
          </div>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col sm="6">
          <div>
            <Label htmlFor="productdesc">Seo Title (english)</Label>
            <Input
              id="brandnameen"
              name="seoTitleEn"
              type="text"
              className="form-control input_cus"
              placeholder="e.g. Title"
              value={seoData?.seoTitleEn}
              onChange={e =>
                handleChangeBlogSeoData(
                  e.target.name,
                  e.target.value,
                  itemIndex
                )
              }
            />
          </div>
        </Col>
        <Col sm="6">
          <div>
            <Label htmlFor="productdesc">Seo Title (arabic)</Label>
            <Input
              id="brandnamear"
              name="seoTitleAr"
              type="text"
              className="form-control input_cus"
              placeholder="مثال. عنوان"
              value={seoData?.seoTitleAr}
              onChange={e =>
                handleChangeBlogSeoData(
                  e.target.name,
                  e.target.value,
                  itemIndex
                )
              }
              dir="rtl"
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm="6">
          <div>
            <Label htmlFor="productdesc">Slug (english)</Label>
            <Input
              id="brandnameen"
              name="slugEn"
              type="text"
              className="form-control input_cus"
              placeholder="e.g. Slug"
              value={seoData?.slugEn}
              onChange={e =>
                handleChangeBlogSeoData(
                  e.target.name,
                  e.target.value,
                  itemIndex
                )
              }
            />
          </div>
        </Col>
        <Col sm="6">
          <div>
            <Label htmlFor="productdesc">Slug (arabic)</Label>
            <Input
              id="brandnamear"
              name="slugAr"
              type="text"
              className="form-control input_cus"
              placeholder="مثال. وصف"
              value={seoData?.slugAr}
              onChange={e =>
                handleChangeBlogSeoData(
                  e.target.name,
                  e.target.value,
                  itemIndex
                )
              }
              dir="rtl"
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm="6">
          <div>
            <Label htmlFor="productdesc">Description (English)</Label>
            <textarea
              className="form-control  mb-0"
              name="descriptionEn"
              id="specification"
              rows="5"
              placeholder="Enter your description here"
              value={seoData?.descriptionEn}
              onChange={e =>
                handleChangeBlogSeoData(
                  e.target.name,
                  e.target.value,
                  itemIndex
                )
              }
            />
          </div>
        </Col>
        <Col sm="6">
          <div>
            <Label htmlFor="productdesc">Description (Arabic)</Label>
            <textarea
              className="form-control  mb-0"
              name="descriptionAr"
              id="specification"
              rows="5"
              placeholder="اكتب وصف هنا"
              value={seoData?.descriptionAr}
              onChange={e =>
                handleChangeBlogSeoData(
                  e.target.name,
                  e.target.value,
                  itemIndex
                )
              }
              dir="rtl"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <div className="mt-3">
            <Label htmlFor="productdesc">Facebook Image</Label>
            <Input
              type="file"
              className="form-control form-control-lg "
              onChange={e => handleFiles(e, e.target.files)}
              style={{ borderRadius: 0 }}
              multiple
            />
          </div>
        </Col>
        <Col xs="12">
          <div className="dropzone-previews" id="file-previews">
            {seoData?.facebookImage ? (
              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={seoData?.facebookImage.name}
                        src={
                          seoData?.facebookImage.preview
                            ? seoData?.facebookImage.preview
                            : seoData?.facebookImage
                        }
                        style={{ objectFit: "cover" }}
                      />
                    </Col>
                    <Col>
                      <Link to="#" className="text-muted ">
                        {seoData?.facebookImage.name}
                      </Link>
                    </Col>
                    <Col>
                      <div className="image__options">
                        <button
                          className="p-0"
                          onClick={e =>
                            prevSelectImage(
                              e,
                              seoData?.facebookImage.preview
                                ? seoData?.facebookImage.preview
                                : seoData?.facebookImage
                            )
                          }
                        >
                          <i className="far fa-eye  fs-5 p-0"></i>
                        </button>
                        <button
                          style={{ color: "#ff3838" }}
                          onClick={e => deleteImage(e)}
                        >
                          <i className="bx bx-trash fs-4"></i>
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            ) : null}
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm="6">
          <div>
            <Label htmlFor="productdesc">Facebook Title (english)</Label>
            <Input
              id="brandnameen"
              name="facebookTitleEn"
              type="text"
              className="form-control input_cus"
              placeholder="e.g. Title"
              value={seoData?.facebookTitleEn}
              onChange={e =>
                handleChangeBlogSeoData(
                  e.target.name,
                  e.target.value,
                  itemIndex
                )
              }
            />
          </div>
        </Col>
        <Col sm="6">
          <div>
            <Label htmlFor="productdesc">Facebook Title (arabic)</Label>
            <Input
              id="brandnamear"
              name="facebookTitleAr"
              type="text"
              className="form-control input_cus"
              placeholder="مثال. عنوان"
              value={seoData?.facebookTitleAr}
              onChange={e =>
                handleChangeBlogSeoData(
                  e.target.name,
                  e.target.value,
                  itemIndex
                )
              }
              dir="rtl"
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm="6">
          <div>
            <Label htmlFor="productdesc">Facebook Description (English)</Label>
            <textarea
              className="form-control  mb-0"
              name="facebookDescriptionEn"
              id="specification"
              rows="5"
              placeholder="Enter your description here"
              value={seoData?.facebookDescriptionEn}
              onChange={e =>
                handleChangeBlogSeoData(
                  e.target.name,
                  e.target.value,
                  itemIndex
                )
              }
            />
          </div>
        </Col>
        <Col sm="6">
          <div>
            <Label htmlFor="productdesc">Facebook Description (Arabic)</Label>
            <textarea
              className="form-control mb-0"
              name="facebookDescriptionAr"
              id="specification"
              rows="5"
              placeholder="اكتب وصف هنا"
              value={seoData?.facebookDescriptionAr}
              onChange={e =>
                handleChangeBlogSeoData(
                  e.target.name,
                  e.target.value,
                  itemIndex
                )
              }
              dir="rtl"
            />
          </div>
        </Col>
      </Row>
      <hr style={{ margin: "20px -20px 20px -20px" }} />

      {/* <Row>
              <Col className="d-flex justify-content-end gap-2">
                <div className="save__btn top m-0 mt-3">
                  <button onClick={e => submit(e, key)}>
                    {active ? (
                      <ReactLoading
                        type={"spin"}
                        color={"#ffffff"}
                        height={30}
                        width={30}
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </Col>
            </Row> */}
    </div>
  )
}

export default SeoComponent
