/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import "./datatables.scss"
import './specification.scss'
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert"
import PharagraphsForm from './Forms/PharagraphsForm'
import axiosConfig from "axiosConfig";
import PharagrapsEditForm from "./Forms/PharagrapsEditForm";
const Pharagraphs = (props) => {
  const [active, setActive] = useState(false)
  const [addActive, setAddActive] = useState(false)
  const[productData, setProductData] = useState([])
  const[avatar, setAvatar] = useState(null)
  const [activeEdit, setActiveEdit] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const columns = [{
    dataField: 'id',
    text: 'Id',
    sort: true,
  }, {
    dataField: 'pharagraphEn',
    text: 'Pharagraph (English)',
    sort: true
  }, {
    dataField: 'pharagraphAr',
    text: 'Pharagraph (Arabic)',
    sort: true
  },{
    dataField: 'actions',
    text: 'Actions',
    sort: true
  }];

  const pageOptions = {
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
    sizePerPageList: [ {
      text: '10', value: 10
    }, {
      text: '25', value: 25
    }, {
      text: '50', value: 50
    }, {
      text: '75', value: 75
    }, {
      text: '100', value: 100
    }, {
      text: 'All', value: productData.length
    } ]
  }


  
  // Select All Button operation
  const selectRow = {
    mode: 'checkbox'
  }

  const { SearchBar } = Search;


  function clickHandle (avatar, type, mess) {
    setActive(type)
    if(mess) {
      setMsg("Added!")
      setsuccess_msg(true)
    }
  }

  function clickHandleEdit (avatar, type, mess) {
    setActiveEdit(type)
    setAvatar(avatar)
    if(mess) {
      setMsg("Updated !")
      setsuccess_msg(true)
    }
  }
  

  

  function deleteAvatar(id) {
    axiosConfig.post(`/dashboard/uiAvatar/contentGenerator/paragraph/delete`, {id}, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
      }
    })
    .then(res => {
      props.update(id, "descdelete")
      setMsg("Deleted!")
      setsuccess_msg(true)
    })
    
  }


  function deleteAll() {
    axiosConfig.post(`/dashboard/uiAvatar/contentGenerator/paragraph/delete/all`,{}, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`, 
      }
    })
    .then(res => {
      props.update(null, "descdeleteall")
      setMsg("Deleted!")
      setsuccess_msg(true)
    })
    
  }

  useEffect(() => {
    setProductData(props.data.map(avatar => {return{...avatar, 
      actions: <div className="d-flex">
                    <button style={editBtn}  onClick={() => clickHandleEdit(avatar, true)}><i className='bx bx-edit fs-4 pr-1' style={{marginRight: "10px"}}></i> Edit</button>
                    <button style={deleteBtn}  onClick={(e)=>deleteAvatar(avatar.id)}><i className='bx bx-trash fs-4' style={{marginRight: "10px"}}></i> Delete</button>
              </div>
    }}))
  }, [props.data]) 


  return (
    <React.Fragment>
        <div className="container-fluid p-0">
          {success_msg ? (
                    <SweetAlert
                      title={msg}
                      success
                      confirmBtnBsStyle="success"
                      onConfirm={() => {
                        setsuccess_msg(false)
                      }}
                      onCancel={() => {
                        setsuccess_msg(false)
                      }}
                    >
                    </SweetAlert>
                  ) : null}
          <Row>
            <Col className="col-12">
              <Card >
                <CardBody className="pb-0">
                  <PharagraphsForm active={active} clickHandle={clickHandle} update={props.update}/>
                  <PharagrapsEditForm active={activeEdit} data={avatar} clickHandle={clickHandleEdit} update={props.update}/>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField='id'
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField='id'
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col>
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative ">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      className="input_cus"
                                    />
                                    <i className="bx bx-search-alt search-icon mt-1 fs-3" />
                                  </div>
                                </div>
                              </Col>
                              <Col >
                                <div className="save__btn top gap-2">
                                    <button onClick={() => clickHandle(null, true, false)}><i className='bx bx-plus-circle fs-4'></i> Add Paragraphs</button>
                                    <button className="delete" onClick={deleteAll}><i className='bx bx-trash fs-4'></i> Delete All</button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                  {productData.length === 0?<div className="emptyStateTable" style={{
                                      width: '100%',
                                      textAlign: 'center',
                                      fontSize: '16px',
                                      padding: '10px',
                                      paddingTop: '0', paddingBottom: "20px"}}>no data available in table</div>:null}
                                </div>
                              </Col>
                            </Row>

                            {productData.length !== 0?<Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>:null}
                          </React.Fragment>
                        )
                        }
                      </ToolkitProvider>
                    )
                    }
                    
                    </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    </React.Fragment>
  )
}

export default Pharagraphs

const deleteBtn = {
  background: "transparent",
  border: "none",
  color:"#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px"
}