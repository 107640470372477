import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import prettyBytes from "pretty-bytes"
import { toast } from "react-toastify"
import SweetAlert from "react-bootstrap-sweetalert"
import axiosConfig from "axiosConfig"

const LearnAboutLayouti = ({sectionData}) => {
  const [active, setActive] = useState(false)
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState(null)
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")
  
  const [otherDescEn , setOtherDescEn ] = useState("")
  const [otherDescAr , setOtherDescAr ] = useState("")
  const [leftImage, setLeftImage] = useState(null)
  const [rightImage, setRightImage] = useState(null)
  const [otherLeftImage, setOtherLeftImage] = useState(null)
  const [otherRightImage, setOtherRightImage] = useState(null)

  function handleCoverImage(e, files,key) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    key == 1? setLeftImage(Object.values(files)[0]):setRightImage(Object.values(files)[0])
    e.target.value = ""
  }
  function handleCoverImageOther(e, files,key) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    key == 1? setOtherLeftImage(Object.values(files)[0]):setOtherRightImage(Object.values(files)[0])
    e.target.value = ""
  }

  function prevSelectImage(e, prev) {
    e.preventDefault()
    setPrev(prev)
    setisOpen(!isOpen)
  }

  const [isLoading, setIsLoading] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  function submit() {
    const formData = new FormData()
    const data = {
      titleEn,
      titleAr,
      descriptionEn: descEn,
      descriptionAr: descAr,
      otherDescriptionEn: otherDescEn,
      otherDescriptionAr: otherDescAr,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    formData.append("leftImage", leftImage)
    formData.append("rightImage", rightImage)
    formData.append("otherLeftImage", otherLeftImage)
    formData.append("otherRightImage", otherRightImage)
    setIsLoading(true)
    axiosConfig.post(`/dashboard/aboutPage/learnAboutLayouti`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res=>{
      //
       toast.success(res.data.message)
      setsuccess_msg(true)
      setIsLoading(false)
    }).catch(err=>{
      //
      toast.error(err.response.data.message)
      setIsLoading(false)
    })
  }
    
  useEffect(()=>{
    setTitleEn(sectionData?.titleEn)
    setTitleAr(sectionData?.titleAr)
    setDescEn(sectionData?.descriptionEn)
    setDescAr(sectionData?.descriptionAr)
    setLeftImage(sectionData?.leftImage)
    setRightImage(sectionData?.rightImage)
    setOtherDescEn(sectionData?.otherDescriptionEn)
    setOtherDescAr(sectionData?.otherDescriptionAr)
    setOtherLeftImage(sectionData?.otherLeftImage)
    setOtherRightImage(sectionData?.otherRightImage)

    // setImage(sectionData?.image)
    //
  },[sectionData])
  return (
    <div>
      {success_msg ? (
        <SweetAlert
          title={msg}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        >
        </SweetAlert>
      ) : null}
    {isOpen ? (
      <Lightbox
        mainSrc={prev}
        enableZoom={false}
        onCloseRequest={() => {
          setisOpen(!isOpen)
        }}
      />
    ) : null}
    <Form className="border-0 pb-2">
      <Row className="mt-3">
        <Col sm="6">
          <div>
            <Label htmlFor="productdesc">Title (english)</Label>
            <Input
              id="itemnameen"
              name="itemnameen"
              type="text"
              className="form-control input_cus"
              placeholder="e.g. Focus Keypharse"
              value={titleEn}
              onChange={e => setTitleEn(e.target.value)}
            />
          </div>
        </Col>

        <Col sm="6">
          <div>
            <Label htmlFor="productdesc">Title (arabic)</Label>
            <Input
              id="itemnamear"
              name="itemnamear"
              type="text"
              className="form-control input_cus"
              placeholder="مثال. "
              value={titleAr}
              onChange={e => setTitleAr(e.target.value)}
              dir="rtl"
              lang="ar"
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col sm="6">
          <div>
            <Label htmlFor="productdesc">Description (English)</Label>
            <textarea
              className="form-control  mb-0"
              id="specification"
              rows="5"
              placeholder="Enter your description here"
              value={descEn}
              onChange={e => setDescEn(e.target.value)}
            />
          </div>
        </Col>
        <Col sm="6">
          <div>
            <Label htmlFor="productdesc">Description (Arabic)</Label>
            <textarea
              className="form-control  mb-0"
              id="specification"
              rows="5"
              placeholder="اكتب وصف هنا"
              value={descAr}
              onChange={e => setDescAr(e.target.value)}
              dir="rtl"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <div className="mt-3">
            <Label htmlFor="productdesc">Left Image</Label>
            <Input
              type="file"
              className="form-control form-control-lg "
              onChange={e => handleCoverImage(e, e.target.files,1)}
              style={{ borderRadius: 0 }}
              multiple
            />
          </div>
        </Col>
        <Col xs="12">
          <div className="dropzone-previews" id="file-previews">
            {leftImage ? (
              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={leftImage.name}
                        src={leftImage?.preview?leftImage?.preview:leftImage}
                        style={{ objectFit: "cover" }}
                      />
                    </Col>
                    <Col>
                      <Link to="#" className="text-muted ">
                        {leftImage.name}
                      </Link>
                    </Col>
                    <Col>
                      <div className="image__options">
                        <button
                          className="p-0"
                          onClick={e => prevSelectImage(e, leftImage?.preview?leftImage?.preview:leftImage)}
                        >
                          <i className="far fa-eye  fs-5 p-0"></i>
                        </button>
                        <button
                          style={{ color: "#ff3838" }}
                          onClick={e => setLeftImage(null)}
                        >
                          <i className="bx bx-trash fs-4"></i>
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            ) : null}
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <div className="mt-3">
            <Label htmlFor="productdesc">Right Image</Label>
            <Input
              type="file"
              className="form-control form-control-lg "
              onChange={e => handleCoverImage(e, e.target.files,2)}
              style={{ borderRadius: 0 }}
              multiple
            />
          </div>
        </Col>
        <Col xs="12">
          <div className="dropzone-previews" id="file-previews">
            {rightImage ? (
              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={rightImage.name}
                        src={rightImage?.preview?rightImage?.preview:rightImage}
                        style={{ objectFit: "cover" }}
                      />
                    </Col>
                    <Col>
                      <Link to="#" className="text-muted ">
                        {rightImage.name}
                      </Link>
                    </Col>
                    <Col>
                      <div className="image__options">
                        <button
                          className="p-0"
                          onClick={e => prevSelectImage(e, rightImage?.preview?rightImage?.preview:rightImage)}
                        >
                          <i className="far fa-eye  fs-5 p-0"></i>
                        </button>
                        <button
                          style={{ color: "#ff3838" }}
                          onClick={e => setRightImage(null)}
                        >
                          <i className="bx bx-trash fs-4"></i>
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            ) : null}
          </div>
        </Col>
      </Row>
    </Form>

        <Row className="mt-3 d-none">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (English)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="Enter your description here"
                value={otherDescEn}
                onChange={e => setOtherDescEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (Arabic)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="اكتب وصف هنا"
                value={otherDescAr}
                onChange={e => setOtherDescAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>
        <Row className="m-0 p-0 d-none">
          <Col xs="12" className="m-0 p-0">
            <div className="mt-3">
              <Label htmlFor="productdesc">Left Image</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => handleCoverImageOther(e, e.target.files,1)}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12">
            <div className="dropzone-previews" id="file-previews">
              {otherLeftImage ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={otherLeftImage.name}
                          src={otherLeftImage?.preview?otherLeftImage.preview:otherLeftImage}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {otherLeftImage.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e =>
                              prevSelectImage(e, otherLeftImage?.preview?otherLeftImage.preview:otherLeftImage)
                            }
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            style={{ color: "#ff3838" }}
                            onClick={e => setOtherLeftImage(null)}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row className="m-0 p-0  d-none">
          <Col xs="12" className="m-0 p-0">
            <div className="mt-3">
              <Label htmlFor="productdesc">Right Image</Label>
              <Input
                type="file"
                className="form-control form-control-lg "
                onChange={e => handleCoverImageOther(e, e.target.files,2)}
                style={{ borderRadius: 0 }}
                multiple
              />
            </div>
          </Col>
          <Col xs="12" className="m-0 p-0">
            <div className="dropzone-previews" id="file-previews">
              {otherRightImage ? (
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          alt={otherRightImage.name}
                          src={otherRightImage?.preview?otherRightImage.preview:otherRightImage}
                          style={{ objectFit: "cover" }}
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted ">
                          {otherRightImage.name}
                        </Link>
                      </Col>
                      <Col>
                        <div className="image__options">
                          <button
                            className="p-0"
                            onClick={e =>
                              prevSelectImage(e, otherRightImage?.preview?otherRightImage.preview:otherRightImage)
                            }
                          >
                            <i className="far fa-eye  fs-5 p-0"></i>
                          </button>
                          <button
                            style={{ color: "#ff3838" }}
                            onClick={e => setOtherRightImage(null)}
                          >
                            <i className="bx bx-trash fs-4"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              ) : null}
            </div>
          </Col>
        </Row>
        <hr style={{ margin: "20px -20px 20px -20px" }} />

        <Row className="mt-3">
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0">
              <button type='button' onClick={submit} disabled={isLoading?true:false}>
                {isLoading ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
              </div>
            </Col>
          </Row>
        </div>
        
  )
}

export default LearnAboutLayouti

const cardStyle = {
  borderTop: "2px solid #dedede",
}
