import axios from "axios"
import React, { useEffect, useState } from "react"
import {Col, Input, Label, Row } from "reactstrap"
import Select from "react-select"

const DeliverablesForm = ({
  data,
  ChangeHandler,
}) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API}/dashboard/configurations/deliverable`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setOptions(
          res.data.data.map(item => ({ label: item.titleEn, value: item.id }))
        )
      })
  }, [])

  return (
    <>
      <Row>
        <Col>
          <div className="mb-3">
            <div className="d-flex">
              <Label>Main Title (English)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="Label"
              name="DesignAppDeliverables_titleEn"
              value={data["DesignAppDeliverables_titleEn"]}
              onChange={e =>
                ChangeHandler({
                  ...data,
                  DesignAppDeliverables_titleEn: e.target.value,
                })
              }
            />
          </div>
        </Col>
        <Col>
          <div className="mb-3">
            <div className="d-flex">
              <Label>Main Title (Arabic)</Label>
              <label className="ms-auto optional-label--color">
                (Optional)
              </label>
            </div>
            <Input
              className="input_cus"
              placeholder="Label"
              name="DesignAppDeliverables_titleAr"
              value={data["DesignAppDeliverables_titleAr"]}
              onChange={e =>
                ChangeHandler({
                  ...data,
                  DesignAppDeliverables_titleAr: e.target.value,
                })
              }
              dir="rtl"
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <Label htmlFor="productdesc">Deliverables</Label>
            <Select
              isMulti={true}
              name="deliverable"
              value={data["DesignAppDeliverables_deliverables"]}
              onChange={e =>
                ChangeHandler({
                  ...data,
                  DesignAppDeliverables_deliverables: e,
                })
              }
              options={options}
              className="select2-selection"
            />
          </div>
        </Col>
      </Row>

      <Row className="mb-1">
        <Col>
          <div className="d-flex">
            <Label htmlFor="colorID">Background Color</Label>
            <label className="ms-auto optional-label--color">(Optional)</label>
          </div>
          <div className="d-flex">
            <input
              type="color"
              className="form-control colorInput"
              name="DesignAppDeliverables_colors"
              value={data["DesignAppDeliverables_colors"]}
              onChange={e =>
                ChangeHandler({
                  ...data,
                  DesignAppDeliverables_colors: e.target.value,
                })
              }
            />
            <Input
              type="text"
              className="color__name rounded-0"
              name="DesignAppDeliverables_colors"
              value={data["DesignAppDeliverables_colors"]}
              onChange={e =>
                ChangeHandler({
                  ...data,
                  DesignAppDeliverables_colors: e.target.value,
                })
              }
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default DeliverablesForm
