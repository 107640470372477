import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import prettyBytes from "pretty-bytes"
import axiosConfig from "axiosConfig"
import SweetAlert from "react-bootstrap-sweetalert"
import { toast } from "react-toastify"

const WhatWeWillServe = ({sectionData}) => {
  // const [active, setActive] = useState(false)
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState(null)
  const [titleEn, setTitleEn] = useState("")
  const [titleAr, setTitleAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const [cardOneTitleEn, setCardOneTitleEn] = useState("")
  const [cardOneTitleAr, setCardOneTitleAr] = useState("")
  const [cardOneDescEn, setCardOneDescEn] = useState("")
  const [cardOneDescAr, setCardOneDescAr] = useState("")
  const [cardOneImage, setCardOneImage] = useState(null)

  const [cardTwoTitleEn, setCardTwoTitleEn] = useState("")
  const [cardTwoTitleAr, setCardTwoTitleAr] = useState("")
  const [cardTwoDescEn, setCardTwoDescEn] = useState("")
  const [cardTwoDescAr, setCardTwoDescAr] = useState("")
  const [cardTwoImage, setCardTwoImage] = useState(null)

  const [cardThreeTitleEn, setCardThreeTitleEn] = useState("")
  const [cardThreeTitleAr, setCardThreeTitleAr] = useState("")
  const [cardThreeDescEn, setCardThreeDescEn] = useState("")
  const [cardThreeDescAr, setCardThreeDescAr] = useState("")
  const [cardThreeImage, setCardThreeImage] = useState(null)

  const [cardForthTitleEn, setCardForthTitleEn] = useState("")
  const [cardForthTitleAr, setCardForthTitleAr] = useState("")
  const [cardForthDescEn, setCardForthDescEn] = useState("")
  const [cardForthDescAr, setCardForthDescAr] = useState("")
  const [cardForthImage, setCardForthImage] = useState(null)

  const [cardFifthTitleEn, setCardFifthTitleEn] = useState("")
  const [cardFifthTitleAr, setCardFifthTitleAr] = useState("")
  const [cardFifthDescEn, setCardFifthDescEn] = useState("")
  const [cardFifthDescAr, setCardFifthDescAr] = useState("")
  const [cardFifthImage, setCardFifthImage] = useState(null)

  const [cardSixTitleEn, setCardSixTitleEn] = useState("")
  const [cardSixTitleAr, setCardSixTitleAr] = useState("")
  const [cardSixDescEn, setCardSixDescEn] = useState("")
  const [cardSixDescAr, setCardSixDescAr] = useState("")
  const [cardSixImage, setCardSixImage] = useState(null)


  function handleImage(e, files, type) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    type == 1?setCardOneImage(Object.values(files)[0]):
    type == 2?setCardTwoImage(Object.values(files)[0]):
    type == 3?setCardThreeImage(Object.values(files)[0]):
    type == 4?setCardForthImage(Object.values(files)[0]):
    type == 5?setCardFifthImage(Object.values(files)[0]):
    setCardSixImage(Object.values(files)[0])
    e.target.value = ""
  }
  
  function prevSelectImage(e, prev) {
    e.preventDefault()
    setPrev(prev)
    setisOpen(!isOpen)
  }

  function submit(e) {
    e.preventDefault()
    const formData = new FormData()
    const data = {
      titleEn,
      titleAr,
      descriptionEn: descEn,
      descriptionAr: descAr,

     
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    formData.append("cards[0][titleEn]", cardOneTitleEn)
    formData.append("cards[0][titleAr]", cardOneTitleAr)
    formData.append("cards[0][descriptionEn]", cardOneDescEn)
    formData.append("cards[0][descriptionAr]", cardOneDescAr)
    
    formData.append("cards[1][titleEn]", cardTwoTitleEn)
    formData.append("cards[1][titleAr]", cardTwoTitleAr)
    formData.append("cards[1][descriptionEn]", cardTwoDescEn)
    formData.append("cards[1][descriptionAr]", cardTwoDescAr)

    formData.append("cards[2][titleEn]", cardThreeTitleEn)
    formData.append("cards[2][titleAr]", cardThreeTitleAr)
    formData.append("cards[2][descriptionEn]", cardThreeDescEn)
    formData.append("cards[2][descriptionAr]", cardThreeDescAr)

    formData.append("cards[3][titleEn]", cardForthTitleEn)
    formData.append("cards[3][titleAr]", cardForthTitleAr)
    formData.append("cards[3][descriptionEn]", cardForthDescEn)
    formData.append("cards[3][descriptionAr]",cardForthDescAr)

    formData.append("cards[4][titleEn]", cardFifthTitleEn)
    formData.append("cards[4][titleAr]", cardFifthTitleAr)
    formData.append("cards[4][descriptionEn]", cardFifthDescEn)
    formData.append("cards[4][descriptionAr]",cardFifthDescAr)

    formData.append("cards[5][titleEn]", cardSixTitleEn)
    formData.append("cards[5][titleAr]", cardSixTitleAr)
    formData.append("cards[5][descriptionEn]", cardSixDescEn)
    formData.append("cards[5][descriptionAr]",cardSixDescAr)
    
    setIsLoading(true)
    axiosConfig.post(`/dashboard/homePage/whatWeWillServe`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res=>{
      //
       toast.success(res.data.message)
      setsuccess_msg(true)
      setIsLoading(false)
    }).catch(err=>{
      //
      toast.error(err.response.data.message)
      setIsLoading(false)
    })
  }

  useEffect(()=>{
    setTitleEn(sectionData?.titleEn)
    setTitleAr(sectionData?.titleAr)
    setDescEn(sectionData?.descriptionEn)
    setDescAr(sectionData?.descriptionAr)

    setCardOneTitleEn(sectionData?.cards[0]?.titleEn)
    setCardOneTitleAr(sectionData?.cards[0]?.titleAr)
    setCardOneDescEn(sectionData?.cards[0]?.descriptionEn)
    setCardOneDescAr(sectionData?.cards[0]?.descriptionAr)
    setCardOneImage(sectionData?.cards[0]?.image)

    setCardTwoTitleEn(sectionData?.cards[1]?.titleEn)
    setCardTwoTitleAr(sectionData?.cards[1]?.titleAr)
    setCardTwoDescEn(sectionData?.cards[1]?.descriptionEn)
    setCardTwoDescAr(sectionData?.cards[1]?.descriptionAr)
    setCardTwoImage(sectionData?.cards[1]?.image)

    setCardThreeTitleEn(sectionData?.cards[2]?.titleEn)
    setCardThreeTitleAr(sectionData?.cards[2]?.titleAr)
    setCardThreeDescEn(sectionData?.cards[2]?.descriptionEn)
    setCardThreeDescAr(sectionData?.cards[2]?.descriptionAr)
    setCardThreeImage(sectionData?.cards[2]?.image)

    setCardForthTitleEn(sectionData?.cards[3]?.titleEn)
    setCardForthTitleAr(sectionData?.cards[3]?.titleAr)
    setCardForthDescEn(sectionData?.cards[3]?.descriptionEn)
    setCardForthDescAr(sectionData?.cards[3]?.descriptionAr)
    setCardForthImage(sectionData?.cards[3]?.image)


    setCardFifthTitleEn(sectionData?.cards[4]?.titleEn)
    setCardFifthTitleAr(sectionData?.cards[4]?.titleAr)
    setCardFifthDescEn(sectionData?.cards[4]?.descriptionEn)
    setCardFifthDescAr(sectionData?.cards[4]?.descriptionAr)
    setCardFifthImage(sectionData?.cards[4]?.image)

    setCardSixTitleEn(sectionData?.cards[5]?.titleEn)
    setCardSixTitleAr(sectionData?.cards[5]?.titleAr)
    setCardSixDescEn(sectionData?.cards[5]?.descriptionEn)
    setCardSixDescAr(sectionData?.cards[5]?.descriptionAr)
    setCardSixImage(sectionData?.cards[5]?.image)


  },[sectionData])

  return (
    <div>
      {success_msg ? (
        <SweetAlert
          title={msg}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        >
        </SweetAlert>
      ) : null}
        {isOpen ? (
        <Lightbox
          mainSrc={prev}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
      <Form className="border-0 pb-2">
        <Row className="">
          <h3 className="fs-4 text-black fw-bolder mb-3">Header Content</h3>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (english)</Label>
              <Input
                id="itemnameen"
                name="itemnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={titleEn}
                onChange={e => setTitleEn(e.target.value)}
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (arabic)</Label>
              <Input
                id="itemnamear"
                name="itemnamear"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. "
                value={titleAr}
                onChange={e => setTitleAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (English)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="Enter your description here"
                value={descEn}
                onChange={e => setDescEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (Arabic)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="اكتب وصف هنا"
                value={descAr}
                onChange={e => setDescAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>


        <div className="mt-2 pt-4" >
            <hr style={{ margin: "0px -20px 20px -20px" }} />
            <h3 className="fs-4 text-black fw-bolder mb-3">Card (01)</h3>
            <Row className="mt-3">
                <Col sm="6">
                    <div>
                    <Label htmlFor="productdesc">Title (english)</Label>
                    <Input
                        id="itemnameen"
                        name="itemnameen"
                        type="text"
                        className="form-control input_cus"
                        placeholder="e.g. Focus Keypharse"
                        value={cardOneTitleEn}
                        onChange={e => setCardOneTitleEn(e.target.value)}
                    />
                    </div>
                </Col>

            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Title (arabic)</Label>
                <Input
                  id="itemnamear"
                  name="itemnamear"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. "
                  value={cardOneTitleAr}
                  onChange={e => setCardOneTitleAr(e.target.value)}
                  dir="rtl"
                  lang="ar"
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Description (English)</Label>
                <textarea
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="Enter your description here"
                  value={cardOneDescEn}
                  onChange={e => setCardOneDescEn(e.target.value)}
                />
              </div>
            </Col>
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Description (Arabic)</Label>
                <textarea
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="اكتب وصف هنا"
                  value={cardOneDescAr}
                  onChange={e => setCardOneDescAr(e.target.value)}
                  dir="rtl"
                />
              </div>
            </Col>
          </Row>
        </div>
        


        <div className="mt-2 pt-4" >
            <hr style={{ margin: "0px -20px 20px -20px" }} />
          <h3 className="fs-4 text-black fw-bolder mb-3">Card (02)</h3>
          <Row className="mt-3">
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Title (english)</Label>
                <Input
                  id="itemnameen"
                  name="itemnameen"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g. Focus Keypharse"
                  value={cardTwoTitleEn}
                  onChange={e => setCardTwoTitleEn(e.target.value)}
                />
              </div>
            </Col>

            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Title (arabic)</Label>
                <Input
                  id="itemnamear"
                  name="itemnamear"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. "
                  value={cardTwoTitleAr}
                  onChange={e => setCardTwoTitleAr(e.target.value)}
                  dir="rtl"
                  lang="ar"
                />
              </div>
            </Col>
           
          </Row>
          <Row className="mt-3">
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Description (English)</Label>
                <textarea
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="Enter your description here"
                  value={cardTwoDescEn}
                  onChange={e => setCardTwoDescEn(e.target.value)}
                />
              </div>
            </Col>
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Description (Arabic)</Label>
                <textarea
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="اكتب وصف هنا"
                  value={cardTwoDescAr}
                  onChange={e => setCardTwoDescAr(e.target.value)}
                  dir="rtl"
                />
              </div>
            </Col>
          </Row>
          
        </div>
        <div className="mt-2 pt-4" >
        <hr style={{ margin: "0px -20px 20px -20px" }} />

          <h3 className="fs-4 text-black fw-bolder mb-3">Card (03)</h3>
          <Row className="mt-3">
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Title (english)</Label>
                <Input
                  id="itemnameen"
                  name="itemnameen"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g. Focus Keypharse"
                  value={cardThreeTitleEn}
                  onChange={e => setCardThreeTitleEn(e.target.value)}
                />
              </div>
            </Col>

            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Title (arabic)</Label>
                <Input
                  id="itemnamear"
                  name="itemnamear"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. "
                  value={cardThreeTitleAr}
                  onChange={e => setCardThreeTitleAr(e.target.value)}
                  dir="rtl"
                  lang="ar"
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Description (English)</Label>
                <textarea
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="Enter your description here"
                  value={cardThreeDescEn}
                  onChange={e => setCardThreeDescEn(e.target.value)}
                />
              </div>
            </Col>
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Description (Arabic)</Label>
                <textarea
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="اكتب وصف هنا"
                  value={cardThreeDescAr}
                  onChange={e => setCardThreeDescAr(e.target.value)}
                  dir="rtl"
                />
              </div>
            </Col>
          </Row>
          
        </div>
        <div className="mt-2 pt-4" >
            <hr style={{ margin: "0px -20px 20px -20px" }} />
            <h3 className="fs-4 text-black fw-bolder mb-3">Card (04)</h3>
            <Row className="mt-3">
                <Col sm="6">
                    <div>
                    <Label htmlFor="productdesc">Title (english)</Label>
                    <Input
                        id="itemnameen"
                        name="itemnameen"
                        type="text"
                        className="form-control input_cus"
                        placeholder="e.g. Focus Keypharse"
                        value={cardForthTitleEn}
                        onChange={e => setCardForthTitleEn(e.target.value)}
                    />
                    </div>
                </Col>

            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Title (arabic)</Label>
                <Input
                  id="itemnamear"
                  name="itemnamear"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. "
                  value={cardForthTitleAr}
                  onChange={e => setCardForthTitleAr(e.target.value)}
                  dir="rtl"
                  lang="ar"
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Description (English)</Label>
                <textarea
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="Enter your description here"
                  value={cardForthDescEn}
                  onChange={e => setCardForthDescEn(e.target.value)}
                />
              </div>
            </Col>
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Description (Arabic)</Label>
                <textarea
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="اكتب وصف هنا"
                  value={cardForthDescAr}
                  onChange={e => setCardForthDescAr(e.target.value)}
                  dir="rtl"
                />
              </div>
            </Col>
          </Row>
        </div>


        <div className="mt-2 pt-4" >
            <hr style={{ margin: "0px -20px 20px -20px" }} />
            <h3 className="fs-4 text-black fw-bolder mb-3">Card (05)</h3>
            <Row className="mt-3">
                <Col sm="6">
                    <div>
                    <Label htmlFor="productdesc">Title (english)</Label>
                    <Input
                        id="itemnameen"
                        name="itemnameen"
                        type="text"
                        className="form-control input_cus"
                        placeholder="e.g. Focus Keypharse"
                        value={cardFifthTitleEn}
                        onChange={e => setCardFifthTitleEn(e.target.value)}
                    />
                    </div>
                </Col>

            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Title (arabic)</Label>
                <Input
                  id="itemnamear"
                  name="itemnamear"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. "
                  value={cardFifthTitleAr}
                  onChange={e => setCardFifthTitleAr(e.target.value)}
                  dir="rtl"
                  lang="ar"
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Description (English)</Label>
                <textarea
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="Enter your description here"
                  value={cardFifthDescEn}
                  onChange={e => setCardFifthDescEn(e.target.value)}
                />
              </div>
            </Col>
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Description (Arabic)</Label>
                <textarea
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="اكتب وصف هنا"
                  value={cardFifthDescAr}
                  onChange={e => setCardFifthDescAr(e.target.value)}
                  dir="rtl"
                />
              </div>
            </Col>
          </Row>
        </div>

        
        <div className="mt-2 pt-4" >
            <hr style={{ margin: "0px -20px 20px -20px" }} />
            <h3 className="fs-4 text-black fw-bolder mb-3">Card (06)</h3>
            <Row className="mt-3">
                <Col sm="6">
                    <div>
                    <Label htmlFor="productdesc">Title (english)</Label>
                    <Input
                        id="itemnameen"
                        name="itemnameen"
                        type="text"
                        className="form-control input_cus"
                        placeholder="e.g. Focus Keypharse"
                        value={cardSixTitleEn}
                        onChange={e => setCardSixTitleEn(e.target.value)}
                    />
                    </div>
                </Col>

            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Title (arabic)</Label>
                <Input
                  id="itemnamear"
                  name="itemnamear"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. "
                  value={cardSixTitleAr}
                  onChange={e => setCardSixTitleAr(e.target.value)}
                  dir="rtl"
                  lang="ar"
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Description (English)</Label>
                <textarea
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="Enter your description here"
                  value={cardSixDescEn}
                  onChange={e => setCardSixDescEn(e.target.value)}
                />
              </div>
            </Col>
            <Col sm="6">
              <div>
                <Label htmlFor="productdesc">Description (Arabic)</Label>
                <textarea
                  className="form-control  mb-0"
                  id="specification"
                  rows="5"
                  placeholder="اكتب وصف هنا"
                  value={cardSixDescAr}
                  onChange={e => setCardSixDescAr(e.target.value)}
                  dir="rtl"
                />
              </div>
            </Col>
          </Row>
        </div>







      </Form>
      <hr style={{ margin: "20px -20px 20px -20px" }} />
      <Row className="mt-2">
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0">
              <button type='button' onClick={submit} disabled={isLoading?true:false}>
                
                {isLoading ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
    </div>
  )
}

export default WhatWeWillServe

const cardStyle = {
  borderTop: "2px solid #dedede",
}
