/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Form, Label, Input, FormFeedback,Collapse, Button } from "reactstrap"
import classnames from "classnames";

import {Link} from "react-router-dom"

import './specification.scss'
import { toast } from "react-toastify";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from "axios";

import SweetAlert from "react-bootstrap-sweetalert"
import ReactLoading from "react-loading";

    
const Links = (props) => {
    const [rows1, setrows1] = useState([{ id: 1 }])
    const [active, setActive] = useState(false)
    const [msg, setMsg] = useState("")
    const [success_msg, setsuccess_msg] = useState(false)
   
    const [isOpen, setisOpen] = useState(false);
    const [prev, setPrev] = useState(null);
    
    
    const [personalImage, setPersonalImage] = useState(null)
    const [titleEn, setTitleEn] = useState('')
    const [titleAr, setTitleAr] = useState('')
    const [descEn, setDescEn] = useState('')
    const [descAr, setDescAr] = useState('')
    const [TlImage, setTlImage] = useState(null)
    const [TrImage, setTrImage] = useState(null)
    const [BlImage, setBlImage] = useState(null)
    const [BrImage, setBrImage] = useState(null)
    const [links, setLinks] = useState([{titleEn: "",titleAr: "", link: ""}])
    const [seoFocusEn, setSeoFocusEn] = useState('');
    const [seoFocusAr, setSeoFocusAr] = useState('');
    const [seoTitleEn, setSeoTitleEn] = useState('')
    const [seoTitleAr, setSeoTitleAr] = useState('')
    const [seoDescEn, setSeoDescEn] = useState('')
    const [seoDescAr, setSeoDescAr] = useState('')
    const [seoSlugEn, setSeoSlugEn] = useState('')
    const [seoSlugAr, setSeoSlugAr] = useState('')
    const [seoFaceTitleEn, setSeoFaceTitleEn] = useState('')
    const [seoFaceTitleAr, setSeoFaceTitleAr] = useState('')
    const [seoFaceDescEn, setSeoFaceDescEn] = useState('')
    const [seoFaceDescAr, setSeoFaceDescAr] = useState('')
    const [facebookImage, setFacebookImage] = useState(null)


    function handleFacebookFiles(e, files) {
        [...files].map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        )
        setFacebookImage(Object.values(files)[0])
        e.target.value = '';
    }


    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }


    function prevSelectImage(e, img) {
        e.preventDefault()
        setPrev(img)
        setisOpen(true)
    }

    function handleAddRowNested() {
        const modifiedRows = [...rows1]
        modifiedRows.push({ id: modifiedRows.length + 1 })
        setrows1(modifiedRows)
        setLinks(specifications =>
        specifications.concat({titleEn: "",titleAr: "", link: ""}))
    }

    function handleRemoveRow(id) {
        if (id !== 1) {
        var modifiedRows = [...rows1]
        modifiedRows = modifiedRows.filter(x => x["id"] !== id)
        setrows1(modifiedRows)
        setLinks(specifications =>
            specifications.filter((c, i) => i != id - 1)
        )
        }
    }


    function informationsChange(e, key, type) {
        setLinks(specs =>
        specs.map((spec, index) => {
            if (index === key && type == "titleen") {
            return { ...spec, titleEn: e.target.value }
            }else if (index === key && type == "titlear") {
                return { ...spec, titleAr: e.target.value }
            }  else if (index === key && type == "link") {
            return { ...spec, link: e.target.value }
            } 
            return spec
        })
        )
    }


    function submit(e) {
        e.preventDefault();
        setActive(true)
        const formData = new FormData();
        let data = {
            titleEn,
            titleAr,
            descriptionEn: descEn,
            descriptionAr: descAr,
            keyphraseEn: seoFocusEn,
            keyphraseAr: seoFocusAr,
            seoTitleEn: seoTitleEn,
            seoTitleAr: seoTitleAr,
            metadescriptionEn: seoDescEn,
            metadescriptionAr: seoDescAr,
            slugEn: seoSlugEn,
            slugAr: seoSlugAr,
            facebookTitlteEn: seoFaceTitleEn,
            facebookTitlteAr: seoFaceTitleAr,
            facebookDescriptionEn: seoFaceDescEn,
            facebookDescriptionAr: seoFaceDescAr,
        }
        
        for (let key in data) {
            formData.append(key, data[key])
        }
        
        if(personalImage&&personalImage.name) {
            formData.append("personalImage", personalImage)
        }
        if(TlImage&&TlImage.name) {
            formData.append("topLeftImage", TlImage)
        }
        if(TrImage&&TrImage.name) {
            formData.append("topRightImage", TrImage)
        }
        if(BlImage&&BlImage.name) {
            formData.append("bottomLeftImage", BlImage)
        }
        if(BrImage&&BrImage.name) {
            formData.append("bottomRightImage", BrImage)
        }
        if(facebookImage&&facebookImage.name) {
            formData.append("facebookImage", facebookImage)
        }

        links.forEach((spec,i) => {
            formData.append(`links[${i}][titleEn]`, spec.titleEn);
            formData.append(`links[${i}][titleAr]`, spec.titleAr);
            formData.append(`links[${i}][link]`, spec.link);
        }); 

        axios.post(`${process.env.REACT_APP_API}/admin/linkPage`,formData ,{
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
            }
        })
        .then(res => {
            
            setActive(false)
            setMsg(`Saved!`)
            setsuccess_msg(true)
        })
    }

    // useEffect(() => {
    //     axios.get(`${process.env.REACT_APP_API}/admin/linkPage` ,{
    //         headers: {
    //             'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
    //         }
    //     })
    //     .then(res => {
    //         let data = res.data.data.linkPage;
    //         
    //         setTitleEn(data?.titltEn)
    //         setTitleAr(data?.titltAr)
    //         setDescEn(data?.descriptionEn)
    //         setDescAr(data?.descriptionAr)
    //         setPersonalImage({preview: data?.personalImage})
    //         setTlImage({preview: data?.topLeftImage})
    //         setTrImage({preview: data?.topRightImage})
    //         setBlImage({preview: data?.bottomLeftImage})
    //         setBrImage({preview: data?.bottomRightImage})
    //         setSeoFocusEn(data?.keyphraseEn)
    //         setSeoFocusAr(data?.keyphraseAr)
    //         setSeoTitleEn(data?.seoTitleEn)
    //         setSeoTitleAr(data?.seoTitleAr)
    //         setSeoSlugEn(data?.slugEn)
    //         setSeoSlugAr(data?.slugAr)
    //         setSeoDescEn(data?.metadescriptionEn)
    //         setSeoDescAr(data?.metadescriptionAr)
    //         setSeoFaceTitleEn(data?.facebookTitlteEn)
    //         setSeoFaceTitleAr(data?.facebookTitlteAr)
    //         setSeoFaceDescEn(data?.facebookDescriptionEn)
    //         setSeoFaceDescAr(data?.facebookDescriptionAr)
    //         setFacebookImage({preview: data?.facebookImage})
    //         if(res.data.data?.links.length !== 0) {
    //             setLinks(res.data.data?.links)
    //             setrows1(res.data.data?.links.map(link => {return {id: link.id}}))
    //         }
    //     })
    // }, []) 


    return (
            <React.Fragment>
                <div className="container-fluid p-0">
                <Card>
                    <CardBody>
                    {success_msg ? (
                        <SweetAlert
                        title={msg}
                        success
                        confirmBtnBsStyle="success"
                        onConfirm={() => {
                            setsuccess_msg(false)
                        }}
                        onCancel={() => {
                            setsuccess_msg(false)
                        }}
                        >
                            </SweetAlert>
                        ) : null}
                    {isOpen ? (
                    <Lightbox
                        mainSrc={prev}
                        enableZoom={false}
                        onCloseRequest={() => {
                        setisOpen(!isOpen);
                        }}
                    />
                    ) : null} 
                    <Form className="border-0">
                        <Row className="mt-3">
                                <Col xs="12">
                                    <div >
                                        <Label htmlFor="productdesc" >
                                            Personal Image
                                        </Label>
                                        <Input
                                            type="file"
                                            className="form-control form-control-lg "
                                            onChange={e => setPersonalImage(Object.assign(e.target.files[0] , {
                                                preview: URL.createObjectURL(e.target.files[0]),
                                                formattedSize: formatBytes(e.target.files[0].size)
                                            }))}
                                            style={{borderRadius: 0}}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="dropzone-previews" id="file-previews">
                                        {personalImage?
                                            <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            >
                                                <div className="p-2">
                                                <Row className="align-items-center">
                                                    <Col className="col-auto">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={personalImage.name}
                                                        src={personalImage.preview}
                                                        style={{ objectFit: "cover" }}
                                                    />
                                                    </Col>
                                                    <Col>
                                                    <Link to="#" className="text-muted ">
                                                        {personalImage.name}
                                                    </Link>
                                                    
                                                    </Col>
                                                    <Col>
                                                    <div className="image__options">
                                                        <button
                                                            className="p-0"
                                                            onClick={e => prevSelectImage(e, personalImage.preview)}
                                                        >
                                                        <i className="far fa-eye  fs-5 p-0"></i>
                                                        </button>
                                                        <button
                                                        style={{ color: "#ff3838" }}
                                                        onClick={e => setPersonalImage(null)}
                                                        >
                                                        <i className="bx bx-trash fs-4"></i>
                                                        </button>
                                                    </div>
                                                    </Col>
                                                </Row>
                                                </div>
                                            </Card>:null
                                            
                                        }
                                        </div>
                                </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <Label>Title (english)</Label>
                                <Input 
                                    className="input_cus"
                                    placeholder="Title"
                                    value= {titleEn}
                                    onChange= {(e) => setTitleEn(e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Label>Title (arabic)</Label>
                                <Input 
                                    className="input_cus"
                                    placeholder="عنوان"
                                    dir="rtl"
                                    value= {titleAr}
                                    onChange= {(e) => setTitleAr(e.target.value)}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <Label>description (english)</Label>
                                <textarea
                                    className="form-control  mb-0"
                                    rows="5"
                                    placeholder="Description"
                                    value={descEn}
                                    onChange={e => setDescEn(e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Label>description (arabic)</Label>
                                <textarea
                                    className="form-control  mb-0"
                                    rows="5"
                                    placeholder="وصف"
                                    value={descAr}
                                    onChange={e => setDescAr(e.target.value)}
                                    dir="rtl"
                                />
                            </Col>
                        </Row>

                        <hr style={{ margin: "25px -20px 20px -20px" }} />
                        <Row className="mt-3">
                            <Col xs="12">
                                <div >
                                    <Label htmlFor="productdesc" >
                                        Top Left Image
                                    </Label>
                                    <Input
                                        type="file"
                                        className="form-control form-control-lg "
                                        onChange={e => setTlImage(Object.assign(e.target.files[0] , {
                                            preview: URL.createObjectURL(e.target.files[0]),
                                            formattedSize: formatBytes(e.target.files[0].size)
                                        }))}
                                        style={{borderRadius: 0}}
                                        multiple
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="dropzone-previews" id="file-previews">
                                    {TlImage?
                                        <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        >
                                            <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                <img
                                                    data-dz-thumbnail=""
                                                    height="80"
                                                    className="avatar-sm rounded bg-light"
                                                    alt={TlImage.name}
                                                    src={TlImage.preview}
                                                    style={{ objectFit: "cover" }}
                                                />
                                                </Col>
                                                <Col>
                                                <Link to="#" className="text-muted ">
                                                    {TlImage.name}
                                                </Link>
                                                
                                                </Col>
                                                <Col>
                                                <div className="image__options">
                                                    <button
                                                    className="p-0"
                                                    onClick={e => prevSelectImage(e, TlImage.preview)}
                                                    >
                                                    <i className="far fa-eye  fs-5 p-0"></i>
                                                    </button>
                                                    <button
                                                    style={{ color: "#ff3838" }}
                                                    onClick={e => setTlImage(null)}
                                                    >
                                                    <i className="bx bx-trash fs-4"></i>
                                                    </button>
                                                </div>
                                                </Col>
                                            </Row>
                                            </div>
                                        </Card>:null
                                        
                                    }
                                    </div>
                            </Col>
                        </Row>

                        <hr style={{ margin: "25px -20px 20px -20px" }} />
                        <Row className="mt-3">
                            <Col xs="12">
                                <div >
                                    <Label htmlFor="productdesc" >
                                        Top Right Image
                                    </Label>
                                    <Input
                                        type="file"
                                        className="form-control form-control-lg "
                                        onChange={e => setTrImage(Object.assign(e.target.files[0] , {
                                            preview: URL.createObjectURL(e.target.files[0]),
                                            formattedSize: formatBytes(e.target.files[0].size)
                                        }))}
                                        style={{borderRadius: 0}}
                                        multiple
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="dropzone-previews" id="file-previews">
                                    {TrImage?
                                        <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        >
                                            <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                <img
                                                    data-dz-thumbnail=""
                                                    height="80"
                                                    className="avatar-sm rounded bg-light"
                                                    alt={TrImage.name}
                                                    src={TrImage.preview}
                                                    style={{ objectFit: "cover" }}
                                                />
                                                </Col>
                                                <Col>
                                                <Link to="#" className="text-muted ">
                                                    {TrImage.name}
                                                </Link>
                                                
                                                </Col>
                                                <Col>
                                                <div className="image__options">
                                                    <button
                                                    className="p-0"
                                                    onClick={e => prevSelectImage(e, TrImage.preview)}
                                                    >
                                                    <i className="far fa-eye  fs-5 p-0"></i>
                                                    </button>
                                                    <button
                                                    style={{ color: "#ff3838" }}
                                                    onClick={e => setTrImage(null)}
                                                    >
                                                    <i className="bx bx-trash fs-4"></i>
                                                    </button>
                                                </div>
                                                </Col>
                                            </Row>
                                            </div>
                                        </Card>:null
                                        
                                    }
                                    </div>
                            </Col>
                        </Row>

                        <hr style={{ margin: "25px -20px 20px -20px" }} />
                        <Row className="mt-3">
                            <Col xs="12">
                                <div >
                                    <Label htmlFor="productdesc" >
                                        Bottom Left Image
                                    </Label>
                                    <Input
                                        type="file"
                                        className="form-control form-control-lg "
                                        onChange={e => setBlImage(Object.assign(e.target.files[0] , {
                                            preview: URL.createObjectURL(e.target.files[0]),
                                            formattedSize: formatBytes(e.target.files[0].size)
                                        }))}
                                        style={{borderRadius: 0}}
                                        multiple
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="dropzone-previews" id="file-previews">
                                    {BlImage?
                                        <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        >
                                            <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                <img
                                                    data-dz-thumbnail=""
                                                    height="80"
                                                    className="avatar-sm rounded bg-light"
                                                    alt={BlImage.name}
                                                    src={BlImage.preview}
                                                    style={{ objectFit: "cover" }}
                                                />
                                                </Col>
                                                <Col>
                                                <Link to="#" className="text-muted ">
                                                    {BlImage.name}
                                                </Link>
                                                
                                                </Col>
                                                <Col>
                                                <div className="image__options">
                                                    <button
                                                    className="p-0"
                                                    onClick={e => prevSelectImage(e, BlImage.preview)}
                                                    >
                                                    <i className="far fa-eye  fs-5 p-0"></i>
                                                    </button>
                                                    <button
                                                    style={{ color: "#ff3838" }}
                                                    onClick={e => setBlImage(null)}
                                                    >
                                                    <i className="bx bx-trash fs-4"></i>
                                                    </button>
                                                </div>
                                                </Col>
                                            </Row>
                                            </div>
                                        </Card>:null
                                        
                                    }
                                    </div>
                            </Col>
                        </Row>
                        
                        <hr style={{ margin: "25px -20px 20px -20px" }} />
                        <Row className="mt-3">
                            <Col xs="12">
                                <div >
                                    <Label htmlFor="productdesc" >
                                        Bottom Right Image
                                    </Label>
                                    <Input
                                        type="file"
                                        className="form-control form-control-lg "
                                        onChange={e => setBrImage(Object.assign(e.target.files[0] , {
                                            preview: URL.createObjectURL(e.target.files[0]),
                                            formattedSize: formatBytes(e.target.files[0].size)
                                            }))}
                                        style={{borderRadius: 0}}
                                        multiple
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="dropzone-previews" id="file-previews">
                                    {BrImage?
                                        <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        >
                                            <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                <img
                                                    data-dz-thumbnail=""
                                                    height="80"
                                                    className="avatar-sm rounded bg-light"
                                                    alt={BrImage.name}
                                                    src={BrImage.preview}
                                                    style={{ objectFit: "cover" }}
                                                />
                                                </Col>
                                                <Col>
                                                <Link to="#" className="text-muted ">
                                                    {BrImage.name}
                                                </Link>
                                                
                                                </Col>
                                                <Col>
                                                <div className="image__options">
                                                    <button
                                                    className="p-0"
                                                    onClick={e => prevSelectImage(e, BrImage.preview)}
                                                    >
                                                    <i className="far fa-eye  fs-5 p-0"></i>
                                                    </button>
                                                    <button
                                                    style={{ color: "#ff3838" }}
                                                    onClick={e => setBrImage(null)}
                                                    >
                                                    <i className="bx bx-trash fs-4"></i>
                                                    </button>
                                                </div>
                                                </Col>
                                            </Row>
                                            </div>
                                        </Card>:null
                                        
                                    }
                                    </div>
                            </Col>
                        </Row>

                        <hr style={{ margin: "25px -20px 20px -20px" }} />
                        <Row>
                            <Col>
                                <h2 className="mb-0 mt-3 fs-4">Links</h2>
                                <div className="inner-repeater">
                                {(rows1 || []).map((formRow, key) => (
                                    <Fragment key={key}>
                                    <Row className="mt-3">
                                        <Col>
                                            <Label>Title (english)</Label>
                                            <Input 
                                                className="input_cus"
                                                placeholder="Title"
                                                value={links[key].titleEn}
                                                onChange={(e) => informationsChange(e, key,"titleen")}
                                            />
                                        </Col>
                                        <Col>
                                            <Label>Title (arabic)</Label>
                                            <Input 
                                                className="input_cus"
                                                placeholder="عنوان"
                                                dir="rtl"
                                                value={links[key].titleAr}
                                                onChange={(e) => informationsChange(e, key,"titlear")}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                        <Label htmlFor="productdesc" className="mt-3">
                                            Link
                                        </Label>
                                        <Input
                                            name="brandnameen"
                                            type="text"
                                            className="form-control input_cus"
                                            placeholder="e.g. title"
                                            value={links[key].link}
                                            onChange={(e) => informationsChange(e, key,"link")}
                                        />
                                        </Col>
                                    </Row>
                                    {key !== 0 ? (
                                        <Button
                                        color="none"
                                        variant="primary"
                                        className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                                        id="unknown-btn"
                                        style={{
                                            color: "#ff3838",
                                            boxShadow: "none",
                                            height: "45px",
                                            borderRadius: 0,
                                        }}
                                        onClick={e => {
                                            handleRemoveRow(formRow.id)
                                        }}
                                        >
                                        <i className="bx bx-trash-alt fs-4 p-0 mr-1"></i>{" "}
                                        Delete
                                        </Button>

                                    ) : null}
                                    {key !== 0 ? (
                                        <hr style={{ margin: "5px -20px 20px -20px" }} />
                                    ) : <hr style={{ margin: "25px -20px 20px -20px" }} />}
                                    </Fragment>
                                ))}
                                <Button
                                    onClick={() => {
                                    handleAddRowNested()
                                    }}
                                    color="none"
                                    className="mt-2 fs-5 fw-bold p-0 text-left"
                                    style={btnStyle}
                                >
                                    <i className="bx bx-plus-circle fs-4"></i>{" "}
                                    <span>Add Link</span>
                                </Button>
                                
                                </div>
                            </Col>
                        </Row>

                        <hr style={{ margin: "25px -20px 20px -20px" }} />
                        <Row>
                            <Col className="d-flex justify-content-end gap-2">
                                <div className="save__btn top m-0">
                                    <button onClick={(e) => submit(e)}>{active?<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />:"Save"}</button>
                                </div>
        
                            </Col>
                        </Row>
            </Form>
            </CardBody>
        </Card>      
        </div>
    </React.Fragment>
  )
}

export default Links

const btnStyle = {
    boxShadow: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    paddingLeft: "0",
}


const deleteBtn = {
    background: "transparent",
    border: "none",
    color:"#D91414",
    display: "flex",
    alignItems: "center",
    gap: "5px"
}

const editBtn = {
    background: "transparent",
    border: "none",
    color:"green",
    display: "flex",
    alignItems: "center",
    gap: "5px"
}