/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Form, Label, Input, } from "reactstrap"
import {Link} from "react-router-dom"
import './specification.scss'
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert"
import ReactLoading from "react-loading";

const CoverLetter = (props) => {
        const [active, setActive] = useState(false)
        const [msg, setMsg] = useState("")
        const [success_msg, setsuccess_msg] = useState(false)
        const [lgImage, setLgImage] = useState(null)
        const [isOpen, setisOpen] = useState(false);
        const [titleEn, setTitleEn] = useState('')
        const [titleAr, setTitleAr] = useState('')
        const [descEn, setDescEn] = useState('')
        const [descAr, setDescAr] = useState('')
        const [smallDescEn, setSmallDescEn] = useState('')
        const [smallDescAr, setSmallDescAr] = useState('')
        const [seoFocusEn, setSeoFocusEn] = useState('');
        const [seoFocusAr, setSeoFocusAr] = useState('');
        const [seoTitleEn, setSeoTitleEn] = useState('')
        const [seoTitleAr, setSeoTitleAr] = useState('')
        const [seoDescEn, setSeoDescEn] = useState('')
        const [seoDescAr, setSeoDescAr] = useState('')
        const [seoSlugEn, setSeoSlugEn] = useState('')
        const [seoSlugAr, setSeoSlugAr] = useState('')
        const [seoFaceTitleEn, setSeoFaceTitleEn] = useState('')
        const [seoFaceTitleAr, setSeoFaceTitleAr] = useState('')
        const [seoFaceDescEn, setSeoFaceDescEn] = useState('')
        const [seoFaceDescAr, setSeoFaceDescAr] = useState('')
        const [facebookImage, setFacebookImage] = useState(null)

        function handleFacebookFiles(e, files) {
            [...files].map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: formatBytes(file.size)
                })
            )
            setFacebookImage(Object.values(files)[0])
            e.target.value = '';
        }

        function handleThumbFiles(e, files) {
            [...files].map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: formatBytes(file.size)
                })
            )
            setLgImage(Object.values(files)[0])
            e.target.value = '';
        }

        function formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return "0 Bytes"
            const k = 1024
            const dm = decimals < 0 ? 0 : decimals
            const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
            const i = Math.floor(Math.log(bytes) / Math.log(k))
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
        }


    function submit(e) {
        e.preventDefault();
        setActive(true)
        const formData = new FormData();
        let data = {
            titleEn,
            titleAr,
            descriptionEn: descEn,
            descriptionAr: descAr,
            smallDescriptionEn: smallDescEn,
            smallDescriptionAr: smallDescAr,
            keyphraseEn: seoFocusEn,
            keyphraseAr: seoFocusAr,
            seoTitleEn: seoTitleEn,
            seoTitleAr: seoTitleAr,
            metadescriptionEn: seoDescEn,
            metadescriptionAr: seoDescAr,
            slugEn: seoSlugEn,
            slugAr: seoSlugAr,
            facebookTitlteEn: seoFaceTitleEn,
            facebookTitlteAr: seoFaceTitleAr,
            facebookDescriptionEn: seoFaceDescEn,
            facebookDescriptionAr: seoFaceDescAr,
            
        }
        for (let key in data) {
            formData.append(key, data[key])
        }
        if(lgImage&&lgImage.name) {
            formData.append("image", lgImage)
        }
        if(facebookImage&&facebookImage.name) {
            formData.append("facebookImage", facebookImage)
        }
        axios.post(`${process.env.REACT_APP_API}/admin/designPage`,formData ,{
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
            }
        })
        .then(res => {
            
            setActive(false)
            setMsg(`Saved!`)
            setsuccess_msg(true)
        })
    }


    return (
            <React.Fragment>
                <div className="container-fluid p-0">
                    {success_msg ? (
                        <SweetAlert
                        title={msg}
                        success
                        confirmBtnBsStyle="success"
                        onConfirm={() => {
                            setsuccess_msg(false)
                        }}
                        onCancel={() => {
                            setsuccess_msg(false)
                        }}
                        >
                            </SweetAlert>
                        ) : null}
                    {isOpen ? (
                    <Lightbox
                        mainSrc={image.preview}
                        enableZoom={false}
                        onCloseRequest={() => {
                        setisOpen(!isOpen);
                        }}
                    />
                    ) : null}
                    
                    <Form className="border-0">
                        
                        <Row >
                            <Col>
                                <Label>Cover letter (english)</Label>
                                <textarea
                                    className="form-control  mb-0"
                                    rows="5"
                                    placeholder="Description"
                                    value={descEn}
                                    onChange={e => setDescEn(e.target.value)}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <Label>description (arabic)</Label>
                                <textarea
                                    className="form-control  mb-0"
                                    rows="5"
                                    placeholder="وصف"
                                    value={descAr}
                                    onChange={e => setDescAr(e.target.value)}
                                    dir="rtl"
                                />
                            </Col>
                        </Row>
                        
                        <hr style={{ margin: "25px -20px 20px -20px" }} />
                        <Row>
                            <Col className="d-flex justify-content-end gap-2">
                                <div className="save__btn top m-0">
                                    <button onClick={(e) => submit(e)}>{active?<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />:"Save"}</button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </React.Fragment>
  )
}

export default CoverLetter

const deleteBtn = {
  background: "transparent",
  border: "none",
  color:"#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  color:"green",
  display: "flex",
  alignItems: "center",
  gap: "5px"
}