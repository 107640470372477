import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
//i18n
import { withTranslation } from "react-i18next"
const SidebarContent = props => {
  const ref = useRef()
  const notifications = useSelector(state => state.Notifications)
  const MainData = useSelector(state => state.MainData)
  const [notify, setNotify] = useState({
    notifications: [],
    userCount: 0,
    toyCount: 0,
    notifyCount: 0,
    suggestionCount: 0,
    feedbackCount: 0,
    reviewCount: 0,
    reportCount: 0,
  })
  const user = useSelector(state => state.UserData.user)
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  useEffect(() => {
    setNotify(notifications)
  }, [notifications])

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("General")} </li>

            <li>
              <Link to="/dashboard">
                <i className="bx bxs-dashboard"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Dashboards")}
                </span>
              </Link>
            </li>
            <li>
              <Link to="/feedback">
                <span className="badge rounded-pill bg-danger float-end">
                  {notifications.feedbackCount !== 0 &&
                  notifications.feedbackCount <= 9
                    ? 0
                    : null}
                  {notifications.feedbackCount === 0
                    ? null
                    : notifications.feedbackCount}
                </span>
                <i className="bx bx-message-square-dots"></i>
                <span style={{ fontSize: "14px" }}>{props.t("Feedback")}</span>
              </Link>
            </li>

            <li>
              <Link to="/advertises">
                <i className="bx bx-spreadsheet"></i>
                <span style={{ fontSize: "14px" }}>
                  {props.t("Advertises")}
                </span>
              </Link>
            </li>

            {user && user.role == 2 ? null : (
              <>
                <li className="menu-title">{props.t("Layouti")} </li>
                <li>
                  <Link to="/layouti/Project">
                    <i className="bx bx-spreadsheet"></i>
                    <span style={{ fontSize: "14px" }}>
                      {props.t("Project")}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/layouti/layoutipages">
                    <i className="bx bx-spreadsheet"></i>
                    <span style={{ fontSize: "14px" }}>
                      {props.t("Layouti Pages")}
                    </span>
                  </Link>
                </li>

                <li>
                  <Link to="/layouti/hireus">
                    <i className="bx bx-support"></i>
                    <span style={{ fontSize: "14px" }}>
                      {props.t("Hire Us")}
                    </span>
                  </Link>
                </li>

                <li>
                  <Link to="/layouti/sayhello">
                    <i className="bx bx-support"></i>
                    <span style={{ fontSize: "14px" }}>
                      {props.t("Say Hello")}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/links">
                    <i className="bx bx-link"></i>
                    <span style={{ fontSize: "14px" }}>{props.t("Links")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/seo/layouti">
                    <i className="bx bx-spreadsheet"></i>
                    <span style={{ fontSize: "14px" }}>{props.t("SEO")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/layouti/ProductTab">
                    <i className="bx bx-cog"></i>
                    <span style={{ fontSize: "14px" }}>
                      {props.t("Product Tab")}
                    </span>
                  </Link>
                </li>

                <li>
                  <Link to="/layouti/options">
                    <i className="bx bx-cog"></i>
                    <span style={{ fontSize: "14px" }}>
                      {props.t("Configurations")}
                    </span>
                  </Link>
                </li>

                <li className="menu-title">{props.t("365 Design")} </li>
                <li>
                  <Link to="/365design">
                    <i className="bx bxl-figma fs-3"></i>
                    <span style={{ fontSize: "14px" }}>
                      {props.t("Projects")}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/365design/pages">
                    <i className="bx bx-spreadsheet"></i>
                    <span style={{ fontSize: "14px" }}>{props.t("Pages")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/365design/configurations">
                    <i className="bx bx-cog"></i>
                    <span style={{ fontSize: "14px" }}>
                      {props.t("Configurations")}
                    </span>
                  </Link>
                </li>
                <li className="menu-title">{props.t("Ui Avatar")} </li>
                <li>
                  <Link to="/uiavatar/uigenerator">
                    <span className="badge rounded-pill bg-danger float-end">
                      {notifications.feedbackCount !== 0 &&
                      notifications.feedbackCount <= 9
                        ? 0
                        : null}
                      {notifications.feedbackCount === 0
                        ? null
                        : notifications.feedbackCount}
                    </span>
                    <i className="bx bx-grid-alt"></i>
                    <span style={{ fontSize: "14px" }}>
                      {props.t("Ui Generator")}
                    </span>
                  </Link>
                </li>

                <li>
                  <Link to="/uiavatar/contentgenerator">
                    <span className="badge rounded-pill bg-danger float-end">
                      {notifications.feedbackCount !== 0 &&
                      notifications.feedbackCount <= 9
                        ? 0
                        : null}
                      {notifications.feedbackCount === 0
                        ? null
                        : notifications.feedbackCount}
                    </span>
                    <i className="bx bx-grid-alt"></i>
                    <span style={{ fontSize: "14px" }}>
                      {props.t("Content Generator")}
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/uiavatar/pages">
                    <i className="bx bx-spreadsheet"></i>
                    <span style={{ fontSize: "14px" }}>{props.t("Pages")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/uiavatar/seo">
                    <i className="bx bx-spreadsheet"></i>
                    <span style={{ fontSize: "14px" }}>{props.t("SEO")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/uiavatar/configurations">
                    <i className="bx bx-cog"></i>
                    <span style={{ fontSize: "14px" }}>
                      {props.t("Configurations")}
                    </span>
                  </Link>
                </li>

                <li className="menu-title">{props.t("eToy App")} </li>
                <li>
                  <Link to="/etoyapp/etoyapppages">
                    <i className="bx bx-spreadsheet"></i>
                    <span style={{ fontSize: "14px" }}>
                      {props.t("ُEtoy App Pages")}
                    </span>
                  </Link>
                </li>

                <li>
                  <Link to="/etoyapp/contactus">
                    <i className="bx bx-support"></i>
                    <span style={{ fontSize: "14px" }}>
                      {props.t("ُContact Us")}
                    </span>
                  </Link>
                </li>
                <li className="menu-title">{props.t("Terms")} </li>
                <li>
                  <Link to="/terms">
                    <i className="bx bx-spreadsheet"></i>
                    <span style={{ fontSize: "14px" }}>{props.t("Terms")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/terms/Configurations">
                    <i className="bx bx-spreadsheet"></i>
                    <span style={{ fontSize: "14px" }}>
                      {props.t("Configurations")}
                    </span>
                  </Link>
                </li>

                <li className="menu-title">{props.t("Settings")} </li>

                <li>
                  <Link to="/adminsetting">
                    <i className="bx bx-cog"></i>
                    <span style={{ fontSize: "14px" }}>
                      {props.t("Settings")}
                    </span>
                  </Link>
                </li>

                <li>
                  <Link to="/footersetting">
                    <i className="bx bx-cog"></i>
                    <span style={{ fontSize: "14px" }}>
                      {props.t("Footer Setting")}
                    </span>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
