import React, { Fragment, useEffect, useState } from "react"
import {
  Row,
  Col,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"

const Experience = () => {
  const [rows2, setrows2] = useState([{ id: 1 }])
  const [rowsPositionRole, setRowsPositionRole] = useState([[]])
  const [rowsPositionPoint, setRowsPositionPoint] = useState([[]])
  const [active, setActive] = useState(false)
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState(null)
  const [subTitleEn, setSubTitleEn] = useState("")
  const [subTitleAr, setSubTitleAr] = useState("")
  const [image, setImage] = useState(null)
  const [years,setYears] =useState([])
  const [currentWork , setCurrentWork] =useState(-1)

  

  let multiDataObjectModal=(itemId)=>{
    return {
            id: itemId,
            positionEn: '',
            positionAr: '',
            positionType:'',
            companyCountry:'',
            positionStartDate:'',
            positionEndDate:'',
            positionRole:[],

    }
  }

  let multiDataObjectRoleModal =(roleId)=>{
    return {
        id:roleId,
        positionRoleTitleEn:'',
        positionRoleTitleAr:'',
        positionRoleDescEn:'',
        positionRoleDescAr:'',
        points:[]
      }
  }

  let multiDataObjectPointModal =(pointId)=>{
    return {
        id:pointId,
        pointEn:'',
        pointAr:'',
      }
  }

  let makeListOfYears=()=>{
    let list=[]
    let currentYear = new Date().getFullYear();    
    let earliestYear = 1970;     
    while (currentYear >= earliestYear) {      
      list.push({text:currentYear,value:currentYear})
      currentYear -= 1;    
    }
    setYears(list)
  }

  const [multiData, setMultiData] = useState([multiDataObjectModal(1)])


  function handleAddRowNested() {
    const modifiedRows = [...rows2]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows2(modifiedRows)

    let modifyRow=[...rowsPositionRole]
    modifyRow.push([])
    setRowsPositionRole(modifyRow)

    
    let modifyRowPoint=[...rowsPositionPoint]
    modifyRowPoint.push([])
    setRowsPositionPoint(modifyRowPoint)

    let modifyData = [...multiData]
    modifyData.push(multiDataObjectModal(modifiedRows.length))
    setMultiData(modifyData)
  }

  function handleRemoveRow(id) {
    if (id !== 0) {
      setrows2(r => r.filter((x,i) => i !== id))
      setRowsPositionRole(r => r.filter((x,i) => i !== id))
      setMultiData(d => d.filter((x,i) => i !== id))
    }
  }

  function handleAddRowRoleNested(key) {
    const modifiedRowsRole = [...rowsPositionRole]
    modifiedRowsRole[key].push({ id: modifiedRowsRole[key].length + 1 })
    setRowsPositionRole(modifiedRowsRole)

    let modifyRowRole=[...rowsPositionPoint]
    modifyRowRole[key].push([])
    setRowsPositionPoint(modifyRowRole)
    
    let modifyDataRole = [...multiData]
    modifyDataRole[key].positionRole.push(multiDataObjectRoleModal(modifiedRowsRole[key].length))
    setMultiData(modifyDataRole)
  }

  function handleRemoveRoleRow(key ,id) {
      let modifyRoleRow = [...rowsPositionRole]
      modifyRoleRow[key] = modifyRoleRow[key].filter((x,i) => i !== id)
      setRowsPositionRole(modifyRoleRow)


      let modifyRoleData = [...multiData]
      modifyRoleData[key].positionRole = modifyRoleData[key].positionRole.filter((x,i) => i !== id)

      setMultiData(modifyRoleData)
  }

  function handleAddRowPointNested(key,roleKey) {
    const modifiedRowsPoint = [...rowsPositionPoint]
    modifiedRowsPoint[key][roleKey].push({ id: modifiedRowsPoint[key][roleKey].length + 1 })
    setRowsPositionPoint(modifiedRowsPoint)
    
    let modifyDataPoint = [...multiData]
    modifyDataPoint[key].positionRole[roleKey].points.push(multiDataObjectPointModal(modifiedRowsPoint[key][roleKey].length))

    setMultiData(modifyDataPoint)
  }

  function handleRemovePointRow(key ,id ,pointKey) {
      let modifyPointRow = [...rowsPositionPoint]
      modifyPointRow[key][id] = modifyPointRow[key][id].filter((x,i) => i !== pointKey)
      setRowsPositionPoint(modifyPointRow)

      let modifyRoleData = [...multiData]
      modifyRoleData[key].positionRole[id].points = modifyRoleData[key].positionRole[id].points.filter((x,i) => i !== pointKey)
      setMultiData(modifyRoleData)
  }

  function positionPointChange(e, key, roleKey , pointKey, type) {
    let modifyData = [...multiData]
    modifyData[key].positionRole[roleKey].points[pointKey][type] =e.target.value
    setMultiData(modifyData)
  }

  function positionRoleChange(e, key, roleKey, type) {
    let modifyData = [...multiData]
    modifyData[key].positionRole[roleKey][type] =e.target.value
    setMultiData(modifyData)
  }
  function pointsChange (e, key, type) {
    let modifyData = [...multiData]
    modifyData[key][type] =e.target.value
    setMultiData(modifyData)
  }
  let changeCurrentWork=(e,key)=>{
    let id = e.target.checked==true ? key : -1
    setCurrentWork(id)
  }

  function submit(e) {
    e.preventDefault()
    const formData = new FormData()
    const data = {
      positionEn,
      positionAr,
      positionCountry,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    formData.append("image", image)
    multiData.forEach((point, i) => {
      formData.append(`point[${i}][positionEn]`, point.positionEn)
      formData.append(`point[${i}][positionAr]`, point.positionAr)
      formData.append(`point[${i}][positionCountry]`, point.positionCountry)
    })
    axios.post(`${process.env.REACT_APP_API}/admin/`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).authToken
        }`,
      },
    })
  }

  useEffect(()=>{
    makeListOfYears()
  },[])
  return (
    <div>
      {isOpen ? (
        <Lightbox
          mainSrc={prev}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
        <Form className="border-0 ">
          {(rows2 || []).map((formRow, key) => (
            <Fragment key={'row'+key}>
              {
                key!=0 &&<hr style={{ margin: "20px -20px 20px -20px" }} />
              }

              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Position title (English)
                    </Label>
                    <Input
                      id="positionEn"
                      name="positionEn"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Position title"
                      value={multiData[key].positionEn}
                      onChange={e => pointsChange(e, key, "positionEn")}
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Position title (Arabic)
                    </Label>
                    <Input
                      id="positionAr"
                      name="positionAr"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={multiData[key].positionAr}
                      onChange={e => pointsChange(e, key, "positionAr")}
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>
              
              <Row className="mt-3">
                <Col>
                  <div>
                    <Label htmlFor="productdesc">
                      Position type 
                    </Label>
                    <select 
                    className="form-select"
                    name="positionType"
                    value={multiData[key].positionType}
                    onChange={e => pointsChange(e, key, "positionType")}
                    >
                      <option value='0' selected>Ful Time</option>
                      <option value='1'>Part Time</option>
                      <option value='2'>Freelance</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <hr style={{ margin: "20px -20px 20px -20px" }} />

              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Company name (English)
                    </Label>
                    <Input
                      id="itemnameen"
                      name="itemnameen"
                      type="text"
                      className="form-control input_cus"
                      placeholder="e.g. Company name"
                      value={subTitleEn}
                      onChange={e => setSubTitleEn(e.target.value)}
                    />
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Company name (Arabic)
                    </Label>
                    <Input
                      id="itemnamear"
                      name="itemnamear"
                      type="text"
                      className="form-control input_cus"
                      placeholder="مثال. "
                      value={subTitleAr}
                      onChange={e => setSubTitleAr(e.target.value)}
                      dir="rtl"
                      lang="ar"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <div>
                    <Label htmlFor="productdesc">
                      Company Country 
                    </Label>
                    <select 
                    className="form-select"
                    name="companyCountry"
                    value={multiData[key].companyCountry}
                    onChange={e => pointsChange(e, key, "companyCountry")}
                    >
                      <option value='0' selected>Egypt</option>
                      <option value='1'>dubai</option>
                      <option value='2'>KSA</option>
                    </select>
                  </div>
                </Col>
              </Row>
              <hr style={{ margin: "20px -20px 20px -20px" }} />

              <Row className="mt-3">
                <Col>
                  <div className='d-flex'>
                    <Label htmlFor={`current-work${key}`}>
                      Current work there
                    </Label>
                    <input 
                    type='radio' 
                    className="form-check-input" 
                    id={`current-work${key}`} 
                    name='currentWork'
                    
                    // checked={checkCkecked(key)}
                    onChange={e => changeCurrentWork(e,key)}
                    />
                  </div>
                </Col>

                
              </Row>

              <Row className="mt-3">
                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      Start date
                    </Label>
                    <select 
                    className="form-select"
                    name="positionStartDate"
                    value={multiData[key].positionStartDate}
                    onChange={e => pointsChange(e, key, "positionStartDate")}
                    >
                      {
                        years && years.map(year=>(
                          <option value={year.value} key={'start' +year.value}>{year.value}</option>
                        ))
                      }
                    </select>
                  </div>
                </Col>

                <Col sm="6">
                  <div>
                    <Label htmlFor="productdesc">
                      End date
                    </Label>
                    <select 
                      className="form-select"
                      name="positionEndDate"
                      value={multiData[key].positionEndDate}
                      onChange={e => pointsChange(e, key, "positionEndDate")}
                      disabled={currentWork==key}
                    >
                      {
                        years && years.map(year=>(
                          <option value={year.value} key={'end' +year.value}>{year.value}</option>
                        ))
                      }
                    </select>
                  </div>
                </Col>
              </Row>

              {(rowsPositionRole[key] || []).map((formRow, roleKey) => (
                <Fragment key={'role'+roleKey}>
                  <hr style={{ margin: "20px -20px 20px -20px" }} />
                  
                  <Row className="mt-3">
                    <Col sm="6">
                      <div>
                        <Label htmlFor="productdesc">
                          Role title (English)
                        </Label>
                        <Input
                          id="positionRoleTitleEn"
                          name="positionRoleTitleEn"
                          type="text"
                          className="form-control input_cus"
                          placeholder="e.g. Position title"
                          value={multiData[key].positionRole.positionRoleTitleEn}
                          onChange={e => positionRoleChange(e, key,roleKey, "positionRoleTitleEn")}
                        />
                      </div>
                    </Col>

                    <Col sm="6">
                      <div>
                        <Label htmlFor="productdesc">
                          Role title (Arabic)
                        </Label>
                        <Input
                          id="positionRoleTitleAr"
                          name="positionRoleTitleAr"
                          type="text"
                          className="form-control input_cus"
                          placeholder="e.g. Position title"
                          value={multiData[key].positionRole.positionRoleTitleAr}
                          onChange={e => positionRoleChange(e, key,roleKey, "positionRoleTitleAr")}
                        />
                      </div>
                    </Col>
                    </Row>
                    <Row className="mt-3">
                      <Col sm="6">
                        <div>
                          <Label htmlFor="productdesc">
                            Role description (English)
                          </Label>
                          
                          <textarea
                              className="form-control  mb-0"
                              id="positionRoleDescEn"
                              name="positionRoleDescEn"
                              rows="5"
                              placeholder="Description"
                              value={multiData[key].positionRole.positionRoleDescEn}
                              onChange={e => positionRoleChange(e, key,roleKey, "positionRoleDescEn")}
                          />
                        </div>
                      </Col>

                      <Col sm="6">
                        <div>
                          <Label htmlFor="productdesc">
                            Role description (Arabic)
                          </Label>
                          
                          <textarea
                              className="form-control  mb-0"
                              rows="5"
                              id="positionRoleDescAr"
                              name="positionRoleDescAr"
                              placeholder="Description"
                              value={multiData[key].positionRole.positionRoleDescAr}
                              onChange={e => positionRoleChange(e, key,roleKey, "positionRoleDescAr")}
                          />
                        </div>
                      </Col>

              
                    </Row>

                    {(rowsPositionPoint[key][roleKey] || []).map((formRow, pointKey) => (
                      <Fragment key={'point'+pointKey}>
                        <hr style={{ margin: "20px -20px 20px -20px" }} />
                          <Row className="mt-3">
                            <Col sm="6">
                              <div>
                                <Label htmlFor="productdesc">
                                Point (English)
                                </Label>
                                
                                <textarea
                                    className="form-control  mb-0"
                                    id="pointEn"
                                    name="pointEn"
                                    rows="5"
                                    placeholder="Point"
                                    value={multiData[key].positionRole[roleKey].points[pointKey].pointEn}
                                    onChange={e => positionPointChange(e, key,roleKey,pointKey, "pointEn")}
                                />
                              </div>
                            </Col>
                            <Col sm="6">
                              <div>
                                <Label htmlFor="productdesc">
                                  Point (Arabic)
                                </Label>
                                
                                <textarea
                                    className="form-control  mb-0"
                                    rows="5"
                                    id="pointAr"
                                    name="pointAr"
                                    placeholder="Point"
                                    value={multiData[key].positionRole[roleKey].points[pointKey].pointAr}
                                    onChange={e => positionPointChange(e, key,roleKey,pointKey, "pointAr")}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Button
                          color="none"
                          variant="primary"
                          className="btn-block fs-5 p-0 ps-4 inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                          id="unknown-btn"
                          style={{
                            color: "#ff3838",
                            boxShadow: "none",
                            height: "45px",
                            borderRadius: 0,
                          }}
                          onClick={e => {
                            handleRemovePointRow(key,roleKey,pointKey)
                          }}
                        >
                          <i className="bx bx-trash-alt fs-4 p-0 mr-1" /> Delete Point
                        </Button>
                      </Fragment>
                      
                      ))}
                      <hr style={{ margin: "10px -20px 20px -20px" }} />

                      <Button
                        onClick={() => {
                          handleAddRowPointNested(key,roleKey)
                        }}
                        color="none"
                        className="mt-2 fs-5 fw-bold p-0 ps-4 text-left"
                        style={btnStyle}
                      >
                        <i className="bx bx-plus-circle fs-4" /> <span>Add Point</span>
                      </Button>
                      {/* <hr style={{ margin: "20px -20px 20px -20px" }} /> */}
                      <hr style={{ margin: "20px -20px 10px -20px" }} />
                      <Button
                        color="none"
                        variant="primary"
                        className="btn-block fs-5 p-0 ps-2  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                        id="unknown-btn"
                        style={{
                          color: "#ff3838",
                          boxShadow: "none",
                          height: "45px",
                          borderRadius: 0,
                        }}
                        onClick={e => {
                          handleRemoveRoleRow(key,roleKey)
                        }}
                      >
                        <i className="bx bx-trash-alt fs-4 p-0 mr-1" /> Delete Role
                      </Button>
                    {/* <hr style={{ margin: "20px -20px 20px -20px" }} /> */}

                  {/* <hr style={{ margin: "25px -20px 25px -20px" }} /> */}
                </Fragment>
              ))}

          <hr style={{ margin: "10px -20px 20px -20px" }} />
          <Button
            onClick={() => {
              handleAddRowRoleNested(key)
            }}
            color="none"
            className="mt-2 fs-5 fw-bold p-0 ps-2 text-left"
            style={btnStyle}
          >
            <i className="bx bx-plus-circle fs-4" /> <span>Add Role</span>
          </Button>
              {key !== 0 ? (
                <>
                <hr style={{ margin: "20px -20px 10px -20px" }} />
                <Button
                  color="none"
                  variant="primary"
                  className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
                  id="unknown-btn"
                  style={{
                    color: "#ff3838",
                    boxShadow: "none",
                    height: "45px",
                    borderRadius: 0,
                  }}
                  onClick={e => {
                    handleRemoveRow(key)
                  }}
                  >
                  <i className="bx bx-trash-alt fs-4 p-0 mr-1" /> Delete experience
                </Button>
                  </>

              ) : null}
              {/* <hr style={{ margin: "25px -20px 25px -20px" }} /> */}
            </Fragment>
          ))}
          <hr style={{ margin: "10px -20px 20px -20px" }} />

          <Button
            onClick={() => {
              handleAddRowNested()
            }}
            color="none"
            className="mt-2 fs-5 fw-bold p-0 text-left"
            style={btnStyle}
          >
            <i className="bx bx-plus-circle fs-4" /> <span>Add experience</span>
          </Button>
          <hr style={{ margin: "20px -20px 20px -20px" }} />
        </Form>
      <Row className="mb-3">
        <Col className="d-flex justify-content-end gap-2">
          <div className="save__btn top m-0">
            <button>
              {active ? (
                <ReactLoading
                  type={"spin"}
                  color={"#ffffff"}
                  height={30}
                  width={30}
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Experience

const cardStyle = {
  borderTop: "2px solid #dedede",
}

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
