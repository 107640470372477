import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Collapse,
  Button,
} from "reactstrap"
import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import SweetAlert from "react-bootstrap-sweetalert"
import { toast } from "react-toastify"
import axiosConfig from "axiosConfig"

const FiredUpInnovated = ({sectionData}) => {
  const [active, setActive] = useState(false)
  const [seoFocusEn, setSeoFocusEn] = useState("")
  const [seoFocusAr, setSeoFocusAr] = useState("")
  const [seoDescEn, setSeoDescEn] = useState("")
  const [seoDescAr, setSeoDescAr] = useState("")
  
  const [isLoading, setIsLoading] = useState(false)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  function submit() {
    const formData = new FormData()
    const data = {
      titleEn:seoFocusEn,
      titleAr:seoFocusAr,
      descriptionEn: seoDescEn,
      descriptionAr: seoDescAr,
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    // formData.append("image", image)
    setIsLoading(true)
    axiosConfig.post(`/dashboard/workPage/workWeFiredUpInnovated`, formData, {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser")).accessToken
        }`,
      },
    }).then(res=>{
      //
       toast.success(res.data.message)
      setsuccess_msg(true)
      setIsLoading(false)
    }).catch(err=>{
      //
      toast.error(err.response.data.message)
      setIsLoading(false)
    })
  }
    
  useEffect(()=>{
    setSeoFocusEn(sectionData?.titleEn)
    setSeoFocusAr(sectionData?.titleAr)
    setSeoDescEn(sectionData?.descriptionEn)
    setSeoDescAr(sectionData?.descriptionAr)
    // setImage(sectionData?.image)
    //
  },[sectionData])
  return (
    <div>
      {success_msg ? (
        <SweetAlert
          title={msg}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        >
        </SweetAlert>
      ) : null}
      <Form className="border-0 pb-2">
        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (english)</Label>
              <Input
                id="itemnameen"
                name="itemnameen"
                type="text"
                className="form-control input_cus"
                placeholder="e.g. Focus Keypharse"
                value={seoFocusEn}
                onChange={e => setSeoFocusEn(e.target.value)}
              />
            </div>
          </Col>

          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Title (arabic)</Label>
              <Input
                id="itemnamear"
                name="itemnamear"
                type="text"
                className="form-control input_cus"
                placeholder="مثال. "
                value={seoFocusAr}
                onChange={e => setSeoFocusAr(e.target.value)}
                dir="rtl"
                lang="ar"
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (English)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="Enter your description here"
                value={seoDescEn}
                onChange={e => setSeoDescEn(e.target.value)}
              />
            </div>
          </Col>
          <Col sm="6">
            <div>
              <Label htmlFor="productdesc">Description (Arabic)</Label>
              <textarea
                className="form-control  mb-0"
                id="specification"
                rows="5"
                placeholder="اكتب وصف هنا"
                value={seoDescAr}
                onChange={e => setSeoDescAr(e.target.value)}
                dir="rtl"
              />
            </div>
          </Col>
        </Row>
      <hr style={{ margin: "20px -20px 20px -20px" }} />

        <Row className="mt-4">
          <Col className="d-flex justify-content-end gap-2">
            <div className="save__btn top m-0">
              <button type='button' onClick={submit} disabled={isLoading?true:false}>
                {isLoading ? (
                  <ReactLoading
                    type={"spin"}
                    color={"#ffffff"}
                    height={30}
                    width={30}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default FiredUpInnovated

const cardStyle = {
  borderTop: "2px solid #dedede",
}
