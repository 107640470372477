import React, { useDebugValue, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Card, CardBody, Col, Row } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import "./datatables.scss";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setTitle } from "../../../store/actions";
import AdminPagination from "../../Utilities/AdminPagination";
import SweetAlert from "react-bootstrap-sweetalert";



const ContactUsEtoy = () => {
    const dispatch = useDispatch();
    const [productData, setProductData] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [filter, setFilter] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const [success_msg, setsuccess_msg] = useState(false)
    const [msg, setMsg] = useState("")
    const sizePerPage = 10;
    const columns = [
        {
            dataField: "id",
            text: "key",
            sort: true,
        },
        {
            dataField: "fullName",
            text: "Full Name",
            sort: true,
        },
        {
            dataField: "email",
            text: "Email Address",
            sort: true,
        },
        {
            dataField: "phone",
            text: "Mobile Phone",
            sort: true,
        },
        {
            dataField: "message",
            text: "Message",
            sort: true,
        },
        {
            dataField: "actions",
            text: "Actions",
            sort: true,
        },
    ];

  

    const pageOptions = {
        sizePerPage: 10,
        totalSize: productData.length, 
        custom: true,
    };
    const onPageHandler = (type, { page }) => {
        setPageNo(page);
    };

    // Custom Pagination Toggle
    const sizePerPageList = [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "15", value: 15 },
        { text: "20", value: 20 },
        { text: "25", value: 25 },
        { text: "All", value: productData.length },
    ];


    function deleteContact(id) {
        axios
            .post(`${process.env.REACT_APP_API}/dashboard/etoy/contact/delete`, {id}, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
                },
            })
            .then((res) => {
                setMsg(`Deleted Successfully`)
                setsuccess_msg(true)
            });
        setProductData((prods) => prods.filter((p) => p.id !== id));
    }

    
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API}/dashboard/etoy/contact`, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
                },
            })
            .then((res) => {
                setProductData(
                    res.data.data.contactUs.map((d) => {
                        return {
                            ...d,
                            actions: (
                                <button
                                    style={deleteBtn}
                                    onClick={() => deleteContact(d.id)}
                                >
                                    <i className="bx bx-trash fs-4"></i>Delete
                                </button>
                            ),
                        };
                    })
                );
            });
            setTotalCount(1)
    }, []);

    useEffect(() => {
        dispatch(setTitle("Contact Us"));
    }, []);

    return (
        <React.Fragment>
            {success_msg ? (
        <SweetAlert
          title={msg}
          success
          confirmBtnBsStyle="success"
          onConfirm={() => {
            setsuccess_msg(false)
          }}
          onCancel={() => {
            setsuccess_msg(false)
          }}
        >
        </SweetAlert>
      ) : null}
            <div className="page-content">
                <MetaTags>
                    <title>
                        FeedBack | Layouti
                    </title>
                </MetaTags>
                <Container fluid>
                    <Card>
                        <CardBody>
                            <AdminPagination
                                data={productData}
                                page={pageNo}
                                setFilter={setFilter}
                                keyFiled="id"
                                buttonText="Add Product"
                                url="/ecommerce-add-product"
                                sizePerPage={sizePerPage}
                                totalSize={totalCount}
                                onTableChange={onPageHandler}
                                columns={columns}
                                none={true}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ContactUsEtoy;

const deleteBtn = {
    background: "transparent",
    border: "none",
    color: "#D91414",
    display: "flex",
    alignItems: "center",
    gap: "5px",
};

const editBtn = {
    background: "transparent",
    border: "none",
    color: "#000",
    display: "flex",
    alignItems: "center",
    gap: "5px",
};
