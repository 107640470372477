/* eslint-disable react/prop-types */
import axios from "axios"
import React, { useState, useRef, useEffect } from "react"
import { Card, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import PulseLoader from "react-spinners/PulseLoader"
import ReactTagInput from "@pathofdev/react-tag-input"
import "@pathofdev/react-tag-input/build/index.css"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import "./specification.scss"
import axiosConfig from "axiosConfig"
import { toast } from "react-toastify"

const AddBudgetForm = props => {
  let p = props
  const [isOpen, setisOpen] = useState(false)
  const [authorImg, setAuthorImg] = useState("")
  const [name_en, setNameEn] = useState("")
  const [name_ar, setNameAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")
  const [id, setId] = useState("")
  const [loading, setLoading] = useState(false)
  
  let handleAuthorImg =(value)=>{
    let file =value.files[0]
    setAuthorImg(file)
  }
  

  function submitHandler() {
    setLoading(true)
    const formData = new FormData()
    let data = {
      budgetEn: name_en,
      budgetAr: name_ar,
      id,
    }
    
    for (let key in data) {
      formData.append(key, data[key])
    }
    let reqLink =props.updatedItem?'update':'add'
    axiosConfig.post(`/dashboard/configurations/layoutiBudget/${reqLink}`,formData,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(res => {
        document.getElementById("categForm").reset()
        setNameAr("")
        setNameEn("")
        props.clickHandle()
        props.getBudget()
        setLoading(false)
      }).catch(err=>{
        toast.error(err.response.data.message)
      })
  }

  function close() {
    document.getElementById("categForm").reset()
    setNameAr("")
    setNameEn("")
    props.clickHandle()
  }

  useEffect(() => {
    
    if (p.updatedItem) {
      setNameEn(p.updatedItem.budgetEn)
      setNameAr(p.updatedItem.budgetAr)
      setId(p.updatedItem.id)
      return
    }
  }, [p.updatedItem])

  return (
    <div className={`overlay_coup ${p.active ? "active" : ""}`}>
      <Form
        id="categForm"
        onSubmit={e => e.preventDefault()}
        style={{ paddingBottom: "70px", height: "330px" }}
      >
        <div className="head_form">
          <div className="log fs-5">
            {/* <i className="fas fa-user fs-5"></i> */}
            {props.updatedItem?'Update':'Add'} Budget
          </div>
          <button onClick={close}>
            <i className="dripicons dripicons-cross"></i>
          </button>
        </div>
        <div className="inputs__wrap ">
        
        
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Budget Name English</Label>
                <Input
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="e.g.Name"
                  value={name_en}
                  onChange={e => setNameEn(e.target.value)}
                />
                <FormFeedback className="fs-6">
                  This Fileld Is Required!
                </FormFeedback>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label htmlFor="productdesc">Budget Name Arabic</Label>
                <Input
                  name="title"
                  type="text"
                  className="form-control input_cus"
                  placeholder="مثال. الاسم"
                  dir="rtl"
                  value={name_ar}
                  onChange={e => setNameAr(e.target.value)}
                />
                <FormFeedback className="fs-6">
                  This Fileld Is Required!
                </FormFeedback>
              </div>
            </Col>
          </Row>
        </div>

        <div className="foot_form">
          <button className="cancel" onClick={close}>
            Cancel
          </button>
          <button className="save" onClick={submitHandler}>
            {loading ? (
              <PulseLoader color={"#ffffff"} loading={true} size={10} />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </Form>
    </div>
  )
}

export default AddBudgetForm
