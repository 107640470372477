/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Form, Label, Input, } from "reactstrap"
import {Link} from "react-router-dom"
import './specification.scss'
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert"
import ReactLoading from "react-loading";

const UiGenerator = (props) => {
        const [active, setActive] = useState(false)
        const [msg, setMsg] = useState("")
        const [success_msg, setsuccess_msg] = useState(false)
        const [lgImage, setLgImage] = useState(null)
        const [isOpen, setisOpen] = useState(false);
        const [titleEn, setTitleEn] = useState('')
        const [titleAr, setTitleAr] = useState('')
        const [descEn, setDescEn] = useState('')
        const [descAr, setDescAr] = useState('')
        const [smallDescEn, setSmallDescEn] = useState('')
        const [smallDescAr, setSmallDescAr] = useState('')
        const [seoFocusEn, setSeoFocusEn] = useState('');
        const [seoFocusAr, setSeoFocusAr] = useState('');
        const [seoTitleEn, setSeoTitleEn] = useState('')
        const [seoTitleAr, setSeoTitleAr] = useState('')
        const [seoDescEn, setSeoDescEn] = useState('')
        const [seoDescAr, setSeoDescAr] = useState('')
        const [seoSlugEn, setSeoSlugEn] = useState('')
        const [seoSlugAr, setSeoSlugAr] = useState('')
        const [seoFaceTitleEn, setSeoFaceTitleEn] = useState('')
        const [seoFaceTitleAr, setSeoFaceTitleAr] = useState('')
        const [seoFaceDescEn, setSeoFaceDescEn] = useState('')
        const [seoFaceDescAr, setSeoFaceDescAr] = useState('')
        const [facebookImage, setFacebookImage] = useState(null)

        function handleFacebookFiles(e, files) {
            [...files].map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: formatBytes(file.size)
                })
            )
            setFacebookImage(Object.values(files)[0])
            e.target.value = '';
        }

        function handleThumbFiles(e, files) {
            [...files].map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: formatBytes(file.size)
                })
            )
            setLgImage(Object.values(files)[0])
            e.target.value = '';
        }

        function formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return "0 Bytes"
            const k = 1024
            const dm = decimals < 0 ? 0 : decimals
            const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
            const i = Math.floor(Math.log(bytes) / Math.log(k))
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
        }


    function submit(e) {
        e.preventDefault();
        setActive(true)
        const formData = new FormData();
        let data = {
            titleEn,
            titleAr,
            descriptionEn: descEn,
            descriptionAr: descAr,
            smallDescriptionEn: smallDescEn,
            smallDescriptionAr: smallDescAr,
            keyphraseEn: seoFocusEn,
            keyphraseAr: seoFocusAr,
            seoTitleEn: seoTitleEn,
            seoTitleAr: seoTitleAr,
            metadescriptionEn: seoDescEn,
            metadescriptionAr: seoDescAr,
            slugEn: seoSlugEn,
            slugAr: seoSlugAr,
            facebookTitlteEn: seoFaceTitleEn,
            facebookTitlteAr: seoFaceTitleAr,
            facebookDescriptionEn: seoFaceDescEn,
            facebookDescriptionAr: seoFaceDescAr,
            
        }
        for (let key in data) {
            formData.append(key, data[key])
        }
        if(lgImage&&lgImage.name) {
            formData.append("image", lgImage)
        }
        if(facebookImage&&facebookImage.name) {
            formData.append("facebookImage", facebookImage)
        }
        axios.post(`${process.env.REACT_APP_API}/admin/uiPage`,formData ,{
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
            }
        })
        .then(res => {
            
            setActive(false)
            setMsg(`Saved!`)
            setsuccess_msg(true)
        })
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API}/admin/uiPage` ,{
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).authToken}`, 
            }
        })
        .then(res => {
            
            let data = res.data.data;
            setTitleEn(data?.titleEn)
            setTitleAr(data?.titleAr)
            setDescEn(data?.descriptionEn)
            setDescAr(data?.descriptionAr)
            setSmallDescEn(data?.smallDescriptionEn)
            setSmallDescAr(data?.smallDescriptionAr)
            setLgImage({preview: data?.image})
            setSeoFocusEn(data?.keyphraseEn)
            setSeoFocusAr(data?.keyphraseAr)
            setSeoTitleEn(data?.seoTitleEn)
            setSeoTitleAr(data?.seoTitleAr)
            setSeoSlugEn(data?.slugEn)
            setSeoSlugAr(data?.slugAr)
            setSeoDescEn(data?.metadescriptionEn)
            setSeoDescAr(data?.metadescriptionAr)
            setSeoFaceTitleEn(data?.facebookTitlteEn)
            setSeoFaceTitleAr(data?.facebookTitlteAr)
            setSeoFaceDescEn(data?.facebookDescriptionEn)
            setSeoFaceDescAr(data?.facebookDescriptionAr)
            setFacebookImage({preview: data?.facebookImage})
        })
    }, []) 

    return (
            <React.Fragment>
                <div className="container-fluid p-0">
                <Card>
                    <CardBody>
                    {success_msg ? (
                        <SweetAlert
                        title={msg}
                        success
                        confirmBtnBsStyle="success"
                        onConfirm={() => {
                            setsuccess_msg(false)
                        }}
                        onCancel={() => {
                            setsuccess_msg(false)
                        }}
                        >
                            </SweetAlert>
                        ) : null}
                    {isOpen ? (
                    <Lightbox
                        mainSrc={image.preview}
                        enableZoom={false}
                        onCloseRequest={() => {
                        setisOpen(!isOpen);
                        }}
                    />
                    ) : null}
                    
                    <Form className="border-0">
                        <Row>
                            <Col>
                                <Label>Title (english)</Label>
                                <Input 
                                    className="input_cus"
                                    placeholder="Title"
                                    value={titleEn}
                                    onChange={e => setTitleEn(e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Label>Title (arabic)</Label>
                                <Input 
                                    className="input_cus"
                                    placeholder="عنوان"
                                    dir="rtl"
                                    value={titleAr}
                                    onChange={e => setTitleAr(e.target.value)}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <Label>description (english)</Label>
                                <textarea
                                    className="form-control  mb-0"
                                    rows="5"
                                    placeholder="Description"
                                    value={descEn}
                                    onChange={e => setDescEn(e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Label>description (arabic)</Label>
                                <textarea
                                    className="form-control  mb-0"
                                    rows="5"
                                    placeholder="وصف"
                                    value={descAr}
                                    onChange={e => setDescAr(e.target.value)}
                                    dir="rtl"
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <Label>small description (english)</Label>
                                <textarea
                                    className="form-control  mb-0"
                                    rows="5"
                                    placeholder="Description"
                                    value={smallDescEn}
                                    onChange={e => setSmallDescEn(e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Label>small description (arabic)</Label>
                                <textarea
                                    className="form-control  mb-0"
                                    rows="5"
                                    placeholder="وصف"
                                    value={smallDescAr}
                                    onChange={e => setSmallDescAr(e.target.value)}
                                    dir="rtl"
                                />
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs="12">
                                <div >
                                    <Label htmlFor="productdesc" >
                                        Cover Image
                                    </Label>
                                    <Input
                                        type="file"
                                        className="form-control form-control-lg "
                                        onChange={e => handleThumbFiles(e, e.target.files)}
                                        style={{borderRadius: 0}}
                                        multiple
                                    />
                                </div>
                            </Col>
                            <Col>
                                <div className="dropzone-previews" id="file-previews">
                                    {lgImage?
                                        <Card
                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        >
                                            <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                <img
                                                    data-dz-thumbnail=""
                                                    height="80"
                                                    className="avatar-sm rounded bg-light"
                                                    alt={lgImage.name}
                                                    src={lgImage.preview}
                                                    style={{ objectFit: "cover" }}
                                                />
                                                </Col>
                                                <Col>
                                                <Link to="#" className="text-muted ">
                                                    {lgImage.name}
                                                </Link>
                                                
                                                </Col>
                                                <Col>
                                                <div className="image__options">
                                                    <button
                                                    className="p-0"
                                                    onClick={e => prevSelectImage(e, lgImage.preview)}
                                                    >
                                                    <i className="far fa-eye  fs-5 p-0"></i>
                                                    </button>
                                                    <button
                                                    style={{ color: "#ff3838" }}
                                                    onClick={e => deleteSelectImage(e, null, "thumb")}
                                                    >
                                                    <i className="bx bx-trash fs-4"></i>
                                                    </button>
                                                </div>
                                                </Col>
                                            </Row>
                                            </div>
                                        </Card>:null
                                        
                                    }
                                    </div>
                            </Col>
                        </Row>

                        <hr style={{ margin: "25px -20px 20px -20px" }} />
                        
                        <Row>
                            <Col className="d-flex justify-content-end gap-2">
                                <div className="save__btn top m-0">
                                    <button onClick={(e) => submit(e)}>{active?<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />:"Save"}</button>
                                </div>
                            </Col>
                        </Row>
              </Form>
            </CardBody>
          </Card>      
        </div>
    </React.Fragment>
  )
}

export default UiGenerator

const deleteBtn = {
  background: "transparent",
  border: "none",
  color:"#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px"

}

const editBtn = {
  background: "transparent",
  border: "none",
  color:"green",
  display: "flex",
  alignItems: "center",
  gap: "5px"
}