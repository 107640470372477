/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, CardBody, Label } from "reactstrap"
import MetaTags from "react-meta-tags"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import { useHistory } from "react-router-dom"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import "./datatables.scss"
import "./specification.scss"
import axios from "axios"
import SweetAlert from "react-bootstrap-sweetalert"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { useSelector, useDispatch } from "react-redux"
import { setTitle, getProjects } from "../../store/actions"
import { toast } from "react-toastify"
import TreePicker from "rsuite/TreePicker"
import "rsuite/dist/rsuite.min.css"
import prettyBytes from "pretty-bytes"
import { getDateMeta } from "@fullcalendar/core"

const InnerPageResourceTable = props => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [active, setActive] = useState(false)
  const [addActive, setAddActive] = useState(false)
  const [productData, setProductData] = useState([])
  const [avatar, setAvatar] = useState(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState("")
  const [disabledNum, setDisabledNum] = useState([])
  const [categoryID, setCategoryID] = useState("")
  const [category, setCategory] = useState("")
  const [categoriess, setCateogriess] = useState([])
  const categoryRef = useRef(null)
  const queryParameters = new URLSearchParams(window.location.search)

  const columns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
    },
    {
      dataField: "image",
      text: "Image",
      sort: true,
    },
    {
      dataField: "titleEn",
      text: "Title (English)",
      sort: true,
    },
    {
      dataField: "titleAr",
      text: "Title (Arabic)",
      sort: true,
    },
    {
      dataField: "categories",
      text: "Category",
      sort: true,
    },
    {
      dataField: "tagData",
      text: "Tag",
      sort: true,
    },
    {
      dataField: "color",
      text: "Color",
      sort: true,
    },
    {
      dataField: "clicks",
      text: "Website Link",
      sort: true,
    },

    {
      dataField: "downloads",
      text: "Design Link",
      sort: true,
    },
    {
      dataField: "actions",
      text: "Actions",
      sort: true,
    },
    {
      dataField: "createdOn",
      text: "Created On",
      sort: true,
    },
  ]

  const pageOptions = {
    totalSize: productData.length, // replace later with size(customers),
    custom: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "75",
        value: 75,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "All",
        value: productData.length,
      },
    ],
  }

  const { SearchBar } = Search

  function setOpen(img) {
    setisOpen(true)
    setPrev(img)
  }

  function clickHandle(id) {
    if (id) {
      return history.push(`/resources/detailsPage/Edit?id=${id}`)
    }
    history.push(`/resources/detailsPage/Add`)
  }

  function deleteAvatar(id) {
    axios
      .post(
        `${process.env.REACT_APP_API}/dashboard/resources/innerPage/delete`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setProductData(products => products.filter(prod => prod.id != id))
        setMsg("Active Resource Deleted Successfuly !")
        setsuccess_msg(true)
      })
      .catch(err => {
        toast.error("Something went wrong")
      })
  }

  let getCategories = () => {
    axios
      .get(
        `${process.env.REACT_APP_API}/dashboard/resources/innerPage/categories`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setCateogriess(res.data.data)
        setDisabledItems(res.data.data)
      })
      .catch(err => {})
  }

  let recursionCheckCategoryHasChildren = category => {
    if (category.children.length == 0) {
      return null
    } else {
      setDisabledNum(prevVal => [...prevVal, category.nameEn])
      category.children.forEach(childCategory => {
        recursionCheckCategoryHasChildren(childCategory)
      })
    }
  }

  let setDisabledItems = categories => {
    categories.forEach(category => {
      recursionCheckCategoryHasChildren(category)
    })
  }

  useEffect(getCategories, [])

  let getFilterData = value => {
    axios
      .get(
        `${process.env.REACT_APP_API}/dashboard/resources/innerPage?perPage=200&page=1&category=${value.nameEn}&status=${value.status}&&type=2`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setCategoryID(value.nameEnCount)

        setProductData(
          res.data.data.pages.map(page => {
            return {
              ...page,
              titleEn: page.titleEn?page.titleEn:<span className="fs-4">-</span>,
              titleAr: page.titleAr?page.titleAr:<span className="fs-4">-</span>,
              image: (
                <img
                  src={page.image}
                  onClick={e => setOpen(e.target.src)}
                  className="usernametabl"
                  alt=""
                />
              ),
              categories:
                page?.categories.length !== 0 ? (
                  page?.categories.map((c, i) => (
                    <span
                      key={i}
                      className={`${c.nameEn === 1 ? "" : "image-opacity"}`}
                      style={{
                        backgroundColor: "rgb(216 216 216)",
                        color: "rgb(0, 0, 0)",
                        borderRadius: "45px",
                        padding: "5px 20px",
                        margin: "0px 2px",
                        display: "inline-block",
                      }}
                    >
                      {c.nameEn}
                    </span>
                  ))
                ) : (
                  <span className="fs-4">-</span>
                ),

              color: page?.color ? (
                <span
                  className={`me-2`}
                  style={{
                    backgroundColor: page?.color,
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    border: "1px solid #bdbdbd",
                    display: "inline-block",
                  }}
                ></span>
              ) : (
                <span className="fs-4">-</span>
              ),

              titleEn: page.titleEn,
              titleAr: page.titleAr,
              clicks: page.Clicks,
              downloads: page.Downloads,
              tagData: (
                <span
                  style={{
                    backgroundColor: page?.tag?.color,
                    color: "rgb(0, 0, 0)",
                    borderRadius: "45px",
                    padding: "5px 20px",
                    margin: "0px 2px",
                    display: "inline-block",
                  }}
                >
                  {page?.tag?.titleEn}
                </span>
              ),
              actions: (
                <div className="d-flex">
                  <a style={editBtn} href={page.link}>
                    <i
                      className="bx bx-edit fs-4 pr-1"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    View
                  </a>
                  <button style={editBtn} onClick={() => clickHandle(page.id)}>
                    <i
                      className="bx bx-edit fs-4 pr-1"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Edit
                  </button>
                  <button
                    style={deleteBtn}
                    onClick={e => deleteAvatar(page.id)}
                  >
                    <i
                      className="bx bx-trash fs-4"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Delete
                  </button>
                </div>
              ),
              createdOn: page.created_at ? (
                page.created_at
              ) : (
                <span className="fs-4">-</span>
              ),
            }
          })
        )
      })
      .catch(err => {
        toast.error("Something went wrong", {
          theme: "light",
          position: "top-right",
        })
      })
  }

  useEffect(() => {
    dispatch(setTitle("Active Resources"))

    axios
      .get(
        `${process.env.REACT_APP_API}/dashboard/resources/innerPage?perPage=200&page=1&&type=2`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("authUser")).accessToken
            }`,
          },
        }
      )
      .then(res => {
        setProductData(
          res.data.data.pages.map(page => {
            return {
              ...page,
              image: (
                <img
                  src={page.image}
                  onClick={e => setOpen(e.target.src)}
                  className="usernametabl"
                  alt=""
                />
              ),
              categories:
                page?.categories.length !== 0 ? (
                  page?.categories.map((c, i) => (
                    <span
                      key={i}
                      className={`${c.nameEn === 1 ? "" : "image-opacity"}`}
                      style={{
                        backgroundColor: "rgb(216 216 216)",
                        color: "rgb(0, 0, 0)",
                        borderRadius: "45px",
                        padding: "5px 20px",
                        margin: "0px 2px",
                        display: "inline-block",
                      }}
                    >
                      {c.nameEn}
                    </span>
                  ))
                ) : (
                  <span className="fs-4">-</span>
                ),

                titleEn: page.titleEn?page.titleEn:<span className="fs-4">-</span>,
                titleAr: page.titleAr?page.titleAr:<span className="fs-4">-</span>,
              clicks: page.Clicks,
              downloads: page.Downloads,
              color: page?.color ? (
                <span
                  className={`me-2`}
                  style={{
                    backgroundColor: page?.color,
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    border: "1px solid #bdbdbd",
                    display: "inline-block",
                  }}
                ></span>
              ) : (
                <span className="fs-4">-</span>
              ),
              tagData: page.tag?(
                <span
                  style={{
                    backgroundColor: page?.tag?.color,
                    color: "rgb(0, 0, 0)",
                    borderRadius: "45px",
                    padding: "5px 20px",
                    margin: "0px 2px",
                    display: "inline-block",
                  }}
                >
                  {page?.tag?.titleEn}
                </span>
              ):<span className="fs-4">-</span>,
              actions: (
                <div className="d-flex">
                  <a
                    style={{ ...editBtn, marginRight: "10px" }}
                    href={page.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i
                      className="bx bx-link fs-4 pr-1"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    View
                  </a>
                  <button style={editBtn} onClick={() => clickHandle(page.id)}>
                    <i
                      className="bx bx-edit fs-4 pr-1"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Edit
                  </button>

                  <button
                    style={deleteBtn}
                    onClick={e => deleteAvatar(page.id)}
                  >
                    <i
                      className="bx bx-trash fs-4"
                      style={{ marginRight: "10px" }}
                    ></i>{" "}
                    Delete
                  </button>
                </div>
              ),
              createdOn: page.created_at ? (
                page.created_at
              ) : (
                <span className="fs-4">-</span>
              ),
            }
          })
        )
      })
      .catch(err => {
        toast.error("Something went wrong")
      })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Resource | Pages Details</title>
        </MetaTags>
        <div className="container-fluid p-0">
          {success_msg ? (
            <SweetAlert
              title={msg}
              success
              confirmBtnBsStyle="success"
              onConfirm={() => {
                setsuccess_msg(false)
              }}
              onCancel={() => {
                setsuccess_msg(false)
              }}
            ></SweetAlert>
          ) : null}
          {isOpen ? (
            <Lightbox
              mainSrc={prev}
              enableZoom={false}
              onCloseRequest={() => {
                setisOpen(!isOpen)
              }}
            />
          ) : null}
          <Row>
            <Col sm="12">
              <div ref={categoryRef}>
                <TreePicker
                  data={categoriess}
                  labelKey="nameEnCount"
                  valueKey="nameEnCount"
                  value={categoryID}
                  disabledItemValues={[1, ...disabledNum]}
                  onSelect={(value, category, e) => {
                    setCategory(value)
                    setCategoryID(value?.nameEn)
                    getFilterData(value)
                  }}
                  className={"mb-3"}
                  onClick={e => e.target.classList.remove("is-invalid")}
                  placeholder="Select Category"
                  renderValue={(value, category, selectedElement) => {
                    if (value.selected === false) {
                      setCategory(value)
                      setDisabledNum(disabledNum => [...disabledNum, value.id])
                    }
                    return (
                      <span>
                        <i
                          className="rs-icon rs-icon-map-marker"
                          value={category.nameEn}
                        />{" "}
                        {category.nameEn}
                      </span>
                    )
                  }}
                  style={{ width: "100%" }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody className="pb-0">
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={productData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={productData}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col>
                                <div className="search-box me-2 mb-2 d-inline-block ">
                                  <div className="position-relative ">
                                    <SearchBar
                                      {...toolkitProps.searchProps}
                                      className="input_cus"
                                    />
                                    <i className="bx bx-search-alt search-icon mt-1 fs-3" />
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className="save__btn top">
                                  <button
                                    onClick={() =>
                                      history.push("/resources/detailsPage/Add")
                                    }
                                  >
                                    <i className="bx bx-plus-circle fs-4"></i>{" "}
                                    Add New Resource
                                  </button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                  {productData.length === 0 ? (
                                    <div
                                      className="emptyStateTable"
                                      style={{
                                        width: "100%",
                                        textAlign: "center",
                                        fontSize: "16px",
                                        padding: "10px",
                                        paddingTop: "0",
                                        paddingBottom: "20px",
                                      }}
                                    >
                                      no data available in table
                                    </div>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>

                            {productData.length !== 0 ? (
                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            ) : null}
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default InnerPageResourceTable

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  display: "flex",
  alignItems: "center",
  gap: "0px",
}
