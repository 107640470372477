import React, { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { Controller, useFieldArray } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Accordion, Button, Card, Col, Collapse, Form, FormFeedback, Label, Row } from 'reactstrap'
import ReactLoading from "react-loading";
import classnames from "classnames";
import prettyBytes from "pretty-bytes";
import ProductsCardParagraph from './ProductsCardParagraph'
import Lightbox from 'react-image-lightbox'

function ProductCard({productData,handleChangeBlogData}) {
    const [logoImage,setLogoImage] =useState([])
    const [sideImage,setSideImage] =useState([])

    function handleAcceptedFiles(files, key,e,paraKey) {
        
        files = files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: prettyBytes(file.size)
            })
        )
        // 
        // setLogoImage(files)
        

    }
    
    function handleAcceptedFilesThumbnail(files, key,e) {
        // e.target.value = ''
        files =[...files].map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: prettyBytes(file.size)
            })
        )
        setLogoImage(files)

        
            setData(data => data.map((obj, i) => {
            if(i === key) {
                return{
                ...obj,
                thumbnailImage: files[0]
                }
            }
            return obj;
            }))
    }
    function handleAcceptedFilesSide(files, key,e) {
        // e.target.value = ''
        files =[...files].map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: prettyBytes(file.size)
            })
        )
        setSideImage(files)

        
        setValue(`product[${key}].thumbnailImage`,files)
            setData(data => data.map((obj, i) => {
            if(i === key) {
                return{
                ...obj,
                thumbnailImage: files[0]
                }
            }
            return obj;
            }))
    }

    function deleteSelectImage(blogId,imageId) {
        let images = getValues(`product[${blogId}].images`)
        images.splice(imageId,1)
        setValue(`product[${blogId}].images`,images)
    }

    const [active, setActive] = useState(false)
    const [deleteLoad, setDelete] = useState(false)
    const [data, setData] = useState([])
    const [appendDataPara,setAppendDataPara]= useState('')
    const [col, setCol] = useState([])
    const t_col1 = (id) => {
            setCol(col => col.map((c,i) => {
            if(i == id) {
                return !c
            }
            return false
            }))
            setData(data => data.concat(
                {   
                    name: "",
                    titleEn: "",
                    titleAr: "",
                    category: "",
                    image: null,
                    thumbnailImage: null,
                    paragraphs:[]
                }))
            setCol(state => state.concat(false))
        };
        const [isOpen, setisOpen] = useState(false)
    const [photoIndex, setphotoIndex] = useState(0)
  
    function prevSelectImage(e, id) {
        e.preventDefault()
        // 
        setphotoIndex(id)
        setisOpen(!isOpen)
      }
    useEffect(()=>{
        if(appendData===''){
            return 
        }
        append()
        t_col1(fields.length)
        
    },[appendData])
    // let
    
  return (
    <>
        {isOpen ? (
            <Lightbox
            mainSrc={photoIndex}
            enableZoom={false}
            onCloseRequest={() => {
                setisOpen(!isOpen)
            }}
            />
        ) : null}
        {productData &&productData.map((product, index) => (

            <div className="accordion-item" key={product.id}>
                <h2 className="accordion-header" id="headingOne">
                    <button
                    className={classnames(
                        "accordion-button",
                        "fw-medium",
                        "fw-bold",
                        "fs-5",
                        { collapsed: !col[index] }
                    )}
                    type="button"
                    onClick={() => t_col1(index)}
                    style={{ cursor: "pointer" }}
                    >
                    {/* {`${getValues(`product[${index}]?.titleEn`) ? getValues(`product[${index}]?.titleEn`):'Product title'} `} */}
                    {product?.titleEn?product?.titleEn:'Product Title'}
                    </button>
                </h2>
                <Collapse isOpen={col[index]} className="accordion-collapse">
                    <div className="accordion-body">
                        <Form className="border-0 p-0">

                            <Row>
                                <Col>
                                    <div className="mb-3">
                                        <Label htmlFor="metatitle">Logo Image</Label>
                                        <div className="mb-3" >
                                            <input 
                                                type="file"
                                                className="form-control form-control-lg "
                                                // {...register(`product[${index}].thumbnailImage`,{
                                                //     onChange:e => handleAcceptedFilesThumbnail(e.target.files,index,e)
                                                // })}
                                                style={{borderRadius: 0}}
                                            />
                                            <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                        </div>
                                    </div>
                                </Col>

                                {(product?.logo)&&
                                    <Row className="align-items-center mb-3" style={{paddingLeft: "14px", paddingRight: "0px"}}>
                                        <Col className="col-auto">
                                            <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={product?.logo?.name}
                                                src={product?.logo?.preview?product?.logo?.preview:product?.logo}
                                                style={{objectFit: "cover"}}
                                            />
                                        </Col>

                                        <Col>
                                            <Link
                                                to="#"
                                                className="text-muted "
                                            >
                                                {product?.logo?.name}
                                            </Link>
                                            <p className="mb-0">
                                                <strong>{product?.logo?.formattedSize}</strong>
                                            </p>
                                        </Col>

                                        <Col className='p-0'>
                                            <div className="image__options">
                                                <button className="p-0" 
                                                type='button'
                                                onClick={(e) => prevSelectImage(e, product?.logo?.preview?product?.logo?.preview:product?.logo)}
                                                >
                                                    <i className="far fa-eye  fs-5 p-0"></i>
                                                </button>
                                                <button 
                                                type='button'
                                                className='p-0' 
                                                style={{color: "#ff3838"}} 
                                                onClick={(e) => setLogoImage([])}
                                                >
                                                    <i className='bx bx-trash fs-4'></i>
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>}

                            </Row>

                            <Row>
                                <Col>
                                    <div className="mb-3">
                                        <Label htmlFor="metatitle">Side Image</Label>
                                        <div className="mb-3" >
                                            <input 
                                                type="file"
                                                className="form-control form-control-lg "
                                                // {...register(`product[${index}].sideImage`,{
                                                //     onChange:e => handleAcceptedFilesSide(e.target.files,index,e)
                                                // })}
                                                style={{borderRadius: 0}}
                                            />
                                            <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                        </div>
                                    </div>
                                </Col>

                                {product?.slide&&
                                    <Row className="align-items-center mb-3" style={{paddingLeft: "14px", paddingRight: "0px"}}>
                                        <Col className="col-auto">
                                            <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={product?.slide?.name}
                                                src={product?.slide?.preview?product?.slide?.preview:product?.slide}
                                                style={{objectFit: "cover"}}
                                            />
                                        </Col>

                                        <Col>
                                            <Link
                                                to="#"
                                                className="text-muted "
                                            >
                                                {product?.slide?.name}
                                            </Link>
                                            <p className="mb-0">
                                                <strong>{product?.slide?.formattedSize}</strong>
                                            </p>
                                        </Col>

                                        <Col className='p-0'>
                                            <div className="image__options">
                                                <button className="p-0" 
                                                type='button'
                                                onClick={(e) => prevSelectImage(e, product?.slide?.preview?product?.slide?.preview:product?.slide)}
                                                >
                                                    <i className="far fa-eye  fs-5 p-0"></i>
                                                </button>
                                                <button 
                                                type='button'
                                                className='p-0' 
                                                style={{color: "#ff3838"}} 
                                                onClick={(e) => setSideImage([])}
                                                >
                                                    <i className='bx bx-trash fs-4'></i>
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>}

                            </Row>

                            <Row className="mb-3">
                                <Col xs='12'>
                                    <Label htmlFor="productdesc">
                                        Category
                                    </Label>
                                    <select
                                        className="form-select fs-5 input_cus"
                                        name=''
                                        {...register(`product[${index}].category`)}
                                        >
                                            <option disabled>Select Category</option>
                                            
                                            <option value="0">UX Design</option>
                                            <option value="1">Branding</option>
                                            <option value="2">UI Design</option>
                                            <option value="3">In-house projects</option>
                                        </select>
                                </Col> 
                                {/* <Col xs='6'>
                                    <div className="mb-3" >
                                        <Label htmlFor="productdesc">
                                            Author
                                        </Label>
                                        <select
                                        className="form-select fs-5 input_cus"
                                        {...register(`product[${index}].name`)}
                                        >
                                            <option disabled>Select Author</option>
                                            <option value="0">Author 1</option>
                                            <option value="1">Author 2</option>
                                            <option value="2">Author 3</option>
                                            <option value="3">Author 4</option>
                                        </select>
                                        <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                    </div>
                                </Col> */}
                            </Row>

                            <Row className="mb-3">
                                <Col xs='6'>
                                    <div className="mb-3" >
                                        <Label htmlFor="titleEn">
                                            Title English 
                                        </Label>
                                        <input
                                            id="titleEn"
                                            type="text"
                                            className="form-control input_cus"
                                            placeholder="e.g. example.com"
                                            {...register(`product[${index}].titleEn`)}
                                        />
                                        <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                    </div>
                                </Col>
                                <Col xs='6'>
                                        <div className="mb-3" >
                                            <Label htmlFor="titleAr">
                                                Title Arabic
                                            </Label>
                                            <input
                                                id="titleAr"
                                                type="text"
                                                className="form-control input_cus"
                                                placeholder="عنوان"
                                                dir="rtl"
                                                {...register(`product[${index}].titleAr`)}
                                            />
                                            <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                        </div>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col>
                                    <Label>description (english)</Label>
                                    <textarea
                                        className="form-control  mb-0"
                                        rows="5"
                                        placeholder="Description"
                                        {...register(`product[${index}].descEn`)}
                                        // value={descEn}
                                        // onChange={e => setDescEn(e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Label>description (arabic)</Label>
                                    <textarea
                                        className="form-control  mb-0"
                                        rows="5"
                                        placeholder="وصف"
                                        {...register(`product[${index}].descAr`)}
                                        // value={descAr}
                                        // onChange={e => setDescAr(e.target.value)}
                                        dir="rtl"
                                    />
                                </Col>
                            </Row>
                            
                            <Row className="mb-3">
                                <Col xs='6'>
                                    <div className="mb-3" >
                                        <Label htmlFor="reviewLink">
                                            Review link 
                                        </Label>
                                        <input
                                            id="reviewLink"
                                            type="text"
                                            className="form-control input_cus"
                                            placeholder="e.g. example.com"
                                            {...register(`product[${index}].reviewLink`)}
                                        />
                                        <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                    </div>
                                </Col>
                                <Col xs='6'>
                                        <div className="mb-3" >
                                            <Label htmlFor="downloadLink">
                                                Download link
                                            </Label>
                                            <input
                                                id="downloadLink"
                                                type="text"
                                                className="form-control input_cus"
                                                placeholder="e.g. example.com"
                                                {...register(`product[${index}].downloadLink`)}
                                            />
                                            <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                        </div>
                                </Col>
                            </Row>

                            <hr style={{ margin: "15px -20px 20px -20px" }} />
                            <Row>
                                <Col xs='6'>
                                    <div className="mb-3" >
                                        <Label htmlFor="reviewLink">
                                            Title 1 (English) 
                                        </Label>
                                        <input
                                            id="reviewLink"
                                            type="text"
                                            className="form-control input_cus"
                                            placeholder="e.g. example.com"
                                            {...register(`product[${index}].featureTitleEn1`)}
                                        />
                                        <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                    </div>
                                </Col>
                                <Col xs='6'>
                                        <div className="mb-3" >
                                            <Label htmlFor="downloadLink">
                                                Title 1 (Arabic) 
                                            </Label>
                                            <input
                                                id="downloadLink"
                                                type="text"
                                                placeholder="عنوان"
                                                dir="rtl"
                                                className="form-control input_cus"
                                                {...register(`product[${index}].featureTitleAr1`)}
                                            />
                                            <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                        </div>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col xs='6'>
                                    <div className="mb-3" >
                                        <Label htmlFor="reviewLink">
                                            Description 1 (English)  
                                        </Label>
                                        <input
                                            id="reviewLink"
                                            type="text"
                                            className="form-control input_cus"
                                            placeholder="e.g. example.com"
                                            {...register(`product[${index}].featureDescEn1`)}
                                        />
                                        <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                    </div>
                                </Col>
                                <Col xs='6'>
                                        <div className="mb-3" >
                                            <Label htmlFor="downloadLink">
                                                Description 1 (Arabic)
                                            </Label>
                                            <input
                                                id="downloadLink"
                                                type="text"
                                                className="form-control input_cus"
                                                placeholder="عنوان"
                                                dir="rtl"
                                                {...register(`product[${index}].featureDescAr1`)}
                                            />
                                            <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                        </div>
                                </Col>
                            </Row>
                            {/* <hr style={{ margin: "15px -20px 20px -20px" }} /> */}


                            <hr style={{ margin: "15px -20px 20px -20px" }} />
                            <Row>
                                <Col xs='6'>
                                    <div className="mb-3" >
                                        <Label htmlFor="reviewLink">
                                            Title 2 (English)  
                                        </Label>
                                        <input
                                            id="reviewLink"
                                            type="text"
                                            className="form-control input_cus"
                                            placeholder="e.g. example.com"
                                            {...register(`product[${index}].featureTitleEn2`)}
                                        />
                                        <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                    </div>
                                </Col>
                                <Col xs='6'>
                                        <div className="mb-3" >
                                            <Label htmlFor="downloadLink">
                                                Title 2 (Arabic)
                                            </Label>
                                            <input
                                                id="downloadLink"
                                                type="text"
                                                placeholder="عنوان"
                                                dir="rtl"
                                                className="form-control input_cus"
                                                {...register(`product[${index}].featureTitleAr2`)}
                                            />
                                            <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                        </div>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col xs='6'>
                                    <div className="mb-3" >
                                        <Label htmlFor="reviewLink">
                                            Description 2 (English)
                                        </Label>
                                        <input
                                            id="reviewLink"
                                            type="text"
                                            className="form-control input_cus"
                                            placeholder="e.g. example.com"
                                            {...register(`product[${index}].featureDescEn2`)}
                                        />
                                        <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                    </div>
                                </Col>
                                <Col xs='6'>
                                        <div className="mb-3" >
                                            <Label htmlFor="downloadLink">
                                                Description 2 (Arabic)
                                            </Label>
                                            <input
                                                id="downloadLink"
                                                type="text"
                                                className="form-control input_cus"
                                                placeholder="عنوان"
                                                dir="rtl"
                                                {...register(`product[${index}].featureDescAr2`)}
                                            />
                                            <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                        </div>
                                </Col>
                            </Row>
                            {/* <hr style={{ margin: "15px -20px 20px -20px" }} /> */}


                            <hr style={{ margin: "15px -20px 20px -20px" }} />
                            <Row>
                                <Col xs='6'>
                                    <div className="mb-3" >
                                        <Label htmlFor="reviewLink">
                                            Title 3 (English)  
                                        </Label>
                                        <input
                                            id="reviewLink"
                                            type="text"
                                            className="form-control input_cus"
                                            placeholder="e.g. example.com"
                                            {...register(`product[${index}].featureTitleEn3`)}
                                        />
                                        <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                    </div>
                                </Col>
                                <Col xs='6'>
                                        <div className="mb-3" >
                                            <Label htmlFor="downloadLink">
                                                Title 3 (Arabic)  
                                            </Label>
                                            <input
                                                id="downloadLink"
                                                type="text"
                                                placeholder="عنوان"
                                                dir="rtl"
                                                className="form-control input_cus"
                                                {...register(`product[${index}].featureTitleAr3`)}
                                            />
                                            <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                        </div>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col xs='6'>
                                    <div className="mb-3" >
                                        <Label htmlFor="reviewLink">
                                            Description 3 (English)
                                        </Label>
                                        <input
                                            id="reviewLink"
                                            type="text"
                                            className="form-control input_cus"
                                            placeholder="e.g. example.com"
                                            {...register(`product[${index}].featureDescEn3`)}
                                        />
                                        <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                    </div>
                                </Col>
                                <Col xs='6'>
                                        <div className="mb-3" >
                                            <Label htmlFor="downloadLink">
                                                Description 3 (Arabic)
                                            </Label>
                                            <input
                                                id="downloadLink"
                                                type="text"
                                                className="form-control input_cus"
                                                placeholder="عنوان"
                                                dir="rtl"
                                                {...register(`product[${index}].featureDescAr3`)}
                                            />
                                            <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>
                                        </div>
                                </Col>
                            </Row>
                            <hr style={{ margin: "15px -20px 20px -20px" }} />

{/* 
                            <Row className='mb-3'>
                                <Col>
                                
                                <div className="d-flex justify-content-between">
                                    <Label className="fs-5">images</Label>
                                </div>
                                <Form className=" pb-0 border-0">
                                    <Controller 
                                    control={control}
                                    name={`product[${index}].images`}
                                    render={({field: { onChange} })=><Dropzone
                                        onDrop={acceptedFiles=>{
                                                onChange(acceptedFiles)
                                                handleAcceptedFiles(acceptedFiles,index)
                                            }
                                        }
                                    >
                                    {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                        <div className="dz-message needsclick"
                                            {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <div className="dz-message needsclick">
                                                <div className="mb-3">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                </div>
                                                <h4>Drop files here or click to upload.</h4>
                                            </div>
                                        </div>
                                        </div>
                                    )}
                                    </Dropzone>
                                    }/>

                                    <div className={`dropzone-previews ${watch(`product[${index}].images`)!=0&&'mt-3'}`} id="file-previews">
                                        {(watch(`product[${index}].images`)||[]).map((blogImage, blogImageIndex) => {
                                            return (
                                            <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                key={blogImageIndex + "-file"}
                                            >
                                                <div className="p-2">
                                                    <Row className="align-items-center">
                                                        <Col className="col-auto">
                                                            <img
                                                                data-dz-thumbnail=""
                                                                height="80"
                                                                className="avatar-sm rounded bg-light"
                                                                alt={blogImage.name}
                                                                src={blogImage.preview}
                                                                style={{ objectFit: "cover" }}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Link to="#" className="text-muted ">
                                                                {blogImage.name}
                                                            </Link>
                                                            <p className="mb-0">
                                                                <strong>{blogImage.formattedSize}</strong>
                                                            </p>
                                                        </Col>
                                                        <Col>
                                                            <div className="image__options">
                                                                <button
                                                                    type='button'
                                                                    className="p-0"
                                                                    onClick={e => prevSelectImage(e, blogImage.preview)}
                                                                >
                                                                    <i className="far fa-eye  fs-5 p-0"/>
                                                                </button>
                                                                <button
                                                                    type='button'
                                                                    style={{ color: "#ff3838" }}
                                                                    onClick={e => deleteSelectImage(index,blogImageIndex)}
                                                                >
                                                                    <i className="bx bx-trash fs-4"/>
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Card>
                                            )
                                        })}
                                    </div>
                                </Form>
                                </Col>
                            </Row> */}


                            {/* <ProductsCardParagraph {...{ control, register ,appendDataPara ,getValues ,watch ,setValue ,index}}/> */}

                            {/* <>
                                <hr style={{ margin: "5px -20px 20px -20px" }} />
                                <Button
                                    onClick={()=>{setAppendDataPara(prevState=>!prevState)}}
                                    color="none"
                                    className="mt-2 fs-5 fw-bold p-0 text-left"
                                    style={btnStyle}
                                    >
                                    <i className="bx bx-plus-circle fs-4" /> <span>Add Paragraph</span>
                                </Button>
                                <hr style={{ margin: "20px -20px 0px -20px" }} />
                            </>
                             */}

                            <Row>
                                <Col className="d-flex justify-content-end gap-2 col pb-2 ">
                                    <div className="save__btn top m-0 me-3">
                                        <button onClick={(e) => submit(e,index)}>{active?<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />:"Save"}</button>
                                    </div>
                                    <div className="delete__btn top m-0">
                                        <button onClick={(e) => remove(index)}>{deleteLoad?<ReactLoading type={"spin"} color={'#ffffff'} height={30} width={30} />:"Delete Product"}</button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>    
                    </div>
                </Collapse>
            </div>     
        ))}

    </>
  )
}

export default ProductCard


const btnStyle = {
    boxShadow: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    paddingLeft: "0",
  }
  