/* eslint-disable react/prop-types */
import React, { useEffect,useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Collapse,
} from "reactstrap"
import { Link } from "react-router-dom"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import SweetAlert from "react-bootstrap-sweetalert"
import classNames from "classnames"
import HeaderContent from "../Components/Settings/HeaderContent"
import Section1 from "../Components/HomePage/Section1"
import Section2 from "../Components/HomePage/Section2"
import Section3 from "../Components/HomePage/Section3"
import Section4 from "../Components/HomePage/Section4"
import ContactFAQ from "../Components/Settings/ContactFAQ"
import OurLatestWork from "../Components/Settings/OurLatestWork"
import axios from "axios"


const Settings = () => {
  const [col, setCol] = useState([
    { id: 0, status: false },
    { id: 1, status: false },
    { id: 2, status: false },
    { id: 3, status: false },
    { id: 4, status: false },
    { id: 5, status: false },
    { id: 6, status: false },
    { id: 7, status: false },
  ])

  const [data, setData] = useState(null)

  function ChangeColState(id) {
    setCol(col =>
      col.map(col => {
        if (col.id === id) {
          return { ...col, status: !col.status }
        }
        return { ...col, status: false }
      })
    )
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/dashboard/etoy/global`, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
      },
    }).then(res => {
      setData(res.data.data);
    })
  }, [])

  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        <Card>
          <CardBody>
            <div className="accordion" id="accordion">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classNames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[1].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(1)}
                    style={{ cursor: "pointer" }}
                  >
                    EtoyApp Settings
                  </button>
                </h2>
                <Collapse isOpen={col[1].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <HeaderContent data={data?.appSetting}/>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classNames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[2].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(2)}
                    style={{ cursor: "pointer" }}
                  >
                    Faq
                  </button>
                </h2>
                <Collapse isOpen={col[2].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <ContactFAQ  data={data?.faq}/>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classNames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[3].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(3)}
                    style={{ cursor: "pointer" }}
                  >
                    Ads
                  </button>
                </h2>
                <Collapse isOpen={col[3].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <OurLatestWork data={data?.ads} />
                  </div>
                </Collapse>
              </div>
              {/* <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classNames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[2].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(2)}
                    style={{ cursor: "pointer" }}
                  >
                    Section (1)
                  </button>
                </h2>
                <Collapse isOpen={col[2].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <Section1 />
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classNames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[3].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(3)}
                    style={{ cursor: "pointer" }}
                  >
                    Section (2)
                  </button>
                </h2>
                <Collapse isOpen={col[3].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <Section2 />
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classNames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[4].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(4)}
                    style={{ cursor: "pointer" }}
                  >
                   Section (3)
                  </button>
                </h2>
                <Collapse isOpen={col[4].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <Section3 />
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classNames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[5].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(5)}
                    style={{ cursor: "pointer" }}
                  >
                    Section (4)
                  </button>
                </h2>
                <Collapse isOpen={col[5].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <Section4 />
                  </div>
                </Collapse>
              </div> */}
              
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default Settings

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "green",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}
