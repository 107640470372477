import React, { Fragment, useState, useRef, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
} from "reactstrap"
import Select from "react-select"

import {toast} from "react-toastify"

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import {useHistory} from 'react-router-dom'
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
import ReactLoading from "react-loading"
 
// Be sure to include styles at some point, probably during your bootstrapping
import 'react-select-plus/dist/react-select-plus.css';
import { useSelector } from "react-redux";
import TreePicker from 'rsuite/TreePicker';
import 'rsuite/dist/rsuite.min.css';
import prettyBytes from "pretty-bytes";
import SweetAlert from "react-bootstrap-sweetalert";
import axiosConfig from "axiosConfig";
import Multiselect from "multiselect-react-dropdown";
import { SelectPicker } from "rsuite";
import { logDOM } from "@testing-library/react";
import './AddInnerPageResource.scss'

import 'rsuite/dist/rsuite.min.css';



const EditInnerPageResource = () => {
  const queryParameters = new URLSearchParams(window.location.search)
  const id = queryParameters.get("id")

  const history = useHistory();


  const [isLoading, setIsLoading] =  useState(false)
  const [category, setCategory] =  useState('')
  const [titleEn, setTitleEn] =  useState('')
  const [titleAr, setTitleAr] =  useState('')
  const [disabledNum, setDisabledNum] = useState([])
  const [name_en, setNameEn] = useState("")
  const [name_ar, setNameAr] = useState("")
  const [descEn, setDescEn] = useState("")
  const [descAr, setDescAr] = useState("")
  const [key, setKey] = useState(null)
  const [link, setLink] = useState("")
  const [file, setFile] = useState("")
  const [subCategory, setSubCategory] = useState("")
  const [loading, setLoading] = useState(false)
  const [facebookImage, setFacebookImage] = useState(null)
  const [isOpen, setisOpen] = useState(false)
  const [prev, setPrev] = useState(null)
  const [categories, setCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [color, setColor] = useState('#ffffff')
  const [tags, setTags] = useState([])
  const [tag, setTag] = useState(null)
  const [page, setPage] = useState('')




  

  
  const [categoriess,setCateogriess]=useState([])
  //inputs Ref
  const titleEnRef = useRef(null)
  const titleArRef = useRef(null)
  const activeRef = useRef(null)
  const categoryRef = useRef(null)
  const [success_msg, setsuccess_msg] = useState(false)
  const [msg, setMsg] = useState("")



  function handleFacebookFiles(e, files) {
    ;[...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )
    setFacebookImage(Object.values(files)[0])
    e.target.value = ""
  }



  


  function prevSelectImage(e, img) {
    e.preventDefault()
    setPrev(img)
    setisOpen(true)
  }

  
  
  function submitHandler () {
    setIsLoading(true)
    let formData = new FormData()    
  
    
    
    let data = {
        id:id,
        titleEn: name_en,
        titleAr: name_ar,
        descriptionEn: descEn,
        descriptionAr: descAr,
        link : link,
        file : file,
        color:color,
        tag,
        page
    }
    for (let key in data) {
      formData.append(key, data[key])
    }
    formData.append("image", facebookImage)

    selectedCategories.forEach((category,i) => {
      formData.append(`categories[${i}][category]`, category.value.id)
      formData.append(`categories[${i}][status]`, category.value.status)
  })
      
   
    axios
      .post(`${process.env.REACT_APP_API}/dashboard/resources/innerPage/update`,formData, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
          "Content-Type": "application/json",
        },
      })
      .then(res => {
        setIsLoading(false)
        toast.success('Page Added Success')
        history.goBack()

    }).catch((err)=>{
      setLoading(false)
    })
  }


  
  



  
  

  let getCategories=()=>{
    axiosConfig
      .get(`dashboard/resources/innerPage/newCategories`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setCategories(res.data.data)
    }).catch(err=>{
    })
  }

  
  
  let getTags=()=>{
    axiosConfig
      .get(`/dashboard/configurations/resourceTags`, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        setTags(res.data.data)
      }).catch(err=>{
      })
  }

 

  
  
  useEffect(() => {
    getCategories()
    getTags()
  },[])


  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API}/dashboard/resources/innerPage/find`,{id:id}, {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("authUser")).accessToken
          }`,
        },
      })
      .then(res => {
        
        setNameEn(res.data.data.titleEn || "")
        setNameAr(res.data.data.titleAr || "")
        setDescAr(res.data.data.descriptionAr || "")
        setDescEn(res.data.data.descriptionEn || "")
        setFile(res.data.data.file || "")
        setLink(res.data.data.link || "")
        setFacebookImage(res.data.data.image)
        // setCategory(res.data.data.category)
        setColor(res.data.data.color)
        setTag(res.data.data.tag.id)
        setPage(res.data.data.page)
        // setCategoryID(res.data.data.category.nameEn)
        // 
        setSelectedCategories(res.data.data.categories.map(c => ({label: `${c.nameEn} - ${c.status}` , value: c})))
    }).catch((err)=>{
      setLoading(false)
    })
}, [])
  

  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add Project Details | Layouti</title>
        </MetaTags>
        <Container fluid>
            {isOpen ? (
                <Lightbox
                    mainSrc={prev}
                    enableZoom={false}
                    onCloseRequest={() => {
                        setisOpen(!isOpen)
                    }}
                />
            ) : null}
        
           {success_msg ? (
                <SweetAlert
                  title={msg}
                  success
                  confirmBtnBsStyle="success"
                  onConfirm={() => {
                    setsuccess_msg(false)
                  }}
                  onCancel={() => {
                    setsuccess_msg(false)
                  }}
                >
                </SweetAlert>
               ) : null}
          {/* <Row>
          
            <Col xs="12"> */}
              <Card>
                <CardBody className="p-0">
                  <h2 className="head__title fs-4 pl-4 pt-3 mb-2" style={{color: "#000", fontSize: "24px",fontWeight: "700 !important", marginLeft: "20px"}}><i className="dripicons-arrow-thin-left fs-5 fw-bold" style={{cursor: "pointer"}} onClick={() => history.goBack()}></i>Edit Resources</h2>
                 
                      <form className="p-4 pb-0" onSubmit={(e) => submitHandler(e)}>
                      <Row  className="mb-3"> 
                            <Col >
                                <Label htmlFor="productdesc " className="req">
                                    Page Type
                                </Label>
                                <select className="form-control input_cus" value={page} onChange={(e) => setPage(e.target.value)}>
                                  <option value={""} disabled>Select</option>
                                  <option value={1}>Layouti Resources</option>
                                  <option value={2}>Resources</option>
                                </select>
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>                          
                            </Col>      
                          </Row>    
                      {categories ? 
                          <Row  className="mb-3"> 
                            <Col >
                                <Label htmlFor="productdesc " className="req">
                                    Category with Status
                                </Label>
                                <Select
                                    isMulti={true}
                                    name="categories"
                                    value={selectedCategories}
                                    closeMenuOnSelect={false}
                                    onChange={e => setSelectedCategories(e)}
                                    options={categories?.map(c => {
                                        return {
                                            label: `${c.nameEn} - ${c.status}`, value:c
                                          }

                                        }).flat()}
                                    className="select2-selection"
                                />
                                <FormFeedback className="fs-6">This Fileld Is Required!</FormFeedback>                          
                            </Col>      
                          </Row>
                          :''
                        }

                         <Row className="mt-3">
                            <Col xs="12">
                            <div>
                                <Label htmlFor="productdesc">Attachement Image</Label>
                                <Input
                                type="file"
                                className="form-control form-control-lg "
                                name="attachmentImage"
                                onChange={e => handleFacebookFiles(e, e.target.files)}
                                style={{ borderRadius: 0 }}
                                // multiple
                                />
                            </div>
                            </Col>
                            <Col xs="12">
                            <div className="dropzone-previews" id="file-previews">
                                {facebookImage ? (
                                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                    <div className="p-2">
                                    <Row className="align-items-center">
                                        <Col className="col-auto">
                                        <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={facebookImage.name}
                                            src={
                                            facebookImage.preview
                                                ? facebookImage.preview
                                                : facebookImage
                                            }
                                            style={{ objectFit: "cover" }}
                                        />
                                        </Col>
                                        <Col>
                                        <Link to="#" className="text-muted ">
                                            {facebookImage.name}
                                        </Link>
                                        </Col>
                                        <Col>
                                        <div className="image__options">
                                            <button
                                            className="p-0"
                                            onClick={e =>
                                                prevSelectImage(
                                                e,
                                                facebookImage.preview
                                                    ? facebookImage.preview
                                                    : facebookImage
                                                )
                                            }
                                            >
                                            <i className="far fa-eye  fs-5 p-0"></i>
                                            </button>
                                            
                                        </div>
                                        </Col>
                                    </Row>
                                    </div>
                                </Card>
                                ) : null}
                            </div>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col className="col-6">
                            <div className="mb-3">
                                <Label htmlFor="productdesc">Title (English)</Label>
                                <Input
                                name="titleEn"
                                type="text"
                                className="form-control input_cus"
                                placeholder="e.g.Name"
                                value={name_en}
                                onChange={e => setNameEn(e.target.value)}
                                />
                                <FormFeedback className="fs-6">
                                This Fileld Is Required!
                                </FormFeedback>
                            </div>
                            </Col>
                        
                            <Col className="col-6">
                            <div className="mb-3">
                                <Label htmlFor="productdesc">Title (Arabic)</Label>
                                <Input
                                name="titleAr"
                                type="text"
                                className="form-control input_cus"
                                placeholder="مثال. الاسم"
                                dir="rtl"
                                value={name_ar}
                                onChange={e => setNameAr(e.target.value)}
                                />
                                <FormFeedback className="fs-6">
                                This Fileld Is Required!
                                </FormFeedback>
                            </div>
                            </Col>
                        </Row>

                        <Row className="mt-3 ">
                            <Col sm="6">
                            <div>
                                <div className="d-flex">
                                  <Label htmlFor="productdesc">Description (English)</Label>
                                  <label className="ms-auto optional-label--color">
                                    (Max 125 Char)
                                  </label>
                                </div>
                                <textarea
                                className="form-control  mb-0"
                                id="specification"
                                rows="5"
                                placeholder="Enter your description here"
                                value={descEn}
                                onChange={e => setDescEn(e.target.value)}
                                maxLength="110"
                                />
                            </div>
                            </Col>
                            <Col sm="6" >
                            <div>
                              <div className="d-flex">
                                <Label htmlFor="productdesc">Description (Arabic)</Label>
                                <label className="ms-auto optional-label--color">
                                  (Max 125 Char)
                                </label>
                              </div>
                                <textarea
                                className="form-control  mb-0"
                                id="specification"
                                rows="5"
                                placeholder="اكتب وصف هنا"
                                value={descAr}
                                onChange={e => setDescAr(e.target.value)}
                                maxLength="110"
                                dir="rtl"
                                />
                            </div>
                            </Col>
                        </Row>

                        <Row className="mt-3 mb-4">
                            <Col>
                            <div>
                                <Label htmlFor="productdesc">Website Link</Label>
                                <Input
                                className="form-control  mb-0"
                                id="specification"
                                rows="5"
                                name="link"
                                placeholder="Enter Website Link"
                                value={link}
                                onChange={e => setLink(e.target.value)}
                                />
                            </div>
                            </Col>
                        
                            <Col>
                            <div>
                                <Label htmlFor="productdesc">Design Link</Label>
                                <Input
                                className="form-control  mb-0"
                                id="specification"
                                rows="5"
                                name="link"
                                placeholder="Enter Design Link"
                                value={file}
                                onChange={e => setFile(e.target.value)}
                                />
                            </div>
                            </Col>
                            
                        </Row>
                        <Row className="mb-4 mt-3">
                          <Col>
                            <Row className="d-flex  w-fill-available">
                                    <Col xs="12" className="d-flex flex-column">
                                    <Label className="fs-5 w-fit-content">Color</Label>
                                    <div className="d-flex w-100">
                                        <Input 
                                            type="color"
                                            name='color'
                                            value={color}
                                            onChange={e => setColor(e.target.value)}
                                            style={{width: "70px", boxShadow: "none"}}
                                            required
                                            />
                                        <Input 
                                        type="text" 
                                        className="color__name"
                                        value={color}
                                        onChange={e => setColor(e.target.value)}
                                        />
                                    </div>
                                    </Col>
                              </Row>
                          </Col>
                          <Col className="d-flex flex-column">
                              <Label className="fs-5 req w-fit-content">Tag Filter Resources</Label>
                              <SelectPicker
                              value={tag}
                              placement="topStart"
                              onChange={e => setTag(e)}
                              data={tags.map(tag =>({label: <div className="d-flex align-items-center"><span className='me-2' style={{backgroundColor: tag.color, width: '25px',
                              height: '25px',
                              borderRadius: '50%',
                              border: "1px solid #ccc",
                              marginRight: "15px",
                              display: 'inline-block'}}></span><span className="fs-5">{tag.titleEn}</span></div>, value:tag.id}))}
                              className="select2-selection"
                            />
                            </Col>
                        </Row>

                     

                        
                      </form>
                   
                </CardBody>
                
              </Card>
            {/* </Col>
          </Row> */}
          
        </Container>
      </div>
      <Row className="submit__btn footer fixed fixed-bottom">
                <Col lg={10} md={10} className='d-flex'>
                <button type='button' className="cancel mr-3" onClick={() => history.goBack()}>
                    cancel
                </button>
                {" "}
                
                <button type='button' className='add-project__submit-button' onClick={(e) => submitHandler(e)}>
                    {isLoading ? (
                    <ReactLoading
                        type={"spin"}
                        color={"#ffffff"}
                        height={30}
                        width={30}
                    />
                    ) : (
                    "Save"
                    )}
                </button>
                </Col>
          </Row>
    </React.Fragment>
  )
}

export default EditInnerPageResource


const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0"
}




