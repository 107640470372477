import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import { Controller, useFieldArray } from "react-hook-form"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  FormFeedback,
  Label,
  Row,
  Input,
} from "reactstrap"
import prettyBytes from "pretty-bytes"
import Lightbox from "react-image-lightbox"

function BlogCardParagraph({
  paragraphData,
  index,
  handleChangeBlogParaData,
  deleteCardParagraph,
  deleteImagePara,
}) {
  const [isOpen, setisOpen] = useState(false)
  const [photoIndex, setphotoIndex] = useState(0)

  function handleAcceptedFiles(files, paraIndex) {
    
    
    let oldImages = [...paragraphData[paraIndex]["cards"]]
    
    files = [...files].map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: prettyBytes(file.size),
      })
    )

    handleChangeBlogParaData(
      "cards",
      [...oldImages, ...files],
      index,
      paraIndex
    )
    
    
  }

  // function deleteSelectImage(paraId,imageId) {
  //     let images = getValues(`blog[${index}].paragraph[${paraId}].cards`)
  //     images.splice(imageId,1)
  //     setValue(`blog[${index}].paragraph[${paraId}].cards`,images)
  // }

  function prevSelectImage(e, id) {
    e.preventDefault()
    setphotoIndex(id)
    setisOpen(!isOpen)
  }

  return (
    <>
      {isOpen ? (
        <Lightbox
          mainSrc={photoIndex}
          enableZoom={false}
          onCloseRequest={() => {
            setisOpen(!isOpen)
          }}
        />
      ) : null}
      {paragraphData &&
        paragraphData.map((para, paraIndex) => (
          <>
            <hr style={{ margin: "5px -20px 20px -20px" }} />
            <Row className="mb-3" key={para.id}>
              <Col>
                <h3 className="fs-4 text-black fw-bolder mb-3">Paragraph</h3>
                <Form className=" pb-0 border-0">
                  <Input
                    type="file"
                    className="form-control form-control-lg "
                    onChange={e =>
                      handleAcceptedFiles(e.target.files, paraIndex)
                    }
                    style={{ borderRadius: 0 }}
                    multiple
                  />
                  {/* <Dropzone
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles(acceptedFiles, paraIndex)
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="dz-message needsclick">
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone> */}

                  <div
                    className={`dropzone-previews ${
                      para.cards.length != 0 && "mt-3"
                    }`}
                    id="file-previews"
                  >
                    {para.cards &&
                      para.cards.map((blogParaImage, blogParaImageIndex) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={blogParaImageIndex + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={blogParaImage?.name}
                                    src={
                                      blogParaImage?.preview
                                        ? blogParaImage?.preview
                                        : blogParaImage.image
                                    }
                                    style={{ objectFit: "cover" }}
                                  />
                                </Col>
                                <Col>
                                  <Link to="#" className="text-muted ">
                                    {blogParaImage?.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>
                                      {blogParaImage?.formattedSize}
                                    </strong>
                                  </p>
                                </Col>
                                <Col>
                                  <div className="image__options">
                                    <button
                                      type="button"
                                      className="p-0"
                                      onClick={e =>
                                        prevSelectImage(
                                          e,
                                          blogParaImage?.preview
                                            ? blogParaImage?.preview
                                            : blogParaImage
                                        )
                                      }
                                    >
                                      <i className="far fa-eye  fs-5 p-0" />
                                    </button>
                                    <button
                                      type="button"
                                      style={{ color: "#ff3838" }}
                                      onClick={e =>
                                        deleteImagePara(
                                          blogParaImage?.id ? "old" : "new",
                                          blogParaImage?.id
                                            ? blogParaImage?.id
                                            : blogParaImageIndex,
                                          index,
                                          paraIndex
                                        )
                                      }
                                    >
                                      <i className="bx bx-trash fs-4" />
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        )
                      })}
                  </div>
                </Form>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs="6">
                <div className="mb-3">
                  <Label htmlFor="paraTitleEn">Title English</Label>
                  <input
                    id="paraTitleEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. example.com"
                    name="titleEn"
                    value={para?.titleEn}
                    onChange={e => {
                      handleChangeBlogParaData(
                        e.target.name,
                        e.target.value,
                        index,
                        paraIndex
                      )
                    }}
                  />
                  <FormFeedback className="fs-6">
                    This Fileld Is Required!
                  </FormFeedback>
                </div>
              </Col>

              <Col xs="6">
                <div className="mb-3">
                  <Label htmlFor="paraTitleAr">Title Arabic</Label>
                  <input
                    id="paraTitleAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. example.com"
                    name="titleAr"
                    value={para?.titleAr}
                    onChange={e => {
                      handleChangeBlogParaData(
                        e.target.name,
                        e.target.value,
                        index,
                        paraIndex
                      )
                    }}
                  />
                  <FormFeedback className="fs-6">
                    This Fileld Is Required!
                  </FormFeedback>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs="6">
                <div className="mb-3">
                  <Label htmlFor="paraDescriptionEn">Description English</Label>

                  <textarea
                    className="form-control  mb-0"
                    id="paraDescriptionEn"
                    rows="5"
                    placeholder="Enter your description here"
                    name="descriptionEn"
                    value={para?.descriptionEn}
                    onChange={e => {
                      handleChangeBlogParaData(
                        e.target.name,
                        e.target.value,
                        index,
                        paraIndex
                      )
                    }}
                  />
                  <FormFeedback className="fs-6">
                    This Fileld Is Required!
                  </FormFeedback>
                </div>
              </Col>

              <Col xs="6">
                <div className="mb-3">
                  <Label htmlFor="paraDescriptionAr">Description Arabic</Label>
                  <textarea
                    className="form-control  mb-0"
                    id="specification"
                    rows="5"
                    placeholder="Enter your description here"
                    name="descriptionAr"
                    value={para?.descriptionAr}
                    onChange={e => {
                      handleChangeBlogParaData(
                        e.target.name,
                        e.target.value,
                        index,
                        paraIndex
                      )
                    }}
                  />
                  <FormFeedback className="fs-6">
                    This Fileld Is Required!
                  </FormFeedback>
                </div>
              </Col>
            </Row>

            <Button
              color="none"
              variant="primary"
              className="btn-block fs-5  inner cursor-pointer  outline-light d-flex align-items-center justify-content-center text-center p-0"
              id="unknown-btn"
              style={{
                color: "#ff3838",
                boxShadow: "none",
                height: "45px",
                borderRadius: 0,
              }}
              onClick={e => {
                deleteCardParagraph(
                  para?.status == "new" ? "new" : "old",
                  index,
                  para.id
                )
              }}
            >
              <i className="bx bx-trash-alt fs-4 p-0 mr-1" /> Delete
            </Button>
          </>
        ))}
    </>
  )
}

export default BlogCardParagraph

const btnStyle = {
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  paddingLeft: "0",
}
