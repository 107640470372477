import React, { useState } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Input,
    FormFeedback,
    Collapse, Button
  } from "reactstrap"
  import ReactLoading from "react-loading"
import { Link } from "react-router-dom"
import { useEffect } from "react"
import prettyBytes from "pretty-bytes"
import Lightbox from "react-image-lightbox"

const SeoComponent = (props) => {
  const [active, setActive] = useState(false)
  const [isOpen, setisOpen] = useState(false)
  const [photoIndex, setphotoIndex] = useState(0)
  const [seoData,setSeoData]=useState({
    focusKeypharseEn:'',
    focusKeypharseAr:'',
    seoTitleEn:'',
    seoTitleAr:'',
    descriptionEn:'',
    descriptionAr:'',
    slugEn:'',
    slugAr:'',
    facebookTitleEn:'',
    facebookTitleAr:'',
    facebookDescriptionEn:'',
    facebookDescriptionAr:'',
    facebookImage:null,
  })
  function handleChangeData(keyName,keyValue){
    setSeoData(prevValue=>{
      return{
        ...prevValue,
        [keyName]:keyValue
      }
    })
  }

  function handleFiles(e) {
    let acceptedFiles =e.target.files;
    [...acceptedFiles].map(file =>
        Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: prettyBytes(file.size)
        })
    )
    handleChangeData('facebookImage',Object.values(acceptedFiles)[0])
    e.target.value = '';
  }
  function deleteImage(){
    handleChangeData('image',null)
  }
  function prevSelectImage(e, id) {
    e.preventDefault()
    setphotoIndex(id)
    setisOpen(!isOpen)
  }
  // useEffect(()=>{
  //   seoData && props?.setSeoData(seoData)
  // },[seoData])
  useEffect(()=>{
    props.updatedSeoData&& setSeoData(updatedSeoData)
  },[props.updatedSeoData])
  return (
    <div>
      {isOpen ? (
            <Lightbox
              mainSrc={photoIndex}
              enableZoom={false}
              onCloseRequest={() => {
                setisOpen(!isOpen)
              }}
            />
          ) : null}
      <Card>
        <CardBody>
          <Form className="border-0 pb-2">
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Focus Keypharse (english)</Label>
                  <Input
                    id="itemnameen"
                    name="focusKeypharseEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Focus Keypharse"
                    value={seoData?.focusKeypharseEn}
                    onChange={e => handleChangeData(e.target.name,e.target.value)}
                  />
                </div>
              </Col>

              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Focus Keypharse (arabic)</Label>
                  <Input
                    id="itemnamear"
                    name="focusKeypharseAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. "
                    value={seoData?.focusKeypharseAr}
                    onChange={e => handleChangeData(e.target.name,e.target.value)}
                    dir="rtl"
                    lang="ar"
                  />
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Seo Title (english)</Label>
                  <Input
                    id="brandnameen"
                    name="seoTitleEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Title"
                    value={seoData?.seoTitleEn}
                    onChange={e => handleChangeData(e.target.name,e.target.value)}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Seo Title (arabic)</Label>
                  <Input
                    id="brandnamear"
                    name="seoTitleAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. عنوان"
                    value={seoData?.seoTitleAr}
                    onChange={e => handleChangeData(e.target.name,e.target.value)}
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Slug (english)</Label>
                  <Input
                    id="brandnameen"
                    name="slugEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Slug"
                    value={seoData?.slugEn}
                    onChange={e => handleChangeData(e.target.name,e.target.value)}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Slug (arabic)</Label>
                  <Input
                    id="brandnamear"
                    name="slugAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. وصف"
                    value={seoData?.slugAr}
                    onChange={e => handleChangeData(e.target.name,e.target.value)}
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (English)</Label>
                  <textarea
                    className="form-control  mb-0"
                    name="descriptionEn"
                    id="specification"
                    rows="5"
                    placeholder="Enter your description here"
                    value={seoData?.descriptionEn}
                    onChange={e => handleChangeData(e.target.name,e.target.value)}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Description (Arabic)</Label>
                  <textarea
                    className="form-control  mb-0"
                    name="descriptionAr"
                    id="specification"
                    rows="5"
                    placeholder="اكتب وصف هنا"
                    value={seoData?.descriptionAr}
                    onChange={e => handleChangeData(e.target.name,e.target.value)}
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <div className="mt-3">
                  <Label htmlFor="productdesc">Facebook Image</Label>
                  <Input
                    type="file"
                    className="form-control form-control-lg "
                    onChange={e => handleFiles(e, e.target.files)}
                    style={{ borderRadius: 0 }}
                    multiple
                  />
                </div>
              </Col>
              <Col xs="12">
                <div className="dropzone-previews" id="file-previews">
                  {seoData?.facebookImage ? (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={seoData?.facebookImage.name}
                              src={seoData?.facebookImage.preview?seoData?.facebookImage.preview:seoData?.facebookImage}
                              style={{ objectFit: "cover" }}
                            />
                          </Col>
                          <Col>
                            <Link to="#" className="text-muted ">
                              {seoData?.facebookImage.name}
                            </Link>
                          </Col>
                          <Col>
                            <div className="image__options">
                              <button
                                className="p-0"
                                onClick={e =>
                                  prevSelectImage(e, seoData?.facebookImage.preview?seoData?.facebookImage.preview:seoData?.facebookImage)
                                }
                              >
                                <i className="far fa-eye  fs-5 p-0"></i>
                              </button>
                              <button
                                style={{ color: "#ff3838" }}
                                onClick={e => deleteImage()}
                              >
                                <i className="bx bx-trash fs-4"></i>
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Facebook Title (english)</Label>
                  <Input
                    id="brandnameen"
                    name="seoFaceTitleEn"
                    type="text"
                    className="form-control input_cus"
                    placeholder="e.g. Title"
                    value={seoData?.seoFaceTitleEn}
                    onChange={e => handleChangeData(e.target.name,e.target.value)}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Facebook Title (arabic)</Label>
                  <Input
                    id="brandnamear"
                    name="seoFaceTitleAr"
                    type="text"
                    className="form-control input_cus"
                    placeholder="مثال. عنوان"
                    value={seoData?.seoFaceTitleAr}
                    onChange={e => handleChangeData(e.target.name,e.target.value)}
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">
                    Facebook Description (English)
                  </Label>
                  <textarea
                    className="form-control  mb-0"
                    name="seoFaceDescEn"
                    id="specification"
                    rows="5"
                    placeholder="Enter your description here"
                    value={seoData?.seoFaceDescEn}
                    onChange={e => handleChangeData(e.target.name,e.target.value)}
                  />
                </div>
              </Col>
              <Col sm="6">
                <div>
                  <Label htmlFor="productdesc">Facebook Description (Arabic)</Label>
                  <textarea
                    className="form-control mb-0"
                    name="seoFaceDescAr"
                    id="specification"
                    rows="5"
                    placeholder="اكتب وصف هنا"
                    value={seoData?.seoFaceDescAr}
                    onChange={e => handleChangeData(e.target.name,e.target.value)}
                    dir="rtl"
                  />
                </div>
              </Col>
            </Row>
            <hr style={{ margin: "20px -20px 20px -20px" }} />

            <Row>
              <Col className="d-flex justify-content-end gap-2">
                <div className="save__btn top m-0 mt-3">
                  <button onClick={e => submit(e, key)}>
                    {active ? (
                      <ReactLoading
                        type={"spin"}
                        color={"#ffffff"}
                        height={30}
                        width={30}
                      />
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}

export default SeoComponent
