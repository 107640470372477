/* eslint-disable react/prop-types */
import React, { useEffect,useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Collapse,
} from "reactstrap"
import "react-image-lightbox/style.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import classNames from "classnames"
import HeaderContent from "../Components/HomePage/HeaderContent"
import Section1 from "../Components/HomePage/Section1"
import Section2 from "../Components/HomePage/Section2"
import Section3 from "../Components/HomePage/Section3"
import Section4 from "../Components/HomePage/Section4"
import axios from "axios"
import Section5 from "../Components/HomePage/Section5"


const HomePage = () => {
  const [col, setCol] = useState([
    { id: 0, status: false },
    { id: 1, status: false },
    { id: 2, status: false },
    { id: 3, status: false },
    { id: 4, status: false },
    { id: 5, status: false },
    { id: 6, status: false },
    { id: 7, status: false },
    { id: 8, status: false },
    
  ])

  const [data, setData] = useState(null)


  function ChangeColState(id) {
    setCol(col =>
      col.map(col => {
        if (col.id === id) {
          return { ...col, status: !col.status }
        }
        return { ...col, status: false }
      })
    )
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/dashboard/etoy/homePage`, {
      headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage.getItem("authUser")).accessToken}`,
      },
    }).then(res => {
      setData(res.data.data);
    })
  }, [])
  
  function prevSelectImage(e, prev) {
    e.preventDefault()
    setPrev(prev)
    setisOpen(!isOpen)
  }
  


  return (
    <React.Fragment>
      <div className="container-fluid p-0">
        <Card>
          <CardBody>
            <div className="accordion" id="accordion">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classNames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[1].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(1)}
                    style={{ cursor: "pointer" }}
                  >
                    Header Content
                  </button>
                </h2>

                <Collapse isOpen={col[1].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <HeaderContent data={data?.headerContent} />
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classNames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[2].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(2)}
                    style={{ cursor: "pointer" }}
                  >
                    Section (1)
                  </button>
                </h2>
                <Collapse isOpen={col[2].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <Section1 data={data?.sectionOne}/>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classNames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[3].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(3)}
                    style={{ cursor: "pointer" }}
                  >
                    Section (2)
                  </button>
                </h2>
                <Collapse isOpen={col[3].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <Section2 data={data?.secitonTwo}/>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classNames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[4].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(4)}
                    style={{ cursor: "pointer" }}
                  >
                   Section (3)
                  </button>
                </h2>
                <Collapse isOpen={col[4].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <Section3 data={data?.sectionThree}/>
                  </div>
                </Collapse>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classNames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[5].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(5)}
                    style={{ cursor: "pointer" }}
                  >
                    Section (4)
                  </button>
                </h2>
                <Collapse isOpen={col[5].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <Section4 data={data?.secitonFour}/>
                  </div>
                </Collapse>
              </div>

              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classNames(
                      "accordion-button",
                      "fw-medium",
                      "fw-bold",
                      "fs-5",
                      { collapsed: !col[8].status }
                    )}
                    type="button"
                    onClick={() => ChangeColState(8)}
                    style={{ cursor: "pointer" }}
                  >
                   SEO
                  </button>
                </h2>
                <Collapse isOpen={col[8].status} className="accordion-collapse">
                  <div className="accordion-body">
                    <Section5 data={data?.seo}/>
                  </div>
                </Collapse>
              </div>
              
            </div>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default HomePage

const deleteBtn = {
  background: "transparent",
  border: "none",
  color: "#D91414",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}

const editBtn = {
  background: "transparent",
  border: "none",
  color: "green",
  display: "flex",
  alignItems: "center",
  gap: "5px",
}
